define("digiprax-web/controllers/auth/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('errorMsgMap', {});
        if (!this.isValid()) return false;
        this.application.resetPassword(this.get('model.password'), this.get('model.password_confirmation'), this.get('model.confirmationToken'), function (error) {
          if (error == null) _this.transitionToRoute('auth.login');else {
            var errorMsg = JSON.stringify(error);

            _this.get('flashMessages').error(errorMsg, {
              timeout: 5000
            });
          }
        });
      }
    },
    isValid: function isValid() {
      var _this2 = this;

      var errors = ['password', 'password_confirmation'].filter(function (e) {
        return [null, '', false].indexOf(_this2.model.get(e) || null) >= 0;
      });

      if (errors.length >= 1) {
        var msgMap = this.msgMap();
        this.set('errorMsgMap', [{}].concat(errors).reduce(function (acc, e) {
          acc[e] = msgMap[e + ': can\'t be blank'];
          return acc;
        }));
      } else {
        errors = ['password'].filter(function (e) {
          return _this2.model.get(e).length <= 5;
        });

        if (errors.length >= 1) {
          var _msgMap = this.msgMap();

          this.set('errorMsgMap', [{}].concat(errors).reduce(function (acc, e) {
            acc[e] = _msgMap[e + ': should be at least %{count} character(s)'];
            return acc;
          }));
        }
      }

      return Object.keys(this.errorMsgMap || {}).length == 0;
    },
    msgMap: function msgMap() {
      return {
        'password: can\'t be blank': 'Passwort muß angegeben werden',
        'password: should be at least %{count} character(s)': 'Passwort muß aus mindestens 6 Zeichen bestehen',
        'password_confirmation: can\'t be blank': 'Passwortbestätigung muss übereinstimmen',
        'password_confirmation: does not match confirmation': 'Passwortbestätigung muss übereinstimmen',
        'password_confirmation: can\'t be blank, does not match confirmation': 'Passwortbestätigung muss übereinstimmen',
        'password_confirmation: does not match confirmation, can\'t be blank': 'Passwortbestätigung muss übereinstimmen',
        'privacy: can\'t be blank': 'Datenschutzerklärung muß akzeptiert werden'
      };
    }
  });

  _exports.default = _default;
});