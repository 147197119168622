define("digiprax-web/helpers/format-time-int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTimeInt = formatTimeInt;
  _exports.default = void 0;

  function formatTimeInt(params, _hash) {
    var hour = parseInt(params[0] / 100);
    return (hour + ':' + (params[0] - hour * 100) + '0').match(/[0-9]{1,2}:[0-9]{2}/)[0];
  }

  var _default = Ember.Helper.helper(formatTimeInt);

  _exports.default = _default;
});