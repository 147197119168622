define("digiprax-web/models/location-data-for-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { A } from '@ember/array';
  // // import ArrayProxy from '@ember/array/proxy';
  // import moment from 'moment';
  var _default = Ember.Object.extend({
    dateMom: null,
    // curWeekDay.clone(),
    slots: null,
    // A([])
    appointments: Ember.computed('slots.@each.appointmentPatients', function () {
      return [[]].concat(this.slots).reduce(function (acc, s) {
        return acc.concat(s.appointmentPatients);
      }).compact();
    })
  });

  _exports.default = _default;
});