define("digiprax-web/serializers/application", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { camelize } from '@ember/string';
  var _default = _emberData.default.RESTSerializer.extend({
    // normalize(modelClass, resourceHash) {
    //   modelClass.eachTransformedAttribute((name, type) => {
    //       if (type == 'date') {
    //         resourceHash.attributes[name] = new Date(resourceHash.attributes[name]);
    //       }
    //       resourceHash.attributes[camelize(name)] = resourceHash.attributes[name];
    //     });
    //   var data = {
    //     id: resourceHash.id,
    //     type: modelClass.modelName,
    //     attributes: resourceHash.attributes
    //   };
    //   return { data: data };
    // },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var modelName = primaryModelClass.modelName;
      var isList = !(payload.response == null || typeof payload.response.pop == 'undefined' || payload.response.pop == null);
      var data = {};

      if (isList) {
        data[(0, _emberInflector.pluralize)(modelName)] = payload.response;
      } else {
        if (requestType == 'updateRecord' && ['cashbook', 'journal'].indexOf(modelName) == -1) {
          data[modelName] = store.peekRecord(modelName, id).toJSON();
          data[modelName].id = id;
        } else if (requestType == 'deleteRecord') {// do nothing
        } else {
          data[modelName] = payload.response;

          if (payload.response.id == null) {
            data[modelName].id = id;
          } // if (requestType == 'createRecord') {
          //   id = payload.response.id;
          // }

        }
      } // console.log('applicationAdapter - normalizeResponse: data[id=' + id + '] = ' + JSON.stringify(data));


      return this._super(store, primaryModelClass, data, id, requestType);
    }
  });

  _exports.default = _default;
});