define("digiprax-web/templates/calendar/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TINKcKki",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showCalProgressBar\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"id\",\"progressbar_overlay\"],[8],[7,\"div\",true],[10,\"id\",\"progressbar_canvas\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"equal\",[[24,[\"timePeriod\"]],\"date\"],null]],null,{\"statements\":[[0,\"  \"],[15,\"calendar/menu-date\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[15,\"calendar/menu-week-or-month\",[]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[28,\"equal\",[[24,[\"timePeriod\"]],\"date\"],null]],null,{\"statements\":[[0,\"  \"],[15,\"calendar/day\",[]],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showTerminDialog\"]]],null,{\"statements\":[[0,\"    \"],[15,\"calendar/appointment-dialog\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"equal\",[[24,[\"timePeriod\"]],\"week\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"calendar-week\",null,[[\"kwSelector\",\"classNames\"],[[23,0,[]],\"layout-column flex\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"equal\",[[24,[\"timePeriod\"]],\"month\"],null]],null,{\"statements\":[[0,\"      \"],[15,\"calendar/month\",[]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "digiprax-web/templates/calendar/index.hbs"
    }
  });

  _exports.default = _default;
});