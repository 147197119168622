define("digiprax-web/routes/index", ["exports", "digiprax-web/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({// beforeModel(_transition) {
    //   var lastViewedPatientId = localStorage.getItem('lastViewedPatientId');
    //   if (lastViewedPatientId != null)
    //     return this.transitionTo('patients.edit.index', lastViewedPatientId);
    //   return this.transitionTo('patients.index');
    // }
  });

  _exports.default = _default;
});