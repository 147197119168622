define("digiprax-web/models/slot-appointment-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = Ember.Object.extend({
    patient: null,
    newPatientName: null,
    appointment: null,
    slot: null,
    editMode: Ember.computed.alias('appointment.editMode')
  });

  _exports.default = _default;
});