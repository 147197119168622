define("digiprax-web/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "moment"], function (_exports, _applicationRouteMixin, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      _moment.default.locale('de');
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this.controller.loadCurrentUser();
    },
    sessionInvalidated: function sessionInvalidated() {
      if (this.controller.get('currentUser') != null) this.controller.get('currentUser').unloadRecord();
      this.set('session.currentUser', null);
    }
  });

  _exports.default = _default;
});