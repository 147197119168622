define("digiprax-web/routes/calendar/index", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    practice: Ember.computed.alias('application.practice'),
    patients: Ember.computed.alias('application.patients'),
    model: function model(_params, _transition) {
      // this.application.set('showProgressBar', true);
      // if (this.controller != null) this.controller.showProgressbar();
      // return this.get('patients');
      return this._practiceAndPatientsPromise(Ember.RSVP.defer());
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (controller.timePeriod == 'week') controller.showProgressbar(); // controller.set('showProgressBar', true);
      // case reload ...

      controller.get('application').setActiveNavLink('linkTo.calendar');
    },
    _practiceAndPatientsPromise: function _practiceAndPatientsPromise(deferred) {
      var _this = this;

      var practice = this.get('practice');

      if (practice != null) {
        if (typeof practice.then == 'function') practice.then(function (_p) {
          return _this._resolvePatients(deferred);
        });else this._resolvePatients(deferred);
      } else {
        // practiceId not ready/set in application => currentUser not loaded
        if (!this.get('session').get('isAuthenticated')) {
          // this.showProgressbar();
          // document.location = '/auth/login?logout=true';
          return this.transitionTo('auth.login');
        } else {
          this.application.debugRemote('check missing user in CalendarIndex-Route - _practiceAndPatientsPromise ...');
          Ember.run.later(this, function () {
            deferred.resolve(this.get('patients'));
          }, 1000);
        }
      }

      return _emberData.default.PromiseObject.create({
        promise: deferred.promise
      });
    },
    _resolvePatients: function _resolvePatients(deferred) {
      var patients = this.get('patients');
      if (typeof patients.then == 'function') patients.then(function (pats) {
        return deferred.resolve(pats);
      });else deferred.resolve(patients);
    }
  });

  _exports.default = _default;
});