define("digiprax-web/serializers/account-summary", ["exports", "digiprax-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      Ember.run(function () {
        return store.unloadAll('entry');
      }); // payload.response = { entries: payload.response.map(e => {
      //                                 // e.notEditable = true;
      //                                 return store.push(store.serializerFor('entry').normalize(Entry, e));// { data: { type: 'entry', id: e.id, attributes: e } });
      //                               }) };

      store.pushPayload({
        entries: payload.response
      });
      payload.response = {
        entries: store.peekAll('entry').toArray()
      };
      return this._super(store, primaryModelClass, payload, id
      /*'current'*/
      , requestType);
    }
  });

  _exports.default = _default;
});