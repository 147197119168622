define("digiprax-web/helpers/starts-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // params[0] ... test
  // params[1] ... prefix
  // params[2] ... (optional) params[0].suffix, f.ex. ('1', '1.', '.')
  var _default = Ember.Helper.extend({
    compute: function compute(params, _hash) {
      if (params[0].pop) {
        // TODO array
        return false;
      } else {
        var test = String(params[0]);
        if (params[2] != null) test += params[2];
        return test.startsWith(params[1]);
      }
    }
  });

  _exports.default = _default;
});