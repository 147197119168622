define("digiprax-web/models/tariff-settlement", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    item: _emberData.default.attr('string'),
    settlementId: _emberData.default.attr('string'),
    settlementShortId: _emberData.default.attr('string'),
    mappingId: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    minimum: _emberData.default.attr('number'),
    'default': _emberData.default.attr('number'),
    maximum: _emberData.default.attr('number')
  });
  /*
   createdAt                 | timestamp with time zone | not null                                                                                                                                
   updatedAt                 | timestamp with time zone | not null                                                                                                                                
   deletedAt                 | timestamp with time zone |                                                                                                                                         
  */


  _exports.default = _default;
});