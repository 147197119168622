define("digiprax-web/controllers/practice/open-invoices", ["exports", "digiprax-web/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    actions: {
      editInvoice: function editInvoice(patientId, invoiceId, invoiceNumber) {
        var _this = this;

        // this.transitionToRoute((this.get('currentUser.plus') ? 'customers' : 'patients')+'.edit.invoices.edit', patientId, invoiceId);
        if (this.get('currentUser.plus') && !invoiceNumber.startsWith('G-')) {
          this.application.setApiDomain.perform('practice', function () {
            _this.transitionToRoute('patients.edit.invoices.edit', patientId, invoiceId);
          });
          return;
        }

        if (!this.get('currentUser.plus') && invoiceNumber.startsWith('G-')) {
          this.application.setApiDomain.perform('business', function () {
            _this.transitionToRoute('customers.edit.invoices.edit', patientId, invoiceId);
          });
          return;
        }

        this.transitionToRoute((this.get('currentUser.plus') ? 'customers' : 'patients') + '.edit.invoices.edit', patientId, invoiceId);
      }
    }
  });

  _exports.default = _default;
});