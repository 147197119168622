define("digiprax-web/routes/patients/edit/invoices/edit", ["exports", "digiprax-web/mixins/invoice-editor-route"], function (_exports, _invoiceEditorRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_invoiceEditorRoute.default, {
    beforeModel: function beforeModel(transition) {
      // console.log('from.patient_id = ' + (transition.from ? transition.from.parent.parent.params.patient_id : null) + ', to.patient_id = ' + transition.to.parent.parent.params.patient_id);
      if (transition.to.parent.parent.params.patient_id == null) return this.transitionTo('patients.overview');
      return this._super(transition);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get('patientId') != 'new') {
        localStorage.setItem('peru.lastViewedPatientId', '"' + model.get('patientId') + '"');
        localStorage.setItem('lastViewedPatientId', model.get('patientId'));
      }

      var patient = this.store.peekRecord('patient', model.get('patientId'));
      model.set('patient', patient);

      this._initInvoiceParams(model);
      /*let initNumNewTreatmentsDirty = */


      model.get('numNewTreatmentsDirty');
      /*let initIsDirty = */

      model.get('isDirty');
      controller.set('actions.deleteTreatment', this.get('actions.deleteTreatment'));
      controller.set('actions.removeNewTreatment', this.get('actions.removeNewTreatment'));
      controller.set('actions.moveTreatment', this.get('actions.moveTreatment')); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.patientenrechnung');
    }
  });

  _exports.default = _default;
});