define("digiprax-web/routes/registration/confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  // import { getOwner } from '@ember/application';
  // import { /*next, run, */schedule } from '@ember/runloop';
  var _default = Ember.Route.extend({
    // application: computed(function() {
    //   return getOwner(this).lookup('controller:application');
    // }),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var confirmationToken = location.pathname.match(/[^\/]+$/)[0];
      this.store.queryRecord('user', {
        confirmationToken: confirmationToken
      }).then(function (_user) {
        transition.abort(); // schedule("afterRender", () => {
        //   this.get('application.flashMessages').success('Ihr Zugang wurde freigeschaltet. Bitte loggen sie sich ein.', {
        //     timeout: 3000
        //   });
        // });

        _this.transitionTo('auth.login', {
          queryParams: {
            registerSuccess: true
          }
        });
      }, function (error) {
        alert(JSON.stringify(error));
      });
    }
  });

  _exports.default = _default;
});