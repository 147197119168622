define("digiprax-web/helpers/format-money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatMoney = formatMoney;
  _exports.default = void 0;

  function formatMoney(params, _hash) {
    var isCents = params.length == 1 || params[1];
    var value = (params[0] || 0) / (isCents ? 100 : 1);
    value = Math.round(value * 100) / 100.0;
    return ((String(value) + '.00').replace(/(\.[0-9]+)\.00/, '$1') + '0').replace(/(\.[0-9]{2}).*0/, '$1').replace(/\./, ',').replace(/([0-9]+)([0-9]{3}(,.+|))$/, '$1.$2').replace(/^\s*([0-9]+)\s*$/, '$1,00') + ' €';
  }

  var _default = Ember.Helper.helper(formatMoney);

  _exports.default = _default;
});