define("digiprax-web/helpers/is-modulo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isModulo = isModulo;
  _exports.default = void 0;

  function isModulo(params, _hash) {
    // eslint-disable-next-line no-constant-condition
    if (params.length == 3) return params[0] % params[1] == params[2];else return params[0] % params[1];
  }

  var _default = Ember.Helper.helper(isModulo);

  _exports.default = _default;
});