define("digiprax-web/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IPPTuQek",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"style\",\"width: 100%; text-align: center;\"],[10,\"class\",\"layout-row\"],[10,\"layout\",\"row\"],[10,\"layout-align\",\"center stretch\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\" \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"auth_commment_box flex-50\"],[10,\"flex\",\"50\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"Impressum und\\tUrheberrechtshinweis\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Digiprax© ist ein Produkt der Daub Praxisberatungs UG (haftungsbeschränkt)\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n    Amtsgericht Berlin-Charlottenburg HRB 138 219 B | Geschäftsführerin Gisela Daub.\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"©\\tCopyright\\t2020\\tDaub\\tPraxisberatungs UG\\t(haftungsbeschränkt),\\t12163\\tBerlin\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      Das Werk einschließlich aller Inhalte ist urheberrechtlich geschützt. Alle Rechte vorbehalten. Veröffentlichung, Kopie oder Reproduktion (auch auszugsweise) in irgendeiner Form (Druck, Fotokopie oder anderes Verfahren) sowie die Einspeicherung, Verarbeitung, Vervielfältigung und Verbreitung mit Hilfe elektronischer Systeme jeglicher Art, gesamt oder auszugsweise, ist ohne ausdrückliche schriftliche Genehmigung der Daub Praxisberatungs UG (haftungsbeschränkt) untersagt. Alle Übersetzungsrechte vorbehalten. Der Name Digiprax ist eine registrierte Marke des Unternehmens.\\n    \"],[9],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"h2\",true],[10,\"style\",\"color:#176524;margin:0\"],[8],[0,\"Kontakt:\\t\"],[7,\"a\",true],[10,\"style\",\"color:#176524\"],[10,\"href\",\"mailto:info@digiprax.com\"],[8],[0,\"info@digiprax.com\"],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "digiprax-web/templates/index.hbs"
    }
  });

  _exports.default = _default;
});