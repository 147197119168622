define("digiprax-web/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "digiprax-web/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    host: _environment.default.DS.host,
    namespace: _environment.default.DS.namespace,
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      var headers = {};

      if (this.session.isAuthenticated) {
        // OAuth 2
        headers['Authorization'] = "Bearer ".concat(this.session.data.authenticated.access_token);
      }

      return headers;
    }),
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (requestType == 'queryRecord') {
        if (modelName == 'user') {
          if (Object.keys(query).length == 0) return this._super.apply(this, arguments).replace(/users/, 'user/current');else return this._super.apply(this, arguments).replace(/users/, 'users/' + query.confirmationToken);
        } else if (modelName == 'revenue-statistic') {
          return this._super.apply(this, arguments).replace(/revenueStatistics/, 'revenueStatisticsAll');
        } else if (modelName == 'appointment-model') {
          return this._super.apply(this, arguments).replace(/appointmentModels/, 'appointments');
        }
      } else if (requestType == 'query') {
        if (modelName == 'appointment-model') {
          return this._super.apply(this, arguments).replace(/appointmentModels/, 'appointments');
        } else if (modelName == 'treatment' && query.from != null && query.until != null) {
          return this._super.apply(this, arguments).replace(/treatments/, 'calendar'); // } else if (modelName == 'invoice' && query != null) {
        } else if (['invoice', 'invoice-for-business'].indexOf(modelName) >= 0 && query != null) {
          return this._super.apply(this, arguments).replace(/invoices/, 'invoiceSearch').replace(/invoiceForBusinesses/, 'invoiceSearch');
        }
      } else if (requestType == 'findRecord') {
        if (modelName == 'month-stat') {
          return this._super.apply(this, arguments).replace(/monthStats/, 'statistics');
        } else if (modelName == 'year-summary') {
          return this._super.apply(this, arguments).replace(/yearSummaries/, 'yearsummary');
        } else if (modelName == 'account-summary') {
          return decodeURIComponent(this._super.apply(this, arguments).replace(/accountSummaries/, 'accounts')).replace(/([0-9]+)-([0-9]+)$/, '$1/$2');
        } else if (modelName == 'invoice-for-business') {
          return this._super.apply(this, arguments).replace(/invoiceForBusinesses/, 'invoices');
        }
      } else if (requestType == 'createRecord') {
        if (modelName == 'appointment-model') {
          return this._super.apply(this, arguments).replace(/appointmentModels/, 'appointments');
        } else if (['cashbook', 'journal'].indexOf(modelName) >= 0) {
          return this._super.apply(this, arguments) + '?page=' + snapshot.record.curPage + '&limit=' + snapshot.record.pageLimit + '&year=' + snapshot.record.selectedYear;
        } else if (modelName == 'invoice-for-business') {
          return this._super.apply(this, arguments).replace(/invoiceForBusinesses/, 'invoices');
        }
      } else if (requestType == 'updateRecord') {
        if (['cashbook', 'journal'].indexOf(modelName) >= 0) {
          return this._super.apply(this, arguments) + '?page=' + snapshot.record.curPage + '&limit=' + snapshot.record.pageLimit + '&year=' + snapshot.record.selectedYear;
        } else if (modelName == 'appointment-model') {
          return this._super.apply(this, arguments).replace(/appointmentModels/, 'appointments');
        } else if (modelName == 'invoice-for-business') {
          return this._super.apply(this, arguments).replace(/invoiceForBusinesses/, 'invoices');
        }
      } else if (requestType == 'deleteRecord') {
        if (modelName == 'appointment-model') {
          return this._super.apply(this, arguments).replace(/appointmentModels/, 'appointments');
        } else if (modelName == 'invoice-for-business') {
          return this._super.apply(this, arguments).replace(/invoiceForBusinesses/, 'invoices');
        }
      }

      return this._super.apply(this, arguments);
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 401 || status === 403) {
        this.application.hideProgressbar();
        this.get('application').on401(headers, payload, requestData);
        return {
          // status: 401,
          get: function get() {
            return ['Bitte melden Sie sich (erneut) an!'];
          }
        };
      } else if (status === 422) {
        this.application.hideProgressbar();
        var content = [];

        if (typeof (payload.errors || payload.response.errors).pop == 'function') {
          // array
          (payload.errors || payload.response.errors).forEach(function (e) {
            return content.push({
              message: e
            });
          });
        } else {
          // object
          Object.keys(payload.errors).forEach(function (k) {
            return content.push({
              message: k + ": " + payload.errors[k].join(', ')
            });
          });
        }

        return _emberData.default.Errors.create({
          content: content
        });
      } else if (status === 500) {
        this.application.hideProgressbar();
        return _emberData.default.Errors.create({
          content: [{
            message: 'Unbekannter Fehler'
          }]
        });
      } else if (status === 502) {
        this.application.hideProgressbar();
        document.location = '/auth/login?logout=true';
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});