define("digiprax-web/mixins/entry-utils", ["exports", "digiprax-web/models/new-entry-placeholder"], function (_exports, _newEntryPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    accountNumberPrint: Ember.computed('accountNumber', {
      get: function get(_key) {
        return this.accountNumber ? (this.store.adapterFor('entry').application.get('currentUser.plus') ? 'G' : '') + this.accountNumber : null;
      },
      set: function set(_key, value) {
        if ([null, ''].indexOf(value) == -1 && this.store.adapterFor('entry').application.get('currentUser.plus') && this.store.adapterFor('entry').application.get('practice.liable_to_sales_tax')) {
          var account = this.store.peekRecord('account', value.replace(/^G/, ''));

          if (account != null && account.netto) {
            var _setupTaxPartEntries = this._taxPartEntries(account);
          } else if (this.block_transfer_part_ids) {
            this.newTransferPartEntries.clear();
            this.setProperties({
              isExpand: false
              /*, newTransferPartEntries: null*/
              ,
              block_transfer_part_ids: null
              /*, entryRevChangedFlag: new Date()*/

            });
          }
        }

        return (this.store.adapterFor('entry').application.get('currentUser.plus') ? 'G' : '') + this.set('accountNumber', value.replace(/^G/, ''));
      }
    }),
    invoiceNumberDash: Ember.computed('accountNumber', {
      get: function get(_key) {
        return this.get('invoiceNumber') || (this.forceDashForInvoiceNumber(this.get('accountNumber')) ? '-' : '');
      },
      set: function set(_key, value) {
        return this.set('invoiceNumber', value) || (this.forceDashForInvoiceNumber(this.get('accountNumber')) ? '-' : '');
      }
    }),
    revenueFmtd: Ember.computed('revenue', {
      get: function get(_key) {
        if (this.transactiontype == 'Lfd. Nr. nicht verwendet') return '';
        return this.formatFloat(this.get('revenue') || 0) + ' €';
      },
      set: function set(_key, value, prevValue, eventPlaceholder) {
        var valueMatcher = this.valueMatcher(value);
        if (valueMatcher != null) this.set('revenue', Number(valueMatcher[1].replace('.', '').replace(',', '.')));
        this.repositionCaretAfterFormatting((eventPlaceholder || event).srcElement);
        return value.replace(/\.([0-9]{4,},)/, '$1').replace(/\.([0-9]{0,2},)/, '$1').replace(/^([0-9]+)([0-9]{3},)/, '$1.$2');
      }
    }),
    expenditureFmtd: Ember.computed('expenditure', {
      get: function get(_key) {
        if (this.transactiontype == 'Lfd. Nr. nicht verwendet') return '';
        return this.formatFloat(this.get('expenditure') || 0) + ' €';
      },
      set: function set(_key, value, prevValue, eventPlaceholder) {
        var valueMatcher = this.valueMatcher(value);
        if (valueMatcher != null) this.set('expenditure', Number(valueMatcher[1].replace('.', '').replace(',', '.')));
        this.repositionCaretAfterFormatting((eventPlaceholder || event).srcElement);
        return value.replace(/\.([0-9]{4,},)/, '$1').replace(/\.([0-9]{0,2},)/, '$1').replace(/^([0-9]+)([0-9]{3},)/, '$1.$2');
      }
    }),
    valueMatcher: function valueMatcher(value) {
      if (value == null) return null; // return value.match(/(([0-9]+\.[0-9]{3}|(?<!.)[0-9]+)(,[0-9]{1,2}|))\s*€?\s*$/);

      return value.match(/(([0-9]+\.[0-9]{3}|^[0-9]+)(,[0-9]{1,2}|))\s*€?\s*$/);
    },
    formatFloat: function formatFloat(value) {
      return ('' + Math.round((value || 0) * 100) * 10 / 1000).replace(/(\...).$/, '$1').replace(/(\..)$/, '$10').replace(/([^.]{3})$/, '$1.00').replace(/(.)(...\...)$/, '$1.$2').replace(/\.(..)$/, ',$1').replace(/^(-?)\./, '$1').replace(/^\s*([0-9]+)\s*$/, '$1,00').replace(/^([0-9]+)([0-9]{3},)/, '$1.$2');
    },
    repositionCaretAfterFormatting: function repositionCaretAfterFormatting(srcElement) {
      var caretPos = srcElement.selectionStart;
      Ember.run.schedule("afterRender", function () {
        srcElement.selectionStart = srcElement.selectionEnd = caretPos;
      });
    },
    taxPartEntries: Ember.computed(function () {
      var _this = this;

      if (this.accountNumber == null) return null; // if (['100', '200'].indexOf(this.accountNumber) == -1) return null;

      var account = this.store.peekRecord('account', this.accountNumber);
      if (!account.netto) return null;
      if (this.isExpand) return this.block_transfer_part_ids.map(function (partId) {
        return _this.store.peekRecord('entry', partId);
      });
      return this._taxPartEntries(account);
    }),
    _taxPartEntries: function _taxPartEntries(account) {
      var _this2 = this;

      var taxPartEntryData = {
        ids: [],
        entries: []
      };
      var taxPartEntries = {};
      this.store.adapterFor('entry').application.get('taxPercentages').forEach(function (tP, idx) {
        if (idx == 0 && ['110', '260'].indexOf(account.account_number) == -1) return; // let curTaxAccountNumber = String(parseInt(account.account_number) + idx + 1);

        var curTaxAccountNumber = String((account.is_debit ? 260 : 110) + idx + 1);

        var curTaxAccount = _this2.store.peekRecord('account', curTaxAccountNumber);

        taxPartEntries[tP] = _newEntryPlaceholder.default.create({
          targetEntry: _this2,
          taxPercentage: tP,
          domain: 'business',
          tmpId: _this2.block_transfer_part_ids == null ? 't' + Math.round(Math.random(1000000) * 1000000) + '-' + tP : _this2.block_transfer_part_ids.find(function (id) {
            return id.endsWith('-' + tP);
          }),
          extraInfo: {
            positionTS: _this2.extraInfo.positionTS + (idx + 1)
          },
          store: _this2.store,
          accountNumber: curTaxAccountNumber,
          accountDesignation: curTaxAccount.account_designation,
          transactiontype: tP + '% MwSt zu ::Lfd-Nr.::',
          invoiceNumber: _this2.invoiceNumber,
          date: _this2.date,
          blockTransfer: _this2
        });
        taxPartEntries[tP].reopen({
          // revenueObserver: observer('targetEntry.revenue', function(_sender, _key, _value, _rev) {
          //   let curTax = Math.floor(this.targetEntry.revenue / (100 + this.taxPercentage) * this.taxPercentage);
          //   // eslint-disable-next-line ember/no-side-effects
          //   this.set('revenue', curTax);
          // }),
          // expenditureObserver: observer('targetEntry.expenditure', function(_sender, _key, _value, _rev) {
          //   let curTax = Math.floor(this.targetEntry.expenditure / (100 + this.taxPercentage) * this.taxPercentage);
          //   // eslint-disable-next-line ember/no-side-effects
          //   this.set('expenditure', curTax);
          // }),
          invoiceNumberObserver: Ember.observer('targetEntry.invoiceNumber', function (_sender, _key, _value, _rev) {
            // eslint-disable-next-line ember/no-side-effects
            this.set('invoiceNumberDash', this.targetEntry.invoiceNumber);
          }),
          dateObserver: Ember.observer('targetEntry.date', function (_sender, _key, _value, _rev) {
            // eslint-disable-next-line ember/no-side-effects
            this.set('date', this.targetEntry.date);
          })
        });
        taxPartEntryData.ids.push(taxPartEntries[tP].tmpId);
        taxPartEntryData.entries.push(taxPartEntries[tP]);
      }); // this.set('block_transfer_part_ids', A(taxPartEntryData));

      this.setProperties({
        isExpand: true,
        newTransferPartEntries: Ember.A(taxPartEntryData.entries),
        block_transfer_part_ids: taxPartEntryData.ids
        /*, entryRevChangedFlag: new Date()*/

      });
      return Ember.Object.create(taxPartEntries);
    },
    isNettoAccount: Ember.computed('accountNumber', function () {
      if ([null, ''].indexOf(this.accountNumber) >= 0) return false;
      var account = this.store.peekRecord('account', this.accountNumber);
      if (account == null) return false;
      return account.netto; // return ['110', '260'].indexOf(this.accountNumber) >= 0;
    }),
    isInvoiceAccount: Ember.computed('accountNumber', function () {
      return ['110', '111', '112', '113'].indexOf(this.accountNumber) >= 0;
    }),
    forceDashForInvoiceNumber: function forceDashForInvoiceNumber(accountNumber) {
      return [null, '', '110', '111', '112', '113'].indexOf(accountNumber) == -1;
    }
  });

  _exports.default = _default;
});