define("digiprax-web/mixins/entry-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      deleteEntry: function deleteEntry(entry) {
        var _this = this;

        if (!entry.get('isNew')) {
          var message;

          if (entry.get('isExpand')) {
            message = 'Mit dem Löschen der Sammelüberweisung werden auch alle Teilbuchungen gelöscht!\n\nSollen alle Teilbuchungen unwiderruflich gelöscht werden?';
          } else {
            message = 'Soll der Eintrag unwiderruflich gelöscht werden?';
          }

          this.set('application.confirmMessage', {
            message: message,
            callback: function callback() {
              entry.destroyRecord().then(function (_dE) {
                if (entry.get('isExpand')) entry.blockTransferParts.forEach(function (e) {
                  if (e.tmpId == null) e.unloadRecord();
                });
                entry.unloadRecord();

                _this.store.findRecord('entry', entry.id).then(function (deletedEntry) {
                  var entries = _this.get(_this.toString().match(/controller:practice\/([^:]+)/)[1] + '.entries');

                  if (_this.toString().match(/controller:practice\/([^:]+)/)[1] == 'cashbook') entries.splice(entries.findIndex(function (e) {
                    return e.id == entry.id;
                  }), 1);else {
                    if (entry.get('isExpand')) {
                      entry.blockTransferParts.forEach(function (bTP) {
                        return entries.splice(entries.findIndex(function (e) {
                          return e.id == bTP.id;
                        }), 1);
                      });
                      entries.splice(entries.findIndex(function (e) {
                        return e.id == entry.id;
                      }), 1, deletedEntry);

                      _this.dirtyBlockTransfers.removeObject(entry);
                    } else {
                      entries.splice(entries.findIndex(function (e) {
                        return e.id == entry.id;
                      }), 1, deletedEntry);

                      if (deletedEntry.get('blockTransfer') != null) {
                        if (deletedEntry.get('blockTransfer.newTransferPartEntries').length == 0) _this.dirtyBlockTransfers.removeObject(deletedEntry.get('blockTransfer'));
                        deletedEntry.set('blockTransfer.deletedBlockTransferPartFlag', new Date());
                      }
                    }
                  }

                  _this.set(_this.toString().match(/controller:practice\/([^:]+)/)[1] + '.entries', entries.concat([])); // $('html, body').animate({scrollTop: 0}, 500);


                  _this.get('flashMessages').success('Eintrag erfolgreich gelöscht ...', {
                    timeout: 3000
                  });
                });
              });
            }
          });
          Ember.$('#confirmMessage').trigger('click');
        } else {
          if (entry.blockTransfer != null) {
            entry.blockTransfer.newTransferPartEntries.removeObject(entry);
            entry.blockTransfer.block_transfer_part_ids.splice(entry.blockTransfer.block_transfer_part_ids.findIndex(function (tmpId) {
              return tmpId == entry.tmpId;
            }), 1);
            entry.blockTransfer.set('block_transfer_part_ids', [].concat(entry.blockTransfer.block_transfer_part_ids));
            if (entry.blockTransfer.newTransferPartEntries.length == 0) this.dirtyBlockTransfers.removeObject(entry.blockTransfer);
            entry.blockTransfer.set('deletedBlockTransferPartFlag', new Date());
          } else {
            var model = this.get(this.target.currentRouteName.match(/^practice\.([^.]+)/)[1]);
            model.newEntryPlaceholders.removeObject(entry);
          }
        }
      },
      removeNewEntry: function removeNewEntry(entry, _idx) {
        this.get(this.toString().match(/controller:practice\/([^:]+)/)[1] + '.newEntryPlaceholders').removeObject(entry);
      }
    }
  });

  _exports.default = _default;
});