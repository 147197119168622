define("digiprax-web/controllers/patients/month-stats", ["exports", "digiprax-web/controllers/base", "moment"], function (_exports, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _base.default.extend({
    // practiceEditor: service(),
    monthsStats: null,
    includeOnlyFinalizedInvoices: false,
    filterObserver: Ember.observer('includeOnlyFinalizedInvoices', function (_sender, _key, _value, _rev) {
      if (this.includeOnlyFinalizedInvoices) {
        this.set('monthsStats', this.store.findRecord('month-stat', String(this.get('selectedYear')) + String(this.get('selectedMonth'))));
      } else {
        this.set('monthsStats', this.store.findRecord('month-stat', String(this.get('selectedYear')) + String(this.get('selectedMonth')) + 'a'));
      }
    }),
    years: Ember.computed('application.currentUser', function () {
      return _toConsumableArray(Array((0, _moment.default)().year() - (0, _moment.default)(this.get('application.currentUser.practice_register_date')).year() + 1).keys()).map(function (numPast) {
        return (0, _moment.default)().year() - numPast;
      });
    }),
    months: Ember.computed(function () {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }),
    selectedYear: Ember.computed({
      get: function get(_key) {
        return this.get('_selectedYear') || parseInt((0, _moment.default)().format('YYYY') + (this.includeOnlyFinalizedInvoices ? '' : 'a'));
      },
      set: function set(_key, value) {
        this.set('_selectedYear', parseInt(value));
        this.set('monthsStats', this.store.findRecord('month-stat', String(value) + String(this.get('selectedMonth')) + (this.includeOnlyFinalizedInvoices ? '' : 'a')));
        return value;
      }
    }),
    selectedMonth: Ember.computed({
      get: function get(_key) {
        return this.get('_selectedMonth') || parseInt((0, _moment.default)().format('M') + (this.includeOnlyFinalizedInvoices ? '' : 'a'));
      },
      set: function set(_key, value) {
        this.set('_selectedMonth', parseInt(value));
        this.set('monthsStats', this.store.findRecord('month-stat', String(this.get('selectedYear')) + String(value) + (this.includeOnlyFinalizedInvoices ? '' : 'a')));
        return value;
      }
    }),
    actions: {
      showPatient: function showPatient(patientId) {
        return this.transitionToRoute('patients.edit.index', patientId);
      },
      prevPage: function prevPage() {
        if (this.selectedMonth == 1) this.setProperties({
          selectedYear: this.selectedYear - 1,
          selectedMonth: 12
        });else this.set('selectedMonth', this.selectedMonth - 1);
      },
      nextPage: function nextPage() {
        if (this.selectedMonth == 12) this.setProperties({
          selectedYear: this.selectedYear + 1,
          selectedMonth: 1
        });else this.set('selectedMonth', this.selectedMonth + 1);
      },
      exportMonthStats: function exportMonthStats() {
        var _this = this;

        // let anchor = document.createElement("a");
        // document.body.appendChild(anchor);
        //
        // let headers = new Headers();
        // headers.append('Authorization', `Bearer ${this.get('application.session.session.content.authenticated.access_token')}`);
        //
        // fetch(this.application.monthStatsExportUrlTemplate.replace(':year', this.selectedYear).replace(':month', this.selectedMonth), { headers })
        //     .then(response => {
        //       return response.blob();
        //     })
        //     .then(blobby => {
        //         let objectUrl = window.URL.createObjectURL(blobby);
        //         anchor.href = objectUrl;
        //         anchor.download = 'month-stats_'+this.selectedYear+'-'+this.selectedMonth+'.csv';
        //         anchor.click();
        //
        //         window.URL.revokeObjectURL(objectUrl);
        //     });
        var downloadCSV = function downloadCSV(monthsStats) {
          var csv = '"lfd. Nr.";"Patient";"1. Woche";"2. Woche";"3. Woche";"4. Woche";"5. Woche";';
          if (monthsStats.patientsVisits[_this.selectedYear][_this.selectedMonth].byWeek[6]) csv += '"6. Woche";';
          csv += '"Anz. Beh.";"R.-Summe"\n';

          var _arr = Object.keys(monthsStats.patientsVisits);

          for (var _i = 0; _i < _arr.length; _i++) {
            var year = _arr[_i];

            var _arr2 = Object.keys(monthsStats.patientsVisits[year]);

            for (var _i2 = 0; _i2 < _arr2.length; _i2++) {
              var month = _arr2[_i2];
              var monthData = monthsStats.patientsVisits[year][month];
              var lfdNr = 0;

              var _arr3 = Object.keys(monthData.byPatient);

              for (var _i3 = 0; _i3 < _arr3.length; _i3++) {
                var patientId = _arr3[_i3];
                var patientData = monthData.byPatient[patientId];
                csv += ++lfdNr + ';"' + patientData.lastName + ', ' + patientData.firstName + '";';
                csv += '"' + ((patientData['1'] || {}).visitDates || '-') + '";';
                csv += '"' + ((patientData['2'] || {}).visitDates || '-') + '";';
                csv += '"' + ((patientData['3'] || {}).visitDates || '-') + '";';
                csv += '"' + ((patientData['4'] || {}).visitDates || '-') + '";';
                csv += '"' + ((patientData['5'] || {}).visitDates || '-') + '";';
                if (monthData.byWeek[6]) csv += '"' + ((patientData['6'] || {}).visitDates || '-') + '";';
                csv += patientData.visitsTotal + ';';
                csv += '"' + _this.formatMoney([patientData.billedTotal || 0]) + '"';
                csv += '\n';
              }

              csv += (monthData.patientsTotal || 0) + ';;';
              csv += ((monthData.byWeek['1'] || {}).visitsTotal || 0) + ';';
              csv += ((monthData.byWeek['2'] || {}).visitsTotal || 0) + ';';
              csv += ((monthData.byWeek['3'] || {}).visitsTotal || 0) + ';';
              csv += ((monthData.byWeek['4'] || {}).visitsTotal || 0) + ';';
              csv += ((monthData.byWeek['5'] || {}).visitsTotal || 0) + ';';
              if (monthData.byWeek[6]) csv += ((monthData.byWeek['6'] || {}).visitsTotal || 0) + ';';
              csv += (monthData.visitsTotal || 0) + ';';
              csv += '"' + _this.formatMoney([monthData.billedTotal || 0]) + '"';
            }
          }

          Ember.run.schedule("afterRender", function () {
            document.getElementById('exportData').select();
            document.execCommand('copy');

            _this.set('exportInProgress', false);
          });

          _this.set('exportInProgress', true);

          var anchor = document.createElement("a");
          document.body.appendChild(anchor);
          anchor.href = window.URL.createObjectURL(new Blob([csv], {
            type: 'text/csv'
          }));
          anchor.download = 'month-stats_' + _this.selectedYear + '-' + _this.selectedMonth + '.csv';
          anchor.click();
        };

        if (this.monthsStats.then) this.monthsStats.then(function (monthsStats) {
          return downloadCSV(monthsStats);
        });else downloadCSV(this.monthsStats); // schedule("afterRender", () => {
        //   // var myWindow = window.open("", "CSV", "width=100vw,height=100vh");
        //   // myWindow.document.write(myWindow.document.write('<textarea style="width:100vw;height:100vh;">'+document.getElementById('exportData').value.trim()+'</textarea>'));
        //   // myWindow.document.write(myWindow.document.write(document.getElementById('exportData').value.trim().replace('\n', '<br>')));
        //
        //   // var myWindow = window.open(window.URL.createObjectURL(new Blob([document.getElementById('exportData').value.trim()], {type: 'text/csv'})), 'month-stats_'+this.selectedYear+'-'+this.selectedMonth+'.csv', "width=100vw,height=100vh");
        //
        //   let anchor = document.createElement("a");
        //   document.body.appendChild(anchor);
        //   anchor.href = window.URL.createObjectURL(new Blob([document.getElementById('exportData').value.trim()], {type: 'text/csv'}));
        //   anchor.download = 'month-stats_'+this.selectedYear+'-'+this.selectedMonth+'.csv';
        //   anchor.click();
        //
        //   // document.getElementById('exportData').select();
        //   // document.execCommand('copy');
        //
        //   this.set('exportInProgress', false);
        // });
        // this.set('exportInProgress', true);
      }
    },
    // @see helper
    formatMoney: function formatMoney(params) {
      var isCents = params.length == 1 || params[1];
      return ((String((params[0] || 0) / (isCents ? 100 : 1)) + '.00').replace(/(\.[0-9]+)\.00/, '$1') + '0').replace(/(\.[0-9]{2})0/, '$1').replace(/\./, ',').replace(/([0-9]+)([0-9]{3}(,.+|))$/, '$1.$2').replace(/^\s*([0-9]+)\s*$/, '$1,00'); // + ' €';
    }
  });

  _exports.default = _default;
});