define("digiprax-web/routes/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('session').get('isAuthenticated')) {
        // this.showProgressbar();
        // document.location = '/auth/login?logout=true';
        return this.transitionTo('auth.login');
      }
    }
  });

  _exports.default = _default;
});