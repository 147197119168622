define("digiprax-web/routes/auth/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params, _transition) {
      var confirmationToken = params['confirmation_token'];
      var m = this.store.queryRecord('user', {
        confirmationToken: confirmationToken
      });
      m.then(function (user) {
        return user.set('confirmationToken', confirmationToken);
      });
      return m;
    }
  });

  _exports.default = _default;
});