define("digiprax-web/controllers/practice/revenue-statistics", ["exports", "digiprax-web/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    statisticTypes: Ember.computed(function () {
      return [];
    }),
    numYearsAvailable: Ember.computed('model.Rows', function () {
      return this.get('model.Rows.length') / (this.get('currentUser.plus') ? 2 : 3);
    }),
    numTreatmentsRowOffIdx: Ember.computed('model.Rows', function () {
      return (
        /*this.get('model.Rows.length') / */
        this.get('numYearsAvailable')
      );
    }),
    numPatientsRowOffIdx: Ember.computed('model.Rows', function () {
      return (
        /*this.get('model.Rows.length') / */
        this.get('numYearsAvailable') * 2
      );
    }),
    revCurrMonth: Ember.computed('model.Rows', function () {
      return Math.round(this.get('model.Rows.0.' + (new Date().getMonth() + 2)) / this.get('model.Rows.' + this.get('numTreatmentsRowOffIdx') + '.' + (new Date().getMonth() + 2)));
    }),
    revPrevYearMonth: Ember.computed('model.Rows', function () {
      return Math.round(this.get('model.Rows.1.' + (new Date().getMonth() + 2)) / this.get('model.Rows.' + (this.get('numTreatmentsRowOffIdx') + 1) + '.' + (new Date().getMonth() + 2)));
    }),
    revCurrYear: Ember.computed('model.Rows', function () {
      return Math.round(this.get('model.Rows.0.14') / this.get('model.Rows.' + this.get('numTreatmentsRowOffIdx') + '.14'));
    }),
    revPrevYear: Ember.computed('model.Rows', function () {
      return Math.round(this.get('model.Rows.1.14') / this.get('model.Rows.' + (this.get('numTreatmentsRowOffIdx') + 1) + '.14'));
    })
  });

  _exports.default = _default;
});