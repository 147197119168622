define("digiprax-web/models/appointment-model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // patient: DS.belongsTo('patient'),
    patient_id: _emberData.default.attr('string'),
    room_id: _emberData.default.attr('number'),
    place_id: _emberData.default.attr('number'),
    treatment_id: _emberData.default.attr('string'),
    hp_id: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    from: _emberData.default.attr('number'),
    until: _emberData.default.attr('number'),
    extra_info: _emberData.default.attr('raw'),
    patient_info: _emberData.default.attr('raw'),
    patientName: Ember.computed('patient_id', 'extra_info', function () {
      return 'TODO';
    }),
    isBusiness: Ember.computed({
      get: function get(_key) {
        return (this.get('extra_info') || {}).domain == 'business';
      },
      set: function set(_key, value) {
        var extraInfo = this.get('extra_info') || this.set('extra_info', {});
        extraInfo.domain = value ? 'business' : 'practice';
        this.set('extra_info', extraInfo);
        return value;
      }
    })
  });

  _exports.default = _default;
});