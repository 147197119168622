define("digiprax-web/serializers/cashbook", ["exports", "digiprax-web/serializers/application", "moment", "digiprax-web/models/new-entry-placeholder"], function (_exports, _application, _moment, _newEntryPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Entry from '../models/entry';
  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      Ember.run(function () {
        return store.unloadAll('entry');
      });
      store.pushPayload({
        entries: payload.response.transferredEntries.concat(payload.response.entries)
      });
      var cashbookProps = {};
      cashbookProps.entries = payload.response.entries = store.peekAll('entry').toArray();

      if ((payload.response.errors || []).length >= 1) {
        var curNEP, curBlockTransfer;
        cashbookProps.newEntryPlaceholders = payload.response.newEntryPlaceholders = (payload.response.errors || []).map(function (e) {
          if (e.id != null) {
            var entry = store.peekRecord('entry', e.id);
            entry.set('errors', e.errors);
            return null;
          }

          e.store = store;
          curNEP = _newEntryPlaceholder.default.create(e);

          if (e.serialNumber == null || e.serialNumber.match(/^[pt]/) == null) {
            e.tmpId = Math.round(Math.random(1000000) * 1000000);
            if (e.isExpand) curBlockTransfer = curNEP;
            return curNEP;
          } else {
            // if curBlockTransfer is not error (and sorted before curTaxPartEntry) than must be stored entry
            if (!curBlockTransfer.block_transfer_part_ids.includes(e.serialNumber)) curBlockTransfer = cashbookProps.entries.find(function (e) {
              return e.isExpand && e.block_transfer_part_ids.includes(e.serialNumber);
            }); // first time curBlockTransfer._taxPartEntries(curBlockTransfer.accountNumber);

            if (curBlockTransfer.newTransferPartEntries == null) curBlockTransfer._taxPartEntries(curBlockTransfer.accountNumber);
            var taxPartEntryIdx = curBlockTransfer.newTransferPartEntries.findIndex(function (e) {
              return e.taxPercentage == curNEP.serialNumber.match(/([0-9.]+$)/)[0];
            });
            curBlockTransfer.newTransferPartEntries[taxPartEntryIdx].setProperties(e);
            curBlockTransfer.newTransferPartEntries[taxPartEntryIdx].setProperties({
              tmpId: e.serialNumber,
              serialNumber: null,
              blockTransfer: curBlockTransfer
            });
            return null;
          }
        });
      }

      var cashbook = store.peekRecord('cashbook', 'current');

      if (cashbook != null) {
        if (cashbookProps.newEntryPlaceholders == undefined) {
          // no errors in response => all saved entries ok
          var previousErrors = cashbook.newEntryPlaceholders.filter(function (e) {
            return (e.errors || []).length >= 1;
          });

          if (previousErrors.length >= 1) {
            var unfixedErrors = previousErrors.filter(function (pE) {
              return cashbookProps.entries.find(function (e) {
                return e.extraInfo.positionTS == pE.extraInfo.positionTS;
              }) == null;
            });
            if (unfixedErrors.length == 0 || payload.response.to < payload.response.total) cashbookProps.newEntryPlaceholders = null;
          }
        } // run(() => cashbook.set('entries', payload.response.entries));


        Ember.run(function () {
          return cashbook.setProperties(cashbookProps);
        });
      }

      return this._super(store, primaryModelClass, payload, requestType == 'createRecord' ? 'transferred' : 'current', requestType);
    },

    /**
     * cashbook always has id 'current'. saved with PUT. only for transfers POST to create new is sent, but response will be 'current' again
     */
    serialize: function serialize(snapshot, _options) {
      var newBlockTransferParts = snapshot.record.entries.toArray().map(function (e) {
        if (e.get('newTransferPartEntries') == null) return null;
        return e.get('newTransferPartEntries').filter(function (e2) {
          return (
            /*e2.tmpId.startsWith('p')||*/
            e2.tmpId.startsWith('t')
          );
        });
      }).compact().flat().concat(snapshot.record.newEntryPlaceholders.toArray().map(function (e) {
        if (e.get('newTransferPartEntries') == null) return null;
        return e.get('newTransferPartEntries').filter(function (e2) {
          return (
            /*e2.tmpId.startsWith('p')||*/
            e2.tmpId.startsWith('t')
          );
        });
      }).compact().flat());
      var json = snapshot.record.entries.toArray().map(function (e) {
        // transfer: if (! (e.get('isDirty') || (e.get('id') && snapshot.record.get('id') != 'current'))) return null;
        if (!e.get('isDirty')) return null;
        var entry = e.toJSON();
        entry.id = e.get('id'); // entry.date = new Date(e.get('date'));
        // if (entry.date.match(/T00:00:00\.[0-9]+Z$/) == null) {
        //   let dateMom = moment(e.get('date'));
        //   entry.date = dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON();
        // }

        return entry;
      }).compact().concat(snapshot.record.newEntryPlaceholders.toArray().map(function (e) {
        // transfer: if (! (e.get('isDirty') || (e.get('id') && snapshot.record.get('id') != 'current'))) return null;
        if (!e.get('isDirty')) return null;
        var persisted = e.store.createRecord('entry', {}); // FIXME: use payload

        var entry = e.getProperties(Object.keys(persisted.toJSON()));
        e.store.unloadRecord(persisted); // entry.id = e.get('id');
        // entry.date = new Date(e.get('date'));

        if ((0, _moment.default)(e.get('date')).toDate().toJSON().match(/T00:00:00\.[0-9]+Z$/) == null) {
          var dateMom = (0, _moment.default)(e.get('date'));
          entry.date = dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON().replace(/T.+Z/, 'T00:00:00Z');
        }

        return entry;
      }).compact()).concat(newBlockTransferParts.map(function (e) {
        e.set('serialNumber', null); // transfer: if (! (e.get('isDirty') || (e.get('id') && snapshot.record.get('id') != 'current'))) return null;

        if (!e.get('isDirty')) return null;
        if (e.domain == 'business' && e.tmpId.startsWith('t') && e.revenue == 0 && e.expenditure == 0) return null;
        var persisted = e.store.createRecord('entry', {});
        var entry = e.getProperties(Object.keys(persisted.toJSON()));
        e.store.unloadRecord(persisted);
        entry.serialNumber = e.get('tmpId'); // overwrite X.x

        if ((0, _moment.default)(e.get('date')).toDate().toJSON().match(/T00:00:00\.[0-9]+Z$/) == null) {
          var dateMom = (0, _moment.default)(e.get('date'));
          entry.date = dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON().replace(/T.+Z/, 'T00:00:00Z');
        }

        return entry;
      }).compact());
      return json;
    }
  });

  _exports.default = _default;
});