define("digiprax-web/models/new-entry-placeholder", ["exports", "moment", "digiprax-web/mixins/entry-utils"], function (_exports, _moment, _entryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { A } from '@ember/array';
  var _default = Ember.Object.extend(_entryUtils.default, {
    // store must be passed when created - @see models/cashbook
    store: null,
    // service(),
    accountDesignation: null,
    accountNumber: null,
    date: null,
    expenditure: 0.0,
    invoiceNumber: null,
    month: null,
    revenue: 0.0,
    serialNumber: null,
    transactiontype: null,
    extraInfo: Ember.computed(function () {
      return {};
    }),
    isNew: true,
    errors: null,
    hasErrors: Ember.computed('errors', function () {
      return (this.errors || []).length >= 1;
    }),
    isDirty: Ember.computed('hasDirtyAttributes', 'extraInfoIsDirtyFlag', function () {
      return this.get('hasDirtyAttributes') || this.get('extraInfoIsDirtyFlag') != null;
    }),
    extraInfoIsDirtyFlag: null,
    isLast: Ember.computed('isDirty', function () {
      return !this.get('isDirty');
    }),
    hasDirtyAttributes: Ember.computed('serialNumber', 'date', 'accountDesignation', 'accountNumber', 'invoiceNumber', 'revenue', 'transactiontype', function () {
      return this.get('serialNumber') != null || this.get('date') != null && this.get('blockTransfer') == null || this.get('accountDesignation') != null && this.get('accountDesignation').trim() != '' || this.get('accountNumber') != null && this.get('accountNumber').trim() != '' || this.get('invoiceNumber') != null && this.get('invoiceNumber').trim() != '' || this.get('revenue') > 0 || this.get('expenditure') > 0 || this.get('transactiontype') != null && this.get('transactiontype').trim() != '';
    }),
    showAddCollective: Ember.computed('accountNumber', function () {
      if (this.store.adapterFor('entry').application.get('currentUser.plus')) return false;
      return String(this.get('accountNumber')) == '110' && this.get('serialNumber') == null;
    }),
    newTransferPartEntries: null,
    // A([]),
    blockTransfer: null,
    // parent
    lastBlockTransferPart: Ember.computed('newTransferPartEntries', 'newBlockTransferPartFlag', 'blockTransfer.deletedBlockTransferPartFlag', function () {
      if (this.get('newTransferPartEntries') != null) {
        return this.get('newTransferPartEntries.lastObject');
      }

      return null;
    }),
    blockTransferRevFmtd: Ember.computed('newTransferPartEntries.@each.revenue', 'newTransferPartEntries.@each.expenditure', function () {
      // children
      var blockTransferRev = [0].concat(this.get('newTransferPartEntries')).reduce(function (acc, bTP) {
        return acc + parseFloat(bTP.get('revenue') || 0) - parseFloat(bTP.get('expenditure') || 0);
      });
      return this.formatFloat(blockTransferRev || 0) + ' €';
    }),
    blockTransferDateChanged: Ember.observer('blockTransfer.date', function () {
      this.set('date', this.get('blockTransfer.date'));
    }),
    isBlockTransferPart: Ember.computed('blockTransfer', function () {
      return this.get('blockTransfer') != null;
    }),
    displayNewTransferPartEntries: Ember.computed('newTransferPartEntries', function () {
      return this.get('newTransferPartEntries') != null;
    }),
    dateFmtd: Ember.computed('date', function () {
      return this.get('date') == null ? '' : (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
    }),
    monthFmtd: Ember.computed('date', function () {
      return this.get('date') != null ? (0, _moment.default)(this.get('date')).format('MMM') : '';
    })
  });

  _exports.default = _default;
});