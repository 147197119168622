define("digiprax-web/components/room-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{room-editor practice=application.practice}}
  var _default = Ember.Component.extend({
    practice: null,
    // param
    newRoom: Ember.Object.create({
      name: null,
      places: Ember.A([Ember.Object.create({
        id: null,
        name: null
      })])
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      // for (var i=0 ; i<(this.practice.rooms||[]).length ; i++) {
      //   if (this.practice.rooms[i].places.find(p => p.id == null) == null) {
      //     this.practice.rooms[i].places.push({id: null, name: null});
      //   }
      // }
      var curRoomId;

      var _arr = Object.keys(this.practice.rooms || {});

      for (var _i = 0; _i < _arr.length; _i++) {
        curRoomId = _arr[_i];

        if (this.practice.rooms[curRoomId].places.find(function (p) {
          return p.id == null;
        }) == null) {
          this.practice.rooms[curRoomId].places.push({
            id: null,
            name: null
          });
        }
      }
    },
    willDestroy: function willDestroy() {
      var curRoomId;

      var _arr2 = Object.keys(this.practice.rooms || {});

      for (var _i2 = 0; _i2 < _arr2.length; _i2++) {
        curRoomId = _arr2[_i2];
        var newPlace = this.practice.rooms[curRoomId].places.find(function (p) {
          return p.id == null;
        });

        if (newPlace != null) {
          this.practice.rooms[curRoomId].places.removeObject(newPlace);
        }
      }
    },
    actions: {
      addRoom: function addRoom() {
        // // if (this.practice.rooms == null)
        // //   this.practice.set('rooms', EmberObject.create({}));
        // // this.practice.rooms.set(this.newRoom.name.replace(/\s*/, '').toLowerCase(), this.newRoom);
        // if (this.practice.rooms == null)
        //   this.practice.set('rooms', []);
        // this.practice.rooms.push(this.newRoom);
        // this.practice.set('rooms', [].concat(this.practice.rooms));
        // this.set('newRoom', EmberObject.create({name: null, places: A([EmberObject.create({id: null, name: null})])}));
        var newRooms = JSON.parse(JSON.stringify(this.practice.get('rooms')) || '{}');
        newRooms[this.newRoom.name.replace(/\s*/, '').toLowerCase()] = this.newRoom;
        this.practice.set('rooms', newRooms);
        this.set('newRoom', Ember.Object.create({
          name: null,
          places: Ember.A([Ember.Object.create({
            id: null,
            name: null
          })])
        }));
      },
      addPlace: function addPlace(room) {
        room.places.addObject(Ember.Object.create({
          id: null,
          name: null
        }));
      }
    }
  });

  _exports.default = _default;
});