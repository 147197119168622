define("digiprax-web/helpers/name-abbrev", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nameAbbrev = nameAbbrev;
  _exports.default = void 0;

  // params[0] ... name
  // params[1] ... max-length
  function nameAbbrev(params, _hash) {
    // eslint-disable-next-line no-constant-condition
    if (params[0] == null) return null;

    if (params[0].length > params[1]) {
      var dashIndex = params[0].indexOf('-');

      if (dashIndex != -1) {
        if (dashIndex <= params[1] - 3) {
          // müller-thurgau 6 => mül-t.
          // eslint-disable-next-line no-constant-condition
          // return params[0].substring(0, params[1]-3) + '-' +  params[0].substring(dashIndex+1, params[1]-1) + '.';
          return params[0].substring(0, dashIndex) + '-' + params[0].substring(dashIndex + 1, params[1] - 1) + '.';
        } else {
          // müller-thurgau 9 => müller-t.
          // eslint-disable-next-line no-constant-condition
          return params[0].substring(0, Math.min(params[1] - 3, dashIndex)) + '-' + params[0].substring(dashIndex + 1, Math.max(params[1], dashIndex + 2)) + '.';
        }
      } else {
        return params[0].substring(0, params[1]) + '.';
      }
    }

    return params[0];
  }

  var _default = Ember.Helper.helper(nameAbbrev);

  _exports.default = _default;
});