define("digiprax-web/sentry", ["exports", "@sentry/browser", "@sentry/integrations/esm/ember", "digiprax-web/config/environment"], function (_exports, Sentry, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startSentry = startSentry;

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function startSentry() {
    Sentry.init(_objectSpread({
      release: "".concat(_environment.default.modulePrefix, "@").concat(_environment.default.APP.version)
    }, _environment.default.sentry, {
      integrations: [new _ember.Ember()],
      beforeSend: function beforeSend(event, hint) {
        if (['test', 'development'].indexOf(_environment.default.environment) >= 0) return null;
        var error = hint.originalException; // ignore aborted route transitions from the Ember.js router

        if (error && error.name === 'TransitionAborted') {
          return null;
        }

        return event;
      }
    }));
  }
});