define("digiprax-web/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SH3ik4Tl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"progressbar_overlay\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"id\",\"progressbar_canvas\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "digiprax-web/templates/components/loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});