define("digiprax-web/mixins/auto-complete-patient-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { A } from '@ember/array';
  // import ArrayProxy from '@ember/array/proxy';
  var _default = Ember.Mixin.create({
    application: Ember.inject.controller(),
    searchTerm: null,
    searchPatient: null,
    patients: Ember.computed.alias('application.patients'),
    sortedPatients: Ember.computed.sort('patients', 'patientSortDef'),
    patientSortDef: Ember.computed(function () {
      return ['lastName:asc', 'firstName:asc'];
    }),
    actions: {
      /*searchForPatients(searchTerm, autoCompleteComponent, event) {
        this.set('searchTerm', searchTerm);
        Ember.run.debounce(this, this._searchForPatients(searchTerm), 800);
      },*/
      searchForPatients: function searchForPatients(searchWord, search) {
        return search.options.filter(function (patient) {
          return patient.lastName.toLowerCase().startsWith(searchWord.toLowerCase());
        });
      },
      switchToPatient: function switchToPatient(patient, _autoCompleteComponent) {
        this.set('application.currentPatient', patient);
        if (this.application.get('currentUser.plus')) this.transitionToRoute('customers.edit.index', patient.id);else this.transitionToRoute('patients.edit.index', patient.id);
      },
      switchToInvoice: function switchToInvoice(patient, _autoCompleteComponent) {
        // invoices need to be preloaded
        var invoiceKey = this.application.get('currentUser.plus') ? 'invoice-for-business' : 'invoice';

        if (this.get('store').peekAll(invoiceKey).filter(function (invoice) {
          return invoice.patientId == patient.id;
        }).length == 0) {
          // this.get('store').query(invoiceKey, {patientId: patient.id}).then(_invoices => {
          //     this.set('application.currentPatient', patient);
          //     this.transitionToRoute('patients.edit.invoices.new', patient.id);
          //   });
          this.set('application.currentPatient', patient);
          if (this.application.get('currentUser.plus')) this.transitionToRoute('customers.edit.invoices.new', patient.id);else this.transitionToRoute('patients.edit.invoices.new', patient.id);
        } else {
          this.set('application.currentPatient', patient);
          if (this.application.get('currentUser.plus')) this.transitionToRoute('customers.edit.invoices.new', patient.id);else this.transitionToRoute('patients.edit.invoices.new', patient.id);
        }
      },
      switchToAllInvoices: function switchToAllInvoices(patient, _autoCompleteComponent) {
        this.set('application.currentPatient', patient);
        if (this.application.get('currentUser.plus')) this.transitionToRoute('customers.edit.invoices.index', patient.id);else this.transitionToRoute('patients.edit.invoices.index', patient.id);
      }
    }
    /*,
    _searchForPatients(searchTerm) {
     return () => {
         if (searchTerm == this.get('searchTerm')) {
           Ember.debug("Searching for ... " + this.get('searchTerm'));
           var patients = this.get('store').peekAll('patient').toArray().filter(patient => patient.get('lastName').match(new RegExp('^'+searchTerm, ['i'])) != null);
           this.set('patients', patients.toArray());
           // // this.get('store').query('product', {fulltext: true, query: 'q_and='+searchWordsAnd}).then((products) => {
           // this.get('store').query('product', {fulltext: false, query: 'q_and='+searchWordsAnd}).then((products) => {
           //     Ember.Logger.debug('Search OK ...');
           //     this.set('products', products.toArray());
           //   });
         }
     }
    }*/

  });

  _exports.default = _default;
});