define("digiprax-web/helpers/show-feature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showFeature = showFeature;
  _exports.default = void 0;

  // params[0] ... featureAuth
  // params[1] ... featureId
  function showFeature(params, _hash) {
    return params[0].get('user.feature_auth.' + params[1]); // if (params[1] == 'calendar2') {
    //   if (true || ['sko@ctrl.info.tm', 'donatus.bock@gmx.de', 'hp1@digiprax.test'].indexOf(params[0].get('user.email')) == -1)
    //     return false;
    // }
    // if (params[1] == 'calendarDev') {
    //   // if (['sko@ctrl.info.tm', 'donatus.bock@gmx.de', 'hp1@digiprax.test'].indexOf(params[0].get('user.email')) == -1)
    //   //   return false;
    //   return true;
    // }
    // return params[0].get('environment') != 'production' || ['sko@ctrl.info.tm', 'donatus.bock@gmx.de', 'hp1@digiprax.test'].indexOf(params[0].get('user.email')) >= 0;
  }

  var _default = Ember.Helper.helper(showFeature);

  _exports.default = _default;
});