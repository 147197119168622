define("digiprax-web/controllers/auth/login", ["exports", "@sentry/browser"], function (_exports, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    session: Ember.inject.service(),
    isChrome: true,
    actions: {
      doLogin: function doLogin() {
        var _this = this;

        var user = this.get('model');
        this.get('session').authenticate('authenticator:digiprax', user.email, user.password).then(function () {
          // this.get('flashMessages').success('TODO: Angemeldet!', { timeout: 3000 });
          // pass the user to sentry
          Sentry.configureScope(function (scope) {
            scope.setUser({
              email: user.email
            });
          });

          _this.transitionToRoute('patients.new');
        }).catch(function (reason) {
          if (reason.errors && reason.errors[0] && reason.errors[0].code == 401) {
            _this.get('flashMessages').error('Falscher Benutzername / Passwort!', {
              timeout: 3000
            });
          } else {
            _this.get('flashMessages').error('Unbekannter Fehler', {
              timeout: 3000
            });
          }
        });
      },
      requestNewPassword: function requestNewPassword() {
        this.set('resetPasswordFlag', true);
      },
      resetPassword: function resetPassword() {
        var _this2 = this;

        this.application.requestNewPassword(this.get('model.email'), function (errors) {
          if (errors == null) _this2.get('flashMessages').success('Bitte klicken Sie den Link in der von uns gesendeten Email um Ihr Passwort neu zu setzen.', {
            timeout: 5000
          });else {
            if (errors[0].code == 404) {
              _this2.get('flashMessages').error('Email-Adresse unbekannt!', {
                timeout: 5000
              });
            } else if (errors[0].code == 422) {
              _this2.get('flashMessages').error(errors[0].detail, {
                timeout: 5000
              });
            } else {
              var errorMsg = JSON.stringify(errors);

              _this2.get('flashMessages').error(errorMsg, {
                timeout: 5000
              });
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});