define("digiprax-web/services/clipboard", ["exports", "digiprax-web/models/new-treatment-placeholder", "digiprax-web/models/new-lot-placeholder"], function (_exports, _newTreatmentPlaceholder, _newLotPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ArrayProxy from '@ember/array/proxy';
  // clipboard: {
  //   treatments: [{model: treatment, ...}, {model: treatment, ...}, ...]
  // }
  var _default = Ember.Service.extend({
    entries: Ember.A([]),
    // entries: ArrayProxy.create({ content: A([]) }),
    treatments: Ember.computed.alias('entries'),
    lots: Ember.computed.alias('entries'),
    clearBeforeCopy: true,
    hasEntries: Ember.computed('entries.length', '_entriesFlag', function () {
      return this.get('entries.length') >= 1;
    }),
    hasTreatments: Ember.computed.alias('hasEntries'),
    hasLots: Ember.computed.alias('hasEntries'),
    checkCopyTreatment: function checkCopyTreatment(entry, clickOnFirstOfDaySelectsAllOfDay) {
      var _this = this;

      var entryIndex = null;

      if (entry.get('clipboard') != null) {
        entryIndex = this.get('entries').findIndex(function (e) {
          return e.model == entry;
        });

        if (entryIndex != -1) {
          this.get('entries').removeAt(entryIndex);
          entry.set('clipboard', null);
          this.set('_entriesFlag', new Date());
          return -1;
        } else {
          this.checkClearBeforeCopy();
          this.get('entries').push({
            model: entry
          });
        }
      } else {
        entry.set('clipboard', this);
        this.checkClearBeforeCopy();
        this.get('entries').push({
          model: entry
        });
        this.set('_entriesFlag', new Date());
      }

      if (entry.get('invoice') != null) {
        if (clickOnFirstOfDaySelectsAllOfDay) {
          if (entry.get('totalDate') != null) {
            entry.get('invoice.' + (entry.get('tmpId') != null ? 'sortedNewEntryPlaceholders' : 'sortedEntries')).forEach(function (t) {
              if (t.get('dateFmtd') == entry.get('dateFmtd')) {
                if (t != entry && t.get('clipboard') == null) _this.checkCopyTreatment(t);
              }
            });
          }
        }
      }

      return true;
    },
    // @see mixins/invoice-dialogs - inserting tariff-chains
    // TODO - refactor
    pasteTreatments: function pasteTreatments(invoice) {
      if (!this.get('clearBeforeCopy')) this.set('clearBeforeCopy', true);
      var firstModifiedInput = Ember.$('[data-name=' + (invoice.domain == 'business' ? 'lot' : 'treatment') + '-new-' + (invoice.get('newEntryPlaceholders.length') - 1) + ']');
      var date = invoice.get('sortedNewEntryPlaceholders.' + (invoice.get('newEntryPlaceholders.length') - 1) + '.date');

      if (date == null) {
        // line above if new entry
        date = invoice.get('sortedNewEntryPlaceholders.' + (invoice.get('newEntryPlaceholders.length') - 2) + '.date');
      }

      if (date == null) {
        // line above if persisted entry
        date = invoice.get('sortedEntries.' + (invoice.get('entries.length') - 1) + '.date');
      }

      var newEntryPlaceholders = [];
      this.get('entries').toArray().forEach(function (copyEntry, idx) {
        // copy tariff-chain-infos would preset sorting
        // let newExtraInfo = JSON.parse(JSON.stringify(copyEntry.model.get('extraInfo')));
        var newExtraInfo = {};
        newExtraInfo.positionTS = new Date().getTime() + idx;

        if (invoice.domain == 'business') {
          newEntryPlaceholders.push(_newLotPlaceholder.default.create({
            tmpId: Math.round(Math.random(1000000) * 1000000),
            patient_id: copyEntry.model.get('patientId'),
            invoice_id: invoice.get('id'),
            invoice: invoice,
            date: date || new Date(),
            // copyEntry.model.get('date'),
            product: copyEntry.model.get('product'),
            product_id: copyEntry.model.get('product_id'),
            lotText: copyEntry.model.get('lotText'),
            number: copyEntry.model.get('number'),
            taxPercentage: copyEntry.model.get('taxPercentage'),
            costFmtd: copyEntry.model.get('costFmtd'),
            // positionTS: new Date().getTime()+(idx+1)
            extraInfo: newExtraInfo
          }));
        } else {
          copyEntry.model.set('tariff.curSettlementId', invoice.get('loadedPatient.settlementId'));
          newEntryPlaceholders.pushObject(_newTreatmentPlaceholder.default.create({
            tmpId: Math.round(Math.random(1000000) * 1000000),
            patientId: copyEntry.model.get('patientId'),
            invoiceId: invoice.get('id'),
            invoice: invoice,
            date: date || new Date(),
            // copyEntry.model.get('date'),
            tariff: copyEntry.model.get('tariff'),
            tariffId: copyEntry.model.get('tariffId'),
            invoiceText: copyEntry.model.get('invoiceText'),
            number: copyEntry.model.get('number'),
            costFmtd: copyEntry.model.get('costFmtd'),
            // positionTS: new Date().getTime()+(idx+1)
            extraInfo: newExtraInfo
          }));
        }
      });

      if (newEntryPlaceholders.length >= 1) {
        var _invoice$get;

        // set empty newEntryPlaceholders at last position/bottom
        invoice.get('newEntryPlaceholders.' + (invoice.get('newEntryPlaceholders.length') - 1)).setProperties({
          date: null,
          extraInfo: {
            positionTS: new Date().getTime() + newEntryPlaceholders.length + invoice.get('newEntryPlaceholders.length')
          }
        });

        (_invoice$get = invoice.get('newEntryPlaceholders')).splice.apply(_invoice$get, [invoice.get('newEntryPlaceholders.length') - 1, 0].concat(newEntryPlaceholders));

        invoice.set('savedAt', new Date());
      }
      /*let touchDayGroups = */


      invoice.get('dayGroups'); // this.clear(); // @see clearBeforeCopy
      // scroll to last entry

      Ember.run.schedule("afterRender", function () {
        Ember.$('html, body').animate({
          scrollTop: firstModifiedInput.offset().top - Ember.$('.nav').height()
        }, 500);
      });
      return false;
    },
    checkClearBeforeCopy: function checkClearBeforeCopy() {
      if (this.get('clearBeforeCopy')) {
        this.clear();
        this.set('clearBeforeCopy', false);
      }
    },
    clear: function clear() {
      this.get('entries').forEach(function (t) {
        if (!(t.model.isDestroying || t.model.isDestroyed)) t.model.set('clipboard', null);else console.log('Clipboard - clear: destroyed model ...');
      });
      this.get('entries').clear();
    }
  });

  _exports.default = _default;
});