define("digiprax-web/serializers/year-summary", ["exports", "digiprax-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var totalEntries = ['Saldo', 'Saldo Einnahmen ./. Ausgaben', 'Summe Ausgaben', 'Summe Einnahmen'];
      payload.response.rows.forEach(function (row) {
        row.insertAt(2, row[1] ? (store.peekRecord('account', row[1]) || {}).account_designation : totalEntries.pop());
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});