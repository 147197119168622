define("digiprax-web/serializers/patient", ["exports", "digiprax-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(_snapshot, _options) {
      var json = this._super.apply(this, arguments);

      delete json.settlementName;
      delete json.trial;
      return json;
    }
  });

  _exports.default = _default;
});