define("digiprax-web/serializers/open-invoice", ["exports", "digiprax-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, payload, 'current', requestType);
    }
  });

  _exports.default = _default;
});