define("digiprax-web/routes/customers/edit/invoices/new", ["exports", "digiprax-web/mixins/invoice-editor-p-route"], function (_exports, _invoiceEditorPRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_invoiceEditorPRoute.default, {
    beforeModel: function beforeModel(transition) {
      var patientIdAvailable = !(typeof transition.to.parent.parent.params.patient_id == "undefined" || transition.to.parent.parent.params.patient_id == null || transition.to.parent.parent.params.patient_id == 'new');

      if (patientIdAvailable) {
        var unprintedSavedInvoice = this.get('store').peekAll('invoice-for-business').filter(function (invoice) {
          return invoice.get('patientId') == transition.to.parent.parent.params.patient_id && [null, 'new'].indexOf(invoice.id) == -1 && !(invoice.get('extraInfo.finalized') != null || invoice.get('extraInfo.canceledAt') != null || invoice.get('printedAt') != null);
        })[0];

        if (unprintedSavedInvoice != null) {
          return this.transitionTo('customers.edit.invoices.edit', transition.to.parent.parent.params.patient_id, unprintedSavedInvoice.id);
        }
      }

      return this._super(transition);
    },
    model: function model(_params, transition) {
      var patientIdAvailable = !(typeof transition.to.parent.parent.params.patient_id == "undefined" || transition.to.parent.parent.params.patient_id == null || transition.to.parent.parent.params.patient_id == 'new');
      if (!patientIdAvailable) return Ember.Object.create({});
      var patient = this.store.peekRecord('patient', transition.to.parent.parent.params.patient_id);
      var newInvoice = this.get('store').peekRecord('invoice-for-business', 'new');

      if (newInvoice != null && (newInvoice.get('patientId') || newInvoice.get('patient.id')) != patient.id) {
        newInvoice.unloadRecord();
        newInvoice = null;
      }

      if (newInvoice != null) {
        this.set('newInvoiceInProgress', newInvoice);
        return newInvoice;
      } else {
        this.set('newInvoiceInProgress', null);

        try {
          return this.get('store').createRecord('invoice-for-business', {
            id: 'new',
            patientId: patient.id,
            patient: patient,
            date: new Date()
          });
        } catch (e) {
          this.get('store').unloadAll('invoice-for-business');
          return this.get('store').createRecord('invoice-for-business', {
            id: 'new',
            patientId: patient.id,
            patient: patient,
            date: new Date()
          });
        }
      }
    },
    setupController: function setupController(controller, model) {
      if (model.get('patientId') != null) {
        this._super(controller, model);

        var patient = this.store.peekRecord('patient', model.get('patientId')); // model.set('patient', patient);

        var practice = controller.get('application.practice');
        if (typeof practice.then == 'function') practice.then(function (p) {
          return model.setProperties({
            patient: patient,
            liableToSalesTax: p.liable_to_sales_tax || false
          });
        });else model.setProperties({
          patient: patient,
          liableToSalesTax: practice.liable_to_sales_tax || false
        });

        var _initPatient = model.get('patient'); // if (this.get('newInvoiceInProgress') == null) {


        this._initInvoiceParams(model); // controller.set('newLotPlaceholders', this._newLotPlaceholders(model));
        // model.set('newLotPlaceholders', this._newLotPlaceholders(model));


        var _initNumNewLotsDirty = model.get('numNewLotsDirty'); // }


        controller.set('actions.deleteLot', this.get('actions.deleteLot'));
        controller.set('actions.removeNewLot', this.get('actions.removeNewLot'));
        controller.set('actions.moveLot', this.get('actions.moveLot'));
      } // case reload ...


      controller.get('application').setActiveNavLink('linkTo.patientenrechnung');
    }
  });

  _exports.default = _default;
});