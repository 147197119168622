define("digiprax-web/routes/practice/open-invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_params, _transition) {
      return new Date(); // any model just required to enable refresh (f.ex api_domain-change) ...
    },
    setupController: function setupController(controller, model) {
      var result = this._super(controller, model);

      var openInvoices = this.store.queryRecord('open-invoice', {});
      openInvoices.then(function (oi) {
        return controller.set('sum', [0].concat(oi.Data).reduce(function (acc, d) {
          return d[5] + parseInt(acc || 0);
        }));
      });
      controller.set('model', openInvoices); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.open-invoices');
      return result;
    }
  });

  _exports.default = _default;
});