define("digiprax-web/mixins/patient-dialogs-p", ["exports", "moment", "digiprax-web/mixins/dialogs-util"], function (_exports, _moment, _dialogsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_dialogsUtil.default, {
    showProductsDialog: null,
    _productsImportFlag: Ember.computed.alias('application.productsImportFlag'),
    products: Ember.computed('_productsImportFlag', function () {
      return this.get('store').peekAll('product').sortBy('name'); // itemSortKey
    }),
    actions: {
      focusInput: function focusInput(treatment, eventPlaceholder) {
        // this.focusInput(treatment, eventPlaceholder);
        this.focusInput(treatment, (eventPlaceholder || event).target);
        return false;
      },
      focusOutInput: function focusOutInput(treatment, eventPlaceholder) {
        if (this.get('focusedTreatment') != treatment) {
          this.set('focusedTreatment', treatment);

          this._focusLine(treatment, Ember.$((eventPlaceholder || event).target).closest('ul'));
        }

        if (this.get('focusedTreatment.date') != null) {
          this.set('lastFocusedLotDate', (0, _moment.default)(this.get('focusedTreatment.date')).format('DD.MM.YYYY')); // console.log('setting lastFocusedLotDate to ' + this.get('lastFocusedLotDate'));
        }

        this.focusNewInput((eventPlaceholder || event).target);
        /* 2018-11: diagnose can be left empty for a treatment - in that case first available diagnose for most recent treatment prior to this treatment is implicitly set
        if ($((eventPlaceholder || event).target).closest('li').attr('name') != 'diagnose') {
          // preset diagnose if user started editing treatment-row
          // if ((treatment.get('diagnosisText') == null || treatment.get('diagnosisText').trim() == '') && (this.get('model.sortedDiagnoses').toArray().length >= 1)) {
          //   if (treatment.isDirty()) treatment.set('diagnosisText', this.get('model.sortedDiagnoses.lastObject.notes'));
          // }
          if ((treatment.get('diagnosisText') == null || treatment.get('diagnosisText').trim() == '') && (this.get('model.sortedTreatments').toArray().length >= 1)) {
            if (treatment.isDirty()) treatment.set('diagnosisText', this.get('model.sortedTreatments.lastObject.diagnosisText'));
          }
        }
        */

        /*let touchNumNewTreatmentsDirty = */

        this.get('model.numNewLotsDirty'); // prevents X to remove last clean line

        return false;
      },
      checkDoneEditInitReception: function checkDoneEditInitReception(eventPlaceholder) {
        if ((eventPlaceholder || window.event || {}).keyCode == 13) {
          if (this.model._pikaDayInitReceptionInput) this.model._pikaDayInitReceptionInput.blur();
          this.model.set('showPikaDayInitReception', false);
        }
      },
      checkDoneEditBirthdate: function checkDoneEditBirthdate(eventPlaceholder) {
        if ((eventPlaceholder || window.event || {}).keyCode == 13) {
          if (this.model._pikaDayBirthdateInput) this.model._pikaDayBirthdateInput.blur();
          this.model.set('showPikaDayBirthdate', false);
        }
      },
      openProductDialog: function openProductDialog(lot, eventPlaceholder) {
        if (lot == null) {
          this._openDialogWithFocusedEntry(eventPlaceholder || event, 'lot', 'showProductsDialog', function () {
            alert('Bitte wählen Sie zuerst ein Eingabe-Element aus einer Behandlungs-Zeile für die Gebühren-Zuordnung.');
          });
        } else {
          this._openEntryDialog(lot, 'lot', 'showProductsDialog', eventPlaceholder || event);
        }

        return false;
      },
      closeProductDialog: function closeProductDialog() {
        return this.set('showProductsDialog', null);
      },
      appendProduct: function appendProduct(product) {
        if (this.get('focusedTreatment.date') == null) this.set('focusedTreatment.dateTotal', (this.get('lastFocusedLotDate') != null ? (0, _moment.default)(this.get('lastFocusedLotDate'), 'DD.MM.YYYY') : (0, _moment.default)()).toDate());
        var lotProps = {
          product: product,
          product_id: product.id,
          cost_cents: parseInt(product.price_cents)
          /**/
          ,
          notes: product.name
        };
        this.selectedLot.setProperties(lotProps);
        /*let touchNumNewLotsDirty = */

        this.get('model.numNewLotsDirty');
        return this.send('closeProductDialog');
      }
    },
    _focusLine: function _focusLine(treatment, lineSelector) {
      this.focusLine(treatment, lineSelector);
    },
    _checkedValuesJSON: function _checkedValuesJSON(values) {
      var checkedValues = {};

      var _arr = Object.keys(values);

      for (var _i = 0; _i < _arr.length; _i++) {
        var attr = _arr[_i];

        if (values[attr]) {
          checkedValues[attr] = true;
        }
      }

      return checkedValues;
    },
    _checkedValuesCSV: function _checkedValuesCSV(values) {
      var checkedValues = [];

      var _arr2 = Object.keys(values);

      for (var _i2 = 0; _i2 < _arr2.length; _i2++) {
        var attr = _arr2[_i2];

        if (values[attr]) {
          checkedValues.push(attr);
        }
      }

      return checkedValues.join(',');
    }
  });

  _exports.default = _default;
});