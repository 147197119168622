define("digiprax-web/mixins/customer-model", ["exports", "digiprax-web/models/new-lot-placeholder"], function (_exports, _newLotPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // _lotsAddedOrDeleted: computed('lots', function() {
    //   return [this.get('lots.length')||0, 1];
    // }),
    _lotsAddedOrDeleted: Ember.computed('lots', {
      get: function get(_key) {
        return this.get('__lotsAddedOrDeleted') || [this.get('lots.length') || 0, 1];
      },
      set: function set(_key, value, _prevValue) {
        return this.set('__lotsAddedOrDeleted', value);
      }
    }),
    lotsAddedOrDeleted: Ember.computed('lots.[]', 'newLotPlaceholders.[]', '_lotsAddedOrDeleted', function () {
      return this.get('lots.length') != this.get('_lotsAddedOrDeleted')[0]; //  || this.get('newLotPlaceholders.length') != this.get('_lotsAddedOrDeleted')[1];
    }),
    lotsDirty: Ember.computed('lots.@each.date', 'lots.@each.notes', 'lots.@each.diagnosisText', 'lots.@each.cost', 'numNewLotsDirty', function () {
      return this.get('numNewLotsDirty') >= 1 || (this.get('lots') || []).toArray().filter(function (t) {
        return t.isDirty();
      }).length >= 1;
    }),
    numNewLotsDirty: Ember.computed('newLotPlaceholders.@each.date', 'newLotPlaceholders.@each.notes', 'newLotPlaceholders.@each.diagnosisText', 'newLotPlaceholders.@each.cost', function () {
      return this._numNewLotsDirty(this.get('newLotPlaceholders').toArray());
    }),
    _numNewLotsDirty: function _numNewLotsDirty(newLotPlaceholders) {
      var num = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = newLotPlaceholders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var lot = _step.value;
          // if (lot.get('hasDirtyAttributes')) num = num + 1;
          if (lot.isDirty()) num = num + 1;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return num;
    },

    /*sortedLots: Ember.computed.sort('lots', 'lotSortDef'),
    lotSortDef: function() {
      return ['date:asc'];
    }.property(),*/
    sortableLots: Ember.computed('lots', 'lots.@each.date', 'lots.@each.extraInfo'
    /*.positionTS'*/
    , function () {
      return this.get('lots');
    }),
    sortedLots: Ember.computed.sort('sortableLots', function (i1, i2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (i1.get('dateFmtd') == i2.get('dateFmtd')) {
        var positionSort = this.sortByPosition(i1, i2);
        if (positionSort != null) return positionSort;
      }

      return i2.get('date') - i1.get('date') >= 0 ? -1 : 1;
    }),
    sortableNewLotPlaceholders: Ember.computed('newLotPlaceholders', 'newLotPlaceholders.@each.date', 'newLotPlaceholders.@each.extraInfo'
    /*.positionTS'*/
    , function () {
      return this.get('newLotPlaceholders');
    }),
    sortedNewLotPlaceholders: Ember.computed.sort('sortableNewLotPlaceholders', function (i1, i2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (!i1.isDirty()) return 1;
      if (!i2.isDirty()) return -1;
      if (i1.get('date') == null) return 1;
      if (i2.get('date') == null) return -1;

      if (i1.get('dateFmtd') == i2.get('dateFmtd')) {
        var positionSort = this.sortByPosition(i1, i2);
        if (positionSort != null) return positionSort;
      }

      return i2.get('date') - i1.get('date') >= 0 ? -1 : 1;
    }),

    /*
    diagnoses: computed({
      get(_key) {
        console
        let diagnoses = this.get('_diagnoses');
        if (diagnoses == null) {
          diagnoses = this.get('store').peekAll('diagnosis').toArray().filter((diagnose) => diagnose.get('patientId') == this.get('id') && diagnose.get('invoiceId') == null);
          if (diagnoses.length == 0) return null;
          this.set('_diagnoses', diagnoses);
        }
        return diagnoses;
      },
      set(_key, diagnoses) {
        this.set('_diagnoses', diagnoses);
        return diagnoses;
      }
    }),
    */
    // sortedDiagnoses: computed.sort('diagnoses', 'diagnoseSortDef'),
    // diagnoseSortDef: computed(function() {
    //   return ['date:asc'];
    // }),
    numNewLotPlaceholders: 1,
    newLotPlaceholders: Ember.computed('numNewLotsDirty', 'savedAt', function () {
      var newLotPlaceholders = this.get('_newLotPlaceholders');

      if (newLotPlaceholders == null) {
        var nTPs = new Array();

        for (var i = 0; i < this.get('numNewLotPlaceholders'); i++) {
          nTPs.push(_newLotPlaceholder.default.create({
            tmpId: Math.round(Math.random(1000000) * 1000000),
            patient_id: this.get('id'),
            positionTS: new Date().getTime() + (i + 1)
          }));
        } // newLotPlaceholders = ArrayProxy.create({ content: A(nTPs) });


        newLotPlaceholders = Ember.A(nTPs); // eslint-disable-next-line ember/no-side-effects

        this.set('_newLotPlaceholders', newLotPlaceholders);
      } else {
        // var numDirty = 0;
        // for (var lot of newLotPlaceholders.toArray()) {
        //   if (lot.get('date') != null) numDirty = numDirty + 1;
        // }
        var numDirty = this._numNewLotsDirty(newLotPlaceholders.toArray());

        if (newLotPlaceholders.toArray().length < this.get('numNewLotPlaceholders') + numDirty) {
          newLotPlaceholders.pushObject(_newLotPlaceholder.default.create({
            tmpId: Math.round(Math.random(1000000) * 1000000),
            patient_id: this.get('id'),
            positionTS: new Date().getTime()
          }));
        }
      }

      return newLotPlaceholders;
    })
    /*numNewLotsDirty: function() {
      var num = 0;
      for (var lot of this.get('newLotPlaceholders').toArray()) {
        if (lot.get('date') != null) num = num + 1;
      }
      return num;
    }.property('newLotPlaceholders.@each.date'),*/

  });

  _exports.default = _default;
});