define("digiprax-web/routes/patients/edit/invoices/new", ["exports", "digiprax-web/mixins/invoice-editor-route"], function (_exports, _invoiceEditorRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_invoiceEditorRoute.default, {
    beforeModel: function beforeModel(transition) {
      var patientIdAvailable = !(typeof transition.to.parent.parent.params.patient_id == "undefined" || transition.to.parent.parent.params.patient_id == null || transition.to.parent.parent.params.patient_id == 'new');

      if (patientIdAvailable) {
        var unprintedSavedInvoice = this.get('store').peekAll('invoice').filter(function (invoice) {
          return invoice.get('patientId') == transition.to.parent.parent.params.patient_id && [null, 'new'].indexOf(invoice.get('id')) == -1 && !(invoice.get('extraInfo.finalized') != null || invoice.get('extraInfo.canceledAt') != null || invoice.get('printedAt') != null);
        })[0];

        if (unprintedSavedInvoice != null) {
          return this.transitionTo('patients.edit.invoices.edit', transition.to.parent.parent.params.patient_id, unprintedSavedInvoice.id);
        }
      }

      return this._super(transition);
    },
    model: function model(_params, transition) {
      var patientIdAvailable = !(typeof transition.to.parent.parent.params.patient_id == "undefined" || transition.to.parent.parent.params.patient_id == null || transition.to.parent.parent.params.patient_id == 'new');
      if (!patientIdAvailable) return Ember.Object.create({});
      var patient = this.store.peekRecord('patient', transition.to.parent.parent.params.patient_id);
      var newInvoice = this.get('store').peekRecord('invoice', 'new');

      if (newInvoice != null && (newInvoice.get('patientId') || newInvoice.get('patient.id')) != patient.id) {
        newInvoice.unloadRecord();
        newInvoice = null;
      }

      if (newInvoice != null) {
        this.set('newInvoiceInProgress', newInvoice);
        return newInvoice;
      } else {
        this.set('newInvoiceInProgress', null);

        try {
          return this.get('store').createRecord('invoice', {
            id: 'new',
            patientId: patient.id,
            patient: patient,
            date: new Date()
          });
        } catch (e) {
          this.get('store').unloadAll('invoice');
          return this.get('store').createRecord('invoice', {
            id: 'new',
            patientId: patient.id,
            patient: patient,
            date: new Date()
          });
        }
      }
    },
    setupController: function setupController(controller, model) {
      if (model.get('patientId') != null) {
        this._super(controller, model);

        var patient = this.store.peekRecord('patient', model.get('patientId'));
        model.set('patient', patient);

        var _initPatient = model.get('patient'); // if (this.get('newInvoiceInProgress') == null) {


        this._initInvoiceParams(model); // controller.set('newTreatmentPlaceholders', this._newTreatmentPlaceholders(model));
        // model.set('newTreatmentPlaceholders', this._newTreatmentPlaceholders(model));


        var _initNumNewTreatmentsDirty = model.get('numNewTreatmentsDirty'); // }


        controller.set('actions.deleteTreatment', this.get('actions.deleteTreatment'));
        controller.set('actions.removeNewTreatment', this.get('actions.removeNewTreatment'));
        controller.set('actions.moveTreatment', this.get('actions.moveTreatment'));
      } // case reload ...


      controller.get('application').setActiveNavLink('linkTo.patientenrechnung');
    }
  });

  _exports.default = _default;
});