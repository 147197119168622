define("digiprax-web/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    practiceId: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    feature_auth: _emberData.default.attr('raw'),
    business_enabled: _emberData.default.attr('boolean'),
    cur_api_domain: _emberData.default.attr('string'),
    // practice||nil | business
    practice_register_date: _emberData.default.attr('string'),
    account: _emberData.default.attr('raw'),
    plus: Ember.computed('cur_api_domain', {
      get: function get(_key) {
        return this.cur_api_domain == 'business' ? true : false;
      },
      set: function set(_key, value) {
        var application = this.store.adapterFor('patient').application;
        var profileRouteMatcher = application.target && application.target.currentRouteName ? application.target.currentRouteName.match(/^(patients|customers)\.edit\.profile/) : null;

        if (profileRouteMatcher != null) {
          if (!value && application.currentPatient.settlementId == null) {
            var profileRoute = Ember.getOwner(application).lookup('route:' + profileRouteMatcher[0]);
            profileRoute.controller.set('showConvertCustomerToPatientDialog', true);
            return this.plus;
          } else if (value && !application.currentPatient.is_customer) {
            // profileRoute.set('controller.convertPatientToCustomer', true);
            application.get('flashMessages').info('Um einen Patienten in die Kundendatenbank zu übernehmen aktivieren Sie bitte auf das Kunden-Kästchen oben rechts und sichern anschließend das Formular.', {
              timeout: 5000
            });
            return this.plus;
          }
        }

        var patient = this.store.peekRecord('patient', 'new'); // this.store.adapterFor('patient').application.currentPatient
        // for existing patients is_customer-checkbox is used

        if (value) {
          this.set('cur_api_domain', 'business');
          if (patient != null) patient.set('is_customer', true);
          this.store.adapterFor('patient').application.setApiDomain.perform('business');
        } else {
          this.set('cur_api_domain', 'practice');
          if (patient != null) patient.set('is_customer', false);
          this.store.adapterFor('patient').application.setApiDomain.perform('practice');
        }

        return value;
      }
    }) //,
    // fullName: computed('firstName', 'lastName', {
    //   get(_key) {
    //     return this.get('firstName') + ' ' + this.get('lastName');
    //   },
    //   set(_key, value) {
    //     let sepIdx = value.indexOf(' ');
    //     this.setProperties({ firstName: value.substring(0, sepIdx), lastName: value.substring(sepIdx + 1), });
    //     return value;
    //   }
    // })

  });

  _exports.default = _default;
});