define("digiprax-web/controllers/patients/edit/profile", ["exports", "digiprax-web/controllers/base", "digiprax-web/mixins/patient-dialogs", "digiprax-web/mixins/auto-complete-patient-search"], function (_exports, _base, _patientDialogs, _autoCompletePatientSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Controller, { inject as controller } from '@ember/controller';
  var _default = _base.default.extend(_patientDialogs.default, _autoCompletePatientSearch.default, {
    patientEditor: Ember.inject.service(),
    // genderList: computed('application.currentUser.plus', function() {
    //   return this.get('application.currentUser.plus') ? ['', 'Frau', 'Herr'] : ['', 'Frau', 'Herr', 'Kind (Junge)', 'Kind (Mädchen)'];
    // }),
    genderList: Ember.computed(function () {
      return ['', 'Frau', 'Herr', 'Kind (Junge)', 'Kind (Mädchen)'];
    }),
    genderListInvoiceRecipient: Ember.computed(function () {
      return ['', 'Frau', 'Herr', 'Firma'];
    }),
    differentInvoiceRecipient: Ember.computed(function () {
      return ['Nein', 'Ja'];
    }),
    disabledSettlements: Ember.computed(function () {
      return ['bh-br', 'bh-hh', 'bh-sl'];
    }),
    // hideSettlements: computed('currentUser.plus', 'modelReloadedFag', function() {
    //   return this.get('currentUser.plus');
    // }),
    showConvertCustomerToPatientDialog: false,

    /*sortedPatients: Ember.computed.sort('patients', 'patientSortDef'),
    patientSortDef: function() {
      return ['lastName:asc', 'firstName:asc'];
    }.property(),*/

    /*scrollDownTreatments: function() {
      if ($('.all_treatments').length >= 1) {
        Ember.run.schedule("afterRender", () => {
          this.send("scrollDownTreatments");
        });
        return '';
      } else {
        return '';
      }
    }.property('model.treatments', 'model.newTreatmentPlaceholders'),*/
    actions: {
      editPatient: function editPatient() {
        document.location.href = (this.currentUser.plus ? '/customers/' : '/patients/') + this.get('model.id') + '/edit';
      },
      createPatient: function createPatient() {
        this.transitionToRoute('/');
      },
      savePatient: function savePatient() {
        this.get('patientEditor').savePatient(this.get('model'), null, this);
      }
      /*,
      scrollDownTreatments() {
       let e = document.querySelector('.all_treatments');
       e.scrollTop = e.scrollHeight;
       $('html, body').animate({scrollTop: $('body').height()}, 500);
      }*/
      ,
      convertCustomerToPatient: function convertCustomerToPatient() {
        var _this = this;

        // this.application.set('currentPatient.settlementId', settlementId);
        var inValid = function inValid(errors, _config) {
          _this.get('flashMessages').error(errors.join('\n<br>'), {
            timeout: 3000
          });

          _this.set('showConvertCustomerToPatientDialog', null);
        };

        var afterSave = function afterSave(_patient) {
          _this.application.set('currentUser.plus', false);

          _this.set('showConvertCustomerToPatientDialog', null);
        };

        this.patientEditor.savePatient(this.get('model'), null, {
          inValid: inValid,
          afterSave: afterSave
        });
      }
    },
    inValid: function inValid(errors, config) {
      Ember.$('html, body').animate({
        scrollTop: 0
      }, 500);
      var configAvailable = !(typeof config == "undefined" || config == null);
      if (!configAvailable) config = {
        timeout: 10000
      };
      var errorHtml = '';
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = errors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var error = _step.value;
          errorHtml += '<li>' + error + '</li>';
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.get('flashMessages').error('<ul>' + errorHtml + '</ul>', config);
    },
    afterSave: function afterSave(_patient) {
      Ember.$('html, body').animate({
        scrollTop: 0
      }, 500);
      this.get('flashMessages').success('Patientenkarte erfolgreich geändert ...', {
        timeout: 3000
      });
      /* with transition:
      Ember.run.schedule("afterRender",() => {
          $('html, body').animate({scrollTop: 0}, 500);
          this.get('flashMessages').success('Patientenkarte erfolgreich geändert ...', {
            timeout: 3000
          });
        });
      this.transitionToRoute('patients.edit.invoices.new', patient.id);
      */
    }
  });

  _exports.default = _default;
});