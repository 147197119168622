define("digiprax-web/routes/practice/journal", ["exports", "digiprax-web/mixins/practice-route"], function (_exports, _practiceRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Route.extend(_practiceRoute.default,
  /*RouteMixin, */
  {
    // model(_params, _transition) {
    //   this.store.queryRecord('journal', {});
    // },
    model: function model(_params, _transition) {
      return new Date(); // any model just required to enable refresh (f.ex api_domain-change) ...
    },
    setupController: function setupController(controller, model) {
      var result = this._super(controller, model);

      if (controller.get('application.accounts') == null) {
        this.store.findAll('account', {}).then(function (a) {
          controller.set('application.accounts', a);
        });
      } // controller.set('journal', model);


      var queryParams = this.getQueryParams(controller); // let dirtyBlockTransfers = this.store.peekAll('entry').filter(e => e.newTransferPartEntries != null && e.newTransferPartEntries.length >= 1);

      var dirtyBlockTransfers = controller.dirtyBlockTransfers;
      var restoredDirtyBlockTransfers = [];
      controller.setProperties({
        journal: null,
        selectedYear: queryParams.year
      });
      var newEntryPlaceholders = (controller.journal || {}).newEntryPlaceholders;
      this.store.queryRecord('journal', queryParams).then(function (journal) {
        if (newEntryPlaceholders != null && newEntryPlaceholders.length >= 2) journal.set('newEntryPlaceholders', newEntryPlaceholders);
        dirtyBlockTransfers.forEach(function (dBT) {
          var proceedNewTransferEntriesBlock = journal.entries.find(function (e) {
            return e.id == dBT.id;
          });

          if (proceedNewTransferEntriesBlock != null) {
            var _proceedNewTransferEn;

            proceedNewTransferEntriesBlock.newTransferPartEntries.addObjects(dBT.newTransferPartEntries);
            dBT.newTransferPartEntries.forEach(function (tPA) {
              return tPA.set('blockTransfer', proceedNewTransferEntriesBlock);
            });

            (_proceedNewTransferEn = proceedNewTransferEntriesBlock.block_transfer_part_ids).splice.apply(_proceedNewTransferEn, [proceedNewTransferEntriesBlock.block_transfer_part_ids.length, 0].concat(_toConsumableArray(dBT.newTransferPartEntries.map(function (nTPE) {
              return nTPE.tmpId;
            }))));

            if (restoredDirtyBlockTransfers.indexOf(proceedNewTransferEntriesBlock) == -1) restoredDirtyBlockTransfers.pushObject(proceedNewTransferEntriesBlock);
          }
        });
        controller.set('journal', journal);
        controller.dirtyBlockTransfers.pushObjects(restoredDirtyBlockTransfers);
        /*let _initNumNewEntriesDirty = */

        controller.get('journal.numNewEntriesDirty');
        Ember.run.schedule("afterRender", function () {
          Ember.$('.fixJournal .container')[0].scrollTo(0, Ember.$('.fixJournal .container')[0].scrollHeight);
        });
      }); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.journal');
      return result;
    },
    getQueryParams: function getQueryParams(controller) {
      // curPage: alias('journal.curPage'),
      // total: alias('journal.total'),
      // from: alias('journal.from'),
      // to: alias('journal.to'),
      // pageLimit: alias('journal.pageLimit'),
      // pageNums: alias('journal.pageNums'),
      // disablePager: alias('journal.entriesDirty'),
      // searchTerm: alias('journal.searchTerm'),
      var queryParams = {
        limit: controller.pageLimit || 25,
        year: controller.selectedYear || new Date().getFullYear()
      };
      if (controller.curPage != null) queryParams.page = controller.curPage;

      if (controller.get('searchTerm') != null) {
        var searchWordsAnd = controller.get('searchTerm').replace(/ +/, ' ').replace(' ', '+');
        queryParams.query = 'q_and=' + searchWordsAnd;
      }

      return queryParams;
    }
  });

  _exports.default = _default;
});