define("digiprax-web/routes/practice/year-summary", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    deactivate: function deactivate() {
      var result = this._super.apply(this, arguments);

      this.controller.set('entryDetail', null);
      return result;
    },

    /*model(params, transition) {
      return this.store.findRecord('year-summary', moment().format('YYYYM'));
    },*/
    model: function model(_params, _transition) {
      return new Date(); // any model just required to enable refresh (f.ex api_domain-change) ...
    },
    setupController: function setupController(controller, model) {
      var result = this._super(controller, model);

      if (controller.get('application.accounts') == null) {
        this.store.findAll('account', {}).then(function (a) {
          controller.set('application.accounts', a);
        });
      } // controller.setProperties({ loadedYear: moment().format('YYYY'), yearSummary: this.store.findRecord('year-summary', moment().format('YYYY')) });


      var yearSummary = this.store.peekRecord('year-summary', (0, _moment.default)().format('YYYY'));

      if (yearSummary != null) {
        controller.setProperties({
          loadedYear: (0, _moment.default)().format('YYYY'),
          yearSummary: yearSummary.reload()
        });
      } else {
        controller.setProperties({
          loadedYear: (0, _moment.default)().format('YYYY'),
          yearSummary: this.store.findRecord('year-summary', (0, _moment.default)().format('YYYY'))
        });
      } // case reload ...


      controller.get('application').setActiveNavLink('linkTo.year-summary');
      return result;
    }
  });

  _exports.default = _default;
});