define("digiprax-web/routes/practice/revenue-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_params, _transition) {
      return new Date(); // any model just required to enable refresh (f.ex api_domain-change) ...
    },
    setupController: function setupController(controller, model) {
      var result = this._super(controller, model);

      controller.set('model', this.store.queryRecord('revenue-statistic', {})); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.revenue-statistics');
      return result;
    }
  });

  _exports.default = _default;
});