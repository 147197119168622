define("digiprax-web/models/slot-appointments", ["exports", "digiprax-web/models/slot-appointment-data"], function (_exports, _slotAppointmentData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newPatientName = newPatientName;
  _exports.default = void 0;

  // import ArrayProxy from '@ember/array/proxy';
  // import AppointmentLocation from '../mixins/appointment-location';
  function newPatientName(patient) {
    return patient.lastName.toLowerCase();
  }

  var _default = Ember.Object.extend({
    // AppointmentLocation, {
    from: null,
    fromInt: Ember.computed.alias('from'),
    // sortedPatients: null, // A([]),
    searchEngine: null,
    until: null,
    date: null,
    readOnly: true,
    // true,
    appointmentPatients: Ember.computed({
      get: function get(_key) {
        return this.get('_appointmentPatients') || Ember.A([]);
      },
      set: function set(_key, value) {
        return this.set('_appointmentPatients', value);
      }
    }),
    noAppointmentPatients: Ember.computed({
      get: function get(_key) {
        // eslint-disable-next-line ember/no-side-effects
        return this.get('_noAppointmentPatients') || this.set('_noAppointmentPatients', []); // A([]));
      },
      set: function set(_key, value) {
        return this.set('_noAppointmentPatients', value);
      }
    }),
    appointments: Ember.computed(function () {
      return Ember.Object.create({});
    }),
    leftAppointments: Ember.computed('appointmentPatients', function () {
      return this.get('appointmentPatients').filter(function (a) {
        return a.appointment.model.extra_info.slot_side == 'l';
      });
    }),
    rightAppointments: Ember.computed('appointmentPatients', function () {
      return this.get('appointmentPatients').filter(function (a) {
        return a.appointment.model.extra_info.slot_side == 'r';
      });
    }),
    editNoteAppointment: Ember.computed('appointmentPatients.@each.editMode', function () {
      return this.get('appointmentPatients').find(function (a) {
        return a.editMode;
      });
    }),
    time: '',
    available: 'all',
    addAppointment: function addAppointment(appointment) {
      // if (appointment.patient == null)
      //   return null;
      var slotKey = parseInt(appointment.range[0] / 100) * 100; // appointment.range[0];

      var slotAppointments = this.appointments.get(slotKey);

      if (slotAppointments != null) {
        if (slotAppointments.indexOf(appointment.toString()) == -1) slotAppointments.pushObject(appointment.toString());
      } else this.appointments.set(slotKey, Ember.A([appointment.toString()]));

      var appointmentData;

      if (appointment.patient.id != null) {
        if (this.readOnly) {
          appointmentData = _slotAppointmentData.default.create({
            patient: appointment.patient,
            slot: this
          });
        } else {
          appointmentData = this.noAppointmentPatients.find(function (a) {
            return a.patient.id == appointment.patient.id;
          });
          this.noAppointmentPatients.removeObject(appointmentData);
        }
      } else {
        // new_patient
        appointmentData = _slotAppointmentData.default.create({
          newPatientName: newPatientName(appointment.patient),
          patient: appointment.patient,
          slot: this
        });
      }

      appointmentData.set('appointment', appointment);
      this.addAppointmentData(appointmentData);
      return appointmentData;
    },
    removeAppointmentData: function removeAppointmentData(appointmentData) {
      var newProperties = {
        appointmentPatients: this.get('appointmentPatients').toArray()
      };
      newProperties.appointmentPatients.removeObject(appointmentData);
      delete appointmentData.appointment;

      if (!this.readOnly) {
        var newNoAppointmentData = this.get('noAppointmentPatients').toArray();
        newNoAppointmentData.push(appointmentData
        /*SlotAppointmentData.create({patientId: appointmentData.patient.lastName.toLowerCase(), patient: appointmentData.patient, slot: appointmentData.slot})*/
        );
        newNoAppointmentData.sort(function (a1, a2) {
          var result = a1.patient.lastName.localeCompare(a2.patient.lastName);
          if (result == 0) result = a1.patient.firstName.localeCompare(a2.patient.firstName);
          return result;
        });
        newProperties.noAppointmentPatients = newNoAppointmentData;
      }

      this.setProperties(newProperties);
    },
    addAppointmentData: function addAppointmentData(appointmentData) {
      // this.appointmentPatients.pushObject(appointmentData);
      // this.appointmentPatients.sort((a1, a2) => {
      //     let result = a1.patient.lastName.localeCompare(a2.patient.lastName);
      //     if (result  == 0)
      //       result = a1.patient.firstName.localeCompare(a2.patient.firstName);
      //     return result;
      //   });
      var newAppointmentData = this.get('appointmentPatients').toArray();
      newAppointmentData.push(appointmentData);
      newAppointmentData.sort(function (a1, a2) {
        var result = a1.patient.lastName.localeCompare(a2.patient.lastName);
        if (result == 0) result = a1.patient.firstName.localeCompare(a2.patient.firstName);
        return result;
      });
      this.setProperties({
        appointmentPatients: newAppointmentData,
        readOnly: true
      });
    }
  });

  _exports.default = _default;
});