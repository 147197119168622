define("digiprax-web/mixins/invoice-editor-route", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      deleteTreatment: function deleteTreatment(treatment, idx) {
        var _this = this;

        this.set('application.confirmMessage', {
          message: 'Soll die Behandlung unwiderruflich gelöscht werden?',
          callback: function callback() {
            treatment.destroyRecord().then(function (_response) {
              treatment.unloadRecord();

              if (treatment.get('invoiceId') != null) {
                _this.get('model').reload().then(function (_i) {
                  // this.set('model.treatments', this.get('model.store').peekAll('treatment').filter(treatment => treatment.get('invoiceId') == model.id));
                  _this.set('model._entriesAddedOrDeleted', [_this.get('model.entries.length'), 1]);
                });
              }

              var treatments = _this.get('model.sortedTreatments');

              treatments.splice(idx, 1);

              _this.set('model.treatments', treatments.concat([]));

              _this.get('flashMessages').success('Behandlung erfolgreich gelöscht ...', {
                timeout: 3000
              });
            });
          }
        });
        Ember.$('#confirmMessage').trigger('click');
      },
      removeNewTreatment: function removeNewTreatment(treatment, _idx) {
        this.get('model.newTreatmentPlaceholders').removeObject(treatment);
      },
      moveTreatment: function moveTreatment(invoice, treatment, idx, direction) {
        if (treatment.extraInfo.tariffChain) delete treatment.extraInfo.tariffChain;
        if (treatment.extraInfo.tariffChainPosition) delete treatment.extraInfo.tariffChainPosition;

        if (direction == 'u') {
          treatment.extraInfo.oldPositionTS = treatment.extraInfo.positionTS;
          treatment.extraInfo.positionTS = invoice.sortedTreatments[idx - 1].extraInfo.positionTS - 1;
          treatment.set('extraInfo', JSON.parse(JSON.stringify(treatment.extraInfo))); // treatment.extraInfo.setProperties({oldPositionTS: treatment.extraInfo.positionTS,
          //                                    positionTS: invoice.sortedTreatments[idx-1].extraInfo.positionTS - 1});
        } else {
          treatment.extraInfo.oldPositionTS = treatment.extraInfo.positionTS;
          treatment.extraInfo.positionTS = invoice.sortedTreatments[idx + 1].extraInfo.positionTS + 1;
          treatment.set('extraInfo', JSON.parse(JSON.stringify(treatment.extraInfo))); // treatment.extraInfo.setProperties({oldPositionTS: treatment.extraInfo.positionTS,
          //                                    positionTS: invoice.sortedTreatments[idx-1].extraInfo.positionTS + 1});
        }

        invoice.set('dayGroups.updatedAt', new Date());
      }
    },
    _initInvoiceParams: function _initInvoiceParams(invoice) {
      var _this2 = this;

      invoice.get('patient').then(function (patient) {
        invoice.set('loadedPatient', patient);

        _this2.get('store').findAll('additional-text').then(function (_additionalTexts) {
          _this2.get('store').findAll('settlement').then(function (_settlements) {
            _this2.get('store').findAll('tariff').then(function (_tariffs) {
              // for (var tariff of tariffs.toArray()) tariff.set('curSettlementId', patient.get('settlementId'));
              // this.get('store').findAll('tariff-settlement').then(_tariffSettlements => {
              _this2.get('store').query('tariff-settlement', {
                settlementId: patient.get('settlementId')
              }).then(function (_tariffSettlements) {
                var sort = function sort(iP1, iP2) {
                  // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
                  return iP2.get('date') - iP1.get('date') >= 0 ? -1 : 1;
                };

                var sorts = {
                  /*diagnosis: sort, */
                  treatment: sort
                };
                /*
                 * treatments X invoices ... 1:1 :: treatment.invoiceId (FIXME: clarify? 1 behandlung kann nur einmal verrechnet werden)
                 * diagnoses  X invoices ... n:m :: invoice.extraInfo.diagnoseIds
                 */
                // show treatments without invoiceId: var filters = {diagnosis: (invoice, diagnose) => { return invoice.hasDiagnose(diagnose); }, treatment: (invoice, treatment) => { return [null, invoice.id].indexOf(treatment.get('invoiceId')) >= 0; }};
                // show treatments with current-model-invoiceId:

                var filters = {
                  /*diagnosis: (invoice, diagnose) => { return invoice.hasDiagnose(diagnose); }, */
                  treatment: function treatment(invoice, _treatment) {
                    return _treatment.get('invoiceId') == invoice.id;
                  }
                };
                var invoiceParamTypes = [
                /*'diagnosis', */
                'treatment'];

                _this2._loadInvoiceParamsSequentially(invoice, invoiceParamTypes, filters, sorts);
                /*for (var i=0 ; i<invoiceParamTypes.length ; i++) {
                  this._loadInvoiceParams(invoice, invoiceParamTypes[i], sorts[invoiceParamTypes[i]]);
                }*/

              });
            });
          });
        });
      });
    },
    _loadInvoiceParamsSequentially: function _loadInvoiceParamsSequentially(invoice, invoiceParamTypes, filters, sorts) {
      this._loadInvoiceParams(invoice, invoiceParamTypes[0], filters[invoiceParamTypes[0]], sorts[invoiceParamTypes[0]], invoiceParamTypes, filters, sorts);
    },
    _loadInvoiceParams: function _loadInvoiceParams(invoice, invoiceParamType, filter, sort, invoiceParamTypes, filters, sorts) {
      var _this3 = this;

      // this.get('store').findAll(invoiceParamType).then(results => this._setInvoiceParam(invoice, invoiceParamType, results.filter((invoiceParam) => {
      this.get('store').query(invoiceParamType, {
        patientId: invoice.get('patientId')
      }).then(function (results) {
        return _this3._setInvoiceParam(invoice, invoiceParamType, results.filter(function (invoiceParam) {
          return invoiceParam.get('patientId') == invoice.get('patient.id');
        }), filter, sort, invoiceParamTypes, filters, sorts);
      });
    },
    _setInvoiceParam: function _setInvoiceParam(invoice, invoiceParamType, invoiceParam, filter, sort, invoiceParamTypes, filters, sorts) {
      invoice.set('patient.' + (0, _emberInflector.pluralize)(invoiceParamType), invoiceParam);
      var iPList = invoice.get('patient.' + (0, _emberInflector.pluralize)(invoiceParamType));
      invoiceParam = new Array();

      for (var i = 0; i < iPList.length; i++) {
        if (filter(invoice, iPList[i])) {
          // iPList[i].type = invoiceParamType; // required by ember _buildInternalModel
          invoiceParam.push(iPList[i]);

          if (invoiceParamType == 'treatment') {
            if (iPList[i].get('tariffId') != null) {
              var tariff = this.get('store').peekRecord('tariff', iPList[i].get('tariffId')); // tariff.type = invoiceParamType; // required by ember _buildInternalModel

              iPList[i].set('tariff', tariff);
            } else {
              iPList[i].set('tariff', Ember.Object.create({
                name: iPList[i].get('notes'),
                settlementTariff: Ember.Object.create({
                  default: 0
                })
              }));
              iPList[i].set('number', 1);
            }

            if (iPList[i].get('invoiceId') != null) {
              iPList[i].set('invoice', invoice);
            }

            iPList[i].set('targetInvoice', invoice);
          }
        }
      }

      invoice.set((0, _emberInflector.pluralize)(invoiceParamType), invoiceParam.sort(sort)); // this.set('controller.'+pluralize(invoiceParamType), invoiceParam.sort(sort));

      if (invoiceParamTypes.length >= 2) {
        this._loadInvoiceParamsSequentially(invoice, invoiceParamTypes.slice(1), filters, sorts);
      }
    }
    /*,
    _newTreatmentPlaceholders(invoice) {
     var newTreatmentPlaceholders = new Array();
     for (var i=0 ; i<this.get('numNewTreatmentPlaceholders') ; i++) {
       newTreatmentPlaceholders.push(NewTreatmentPlaceholder.create({
         invoiceId: invoice.get('id')
       }));
     }
     return newTreatmentPlaceholders;
    }*/

  });

  _exports.default = _default;
});