define("digiprax-web/routes/practice/cashbook", ["exports", "digiprax-web/mixins/practice-route"], function (_exports, _practiceRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_practiceRoute.default, {
    // model(_params, _transition) {
    //   return this.store.queryRecord('cashbook', {});
    // },
    model: function model(_params, _transition) {
      return new Date(); // any model just required to enable refresh (f.ex api_domain-change) ...
    },
    setupController: function setupController(controller, model) {
      var result = this._super(controller, model);

      if (controller.get('application.accounts') == null) {
        this.store.findAll('account', {}).then(function (a) {
          controller.set('application.accounts', a);
        });
      } // controller.set('cashbook', model);
      // let pageLimit = controller.pageLimit || 25;


      var queryParams = this.getQueryParams(controller);
      controller.setProperties({
        cashbook: null,
        selectedYear: queryParams.year
      });
      var newEntryPlaceholders = (controller.cashbook || {}).newEntryPlaceholders;
      this.store.queryRecord('cashbook', queryParams).then(function (cB) {
        if (newEntryPlaceholders != null && newEntryPlaceholders.length >= 2) cB.set('newEntryPlaceholders', newEntryPlaceholders);
        controller.set('cashbook', cB);
        /*let _initNumNewEntriesDirty = */

        controller.get('cashbook.numNewEntriesDirty');
        Ember.run.schedule("afterRender", function () {
          Ember.$('.fixCashBook .container')[0].scrollTo(0, Ember.$('.fixCashBook .container')[0].scrollHeight);
        });
      }); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.cashbook');
      return result;
    },
    getQueryParams: function getQueryParams(controller) {
      // curPage: alias('cashbook.curPage'),
      // total: alias('cashbook.total'),
      // from: alias('cashbook.from'),
      // to: alias('cashbook.to'),
      // pageLimit: alias('cashbook.pageLimit'),
      // pageNums: alias('cashbook.pageNums'),
      // disablePager: alias('cashbook.entriesDirty'),
      // searchTerm: alias('cashbook.searchTerm'),
      var queryParams = {
        limit: controller.pageLimit || 25,
        year: controller.selectedYear || new Date().getFullYear()
      };
      if (controller.curPage != null) queryParams.page = controller.curPage;

      if (controller.get('searchTerm') != null) {
        var searchWordsAnd = controller.get('searchTerm').replace(/ +/, ' ').replace(' ', '+');
        queryParams.query = 'q_and=' + searchWordsAnd;
      }

      return queryParams;
    }
  });

  _exports.default = _default;
});