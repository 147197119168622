define("digiprax-web/routes/customers/edit/invoices/edit", ["exports", "digiprax-web/mixins/invoice-editor-p-route"], function (_exports, _invoiceEditorPRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_invoiceEditorPRoute.default, {
    beforeModel: function beforeModel(transition) {
      // console.log('from.patient_id = ' + (transition.from ? transition.from.parent.parent.params.patient_id : null) + ', to.patient_id = ' + transition.to.parent.parent.params.patient_id);
      if (transition.to.parent.parent.params.patient_id == null) return this.transitionTo('patients.overview');
      return this._super(transition);
    },
    model: function model(params, _transition) {
      // this.application.showProgressbar();
      return this.store.findRecord('invoice-for-business', params['invoice_id']);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get('patientId') != 'new') {
        localStorage.setItem('peru.lastViewedPatientId', '"' + model.get('patientId') + '"');
        localStorage.setItem('lastViewedPatientId', model.get('patientId'));
      }

      var patient = this.store.peekRecord('patient', model.get('patientId'));
      model.set('patient', patient); // model.setProperties({ patient: patient, liableToSalesTax: controller.get('application.practice.liable_to_sales_tax')||false });

      this._initInvoiceParams(model);
      /*let _initNumNewLotsDirty = */


      model.get('numNewLotsDirty');
      /*let _initIsDirty = */

      model.get('isDirty');
      controller.set('actions.deleteLot', this.get('actions.deleteLot'));
      controller.set('actions.removeNewLot', this.get('actions.removeNewLot'));
      controller.set('actions.moveLot', this.get('actions.moveLot')); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.patientenrechnung');
    }
  });

  _exports.default = _default;
});