define("digiprax-web/serializers/custom-tariff-chain", ["exports", "digiprax-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      store.pushPayload({
        tariffChain: {
          id: payload.response.id,
          item: payload.response.item,
          name: payload.response.name
        }
      });
      payload.response.tariffChainItems.forEach(function (tariffChainItem) {
        store.pushPayload({
          tariffChainItem: tariffChainItem
        });
      });
      return this._super(store, primaryModelClass, payload, payload.response.id, requestType);
    }
  });

  _exports.default = _default;
});