define("digiprax-web/helpers/inc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inc = inc;
  _exports.default = void 0;

  // increase with numbers, include/append with strings is abuse
  function inc(params, _hash) {
    return params[0] + (params[1] || 1);
  }

  var _default = Ember.Helper.helper(inc);

  _exports.default = _default;
});