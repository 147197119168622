define("digiprax-web/templates/practice/entry-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "habQe8BM",
    "block": "{\"symbols\":[\"entry\",\"index\",\"entry\",\"index\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"application\",\"currentRouteName\"]],\"practice.cashbook\"],null]],null,{\"statements\":[[0,\"  \"],[15,\"practice/cashbook-entry-row\",[]],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"application\",\"currentUser\",\"plus\"]],[24,[\"application\",\"practice\",\"liable_to_sales_tax\"]],[24,[\"entry\",\"displayNewTransferPartEntries\"]]],null]],null,{\"statements\":[[4,\"each\",[[28,\"get\",[[28,\"or\",[[24,[\"entry\",\"blockTransfer\"]],[24,[\"entry\"]]],null],\"newTransferPartEntries\"],null]],null,{\"statements\":[[0,\"      \"],[15,\"practice/cashbook-entry-row\",[3,4]],[0,\"\\n\"]],\"parameters\":[3,4]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"application\",\"currentRouteName\"]],\"practice.journal\"],null]],null,{\"statements\":[[0,\"  \"],[15,\"practice/journal-entry-row\",[]],[0,\"\\n\"],[4,\"if\",[[24,[\"entry\",\"displayNewTransferPartEntries\"]]],null,{\"statements\":[[4,\"each\",[[28,\"get\",[[28,\"or\",[[24,[\"entry\",\"blockTransfer\"]],[24,[\"entry\"]]],null],\"newTransferPartEntries\"],null]],null,{\"statements\":[[0,\"      \"],[15,\"practice/journal-entry-row\",[1,2]],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "digiprax-web/templates/practice/entry-row.hbs"
    }
  });

  _exports.default = _default;
});