define("digiprax-web/controllers/patients/index", ["exports", "digiprax-web/controllers/base", "digiprax-web/mixins/auto-complete-patient-search"], function (_exports, _base, _autoCompletePatientSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_autoCompletePatientSearch.default, {
    actions: {
      editPatient: function editPatient(patient) {
        // this.model.save();
        this.transitionToRoute('patients.edit.index', patient);
      },
      createPatient: function createPatient() {
        // this.model.save();
        this.transitionToRoute('/');
      },
      deletePatient: function deletePatient(patient, _idx) {
        var _this = this;

        // this.model.save();
        this.set('application.confirmMessage', {
          message: 'Soll der Patient gelöscht werden?',
          callback: function callback() {
            patient.destroyRecord().then(function (_response) {
              //this.get('model').save();
              patient.unloadRecord(); // $('html, body').animate({scrollTop: 0}, 500);

              _this.get('flashMessages').success('Patient erfolgreich gelöscht ...', {
                timeout: 3000
              });
            });
          }
        });
        Ember.$('#confirmMessage').trigger('click');
      },
      exportPatients: function exportPatients() {
        var anchor = document.createElement("a");
        document.body.appendChild(anchor);
        var headers = new Headers();
        headers.append('Authorization', "Bearer ".concat(this.get('application.session.session.content.authenticated.access_token')));
        fetch(this.application.patientenExportUrl, {
          headers: headers
        }).then(function (response) {
          return response.blob();
        }).then(function (blobby) {
          var objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = 'patienten.csv';
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
        });
      },
      exportPatientenkarten: function exportPatientenkarten() {
        var anchor = document.createElement("a");
        document.body.appendChild(anchor);
        var headers = new Headers();
        headers.append('Authorization', "Bearer ".concat(this.get('application.session.session.content.authenticated.access_token')));
        fetch(this.application.patientenkartenExportUrl, {
          headers: headers
        }).then(function (response) {
          return response.blob();
        }).then(function (blobby) {
          var objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = 'behandlungen.csv';
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
        });
      }
    }
  });

  _exports.default = _default;
});