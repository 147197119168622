define("digiprax-web/helpers/safe-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // params[0] ... css-attribute
  // params[1] ... css-value with optional :value-placeholder
  // params[3](optional) ... :value
  var _default = Ember.Helper.extend({
    compute: function compute(params, _hash) {
      return Ember.String.htmlSafe(params[0] + ': ' + params[1].replace(':value', params[2] || ''));
    }
  });

  _exports.default = _default;
});