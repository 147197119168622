define("digiprax-web/mixins/appointment-location", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    appointments: Ember.Object.create({}),
    // per day: key: YYYYMMDD
    addAppointment: function addAppointment(appointment) {
      var dayKey = _moment.default.format(appointment.date, 'YYYYMMDD');

      var appointmentsForDay = this.appointments.get(dayKey);
      if (appointmentsForDay == null) appointmentsForDay = this.appointments.set(dayKey, Ember.A([appointment]));else appointmentsForDay.pushObject(appointment);
      return appointment;
    }
  });

  _exports.default = _default;
});