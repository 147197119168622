define("digiprax-web/mixins/invoice-treatments", ["exports", "moment", "digiprax-web/models/new-treatment-placeholder"], function (_exports, _moment, _newTreatmentPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    invoiceNumberPrint: Ember.computed.alias('invoiceNumber'),
    domain: 'practice',
    entries: Ember.computed.alias('treatments'),
    sortedEntries: Ember.computed.alias('sortedTreatments'),
    newEntryPlaceholders: Ember.computed.alias('newTreatmentPlaceholders'),
    sortedNewEntryPlaceholders: Ember.computed.alias('sortedNewTreatmentPlaceholders'),
    // _newEntryPlaceholders: alias('_newTreatmentPlaceholders'),
    hasTreatments: Ember.computed.alias('hasEntries'),
    entriesDirty: Ember.computed.alias('treatmentsDirty'),
    sortByTariffChain: function sortByTariffChain(t1, t2) {
      // tariff-chains are sorted before
      if (!(typeof t1.get('extraInfo') == "undefined" || t1.get('extraInfo') == null)) {
        if (!(typeof t2.get('extraInfo') == "undefined" || t2.get('extraInfo') == null)) {
          if (!(typeof t1.get('extraInfo.tariffChain') == "undefined" || t1.get('extraInfo.tariffChain') == null)) {
            if (!(typeof t2.get('extraInfo.tariffChain') == "undefined" || t2.get('extraInfo.tariffChain') == null)) {
              // both tariff chains
              if (t1.get('extraInfo.tariffChain') == t2.get('extraInfo.tariffChain')) {
                return t1.get('extraInfo.tariffChainPosition') > t2.get('extraInfo.tariffChainPosition') ? 1 : -1;
              } else {
                return t1.get('extraInfo.tariffChain') > t2.get('extraInfo.tariffChain') ? 1 : -1;
              }
            } else {
              // only t1 tariffchain
              // return -1;
              return this.sortByPosition(t1, t2);
            }
          } else if (!(typeof t2.get('extraInfo.tariffChain') == "undefined" || t2.get('extraInfo.tariffChain') == null)) {
            // only t2 tariffchain
            // return 1;
            return this.sortByPosition(t1, t2);
          }
        } else {
          if (!(typeof t1.get('extraInfo.tariffChain') == "undefined" || t1.get('extraInfo.tariffChain') == null)) {
            // only t1 tariffchain
            // return -1;
            return this.sortByPosition(t1, t2);
          }
        }
      } else if (!(typeof t2.get('extraInfo') == "undefined" || t2.get('extraInfo') == null)) {
        if (!(typeof t2.get('extraInfo.tariffChain') == "undefined" || t2.get('extraInfo.tariffChain') == null)) {
          // only t2 tariffchain
          // return 1;
          return this.sortByPosition(t1, t2);
        }
      }

      return null;
    },
    sortableTreatments: Ember.computed('treatments', 'treatments.@each.date', 'treatments.@each.extraInfo'
    /*.positionTS'*/
    , function () {
      return this.get('treatments');
    }),
    sortedTreatments: Ember.computed.sort('sortableTreatments', function (i1, i2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (i1.get('dateFmtd') == i2.get('dateFmtd')) {
        var tariffChainSort = this.sortByTariffChain(i1, i2);
        if (tariffChainSort != null) return tariffChainSort;
        var positionSort = this.sortByPosition(i1, i2);
        if (positionSort != null) return positionSort;
      }

      return i2.get('date') - i1.get('date') >= 0 ? -1 : 1;
    }),
    sortableNewTreatmentPlaceholders: Ember.computed('newTreatmentPlaceholders', 'newTreatmentPlaceholders.@each.date', 'newTreatmentPlaceholders.@each.extraInfo'
    /*.positionTS'*/
    , function () {
      return this.get('newTreatmentPlaceholders');
    }),
    sortedNewTreatmentPlaceholders: Ember.computed.sort('sortableNewTreatmentPlaceholders', function (i1, i2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (!i1.isDirty()) return 1;
      if (!i2.isDirty()) return -1;
      if (i1.get('date') == null) return 1;
      if (i2.get('date') == null) return -1;

      if (i1.get('dateFmtd') == i2.get('dateFmtd')) {
        /* old
        // tariffChain-items on same day are always set older. otherwise new lines are added before
        var tariffChainSort = this.sortByTariffChain(i1, i2);
        if (tariffChainSort != null) return tariffChainSort;
        */
        var tariffChainSort = this.sortByTariffChain(i1, i2);
        if (tariffChainSort != null) return tariffChainSort;
        var positionSort = this.sortByPosition(i1, i2);
        if (positionSort != null) return positionSort;
      }

      return i2.get('date') - i1.get('date') >= 0 ? -1 : 1;
    }),
    treatmentsDirty: Ember.computed('treatments.@each.date', 'treatments.@each.tariffId', 'treatments.@each.treatmentText', 'treatments.@each.number', 'treatments.@each.costFmtd', 'numNewTreatmentsDirty', function () {
      return this.get('numNewTreatmentsDirty') >= 1 || (this.get('treatments') || []).toArray().filter(function (t) {
        return t.isDirty();
      }).length >= 1;
    }),
    newTreatmentPlaceholders: Ember.computed('numNewTreatmentsDirty', 'savedAt', {
      get: function get(_key) {
        var newTreatmentPlaceholders = this.get('_newTreatmentPlaceholders');

        if (newTreatmentPlaceholders == null) {
          var nTPs = new Array();

          for (var i = 0; i < this.get('numNewTreatmentPlaceholders'); i++) {
            nTPs.push(_newTreatmentPlaceholder.default.create({
              tmpId: Math.round(Math.random(1000000) * 1000000),
              patientId: this.get('patient.id') || this.get('patientId'),
              invoiceId: this.get('id'),
              invoice: this,
              positionTS: new Date().getTime() + (i + 1)
            }));
          }

          newTreatmentPlaceholders = Ember.A(nTPs); // eslint-disable-next-line ember/no-side-effects

          this.set('_newTreatmentPlaceholders', newTreatmentPlaceholders);
        } else {
          var numDirty = this._numNewTreatmentsDirty(newTreatmentPlaceholders.toArray());

          if (newTreatmentPlaceholders.toArray().length < this.get('numNewTreatmentPlaceholders') + numDirty) {
            // Ember.Logger.debug('nTPs.length = '+newTreatmentPlaceholders.toArray().length+', numDirty = '+numDirty);
            newTreatmentPlaceholders.pushObject(_newTreatmentPlaceholder.default.create({
              tmpId: Math.round(Math.random(1000000) * 1000000),
              patientId: this.get('patient.id') || this.get('patientId'),
              invoiceId: this.get('id'),
              invoice: this,
              positionTS: new Date().getTime()
            }));
          }
        }

        return newTreatmentPlaceholders;
      },
      set: function set(_key, value) {
        this.set('_newTreatmentPlaceholders', value);
        return value;
      }
    }),
    numNewTreatmentsDirty: Ember.computed('newTreatmentPlaceholders.@each.date', 'newTreatmentPlaceholders.@each.tariff', 'newTreatmentPlaceholders.@each.treatmentText', 'newTreatmentPlaceholders.@each.number', 'newTreatmentPlaceholders.@each.costFmtd', function () {
      return this._numNewTreatmentsDirty(this.get('newTreatmentPlaceholders').toArray());
    }),
    _numNewTreatmentsDirty: function _numNewTreatmentsDirty(newTreatmentPlaceholders) {
      var num = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = newTreatmentPlaceholders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var treatment = _step.value;
          // if (treatment.get('hasDirtyAttributes')) num = num + 1;
          if (treatment.isDirty()) num = num + 1;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return num;
    },
    testDetails: Ember.computed.alias('extraInfo.testDetails.text'),
    diagnoseText: Ember.computed('invoiceMonth', 'loadedPatient.treatments.[]', {
      get: function get(_key) {
        var extraInfoAvailable = !(typeof this.get('extraInfo') == "undefined" || this.get('extraInfo') == null);
        var diagnoseTextAvailable = false;
        if (extraInfoAvailable) diagnoseTextAvailable = !(typeof this.get('extraInfo.diagnoseText') == "undefined" || this.get('extraInfo.diagnoseText') == null);

        if (diagnoseTextAvailable) {
          if (this.loadedPatient == null) return this.get('extraInfo.diagnoseText'); // loadedPatient might be null while background-loading
          // check if new diagnoses where added in invoice-month, if so then add - but only if no meanwhile custom modifications where done

          var diagnoseFilter = this.diagnoseFilter();
          var visitTreatmentsInInvoiceMonth = diagnoseFilter(this.get('loadedPatient.sortedTreatments'), (0, _moment.default)(this.get('date')).format('YYYY') + this.get('invoiceMonthNum'));

          if (visitTreatmentsInInvoiceMonth.length >= 1) {
            var defaultDiagnoseText = visitTreatmentsInInvoiceMonth.join(', ');

            if (defaultDiagnoseText.startsWith(this.get('extraInfo.diagnoseText'))) {
              return defaultDiagnoseText;
            }
          }

          return this.get('extraInfo.diagnoseText');
        } else {
          if (this.get('id') == 'new') {
            var _diagnoseFilter = this.diagnoseFilter();

            var _visitTreatmentsInInvoiceMonth = _diagnoseFilter(this.get('loadedPatient.sortedTreatments'), (0, _moment.default)(this.get('date')).format('YYYY') + this.get('invoiceMonthNum'));

            if (_visitTreatmentsInInvoiceMonth.length >= 1) {
              return _visitTreatmentsInInvoiceMonth.join(', ');
            } else {
              /**
               * TODO: here we try getting diagnoseText from previous invoices. but is this in specification?
               */
              if (this.get('loadedPatient.sortedInvoices').toArray().length >= 2) {
                // new one is in sortedInvoices, index depends on date
                var prevInvoice_1 = this.get('loadedPatient.sortedInvoices').toArray()[this.get('loadedPatient.sortedInvoices').toArray().length - 1];
                var prevInvoice_2;

                if (prevInvoice_1.get('id') != 'new' && prevInvoice_1.get('id') != this.get('id') && prevInvoice_1.get('diagnoseText') != null && !prevInvoice_1.get('diagnoseText').pop) {
                  return prevInvoice_1.get('diagnoseText');
                } else if ((prevInvoice_2 = this.get('loadedPatient.sortedInvoices').toArray()[this.get('loadedPatient.sortedInvoices').toArray().length - 2]).get('id') != 'new' && prevInvoice_2.get('id') != this.get('id') && prevInvoice_2.get('diagnoseText') != null && !prevInvoice_2.get('diagnoseText').pop) {
                  return prevInvoice_2.get('diagnoseText');
                }
              } // like visitTreatmentsInInvoiceMonth with older date


              if (this.get('loadedPatient.sortedTreatments').length >= 1) {
                var _diagnoseFilter2 = this.diagnoseFilter(); // return diagnoseFilter(this.get('loadedPatient.sortedTreatments'), moment(this.get('loadedPatient.sortedTreatments.lastObject.date')).format('YYYYMM')).


                var sortedTreatments = this.get('loadedPatient.sortedTreatments').filter(function (t) {
                  return t.get('invoiceId') == null && [null, '', ' '].indexOf(t.get('diagnosisText') || null) == -1;
                });

                if (sortedTreatments.length >= 1) {
                  return _diagnoseFilter2(this.get('loadedPatient.sortedTreatments'), (0, _moment.default)(sortedTreatments[sortedTreatments.length - 1].date).format('YYYYMM')).join(', ');
                } else {
                  return _diagnoseFilter2(this.get('loadedPatient.sortedTreatments'), null).join(', ');
                }
              }
            }
          }

          return null;
        }
      },
      set: function set(_key, value) {
        var extraInfoAvailable = !(typeof this.get('extraInfo') == "undefined" || this.get('extraInfo') == null);

        if (extraInfoAvailable) {
          this.set('extraInfo.diagnoseText', value);
        } else {
          this.set('extraInfo', {
            diagnoseText: value
          });
        }

        this.set('extraInfoIsDirtyFlag', new Date());
        return value;
      }
    }),
    hasDiagnose: function hasDiagnose(diagnose) {
      var extraInfoAvailable = !(typeof this.get('extraInfo') == "undefined" || this.get('extraInfo') == null);
      if (extraInfoAvailable) extraInfoAvailable = !(typeof this.get('extraInfo.diagnoseIds') == "undefined" || this.get('extraInfo.diagnoseIds') == null);

      if (extraInfoAvailable) {
        return this.get('extraInfo.diagnoseIds').toArray().indexOf(diagnose.id) >= 0;
      } else {
        return false;
      }
    },
    diagnoseFilter: function diagnoseFilter() {
      return function (treatments, maxDateYYYYMM) {
        return treatments.filter(function (treatment) {
          return treatment.get('invoiceId') == null && // ' ' because it's possible to save whitespace instead of diagnosis-text in patientenkarte
          // if diagnosis didn't change to preavious treatment
          [null, '', ' '].indexOf(treatment.get('diagnosisText') || null) == -1 && (maxDateYYYYMM == null || (0, _moment.default)(treatment.get('date')).format('YYYYMM') == maxDateYYYYMM);
        }).map(function (treatment) {
          return (treatment.get('diagnosisText') || '').trim();
        }). // remove double-entries
        filter(function (value, index, self) {
          return self.indexOf(value) == index;
        }); // <=> uniq()
      };
    },
    gebuehDialogType: Ember.computed({
      get: function get(_key) {
        return this.get('_gebuehDialogType') || 'tariff';
      },
      set: function set(_key, value) {
        return this.set('_gebuehDialogType', value || 'tariff');
      }
    }),
    containsTreatmentWithBioresonanz: Ember.computed('treatments.@each.invoiceText', 'newTreatmentPlaceholders.@each.invoiceText', function () {
      if ((this.get('treatments') || []).find(function (t) {
        return (t.get('invoiceText') || '').trim().endsWith('*');
      }) != null) return true;
      if ((this.get('newTreatmentPlaceholders') || []).find(function (t) {
        return (t.get('invoiceText') || '').trim().endsWith('*');
      }) != null) return true;
      return false;
    })
  });

  _exports.default = _default;
});