define("digiprax-web/models/appointment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { htmlSafe } from '@ember/template';
  // import ArrayProxy from '@ember/array/proxy';
  var _default = Ember.Object.extend({
    // { roomId: 'room1', placeId: 'place1', from: '8:15', until: '10:00', range: [815, 1000], patient: patientIterator.next(), hp: 'icke', toString: function(){ return this.from+' - '+this.until+': '+this.patient.get('lastName'); } }
    model: null,
    room: null,
    place: null,
    // from: null,   // '8:15'
    // until: null,     // '10:00'
    // patient: null,
    // virtual
    // allPlaces: place == null,
    // todaysLocationData: todaysLocationData,
    // slotDuration: slotDuration,
    // timesFrom: timesFrom,
    // timesUntil: timesUntil,
    patients: Ember.A([]),
    // replace this.patients with this.store.findRecord when api is ready for calendar
    slot: null,
    // date: null,
    patient: Ember.computed({
      get: function get(_key) {
        return this.get('_patient');
      },
      set: function set(_key, selectChangeEventOrValue) {
        if (selectChangeEventOrValue != null) {
          var patient = this.patients.find(function (p) {
            return p.id == (selectChangeEventOrValue.target != null ? selectChangeEventOrValue.target.value : selectChangeEventOrValue);
          });
          return this.set('_patient', selectChangeEventOrValue.target != null ? selectChangeEventOrValue.target.value == '' ? null : patient : selectChangeEventOrValue);
        } else return this.set('_patient', selectChangeEventOrValue);
      }
    }),
    from: Ember.computed({
      get: function get(_key) {
        return this.get('_from');
      },
      set: function set(_key, selectChangeEventOrValue) {
        var _from = selectChangeEventOrValue.target != null ? selectChangeEventOrValue.target.value == '' ? null : selectChangeEventOrValue.target.value : selectChangeEventOrValue != null ? selectChangeEventOrValue == '' ? null : selectChangeEventOrValue : null; // if (this.get('until') == null || this.parseTimeToInt(this.get('until')) < this.parseTimeToInt(_from || '0:00')) this.set('until', selectChangeEventOrValue);


        if (this.get('until') == null || parseInt(this.get('until').replace(':', '')) < parseInt((_from || '0:00').replace(':', ''))) this.set('until', selectChangeEventOrValue);
        return this.set('_from', _from);
      }
    }),
    until: Ember.computed({
      get: function get(_key) {
        return this.get('_until');
      },
      set: function set(_key, selectChangeEventOrValue) {
        return this.set('_until', selectChangeEventOrValue.target != null ? selectChangeEventOrValue.target.value == '' ? null : selectChangeEventOrValue.target.value : selectChangeEventOrValue != null ? selectChangeEventOrValue == '' ? null : selectChangeEventOrValue : null);
      }
    }),
    editMode: false,
    color: Ember.computed.alias('model.extra_info.color'),
    note: Ember.computed.alias('model.extra_info.note'),
    // note: computed({
    //   get(_key) {
    //     return this.get('model.extra_info.note');
    //   },
    //   set(_key, selectChangeEventOrValue) {
    //     if (selectChangeEventOrValue != null) {
    //       return this.set('model.extra_info.note', selectChangeEventOrValue.target != null ? selectChangeEventOrValue.target.value : selectChangeEventOrValue);
    //     } else
    //       return this.set('model.extra_info.note', selectChangeEventOrValue);
    //   }
    // }),
    // lastAppointments: computed(function(){
    //   return [{ all: room.appointments.reverse().find(a => a.range[0] >= this.slot.fromInt && a.range[0] < this.slot.fromInt+this.slotDuration) }].concat(room.places).reduce((acc, curPlace) => {
    //     acc[curPlace.id] = curPlace.appointments.reverse().find(a => a.range[0] >= this.slot.fromInt && a.range[0] < this.slot.fromInt+this.slotDuration);
    //     if (acc[curPlace.id] != null && (acc.all == null || acc[curPlace.id].range[0] >= acc.all.range[0])) acc.all = acc[curPlace.id];
    //     return acc;
    //   });
    // }),
    // nextAppointments: computed(function(){
    //   return [{ all: room.appointments.find(a => a.range[0] >= this.slot.fromInt+this.slotDuration) }].concat(room.places).reduce((acc, curPlace) => {
    //     acc[curPlace.id] = curPlace.appointments.find(a => a.range[0] >= this.slot.fromInt+this.slotDuration);
    //     if (acc[curPlace.id] != null && (acc.all == null || acc[curPlace.id].range[0] < acc.all.range[0])) acc.all = acc[curPlace.id];
    //     return acc;
    //   });
    // }),
    availabilities: Ember.computed(function () {
      var _this = this;

      // let locationAvailabilities = [{ all: this.todaysLocationData[this.room.id].all.availableRanges.filter(range => range[0] >= this.slot.fromInt && range[0] < this.slot.fromInt+this.slotDuration).map(aR => { return { range: aR }; }) }].concat(room.places).reduce((acc, curPlace) => {
      var locationAvailabilities = [{
        all: []
      }].concat(this.room.places).reduce(function (acc, curPlace) {
        acc[curPlace.id] = [];
        var appointments = curPlace.appointments.filter(function (a) {
          return a.range[1] > _this.slot.fromInt;
        }
        /* && a.range[0] < this.slot.fromInt+this.slotDuration*/
        ).concat(_this.room.appointments.filter(function (a) {
          return a.range[1] > _this.slot.fromInt;
        }
        /* && a.range[0] < this.slot.fromInt+this.slotDuration*/
        )).sort(function (a1, a2) {
          return a1.range[0] > a2.range[0] ? 1 : -1;
        });

        _this.mergeSlotRanges(_this.room, curPlace, acc[curPlace.id], _this.slot, _this.slotDuration, curPlace.lastSlotEnd, _this.todaysLocationData[_this.room.id][curPlace.id].availableRanges, appointments);

        return acc;
      });
      var appointments = [{
        appointments: this.room.appointments.filter(function (a) {
          return a.range[1] > _this.slot.fromInt;
        }
        /* && a.range[0] < this.slot.fromInt+this.slotDuration*/
        )
      }].concat(this.room.places).reduce(function (acc, curPlace) {
        var curPlaceSlotAppointments = curPlace.appointments.filter(function (a) {
          return a.range[1] > _this.slot.fromInt;
        }
        /* && a.range[0] < this.slot.fromInt+this.slotDuration*/
        );
        acc.appointments = acc.appointments.concat(curPlaceSlotAppointments);
        return acc;
      }).appointments.sort(function (a1, a2) {
        return a1.range[0] > a2.range[0] ? 1 : -1;
      });
      this.mergeSlotRanges(this.room, null, locationAvailabilities.all, this.slot, this.slotDuration, this.room.slotLimits[1], this.todaysLocationData[this.room.id].all.availableRanges, appointments);
      return locationAvailabilities;
    }),
    mergeSlotRanges: function mergeSlotRanges(room, place, availabilities, slot, slotDuration, lastSlotEnd, availableRanges, appointments) {
      var numAvailabilities = availabilities.length;
      var curStartTimeInt = slot.fromInt;
      availableRanges.filter(function (range) {
        return range[1] > slot.fromInt && range[0] < slot.fromInt + slotDuration;
      }).forEach(function (availableRange) {
        // f.ex. slot 900-1000
        if (availableRange[0] <= curStartTimeInt) {
          // availableRange [900, 915]
          //
          availabilities.push({
            range: [curStartTimeInt, availableRange[1]],
            room: room,
            place: place
          });

          if (availableRange[1] < lastSlotEnd
          /*slot.fromInt+slotDuration*/
          ) {
              // there must be another appointment
              var appointment = appointments.find(function (a) {
                return a.range[0] >= availableRange[1];
              });
              availabilities.push({
                isAppointment: true,
                appointment: appointment,
                range: appointment.range
              });
            }
        } else {
          // appointment [<=900, 900+]
          var _appointment = appointments.find(function (a) {
            return a.range[1] > curStartTimeInt;
          });

          availabilities.push({
            isAppointment: true,
            appointment: _appointment,
            range: _appointment.range
          });

          if (availableRange[1] >= slot.fromInt + slotDuration) {
            availabilities.push({
              range: [availableRange[0], availableRange[1]],
              room: room,
              place: place
            });
            if (_appointment.nextAppointment != null) availabilities.push({
              isAppointment: true,
              appointment: _appointment.nextAppointment,
              range: _appointment.nextAppointment.range
            });
          }
        }

        curStartTimeInt = availabilities[availabilities.length - 1].range[1];
      });
      if (numAvailabilities == availabilities.length) // no availableRanges means full of appointments
        appointments.forEach(function (a, idx, as) {
          if (idx == 0 || as[idx - 1].range[0] < slot.fromInt + slotDuration) availabilities.push({
            isAppointment: true,
            appointment: a,
            range: a.range
          });
        });
    }
    /*,
    parseTimeToInt(timeString) {
     let hours = parseInt(timeString.match(/(^00?|[1-9][0-9]?):/)[1]);
     return hours * 100 + parseInt(timeString.match(/[0-9][0-9]$/)[0]);
    }*/
    ,
    // range: A([]), // [815, 1000]
    // range: computed('from', 'until', function(){
    //   return A([parseInt(this.from.replace(':', '')), parseInt(this.until.replace(':', ''))]);
    // }),
    range: Ember.computed({
      get: function get(_key) {
        return this._range || Ember.A([parseInt(this.from.replace(':', '')), parseInt(this.until.replace(':', ''))]);
      },
      set: function set(_key, value) {
        return this.set('_range', value);
      }
    }),
    hp: null,
    roomId: Ember.computed('room', function () {
      return this.get('room.id');
    }),
    placeId: Ember.computed('place', function () {
      return this.get('place.id');
    }),
    toString: function toString() {
      return this.from + ' - ' + this.until + ': ' + this.get('patient.lastName');
    },
    nextAppointment: Ember.computed('room.sortableAppointments.[]', 'place.sortableAppointments.[]', function () {
      var _this2 = this;

      var nextInRoom = this.room.appointments.find(function (a) {
        return a.range[0] >= _this2.range[1];
      });
      var nextInPlace = this.place != null ? this.place.appointments.find(function (a) {
        return a.range[0] >= _this2.range[1];
      }) : null;
      if (nextInRoom != null) return nextInPlace != null ? nextInRoom.range[0] < nextInPlace.range[0] ? nextInRoom : nextInPlace : nextInRoom;else return nextInPlace;
    })
  });

  _exports.default = _default;
});