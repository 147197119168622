define("digiprax-web/components/api-pager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{api-pager modelName='cashbook' pageable=this store=store onLoad=entriesLoaded classNames='api-pager'}}
  var _default = Ember.Component.extend({
    modelName: null,
    onLoad: null,
    store: null,
    pageable: null,
    // cashbook | journal. has total and pageLimit
    disablePager: Ember.computed.alias('pageable.disablePager'),
    year: Ember.computed.alias('pageable.selectedYear'),
    searchTerm: Ember.computed.alias('pageable.searchTerm'),
    curPage: Ember.computed.alias('pageable.curPage'),
    // numTotal: alias('pageable.total'),
    numTotal: Ember.computed('pageable.total', function () {
      // console.log('ApiPager - numTotal: numTotal = ' + this.get('pageable.total'));
      return this.get('pageable.total') || 0;
    }),
    from: Ember.computed.alias('pageable.from'),
    to: Ember.computed.alias('pageable.to'),
    // perPage: alias('pageable.pageLimit'),
    perPage: Ember.computed('pageable.perPage', function () {
      // console.log('ApiPager - perPage: perPage = ' + this.get('pageable.perPage'));
      return this.get('pageable.perPage') || 25;
    }),
    totalPages: Ember.computed('numTotal', 'perPage', function () {
      return this.get('numTotal') >= 1 ? parseInt((this.get('numTotal') - 1) / this.get('perPage')) + 1 : 0
      /*1*/
      ;
    }),
    limits: Ember.computed('numTotal', 'perPage', function () {
      var limits = [];
      if (this.get('numTotal') > 25) limits.push(25);
      if (this.get('numTotal') > 50) limits.push(50);
      if (this.get('numTotal') > 100) limits.push(100);
      limits.push(Math.max(this.get('perPage'), this.get('numTotal')));
      return limits;
    }),
    pageNums: Ember.computed.alias('pageable.pageNums'),
    progressHandler: Ember.computed.alias('pageable.entryEditor.application'),
    actions: {
      changeLimit: function changeLimit(perPage) {
        this.set('perPage', perPage);
        this.send('showPage');
      },
      firstPage: function firstPage() {
        var _this = this;

        this.progressHandler.showProgressbar();
        this.store.queryRecord(this.get('modelName'), this.getQueryParams(1)).then(function (cB) {
          _this.progressHandler.hideProgressbar();

          _this.get('onLoad')(cB, _this.get('curPage'), _this.get('perPage'));
        });
      },
      lastPage: function lastPage() {
        var _this2 = this;

        this.progressHandler.showProgressbar();
        this.store.queryRecord(this.get('modelName'), this.getQueryParams(this.get('totalPages'))).then(function (cB) {
          _this2.progressHandler.hideProgressbar();

          _this2.get('onLoad')(cB, _this2.get('curPage'), _this2.get('perPage'));
        });
      },
      prevPage: function prevPage() {
        var _this3 = this;

        this.progressHandler.showProgressbar();
        this.store.queryRecord(this.get('modelName'), this.getQueryParams(Math.max(this.get('curPage') - 1, 1))).then(function (cB) {
          _this3.progressHandler.hideProgressbar();

          _this3.get('onLoad')(cB, _this3.get('curPage'), _this3.get('perPage'));
        });
      },
      nextPage: function nextPage() {
        var _this4 = this;

        this.progressHandler.showProgressbar();
        this.store.queryRecord(this.get('modelName'), this.getQueryParams(Math.min(this.get('curPage') + 1, this.get('totalPages')))).then(function (cB) {
          _this4.progressHandler.hideProgressbar();

          _this4.get('onLoad')(cB, _this4.get('curPage'), _this4.get('perPage'));
        });
      },
      showPage: function showPage(pageNum) {
        var _this5 = this;

        this.progressHandler.showProgressbar();
        this.store.queryRecord(this.get('modelName'), this.getQueryParams(pageNum)).then(function (cB) {
          _this5.progressHandler.hideProgressbar();

          _this5.get('onLoad')(cB, _this5.get('curPage'), _this5.get('perPage'));
        });
      }
    },
    getQueryParams: function getQueryParams(pageNum) {
      var queryParams = {
        limit: this.get('perPage')
      };
      if (pageNum != null) queryParams.page = pageNum;

      if (this.get('searchTerm') != null) {
        var searchWordsAnd = this.get('searchTerm').replace(/ +/, ' ').replace(' ', '+');
        queryParams.query = 'q_and=' + searchWordsAnd;
      }

      if (this.get('year') != null) {
        queryParams.year = this.get('year');
      }

      return queryParams;
    },
    getPageNums: function getPageNums(pageNum, numPages) {
      return [[], pageNum - 2, pageNum - 1, pageNum, pageNum + 1, pageNum + 2, pageNum + 3, pageNum + 4].reduce(function (acc, num, idx) {
        if (numPages >= num) {
          if (num >= 1 && acc.length <= 4) acc.push(num);
          return acc;
        } else {
          if (numPages >= 5 && acc.length <= 4) acc.insertAt(0, acc[0] + acc.length - idx);
          return acc;
        }
      });
    }
  });

  _exports.default = _default;
});