define("digiprax-web/components/csv-upload", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader"], function (_exports, _fileField, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{csv-upload url=application.productsImportUrl auth_token=... uploadController=... uploadKey=...}}
  var _default = _fileField.default.extend({
    url: null,
    // param
    auth_token: null,
    // param
    uploadController: null,
    // param
    uploadKey: null,
    // param
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      var uploader = _uploader.default.create({
        url: this.get('url'),
        method: 'PUT',
        ajaxSettings: {
          headers: {
            'Authorization': "Bearer ".concat(this.get('auth_token'))
          }
        }
      });

      if (!Ember.isEmpty(files)) {
        uploader.on('progress', function (e) {
          // Handle progress changes
          // Use `e.percent` to get percentage
          console.log("upload-progress: " + JSON.stringify(e));
        });
        this.get('uploadController').showProgressbar();
        uploader.upload(files[0]).then(function (data) {
          _this.uploadController.handleUpload(_this.uploadKey, data);

          _this.get('uploadController').hideProgressbar();

          _this.set('uploadController.nextUploadToggle', !(_this.get('uploadController.nextUploadToggle') || false));

          _this.get('uploadController.application.flashMessages').success("Die Datei wurde importiert.", {
            timeout: 3000
          });
        }, function (error) {
          // Handle failure
          _this.get('uploadController').hideProgressbar();

          _this.set('uploadController.nextUploadToggle', !(_this.get('uploadController.nextUploadToggle') || false));

          _this.get('uploadController.application.flashMessages').error(JSON.stringify(error)
          /*, { timeout: 10000 }*/
          );
        });
      }
    }
  });

  _exports.default = _default;
});