define("digiprax-web/models/new-lot-placeholder", ["exports", "moment", "digiprax-web/models/day-group-data"], function (_exports, _moment, _dayGroupData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    store: Ember.inject.service(),
    isDirty: function isDirty() {
      // api-method
      return this.get('hasDirtyAttributes') || this.get('extraInfoIsDirtyFlag') != null;
    },
    isNew: true,
    isLast: Ember.computed('hasDirtyAttributes', 'extraInfoIsDirtyFlag', function () {
      return !this.isDirty();
    }),
    extraInfoIsDirtyFlag: null,
    hasDirtyAttributes: Ember.computed('date', 'lotText', 'cost_cents', 'notes', function () {
      if (this.get('invoice_id') == null) {
        // patientenkarte
        return this.get('date') != null || this.get('cost_cents') != null && ('' + this.get('cost_cents')).trim() != '' || this.get('notes') != null && this.get('notes').trim() != '';
      } else {
        // patientenrechnung
        // return this.get('date') != null ||
        return this.get('lotText') != null && this.get('lotText').trim() != '';
      }
    }),
    invoice_id: null,
    invoice: Ember.computed('invoice_id', function () {
      if (this.get('invoice_id') != null) {
        return this.store.peekRecord('invoice', this.get('invoice_id'));
      }

      return null;
    }),
    date: null,
    notes: null,
    invoice_text: null,
    number: 1,
    cost_cents: Ember.computed.alias('cost'),
    validDate: Ember.computed('date', 'invoice.date', function () {
      if (this.get('date') != null && this.get('invoice') != null) {
        return !(0, _moment.default)(this.get('date')).startOf('day').isAfter((0, _moment.default)(this.get('invoice.date')).startOf('day'));
      }

      return true;
    }),
    // required to display correct pikaday-value: empty (no placeholder) if previous entry is on same day
    dateTotal: Ember.computed('date', {
      get: function get(_key) {
        return this.get('date');
      },
      set: function set(_key, value) {
        if (value != null) {
          this.set('date', value);
        }

        return this.get('date');
      }
    }),
    totalDate: Ember.computed('dateTotal', 'invoice.dayGroups', 'invoice.dayGroups.updatedAt', function () {
      if (this.get('dateFmtd') == null) return null;
      if (this.get('invoice.dayGroups')[this.get('dateFmtd')] == null) return null;

      if (this.get('invoice.newLotPlaceholders') != null) {
        var sameDay = this.get('invoice.dayGroups')[this.get('dateFmtd')].entries;

        if (sameDay[sameDay.findIndex(function (t) {
          return t.get('tmpId') != null;
        })].get('tmpId') == this.get('tmpId')) {
          return this.get('date');
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    dailyTotal: Ember.computed('invoice.virtTotal', 'invoice.dayGroups', 'invoice.dayGroups.updatedAt', function () {
      if (this.get('dateFmtd') == null) return null;
      if (this.get('invoice.dayGroups')[this.get('dateFmtd')] == null) return null;
      var sameDay = this.get('invoice.dayGroups')[this.get('dateFmtd')].entries;

      if (sameDay[sameDay.length - 1].get('tmpId') == this.get('tmpId')) {
        var total = sameDay.reduce(function (acc, curLot) {
          return acc + curLot.get('number') * curLot.getCost();
        }, 0);
        return (total / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',') + ' €';
      } else {
        return null;
      }
    }),
    lotText: Ember.computed({
      get: function get(_key) {
        if (this.get('invoice_text') != null) {
          return this.get('invoice_text');
        } else if (this.get('product_id') != null) {
          return this.get('store').peekRecord('product', this.get('product_id')).get('name');
        } else if (this.get('notes') != null) {
          return this.get('notes');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        this.set('invoice_text', value);
        return value;
      }
    }),
    dateFmtd: Ember.computed('date', {
      get: function get(_key) {
        if (this.get('date') != null) {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        if ([null, ''].indexOf(value) == -1) {
          if (typeof value == 'string') {
            this.set('date', (0, _moment.default)(value, 'DD.MM.YYYY').toDate());
            return value;
          } else {
            this.set('date', value || new Date());
            return (0, _moment.default)(value).format('DD.MM.YYYY');
          }
        } else {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        }
      }
    }),
    // _cost: null,
    cost: Ember.computed('_cost', function () {
      if (this.get('_cost') != null) return this.get('_cost');else return null;
    }),
    getCost: function getCost() {
      return typeof this.get('_cost') == 'undefined' || this.get('_cost') == null ? this.get('cost_cents') : this.get('_cost');
    },
    costFmtd: Ember.computed('cost_cents', {
      // return ((this.get('cost_cents') / 100).toFixed(2)).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
      get: function get(_key) {
        var cost = this.getCost();

        if (cost != null) {
          return (cost / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        var newCost = Math.round(parseFloat(value.replace(',', '.') * 100));
        this.set('_cost', newCost); // return (this.get('cost_cents') * this.get('number')) + ',00';

        return value;
      }
    }),
    costMut: Ember.computed('cost_cents', '_cost', 'number', {
      get: function get(_key) {
        var cost = this.getCost();
        if (cost != null) // return ((cost * (this.get('number')||1) / 100).toFixed(2)).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
          return (cost * (this.get('number') || 1) / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');else return null;
      },
      set: function set(_key, value) {
        // var newCost = Math.round(parseFloat(value.replace(',', '.') * 100) / (this.get('number')||1));
        var newCost = Math.round(parseFloat(value.replace(',', '.').replace(/\s*€/, '') * 100) / (this.get('number') || 1));
        this.set('_cost', newCost);
        return value;
      }
    }),
    positionTS: Ember.computed({
      get: function get(_key) {
        return this.get('extraInfo.positionTS') || null;
      },
      set: function set(_key, tSMillis) {
        var extraInfoAvailable = !(typeof this.get('extraInfo') == "undefined" || this.get('extraInfo') == null);

        if (extraInfoAvailable) {
          if (this.get('extraInfo.positionTS') == null) {
            this.set('extraInfo.positionTS', tSMillis);
          }
        } else {
          this.set('extraInfo', {
            positionTS: tSMillis
          });
        }

        return tSMillis;
      }
    }),
    clipboardPosition: Ember.computed('clipboard.lots.[]', function () {
      var _this = this;

      var idx = (this.get('clipboard.lots') || []).findIndex(function (t) {
        return t.model == _this;
      });
      return idx == -1 ? null : idx + 1;
    }),
    dayGroupData: Ember.computed('dayGroup.last', function () {
      if (this == (this.dayGroup || {}).last && this.get('date') != null) {
        // return this.targetInvoice.get('extraInfo.dayGroupData.'+moment(this.get('date')).format('YYYYMMDD'));
        return _dayGroupData.default.create({
          targetInvoice: this.invoice || this.targetInvoice,
          dayGroup: this.dayGroup
        });
      }

      return null;
    }),
    extraLine: Ember.computed(
    /**/
    'extraInfoIsDirtyFlag', {
      get: function get(_key) {
        return this.get('extraInfo.extraLine') || null;
      },
      set: function set(_key, extraLine) {
        if (extraLine == null || extraLine.trim() == '') {
          var extraInfo = this.get('extraInfo');
          delete extraInfo.extraLine;
          this.set('extraInfo', extraInfo);
        } else {
          this.set('extraInfo.extraLine', extraLine
          /*.trim()*/
          );
        }

        this.set('extraInfoIsDirtyFlag', new Date());
        return this.get('extraInfo.extraLine');
      }
    }),
    taxPercentage: Ember.computed('product_id', {
      get: function get(_key) {
        return this.get('product_id') ? this.product ? this.product.tax_percentage : null : this.get('tax_percentage');
      },
      set: function set(_key, taxPercentage) {
        if (this.get('product_id')) return this.product ? this.product.tax_percentage : null;
        return this.set('tax_percentage', taxPercentage.replace(',', '.'));
      }
    })
  });

  _exports.default = _default;
});