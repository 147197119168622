define("digiprax-web/models/chain-categories", ["exports", "digiprax-web/models/tariff-chain-category"], function (_exports, _tariffChainCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    store: null,
    categoryMap: Ember.computed(function () {
      var categoryNameKeyMap = {
        'Untersuchungen': ['40'],
        'Allergien / Belastungen': ['43'],
        'Atemwege / Erkältungsinfekte': ['45', '46'],
        'Augen': ['50'],
        'Bewegungsapparat': ['53', '54'],
        'Blut / Lymphsystem': ['58'],
        'Geschlechtsspezifisches': ['60'],
        'Haut/Haare': ['63', '64'],
        'Herz / Kreislauf': ['65'],
        'Leber / Galle / Pankreas': ['67'],
        'Nervensystem / Psyche ': ['70'],
        'Niere / Blase / Harnwege': ['73', '74'],
        'Ohren/Gleichgewicht': ['75'],
        'Rekonvaleszenz / Stress': ['77'],
        'Stoffwechsel / Immunsystem': ['80'],
        'Verdauungssystem': ['83', '84'],
        'Eigene': ['0']
      };
      return Object.keys(categoryNameKeyMap).map(function (key, idx) {
        return _tariffChainCategory.default.create({
          name: key,
          tariffChainKeys: categoryNameKeyMap[key],
          tariffChains: [],
          index: idx
        });
      });
    }),
    selectedCategoryIndex: -1,
    selectedTariffChain: Ember.computed('selectedCategoryIndex', 'newCustomTariffChainFlag', function () {
      var curCategory = null;

      if (this.get('selectedCategoryIndex') != -1) {
        curCategory = this.get('categoryMap')[this.get('selectedCategoryIndex')];

        if (curCategory.name == 'Eigene' && this.newCustomTariffChainFlag != null) {
          if (this.newCustomTariffChainFlag != this.prevNewCustomTariffChainFlag) {
            // eslint-disable-next-line ember/no-side-effects
            this.set('prevNewCustomTariffChainFlag', this.newCustomTariffChainFlag); // actually onyl required if custom-tariff-chain created before first time opening dialog

            this._initTariffChains(curCategory);
          }
        } else {
          if (curCategory.get('tariffChains').length == 0) {
            this._initTariffChains(curCategory); // } else if (curCategory.get('tariffChains.items') == null) {

          } else {
            // tariff-chains are loaded asynchronously - _initTariffChains must handle separetely
            // this._initSelectedTariffChainItems(curCategory.get('tariffChains'));
            this._initSelectedTariffChainItems(curCategory.get('tariffChains').filter(function (tC) {
              return tC.items == null || tC.items.length == 0;
            }));
          }
        }
      }

      return curCategory;
    }),
    _initTariffChains: function _initTariffChains(category) {
      var _this = this;

      this.get('store').query('tariff-chain', {
        item_keys: category.tariffChainKeys
      }).then(function (tariffChains) {
        // for (var tariffChain of tariffChains.toArray()) {
        //   for (var category of this.get('categoryMap').toArray()) {
        //     for (var key of category.get('tariffChainKeys')) {
        //       if (tariffChain.get('item').startsWith(key+'.')) {
        //         if (key == '0' || this.newCustomTariffChainFlag == null) {
        //           category.set('tariffChains', category.get('tariffChains').concat([tariffChain]).sort((tC1, tC2) => {
        //             // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
        //             return tC1.get('item') > tC2.get('item') ? 1 : -1;
        //           }));
        //         }
        //       }
        //     }
        //   }
        // }
        category.set('tariffChains', tariffChains.toArray().sort(function (tC1, tC2) {
          // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
          return tC1.get('item') > tC2.get('item') ? 1 : -1;
        })); // tariff-chains are loaded asynchronously ...

        _this._initSelectedTariffChainItems(_this.get('categoryMap')[_this.get('selectedCategoryIndex')].get('tariffChains'));
      });
    },
    _initSelectedTariffChainItems: function _initSelectedTariffChainItems(tariffChains) {
      var _this2 = this;

      if (tariffChains.length >= 1) {
        this.get('store').query('tariff-chain-item', {
          tariffChainsId: tariffChains[0].id
        }).then(function (tariffChainItems) {
          tariffChains[0].set('items', tariffChainItems.toArray().sort(function (tCI1, tCI2) {
            // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
            return tCI1.get('position') > tCI2.get('position') ? 1 : -1;
          }));
          if (tariffChains.length >= 2) _this2._initSelectedTariffChainItems(tariffChains.slice(1));
        });
      }
    }
  });

  _exports.default = _default;
});