define("digiprax-web/routes/patients/edit/profile", ["exports", "digiprax-web/mixins/patient-editor-route"], function (_exports, _patientEditorRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_patientEditorRoute.default, {
    // model(params, transition) {
    //   let patient = this.store.peekRecord('patient', transition.to.parent.params.patient_id);
    //   return patient != null ? patient : this.store.queryRecord('patient', transition.to.parent.params.patient_id);
    // },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get('id') != 'new') {
        localStorage.setItem('peru.lastViewedPatientId', '"' + model.get('id') + '"');
        localStorage.setItem('lastViewedPatientId', model.get('id'));
      } // this._initPatientRelations(model);


      controller.set('application.currentPatient', model); // this._setPracticeId(controller, model);

      var settlementSort = function settlementSort(s1, s2) {
        if (s1.get('group') > s2.get('group')) {
          return 1;
        }

        return s1.get('name').replace(/\s/g, '').toLowerCase() >= s2.get('name').replace(/\s/g, '').toLowerCase() ? 1 : -1;
      };

      var settlements = this.get('store').peekAll('settlement');

      if (settlements != null && settlements.toArray().length >= 1) {
        controller.set('settlements', settlements.toArray().sort(settlementSort));
      } else {
        this.get('store').findAll('settlement').then(function (settlements) {
          controller.set('settlements', settlements.toArray().sort(settlementSort));
        });
      }
      /*
      // controller.set('newTreatmentPlaceholders', this._newTreatmentPlaceholders(model));
      let initNumDirty = model.get('numNewTreatmentsDirty');
      controller.set('actions.deleteTreatment', this.get('actions.deleteTreatment'));
      controller.set('actions.removeNewTreatment', this.get('actions.removeNewTreatment'));
      controller.set('actions.moveTreatment', this.get('actions.moveTreatment'));
      */
      // case reload ...


      controller.get('application').setActiveNavLink('linkTo.patientenkarte');
    }
  });

  _exports.default = _default;
});