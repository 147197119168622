define("digiprax-web/models/room", ["exports", "digiprax-web/mixins/appointment-location"], function (_exports, _appointmentLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { A } from '@ember/array';
  var _default = Ember.Object.extend(_appointmentLocation.default, {
    id: null,
    name: null,
    places: null,
    // A([])
    slotLimits: Ember.computed('places.@each.firstSlotStart', 'places.@each.lastSlotEnd', function () {
      return [[-1, -1]].concat(this.get('places')).reduce(function (acc, place) {
        if (acc[0] == -1 || place.times[0] < acc[0]) acc[0] = place.times[0];
        if (acc[1] == -1 || place.times[1] > acc[1]) acc[1] = place.times[1];
        return acc;
      });
    }),
    overlappingRanges: function overlappingRanges(range1, range2) {
      return range1[0] >= range2[0] && range1[0] < range2[1] || // [200-250, _] - [200, 300]
      range1[0] < range2[0] && range1[1] > range2[0]; // [150, 250]   - [200, _]
    },
    lastSlotEndTime: Ember.computed('slotLimits.1', function () {
      var hour = parseInt(this.slotLimits[1] / 100);
      return hour + ':' + ('0' + (this.slotLimits[1] - hour * 100)).match(/[0-9]{2}$/)[0];
    })
  });

  _exports.default = _default;
});