define("digiprax-web/components/file-upload", ["exports", "ember-uploader/components/file-field", "ember-uploader/uploaders/uploader"], function (_exports, _fileField, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { inject as service } from '@ember/service';
  // {{file-upload paramName='logo' url=application.logoUploadUrl auth_token=... model=... progressHandler=...}}
  var _default = _fileField.default.extend({
    // practiceEditor: service(),
    model: null,
    // param
    paramName: null,
    // param
    url: null,
    // param
    auth_token: null,
    // param
    progressHandler: null,
    // param
    filesDidChange: function filesDidChange(files) {
      var _this = this;

      if (files[0].type.match(/^image\/jpe?g/i) == null) {
        this.get('progressHandler.flashMessages').error('Bilddatei muß im JPEG-Format gespeichert werden. (Datei-Endung *.jpg bzw. *.jpeg)', {
          timeout: 5000
        });
        return false;
      }

      var uploader = _uploader.default.create({
        url: this.get('url'),
        paramNamespace: 'practice',
        paramName: this.get('paramName'),
        method: 'PUT',
        ajaxSettings: {
          headers: {
            'Authorization': "Bearer ".concat(this.get('auth_token'))
          }
        }
      });

      if (!Ember.isEmpty(files)) {
        uploader.on('progress', function (_e) {// Handle progress changes
          // Use `e.percent` to get percentage
        });
        this.get('progressHandler').showProgressbar();
        uploader.upload(files[0]).then(function (data) {
          // Handle success
          _this.get('progressHandler').hideProgressbar();

          _this.set('model.' + _this.get('paramName'), data[_this.get('paramName') + '_url']); // this.get('practiceEditor').savePractice(this);


          _this.set('model.savedAt', new Date());
        }, function (_error) {
          // Handle failure
          _this.get('progressHandler').hideProgressbar();
        });
      }
    },
    inValid: function inValid(errors) {
      // $('html, body').animate({scrollTop: 0}, 500);
      var errorHtml = '';
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = errors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var error = _step.value;
          errorHtml += '<li>' + error + '</li>';
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.get('flashMessages').error('<ul>' + errorHtml + '</ul>', {
        timeout: 10000
      });
    },
    afterSave: function afterSave(_invoice) {
      // $('html, body').animate({scrollTop: 0}, 500);
      this.get('flashMessages').success('Praxis erfolgreich geändert ...', {
        timeout: 3000
      });
    }
  });

  _exports.default = _default;
});