define("digiprax-web/services/patient-editor", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    createPatient: function createPatient(patient, listener) {
      var _this = this;

      var errors = [];

      if (this._validatePatient(patient, errors)) {
        var forBusiness = this.get('application.currentUser.plus');
        this.get('application').showProgressbar();
        var newPatient = null;

        if (patient.id == 'new') {
          newPatient = patient.get('store').createRecord('patient', patient.toJSON());
        } else {
          newPatient = patient;
          patient.set('id', null);
        }

        newPatient.set('date', (0, _moment.default)(patient.get('dateFmtd'), 'DD.MM.YYYY').toDate());
        newPatient.set('settlementName', null);
        newPatient.set(forBusiness ? 'lots' : 'treatments', []);
        if (newPatient.get('notes') == null) newPatient.set('notes', '-');
        newPatient.save().then(function (newPatient) {
          _this.get('application').hideProgressbar();

          listener.afterSave(newPatient);
        }, function (error) {
          _this.get('application').hideProgressbar();

          if (typeof error.get == 'function') {
            listener.inValid(error.get('messages').map(function (errorMessage) {
              if (errorMessage.match(/^['"]?birthdate['"]? can't be blank/)) return 'Ein Geburts-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              if (errorMessage.match(/^['"]?initReception['"]? can't be blank/)) return 'Ein Erstaufnahme-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Erstaufnahme-Datum darf nicht in der Zukunft liegen.';
              if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Erstaufnahme-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              return errorMessage;
            }));
          } else {
            _this.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

            listener.inValid(['Unbekannter Fehler!']);
          }
        });
      } else {
        listener.inValid(errors, {
          timeout: 5000
        });
      }
    },

    /**
     * TODO use sortedNewTreatmentPlaceholders when calling this - check
     */
    savePatient: function savePatient(patient, newTreatmentPlaceholders, listener) {
      var _this2 = this;

      var errors = [];

      if (!this._validatePatient(patient, errors)) {
        listener.inValid(errors, {
          timeout: 5000
        });
        return;
      }

      var forBusiness = this.get('application.currentUser.plus');
      this.get('application').showProgressbar();

      if (patient.get('isNew')) {
        patient.set('id', null);
        patient.set('date', (0, _moment.default)(patient.get('dateFmtd'), 'DD.MM.YYYY').toDate()); // patient.set('diagnoses', patient.get('diagnoses'));

        patient.set(forBusiness ? 'lots' : 'treatments', []);
        if (patient.get('notes') == null) patient.set('notes', '-');
        patient.save().then(function (newPatient) {
          _this2._updateTreatmentsSequentially(newPatient, patient.get(forBusiness ? 'lots' : 'treatments').toArray(), newTreatmentPlaceholders, listener, errors, forBusiness);
        }, function (error) {
          _this2.get('application').hideProgressbar();

          if (typeof error.get == 'function') {
            listener.inValid(error.get('messages').map(function (errorMessage) {
              if (errorMessage.match(/^['"]?birthdate['"]? can't be blank/)) return 'Ein Geburts-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              if (errorMessage.match(/^['"]?initReception['"]? can't be blank/)) return 'Ein Erstaufnahme-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Erstaufnahme-Datum darf nicht in der Zukunft liegen.';
              if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Erstaufnahme-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              return errorMessage;
            }));
          } else {
            _this2.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

            listener.inValid(['Unbekannter Fehler!']);
          }
        });
      } else {
        this._fixMissingPositionTs(patient, forBusiness);

        if (patient.get('hasDirtyAttributes')) {
          patient.save().then(function (updatedPatient) {
            _this2._updateTreatmentsSequentially(updatedPatient, (patient.get(forBusiness ? 'lots' : 'treatments') || []).toArray(), newTreatmentPlaceholders || [], listener, errors, forBusiness);
          }, function (error) {
            _this2.get('application').hideProgressbar();

            if (typeof error.get == 'function') {
              listener.inValid(error.get('messages'));
            } else {
              _this2.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

              listener.inValid(['Unbekannter Fehler!']);
            }
          });
        } else {
          this._updateTreatmentsSequentially(patient, (patient.get(forBusiness ? 'lots' : 'treatments') || []).toArray(), newTreatmentPlaceholders || [], listener, errors, forBusiness);
        }
      }
    },
    _fixMissingPositionTs: function _fixMissingPositionTs(patient, forBusiness) {
      patient.get('sorted' + (forBusiness ? 'Lots' : 'Treatments')).toArray().reverse().reduce(function (curMaxPositionTS, curTreatment) {
        var extraInfoAvailable = !(typeof curTreatment.get('extraInfo') == "undefined" || curTreatment.get('extraInfo') == null);

        if (extraInfoAvailable) {
          if (curTreatment.get('extraInfo.positionTS') == null) {
            curTreatment.set('extraInfo.positionTS', curMaxPositionTS - 1000);
            curTreatment.set('extraInfoIsDirtyFlag', new Date());
          } // if (curTreatment.get('extraInfo.position') != null) delete curTreatment.get('extraInfo').position;

        } else {
          curTreatment.set('extraInfo', {
            positionTS: curMaxPositionTS - 1000
          });
          curTreatment.set('extraInfoIsDirtyFlag', new Date());
        }

        return curTreatment.get('extraInfo.positionTS');
      }, patient.get('sortedNew' + (forBusiness ? 'Lot' : 'Treatment') + 'Placeholders').toArray()[0].get('positionTS') || new Date().getTime());
    },
    _updateTreatmentsSequentially: function _updateTreatmentsSequentially(patient, treatments, newTreatmentPlaceholders, listener, errors, forBusiness) {
      var _this3 = this;

      if (treatments.length >= 1) {
        // if (treatments[0].get('hasDirtyAttributes')) {
        if (treatments[0].isDirty()) {
          /**
           * TODO: if diagnoseText is null || '' then set to most recent before this treatment
           *       we need one per day.
           */
          if (treatments[0].get('diagnosisText') == null) treatments[0].set('diagnosisText', '');

          if ([null, '', -1].indexOf(treatments[0].get('cost')) == -1 && (treatments[0].get('cost') + '').match(/^[0-9]+([.,][0-9]{1,2}|)$/) == null) {
            errors.push('Betrag darf nur gültige Geldbeträge enthalten.');
            /*if (newTreatmentPlaceholders.length >= 2) */

            this._updateTreatmentsSequentially(patient, treatments.slice(1), newTreatmentPlaceholders, listener, errors, forBusiness);
          } else if (treatments[0].get('date') == null) {
            errors.push('Datum muß angegeben werden.');
            /*if (newTreatmentPlaceholders.length >= 2) */

            this._updateTreatmentsSequentially(patient, treatments.slice(1), newTreatmentPlaceholders, listener, errors, forBusiness);
          } else {
            // // treatments[0].set('cost', Math.abs(parseFloat(treatments[0].get('cost').replace(',','.'))*100));
            if (treatments[0].get('extraInfoIsDirtyFlag') != null) treatments[0].set('extraInfoIsDirtyFlag', null);
            treatments[0].set('date', (0, _moment.default)(treatments[0].get('dateFmtd'), 'DD.MM.YYYY').toDate());
            if (!forBusiness) treatments[0].set('notes', ((treatments[0].get('treatmentNotes') || '').match(/[^ ].*/) || [null])[0] || '-'); // var newDiagnoses = this._newDiagnoses(patient, treatments[0]);
            // this._updateDiagnosesSequentially(patient, treatments[0], newDiagnoses, listener, errors, () => {

            treatments[0].save().then(function (_treatment) {
              console.debug('updated ' + (forBusiness ? 'lot' : 'treatment') + ' ...');

              _this3._updateTreatmentsSequentially(patient, treatments.slice(1), newTreatmentPlaceholders, listener, errors, forBusiness);
            }, function (error) {
              _this3.get('application').hideProgressbar();

              if (typeof error.get == 'function') {
                listener.inValid(error.get('messages').map(_this3._errorMessageMapperForEntries(forBusiness)).compact().uniq()); // listener.inValid(error.get('messages').map(errorMessage => {
                //     return e.errors.map(this._errorMessageMapper()).join('\n\r<br>');
                //     if (errorMessage.match(/^['"]?extraInfo['"]?/)) return null;
                //     if (errorMessage.match(/^['"]?date['"]? can't be blank/)) return 'Ein Behandlungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
                //     if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Behandlungs-Datum darf nicht in der Zukunft liegen.';
                //     if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Behandlungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
                //     if (errorMessage.match(/^diagnosisText: can't be blank/)) return 'Diagnose muss angegeben werden.';
                //     return errorMessage;
                //   }));
              } else {
                _this3.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

                listener.inValid(['Unbekannter Fehler!']);
              }
            }); // });
          }
        } else {
          this._updateTreatmentsSequentially(patient, treatments.slice(1), newTreatmentPlaceholders, listener, errors, forBusiness);
        }
      } else this._saveNewTreatmentsSequentially(patient, newTreatmentPlaceholders, listener, errors, forBusiness);
    },
    _saveNewTreatmentsSequentially: function _saveNewTreatmentsSequentially(patient, newTreatmentPlaceholders, listener, errors, forBusiness) {
      if (newTreatmentPlaceholders.length >= 1) {
        if (newTreatmentPlaceholders[0].get('hasDirtyAttributes')) {
          if ([null, '', -1].indexOf(newTreatmentPlaceholders[0].get('cost')) == -1 && (newTreatmentPlaceholders[0].get('cost') + '').match(/^[0-9]+([.,][0-9]{1,2}|)$/) == null) {
            errors.push('Betrag darf nur gültige Geldbeträge enthalten.');
            /*if (newTreatmentPlaceholders.length >= 2) */

            this._saveNewTreatmentsSequentially(patient, newTreatmentPlaceholders.slice(1), listener, errors, forBusiness);
          } else if (newTreatmentPlaceholders[0].get('date') == null) {
            errors.push('Datum muß angegeben werden.');
            /*if (newTreatmentPlaceholders.length >= 2) */

            this._saveNewTreatmentsSequentially(patient, newTreatmentPlaceholders.slice(1), listener, errors, forBusiness);
          } else {
            //newTreatmentPlaceholders[0].set('cost', Math.abs(parseFloat(newTreatmentPlaceholders[0].get('cost').replace(',','.'))*100));
            newTreatmentPlaceholders[0].set('date', (0, _moment.default)(newTreatmentPlaceholders[0].get('dateFmtd'), 'DD.MM.YYYY').toDate()); // var newDiagnoses = this._newDiagnoses(patient, newTreatmentPlaceholders[0]);
            // this._updateDiagnosesSequentially(patient, newTreatmentPlaceholders[0], newDiagnoses, listener, errors, () => {

            this._saveNewTreatment(patient, newTreatmentPlaceholders, listener, errors, forBusiness); // });

          }
        } else {
          /*if (newTreatmentPlaceholders.length >= 2) */
          this._saveNewTreatmentsSequentially(patient, newTreatmentPlaceholders.slice(1), listener, errors, forBusiness);
        }
      } else {
        // done ...
        this.get('application').hideProgressbar();

        if (errors.length >= 1) {
          listener.inValid(errors);
        } else {
          if (forBusiness) {
            patient.set('lots', patient.get('store').peekAll('lot').filter(function (treatment) {
              return (treatment.get('patient.id') || treatment.get('patient_id')) == patient.id && (treatment.get('invoice') || treatment.get('invoice_id')) == null;
            }).sort(function (t1, t2) {
              return t2.get('date') - t1.get('date') >= 0 ? -1 : 1;
            }));
          } else {
            patient.set('treatments', patient.get('store').peekAll('treatment').filter(function (treatment) {
              return (treatment.get('patient.id') || treatment.get('patientId')) == patient.id && (treatment.get('invoice') || treatment.get('invoiceId')) == null;
            }).sort(function (t1, t2) {
              return t2.get('date') - t1.get('date') >= 0 ? -1 : 1;
            }));
          }

          patient.afterSave(); // after setting new treatments because isDirty-flag

          listener.afterSave(patient);
        }
      }
    },
    _saveNewTreatment: function _saveNewTreatment(patient, newEntryPlaceholders, listener, errors, forBusiness) {
      var _this4 = this;

      if (!forBusiness) {
        if (newEntryPlaceholders[0].diagnosisText == null || newEntryPlaceholders[0].diagnosisText.trim() == '') {
          var previousTreatment = null;
          var newTPIdx = patient.get('sortedNewTreatmentPlaceholders').indexOf(newEntryPlaceholders[0]);

          if (newTPIdx >= 1) {
            previousTreatment = [].concat(patient.get('sortedNewTreatmentPlaceholders').toArray().slice(0, newTPIdx).reverse()).find(function (t) {
              return t.diagnosisText != null && t.diagnosisText.trim() != '';
            });
          }

          if (previousTreatment == null) {
            previousTreatment = [].concat(patient.get('sortedTreatments').toArray().reverse()).find(function (t) {
              return (0, _moment.default)(t.date).format('YYYYMMDD') <= (0, _moment.default)(newEntryPlaceholders[0].date).format('YYYYMMDD');
            });
            if (previousTreatment != null) newEntryPlaceholders[0].set('diagnosisText', previousTreatment.diagnosisText);
          } else {
            newEntryPlaceholders[0].set('diagnosisText', previousTreatment.diagnosisText);
          }
        }

        if (newEntryPlaceholders[0].get('diagnosisText') == null) newEntryPlaceholders[0].set('diagnosisText', '');
      }

      if (newEntryPlaceholders[0].get('extraInfoIsDirtyFlag') != null) newEntryPlaceholders[0].set('extraInfoIsDirtyFlag', null);

      if (newEntryPlaceholders[0].get('appointment') != null) {
        var extraInfo = newEntryPlaceholders[0].get('extraInfo');
        extraInfo.appointment_id = newEntryPlaceholders[0].get('appointment').id;
        newEntryPlaceholders[0].set('extraInfo', extraInfo);
      }

      var entryRecord;

      if (forBusiness) {
        entryRecord = patient.get('store').createRecord('lot', {
          patient_id: patient.id,
          date: (0, _moment.default)(newEntryPlaceholders[0].get('dateFmtd'), 'DD.MM.YYYY').toDate(),
          cost_cents: newEntryPlaceholders[0].get('cost_cents'),
          tax_percentage: newEntryPlaceholders[0].get('product.tax_percentage'),
          product_id: parseInt(newEntryPlaceholders[0].get('product.id')),
          // notes: ((newEntryPlaceholders[0].get('notes')||'').match(/[^ ].*/)||[null])[0] || '-',
          notes: newEntryPlaceholders[0].get('notes'),
          extraInfo: newEntryPlaceholders[0].get('extraInfo')
        });
      } else {
        entryRecord = patient.get('store').createRecord('treatment', {
          patientId: patient.id,
          date: (0, _moment.default)(newEntryPlaceholders[0].get('dateFmtd'), 'DD.MM.YYYY').toDate(),
          cost: newEntryPlaceholders[0].get('cost'),
          notes: ((newEntryPlaceholders[0].get('treatmentNotes') || '').match(/[^ ].*/) || [null])[0] || '-',
          extraInfo: newEntryPlaceholders[0].get('extraInfo')
        });
      }

      entryRecord.save().then(function (treatment) {
        console.debug('created ' + (forBusiness ? 'lot' : 'treatment') + ' ...');
        treatment.set('patient', patient);
        /*if (newEntryPlaceholders.length >= 2) */

        _this4._saveNewTreatmentsSequentially(patient, newEntryPlaceholders.slice(1), listener, errors, forBusiness);
      }, function (error) {
        _this4.get('application').hideProgressbar();

        if (typeof error.get == 'function') {
          listener.inValid(error.get('messages').map(_this4._errorMessageMapperForEntries(forBusiness)).compact().uniq()); // listener.inValid(error.get('messages').map(errorMessage => {
          //     if (errorMessage.match(/^['"]?extraInfo['"]?/)) return null;
          //     if (errorMessage.match(/^['"]?date['"]? can't be blank/)) return 'Ein Behandlungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
          //     if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Behandlungs-Datum darf nicht in der Zukunft liegen.';
          //     if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Behandlungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
          //     if (errorMessage.match(/^diagnosisText: can't be blank/)) return 'Diagnose muss angegeben werden.';
          //     return errorMessage;
          //   }));
        } else {
          _this4.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

          listener.inValid(['Unbekannter Fehler!']);
        }
      });
    },
    _validatePatient: function _validatePatient(patient, errors) {
      if (patient.get('initReception') == null) errors.push('Erstaufnahme muss angegeben werden.');
      if (patient.get('gender') == null) errors.push('Anrede muss angegeben werden.');
      if (patient.get('birthdate') == null) errors.push('Geburtstag muss angegeben werden.');
      if (patient.get('firstName') == null) errors.push('Vorname muss angegeben werden.');
      if (patient.get('lastName') == null) errors.push('Nachname muss angegeben werden.');
      if (patient.get('addressStreet') == null) errors.push('Strasse muss angegeben werden.');
      if (patient.get('addressHouseNumber') == null) errors.push('Hausnummer muss angegeben werden.');
      if (patient.get('addressZip') == null) errors.push('PLZ muss angegeben werden.');
      if (patient.get('addressCity') == null) errors.push('Ort muss angegeben werden.');
      if (patient.get('settlementId') == null && !patient.get('is_customer')) errors.push('Abbrechnungsstatus muss angegeben werden.'); // if (patient.get('phoneLandline') != null && patient.get('phoneLandline').length >= 17) errors.push('Telefon darf maximal 16 Zeichen lang sein.');
      // if (patient.get('phoneCellular') != null && patient.get('phoneCellular').length >= 17) errors.push('Mobil darf maximal 16 Zeichen lang sein.');
      // if (patient.get('phoneFax') != null && patient.get('phoneFax').length >= 17) errors.push('Fax darf maximal 16 Zeichen lang sein.');

      if (patient.get('isChild') && patient.get('differentInvoiceRecipient') == 'Nein') errors.push('Für Kinder muß ein abweichender Rechnungsempfänger angegeben werden.');

      if (patient.get('differentInvoiceRecipient') == 'Ja') {
        if (patient.get('invoiceSalutationMut') == null) errors.push('Anrede muss für abweichenden Rechnungsempfänger angegeben werden.');

        if (patient.get('invoiceSalutationMut') == 'Firma') {
          if (patient.get('invoiceLastName') == null) errors.push('Firmenname muss für abweichenden Rechnungsempfänger angegeben werden.');
        } else {
          if (patient.get('invoiceFirstName') == null) errors.push('Vorname muss für abweichenden Rechnungsempfänger angegeben werden.');
          if (patient.get('invoiceLastName') == null) errors.push('Nachname muss für abweichenden Rechnungsempfänger angegeben werden.');
        }

        if (patient.get('invoiceAddressStreet') == null) errors.push('Strasse muss für abweichenden Rechnungsempfänger angegeben werden.');
        if (patient.get('invoiceAddressHouseNumber') == null) errors.push('Hausnummer muss für abweichenden Rechnungsempfänger angegeben werden.');
        if (patient.get('invoiceAddressZip') == null) errors.push('PLZ muss für abweichenden Rechnungsempfänger angegeben werden.');
        if (patient.get('invoiceAddressCity') == null) errors.push('Ort muss für abweichenden Rechnungsempfänger angegeben werden.');
      }

      return errors.length == 0;
    },
    _errorMessageMapperForEntries: function _errorMessageMapperForEntries(forBusiness) {
      return function (errorMessage) {
        if (errorMessage.match(/^['"]?extraInfo['"]?/)) return null;
        if (errorMessage.match(/^['"]?date['"]? can't be blank/)) return 'Ein Behandlungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
        if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Behandlungs-Datum darf nicht in der Zukunft liegen.';
        if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Behandlungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
        if (!forBusiness && errorMessage.match(/^diagnosisText: can't be blank/)) return 'Diagnose muss angegeben werden.';
        if (forBusiness && errorMessage.match(/^notes: can't be blank/)) return 'Artikel / Angebots Bezeichnung muss angegeben werden.';
        return errorMessage;
      };
    }
  });

  _exports.default = _default;
});