define("digiprax-web/helpers/raise-suffix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // params[0] ... target
  // params[1] ... suffix
  var _default = Ember.Helper.extend({
    compute: function compute(params, _hash) {
      return Ember.String.htmlSafe((params[0] || '').replace(new RegExp(params[1] + '$'), '<sup>' + params[1] + '</sup>'));
    }
  });

  _exports.default = _default;
});