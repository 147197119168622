define("digiprax-web/routes/patients/month-stats", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    model: function model(_params, _transition) {
      this.application.showProgressbar();
      return this.store.findRecord('month-stat', (0, _moment.default)().format('YYYYM'));
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var result = this._super(controller, model); // next(() => {


      Ember.run.schedule("afterRender", function () {
        _this.application.hideProgressbar();
      }); // });

      if (controller.get('monthsStats') == null) controller.set('monthsStats', model); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.month-stats');
      return result;
    }
  });

  _exports.default = _default;
});