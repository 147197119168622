define("digiprax-web/models/test-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    item: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    information: _emberData.default.attr('string')
  });
  /*
   createdAt                 | timestamp with time zone | not null                                                                                                                                
   updatedAt                 | timestamp with time zone | not null                                                                                                                                
  */


  _exports.default = _default;
});