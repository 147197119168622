define("digiprax-web/services/calendar-editor", ["exports", "moment", "digiprax-web/models/appointment", "digiprax-web/models/location-appointments"], function (_exports, _moment, _appointment2, _locationAppointments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // FIXME remove eslint-disable

  /* eslint-disable */
  // import DS from 'ember-data';
  // import { Promise } from 'rsvp';
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    curAppointment: null,
    // set as message/param for patientenkarte
    // appointmentsLoadedFlag: null,
    // treatmentsForTimespanByPlace(from, until, listener) {
    //   // return this.store.query('treatment', {from: from, until: until});
    //   let rooms = EmberObject.create({});
    //
    //   this.get('application').showProgressbar();
    //   let appointments = this.store.query('treatment', {from: from, until: until});
    //   appointments.then(as => {
    //     this.addRoomDataForTreatments(rooms, as);
    //     this.get('application').hideProgressbar();
    //     this.set('appointmentsLoadedFlag', new Date());
    //   }, error => {
    //     this.get('application').hideProgressbar();
    //     if (typeof(error.get) == 'function') {
    //       listener.inValid(error.get('messages'));
    //     } else {
    //       this.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));
    //       listener.inValid(['Unbekannter Fehler!']);
    //     }
    //   });
    //
    //   return A([rooms]);
    // },
    //
    // with promise - but then calendar blinks, so appointmentsLoadedFlag is better
    // _appointmentsForTimespanByPlace(from, until, deferred, listener) {
    //   let rooms = EmberObject.create({});
    //   let appointments = this.store.query('appointment-model', {from: from, until: until});
    //   appointments.then(as => {
    //     this.get('application').hideProgressbar();
    //     let roomSetup = {};
    //     this.addRoomData(/*rooms*/roomSetup, as);
    //     rooms.setProperties(roomSetup);
    //     deferred.resolve(A([rooms]));
    //   }, error => {
    //     this.get('application').hideProgressbar();
    //     if (typeof(error.get) == 'function') {
    //       listener.inValid(error.get('messages'));
    //     } else {
    //       this.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));
    //       listener.inValid(['Unbekannter Fehler!']);
    //     }
    //   });
    //   return DS.PromiseObject.create({promise: deferred.promise});
    // },
    clearPending: function clearPending(date) {
      // this.set('currentQuery', null);
      var purgeProps = {
        currentQuery: null
      };
      var dateMom = (0, _moment.default)(date);
      var startOfWeek = dateMom.add(-dateMom.weekday(), 'day');
      var pendingRoomsKey = 'rooms_' + startOfWeek.format('YYYY-MM-DD') + '_' + startOfWeek.clone().add(6, 'days').format('YYYY-MM-DD');
      purgeProps[pendingRoomsKey] = null;
      purgeProps[pendingRoomsKey.replace(/^rooms_/, 'query_')] = null; // console.log('CalendarEditorService - clearPending: purgeProps = ' + purgeProps);

      this.setProperties(purgeProps);
    },
    appointmentsForTimespanByPlace: function appointmentsForTimespanByPlace(from, until, listener, weekSetup, weekDayData) {
      var _this = this;

      // console.log('CalendarEditorService - appointmentsForTimespanByPlace: from = ' + from + ', until = ' + until);
      // let rooms = noPendingRooms ? [noPendingRooms] : this.pendingRooms(from, until);
      var rooms = [weekDayData]; // if (this.application.practice == null || (this.application.practice.isPending && (!this.application.practice.isFulfilled))) return rooms;

      if (this.get('application.practice.rooms') == null) return rooms;
      if (this.get('application.patients') == null) return rooms; // if (this.application.patients.isPending && (!this.application.patients.isFulfilled)) return rooms;
      // console.log('CalendarEditorService - appointmentsForTimespanByPlace: from = ' + from + ', until = ' + until + ', send query ...');
      // calendar blinks with deferred
      // if (true) // <-> calendar-week - use defer with availabilities
      //   return this._appointmentsForTimespanByPlace(from, until, defer(), listener);
      // let appointments = this.store.query('appointment-model', {from: from, until: until});

      var pendingAppointments = this.get('query_' + from + '_' + until);

      if (pendingAppointments == null) {
        // console.log('CalendarEditorService - appointmentsForTimespanByPlace: from = ' + from + ', until = ' + until + ', send query ...');
        if (listener.showProgressbar) listener.showProgressbar();else this.get('application').showProgressbar();
        var currentQueryProps = {
          currentQuery: 'rooms_' + from + '_' + until
        };
        currentQueryProps[currentQueryProps.currentQuery] = rooms;
        this.setProperties(currentQueryProps);
        var appointments = this.store.query('appointment-model', {
          from: from,
          until: until
        });
        pendingAppointments = this.set('query_' + from + '_' + until, {
          appointments: appointments,
          newAppointments: Ember.A([])
        });
      } else {
        pendingAppointments.newAppointments.removeObjects(pendingAppointments.newAppointments.filter(function (a) {
          return a.isDeleted;
        }));
        this.set('currentQuery', 'rooms_' + from + '_' + until);
      }

      pendingAppointments.appointments.then(function (as) {
        try {
          _this._setupAppointments(rooms, as.toArray().concat(pendingAppointments.newAppointments.toArray()), listener, weekSetup);
        } catch (e) {
          if (listener.hideProgressbar) listener.hideProgressbar();else _this.get('application').hideProgressbar();

          _this.get('application').debugRemote('CalendarEditor - appointmentsForTimespanByPlace: ' + e);
        }
      }, function (error) {
        if (listener.hideProgressbar) listener.hideProgressbar();else _this.get('application').hideProgressbar();

        if (typeof error.get == 'function') {
          listener.inValid(error.get('messages'));
        } else {
          _this.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

          listener.inValid(['Unbekannter Fehler!']);
        }
      });
      return rooms;
    },
    _setupAppointments: function _setupAppointments(rooms, as, listener, weekSetup) {
      var _this2 = this;

      if (!(this.application.patients.isPending && !this.application.patients.isFulfilled)) {
        var setupHandler = function setupHandler(_patients) {
          // console.log('CalendarEditorService - appointmentsForTimespanByPlace: patients ready - setting up appointments ...');
          var roomSetup = {};

          _this2.addRoomData(
          /*rooms*/
          roomSetup, as);

          if (weekSetup != null) {
            // run(() => weekSetup(roomSetup));
            weekSetup(roomSetup); // rooms[0].setProperties(roomSetup);
            // weekSetup(rooms[0])
            // this.set('appointmentsLoadedFlag', new Date());
          } else {
            rooms[0].setProperties(roomSetup);

            _this2.set('appointmentsLoadedFlag', new Date());
          }

          if (listener.hideProgressbar) listener.hideProgressbar();else _this2.get('application').hideProgressbar();
        };

        if (this.application.patients.then) {
          console.log('CalendarEditorService - appointmentsForTimespanByPlace: waiting for patients ...');
          this.get('application.patients').then(function (patients) {
            return setupHandler(patients);
          });
        } else {
          setupHandler(this.application.patients);
        }
      } else {
        console.log('CalendarEditorService - appointmentsForTimespanByPlace: waiting for patients ...');
      }
    },
    appointmentsForDateByPlace: function appointmentsForDateByPlace(date, listener) {
      return this.appointmentsForTimespanByPlace(date, (0, _moment.default)(date).add(1, 'day').format('YYYY-MM-DD'));
    },
    // addRoomDataForTreatments(rooms, treatments) {
    //   let room = Room.create({name: '*'});
    //   room.set('places', A([]));
    //
    //   treatments.toArray().forEach(t => {
    //     let appointmentParams = {
    //       model: t,
    //       room: room,
    //       date: t.date,
    //       from: '8:00',
    //       until: '20:00',
    //       range: [800, 2000],
    //       patient: this.store.peekRecord('patient', t.patientId),
    //       hp: 'HP ' + this.application.get('currentUser.firstName') + ' ' + this.application.get('currentUser.lastName')
    //     };
    //
    //     let appointment = room.sortableAppointments.pushObject(Appointment.create(appointmentParams));
    //     if (rooms['0'] == null)
    //       rooms.set('0', EmberObject.create({all: [appointment]}));
    //     else {
    //       rooms.get('0' + '.all').push(appointment);
    //       rooms.get('0' + '.all').sort((a1, a2) => a1.range[0] > a2.range[0] ? 1 : -1);
    //     }
    //   });
    // },
    extendAppointment: function extendAppointment(appointment, places) {
      var appointmentParams = {
        model: appointment,
        date: appointment.date,
        from: String(appointment.from).replace(/([0-9]{2})$/, ':$1'),
        until: String(appointment.until).replace(/([0-9]{2})$/, ':$1'),
        range: [appointment.from, appointment.until],
        hp: 'HP ' + this.application.get('currentUser.firstName') + ' ' + this.application.get('currentUser.lastName')
      };

      if (appointment.patient_id != null) {
        appointmentParams.patient = this.store.peekRecord('patient', appointment.patient_id);

        if (appointmentParams.patient == null) {
          console.log('CalendarEditorService - extendAppointment: patient[' + appointment.patient_id + '] not peekable ...');
          appointmentParams.patient = this.get('application.patients').find(function (p) {
            return p.id == appointment.patient_id;
          }); // if (appointmentParams.patient == null) console.log('CalendarEditorService - extendAppointment: patient['+appointment.patient_id+'] not ready ...');

          if (appointmentParams.patient == null) {
            // console.log('CalendarEditorService - addRoomData: patient['+a.patient_id+'] not ready ...');
            appointmentParams.model.patient_info.id = appointmentParams.model.patient_id;
            appointmentParams.patient = Ember.Object.create(appointmentParams.model.patient_info);
          }
        } else {
          if (appointmentParams.model.patient_info == null) appointmentParams.model.set('patient_info', {
            firstName: appointmentParams.patient.firstName,
            lastName: appointmentParams.patient.lastName
          });
        }
      } else {
        var patientNameMatch = appointment.extra_info.new_patient.match(/^([^,]+)(,.+|)$/);
        var patient_info = {
          firstName: patientNameMatch[2].replace(',', '').trim(),
          lastName: patientNameMatch[1].trim()
        };
        if (appointmentParams.model.patient_info == null) appointmentParams.model.set('patient_info', patient_info);
        appointmentParams.patient = Ember.Object.create(patient_info);
      }

      if (places != null) {
        if (appointment.room_id != null) {
          appointmentParams.room = this.application.get('practice.rooms.' + appointment.room_id);
        } else {
          appointmentParams.room = places[appointment.place_id].room;
          appointmentParams.place = places[appointment.place_id].place;
        }
      }

      return _appointment2.default.create(appointmentParams);
    },
    addRoomData: function addRoomData(rooms, as) {
      var _this3 = this;

      var places = {};
      var defaultColor = '#e0e0e0';
      Object.keys(this.application.get('practice.rooms') || {}).forEach(function (roomId) {
        // this.application.practice.rooms[roomId].set('appointments', EmberObject.create({}));
        (_this3.application.get('practice.rooms.' + roomId + '.places') || []).forEach(function (place) {
          // place.set('appointments', EmberObject.create({}));
          places[place.id] = {
            room: _this3.application.get('practice.rooms.' + roomId),
            place: place
          };
        });
      });
      as.toArray().forEach(function (a) {
        var appointmentParams = {
          model: a,
          date: a.date,
          from: String(a.from).replace(/([0-9]{2})$/, ':$1'),
          until: String(a.until).replace(/([0-9]{2})$/, ':$1'),
          range: [a.from, a.until],
          hp: 'HP ' + _this3.application.currentUser.firstName + ' ' + _this3.application.currentUser.lastName,
          color: a.extra_info.color || parseInt('0x' + defaultColor.substring(1, 3)) + ',' + parseInt('0x' + defaultColor.substring(3, 5)) + ',' + parseInt('0x' + defaultColor.substring(5, 7))
        };

        if (a.patient_id != null) {
          appointmentParams.patient = _this3.store.peekRecord('patient', a.patient_id);

          if (appointmentParams.patient == null) {
            // console.log('CalendarEditorService - addRoomData: patient['+a.patient_id+'] not peekable ...');
            appointmentParams.patient = _this3.get('application.patients').find(function (p) {
              return p.id == a.patient_id;
            }); // if (appointmentParams.patient == null) console.log('CalendarEditorService - addRoomData: patient['+a.patient_id+'] not ready ...');

            if (appointmentParams.patient == null) {
              // console.log('CalendarEditorService - addRoomData: patient['+a.patient_id+'] not ready ...');
              appointmentParams.model.patient_info.id = appointmentParams.model.patient_id;
              appointmentParams.patient = Ember.Object.create(appointmentParams.model.patient_info);
            }
          } else {
            if (appointmentParams.model.patient_info == null) appointmentParams.model.set('patient_info', {
              firstName: appointmentParams.patient.firstName,
              lastName: appointmentParams.patient.lastName
            });
          }
        } else {
          var patientNameMatch = a.extra_info.new_patient.match(/^([^,]+)(,.+|)$/);
          var patient_info = {
            firstName: patientNameMatch[2].replace(',', '').trim(),
            lastName: patientNameMatch[1].trim()
          };
          if (appointmentParams.model.patient_info == null) appointmentParams.model.set('patient_info', patient_info);
          appointmentParams.patient = Ember.Object.create(patient_info);
        }

        if (a.room_id != null) {
          appointmentParams.room = _this3.application.get('practice.rooms.' + a.room_id); // // let appointment = (this.application.get('practice.rooms.'+a.room_id+'.sortableAppointments')||A([])).pushObject(Appointment.create(appointmentParams));
          // let appointment = this.application.get('practice.rooms.'+a.room_id+'.appointments').addAppointment(Appointment.create(appointmentParams));

          var appointment = _appointment2.default.create(appointmentParams);

          if (rooms[a.room_id] == null) {
            // rooms[a.room_id] = EmberObject.create({all: [appointment]}); // rooms.set(a.room_id, EmberObject.create({all: [appointment]}));
            rooms[a.room_id] = _locationAppointments.default.create(); // rooms.set(a.room_id, EmberObject.create({all: [appointment]}));

            rooms[a.room_id].addAppointment('all', appointment);
          } else {
            // rooms[a.room_id].all.push(appointment); // rooms.get(a.room_id + '.all').push(appointment);
            // rooms[a.room_id].all.sort((a1, a2) => a1.range[0] > a2.range[0] ? 1 : -1); // rooms.get(a.room_id + '.all').sort((a1, a2) => a1.range[0] > a2.range[0] ? 1 : -1);
            rooms[a.room_id].addAppointment('all', appointment);
          }
        } else {
          appointmentParams.room = places[a.place_id].room;
          appointmentParams.place = places[a.place_id].place; // // let appointment = places[a.place_id].place.sortableAppointments.pushObject(Appointment.create(appointmentParams));
          // let appointment = places[a.place_id].place.appointments.addAppointment(Appointment.create(appointmentParams));

          var _appointment = _appointment2.default.create(appointmentParams);

          if (rooms[places[a.place_id].room.id] == null) {
            var placeData = {};
            placeData[a.place_id] = [_appointment];
            rooms[places[a.place_id].room.id] = Ember.Object.create(placeData); // rooms.set(places[a.place_id].room.id, EmberObject.create(placeData));
          } else {
            if (rooms[places[a.place_id].room.id][a.place_id] == null) rooms[places[a.place_id].room.id][a.place_id] = [_appointment]; // rooms.set(places[a.place_id].room.id + '.' + a.place_id, [appointment]);
            else {
                rooms[places[a.place_id].room.id][a.place_id].push(_appointment); // rooms.get(places[a.place_id].room.id + '.' + a.place_id).push(appointment);

                rooms[places[a.place_id].room.id][a.place_id].sort(function (a1, a2) {
                  return a1.range[0] > a2.range[0] ? 1 : -1;
                }); // rooms.get(places[a.place_id].room.id + '.' + a.place_id).sort((a1, a2) => a1.range[0] > a2.range[0] ? 1 : -1);
              }
          }
        }
      });
    },
    saveAppointment: function saveAppointment(appointment, listener) {
      var _this4 = this;

      if (listener.showProgressbar) listener.showProgressbar();else this.get('application').showProgressbar();

      if (appointment.get('isNew') || appointment.id == 'new') {
        if (this.get('application.currentUser.plus')) appointment.set('isBusiness', true);
        appointment.save().then(function (newA) {
          // done ...
          if (listener.hideProgressbar) listener.hideProgressbar();else _this4.get('application').hideProgressbar();

          if (_this4.get('application.practice.rooms.' + newA.room_id) == null) {
            _this4.get('application.practice').reload().then(function (_p) {
              var newAppointment = _this4._addAppointmentToCurrent(newA);

              listener.afterSave(newAppointment);
            });
          } else {
            var newAppointment = _this4._addAppointmentToCurrent(newA);

            listener.afterSave(newAppointment);
          }
        }, function (error) {
          if (listener.hideProgressbar) listener.hideProgressbar();else _this4.get('application').hideProgressbar();

          if (typeof error.get == 'function') {
            listener.inValid(error.get('messages').map(function (errorMessage) {
              if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Rechnungs-Datum darf nicht in der Zukunft liegen.';
              if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              if (errorMessage.match(/^transactiontype: can't be blank/)) return 'Art des Zahlungsvorganges muss angegeben werden.';
              return errorMessage;
            }));
          } else {
            _this4.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

            listener.inValid(['Unbekannter Fehler!']);
          }
        });
      } else {
        appointment.save().then(function (updatedAppointment) {
          // done ...
          if (listener.hideProgressbar) listener.hideProgressbar();else _this4.get('application').hideProgressbar();

          if (listener.before) {
            _this4._removeAppointmentFromCache(listener.before);

            listener.afterSave(_this4._addAppointmentToCurrent(updatedAppointment));
            return;
          } // listener.afterSave(updatedAppointment);


          listener.afterSave(_this4.extendAppointment(updatedAppointment));
        }, function (error) {
          if (listener.hideProgressbar) listener.hideProgressbar();else _this4.get('application').hideProgressbar();

          if (typeof error.get == 'function') {
            listener.inValid(error.get('messages').map(function (errorMessage) {
              if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Rechnungs-Datum darf nicht in der Zukunft liegen.';
              if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              if (errorMessage.match(/^transactiontype: can't be blank/)) return 'Art des Zahlungsvorganges muss angegeben werden.';
              return errorMessage;
            }));
          } else {
            _this4.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

            listener.inValid(['Unbekannter Fehler!']);
          }
        });
      }
    },
    _removeAppointmentFromCache: function _removeAppointmentFromCache(appointment) {
      var weekKey = (0, _moment.default)(appointment.date).startOf('week').format('YYYY-MM-DD') + '_' + (0, _moment.default)(appointment.date).endOf('week').format('YYYY-MM-DD');
      var queryResult = this.get('query_' + weekKey);

      if (queryResult != null) {// TODO debugger;
      }
    },
    _addAppointmentToCurrent: function _addAppointmentToCurrent(appointment) {
      var newAppointment;

      if (this.currentQuery != null) {
        var queryResultKey = this.currentQuery.replace('rooms_', 'query_');

        if (this.get(this.currentQuery)[0][appointment.room_id] == null) {
          var roomSetup = {};
          this.addRoomData(roomSetup, [appointment]);
          newAppointment = roomSetup[appointment.room_id].locations.all[(0, _moment.default)(appointment.date).format('YYYY-MM-DD')][0];
        } else {
          newAppointment = this.extendAppointment(appointment);
          this.get(this.currentQuery)[0][appointment.room_id].locations.all[(0, _moment.default)(appointment.date).format('YYYY-MM-DD')].push(newAppointment);
        }

        var queryResult = this.get(queryResultKey);
        queryResult.newAppointments.pushObject(appointment);
      } else {
        newAppointment = this.extendAppointment(appointment);
      }

      return newAppointment;
    }
  });

  _exports.default = _default;
});