define("digiprax-web/routes/customers/overview", ["exports", "digiprax-web/routes/customers/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend({
    beforeModel: function beforeModel(_transition) {// just overwrite to prevent super.beforeModel from being executed.
    }
  });

  _exports.default = _default;
});