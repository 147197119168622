define("digiprax-web/serializers/user", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var data = {};
      var isList = !(typeof payload.pop == 'undefined' || payload.pop == null);

      if (isList) {
        data[(0, _emberInflector.pluralize)(primaryModelClass.modelName)] = payload;
      } else {
        data[primaryModelClass.modelName] = payload;
      }

      return this._super(store, primaryModelClass, data, id, requestType);
    },
    serialize: function serialize(snapshot, options) {
      var json;

      if (snapshot.id == null) {
        // json = {
        //   "practice[name]": snapshot.record.get('practiceName'),
        //   "practice[addressZip]": snapshot.record.get('addressZip'),
        //   "practice[phoneLandline]": snapshot.record.get('phoneLandline'),
        //   "practice[email]": snapshot.record.get('email'),
        //   "user[firstName]": snapshot.record.get('firstName'),
        //   "user[lastName]": snapshot.record.get('lastName'),
        //   "user[password]": snapshot.record.get('password'),
        //   "user[password_confirmation]": snapshot.record.get('passwordConfirmation')
        // };
        delete options.includeId;
        json = {
          "practice": {
            "name": snapshot.record.get('practiceName'),
            "addressZip": snapshot.record.get('addressZip'),
            "phoneLandline": snapshot.record.get('phoneLandline'),
            "email": snapshot.record.get('email')
          },
          "user": {
            "firstName": snapshot.record.get('firstName'),
            "lastName": snapshot.record.get('lastName'),
            "password": snapshot.record.get('password'),
            "password_confirmation": snapshot.record.get('passwordConfirmation')
          }
        };
      } else {
        json = this._super.apply(this, arguments);
      }

      return json;
    }
  });

  _exports.default = _default;
});