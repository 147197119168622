define("digiprax-web/models/open-invoice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    Data: _emberData.default.attr('raw') // patientLastName: DS.attr('string'),
    // patientFirstName: DS.attr('string'),
    // patientBirthDate: DS.attr('string'),
    // invoiceDate: DS.attr('string'),
    // invoiceMonth: DS.attr('string'),
    // invoiceAmount: DS.attr('string'),
    // invoiceNumber: DS.attr('string')

  });

  _exports.default = _default;
});