define("digiprax-web/models/tariff", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    mappingId: _emberData.default.attr('number'),
    item: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    info: _emberData.default.attr('string'),
    extraInfo: _emberData.default.attr('raw'),
    position: _emberData.default.attr('number'),

    /*itemSortKey: function() {
      var alphabet = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];
      var firstPart = parseInt(this.get('item').match(/^[0-9]+/)[0]) * 100;
      var secondPart = parseInt((this.get('item').match(/^[0-9]+\.([0-9]+)/)||[null,0])[1]);
      var thirdPart = (this.get('item').match(/^[0-9]+\.[0-9]+\s*([a-z])/i)||[null,null])[1];
      if (firstPart == 1100) Ember.Logger.debug(firstPart+' + '+secondPart+' = '+(firstPart + secondPart));
      return thirdPart != null ? [firstPart*100 + secondPart*100 + alphabet.indexOf(thirdPart.toLowerCase())] : [firstPart*100  + secondPart*100];
    }.property(),*/
    settlementTariff: Ember.computed('curSettlementId', function () {
      var _this = this;

      return this.get('store').peekAll('tariff-settlement').filter(function (tariffSettlement) {
        return tariffSettlement.get('mappingId') == _this.get('mappingId') && tariffSettlement.get('settlementId') == _this.get('curSettlementId');
      })[0];
    }),
    additionalText: Ember.computed(function () {
      var additionalTextId = this.get('extraInfo.additional_text_id');

      if (additionalTextId != null) {
        return this.get('store').peekRecord('additional-text', additionalTextId);
      } else {
        return null;
      }
    }),
    curSettlementId: null,
    bioresonanzAvailable: Ember.computed(function () {
      // return ['12.13', '12.14', '13.1', '14.7 A', '20.4', '21.1', '25.6', '28.1', '28.2',
      //         '30.1', '31.1', '31.2', '35.1', '35.2', '35.3', '35.4', '35.5', '35.6', '36.3', '37.2'].indexOf(this.get('item')) >= 0;
      return Boolean(this.get('extraInfo.bioresonanzAvailable'));
    })
  });
  /*
   createdAt                 | timestamp with time zone | not null
   updatedAt                 | timestamp with time zone | not null
   deletedAt                 | timestamp with time zone |
  */


  _exports.default = _default;
});