define("digiprax-web/routes/patients/new", ["exports", "digiprax-web/mixins/patient-editor-route"], function (_exports, _patientEditorRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_patientEditorRoute.default, {
    calendarEditor: Ember.inject.service(),
    deactivate: function deactivate() {
      if (this.get('controller.model.id') == 'new' || this.get('controller.model.isNew') && !this.get('controller.model.isSaving')) {
        // delete model when create was canceled
        // this.get('controller.model').deleteRecord();
        this.get('controller.model').unloadRecord();
      }
    },
    model: function model(_params, _transition) {
      var newPatient = this.get('store').peekRecord('patient', 'new');
      if (newPatient != null) newPatient.unloadRecord();
      var modelParams = {
        id: 'new',
        invoiceCopies: '1',
        initReception: new Date()
      };

      if (this.calendarEditor.curAppointment != null) {
        if (this.calendarEditor.curAppointment.patient != null && this.calendarEditor.curAppointment.patient.id == null) {
          modelParams.firstName = this.calendarEditor.curAppointment.patient.firstName;
          modelParams.lastName = this.calendarEditor.curAppointment.patient.lastName;
          modelParams.initReception = this.calendarEditor.curAppointment.date || this.calendarEditor.curAppointment.model.date;
        } // FIXME - when new patient is saved also save appointments patient-id: pass appointmentId


        this.calendarEditor.set('curAppointment', null);
      }

      return this.get('store').createRecord('patient', modelParams);
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model); // this._setPracticeId(controller, model);


      var domainSpecificSetup = function domainSpecificSetup(currentUser) {
        if (currentUser && currentUser.cur_api_domain == 'business') {
          model.set('is_customer', true);
        } else {
          var settlementSort = function settlementSort(s1, s2) {
            if (s1.get('group') > s2.get('group')) return 1;
            return s1.get('name').replace(/\s/g, '').toLowerCase() >= s2.get('name').replace(/\s/g, '').toLowerCase() ? 1 : -1;
          };

          var settlements = _this.get('store').peekAll('settlement');

          if (settlements != null && settlements.toArray().length >= 1) {
            controller.set('settlements', settlements.toArray().sort(settlementSort));
          } else {
            _this.get('store').findAll('settlement').then(function (settlements) {
              controller.set('settlements', settlements.toArray().sort(settlementSort));
            });
          }
        }
      };

      if (controller.currentUser && typeof controller.currentUser.then == 'function') controller.currentUser.then(function (currentUser) {
        return domainSpecificSetup(currentUser);
      });else domainSpecificSetup(controller.currentUser); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.patientnew');
    }
  });

  _exports.default = _default;
});