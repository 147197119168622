define("digiprax-web/helpers/to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toString = toString;
  _exports.default = void 0;

  // f.ex. used to convert SafeString to plain-string in helper if other helper requires plain-string:
  // {{moment-format (moment slot.time 'HH:mm') (to-string (t 'booking.formats.full_time_format'))}}
  function toString(params, _hash) {
    if (params[0] == '__to_json__') return JSON.stringify(params[1]);
    return params[0].toString();
  }

  var _default = Ember.Helper.helper(toString);

  _exports.default = _default;
});