define("digiprax-web/models/place", ["exports", "digiprax-web/mixins/appointment-location"], function (_exports, _appointmentLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_appointmentLocation.default, {
    id: null,
    name: null,
    times: Ember.A([800, 1800]),
    firstSlotStart: Ember.computed({
      get: function get(_key) {
        return this.get('times.0');
      },
      set: function set(_key, selectChangeEventOrValue) {
        if (selectChangeEventOrValue != null && selectChangeEventOrValue.target != null) return this.set('times.0', selectChangeEventOrValue.target.value == '' ? 800 : parseInt(selectChangeEventOrValue.target.value));else return this.set('times.0', selectChangeEventOrValue || 800);
      }
    }),
    lastSlotEnd: Ember.computed({
      get: function get(_key) {
        return this.get('times.1');
      },
      set: function set(_key, selectChangeEventOrValue) {
        if (selectChangeEventOrValue != null && selectChangeEventOrValue.target != null) return this.set('times.1', selectChangeEventOrValue.target.value == '' ? 1800 : parseInt(selectChangeEventOrValue.target.value));else return this.set('times.1', selectChangeEventOrValue || 1800);
      }
    }),
    lastSlotEndTime: Ember.computed('lastSlotEnd', function () {
      var hour = parseInt(this.lastSlotEnd / 100);
      return hour + ':' + ('0' + (this.lastSlotEnd - hour * 100)).match(/[0-9]{2}$/)[0];
    })
  });

  _exports.default = _default;
});