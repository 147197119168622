define("digiprax-web/mixins/appointment-dialogs", ["exports", "moment", "digiprax-web/models/appointment"], function (_exports, _moment, _appointment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      openAppointmentDialogCW: function openAppointmentDialogCW(slot, date, eventPlaceholder) {
        var _this = this;

        var allPlacesByRooms = [{}].concat(Object.keys(this.practice.rooms)).reduce(function (roomData, roomId) {
          roomData[roomId] = {
            /*id: roomId, */
            all: []
          };

          _this.practice.rooms[roomId].places.forEach(function (place) {
            return roomData[roomId][place.id] = [];
          });

          return roomData;
        });
        var dateAppointents = this.availabilities.appointmentsByDay[(0, _moment.default)(date, 'YYYYMMDD').format('YYYY-MM-DD')] || []; // let appointmentsByPlace = [{}].concat(dateAppointents).reduce((roomData, appointment) => {

        var appointmentsByPlace = [allPlacesByRooms].concat(dateAppointents).reduce(function (roomData, appointment) {
          if ((0, _moment.default)(appointment.date).format('YYYYMMDD') == date) {
            // if (roomData[appointment.roomId] == null)
            //   roomData[appointment.roomId] = {};
            if (appointment.placeId == null) {
              // if (roomData[appointment.roomId].all == null)
              //   roomData[appointment.roomId].all = [];
              roomData[appointment.roomId].all.push(appointment);
            } else {
              // if (roomData[appointment.roomId][appointment.placeId] == null)
              //   roomData[appointment.roomId][appointment.placeId] = [];
              roomData[appointment.roomId][appointment.placeId].push(appointment);
            }
          }

          return roomData;
        });
        var todaysLocationData = [{}].concat(Object.keys(appointmentsByPlace)).reduce(function (roomData, roomId) {
          roomData[roomId] = [{
            id: roomId,
            slots: [],
            all: {
              availableRanges: _this.availableRanges(appointmentsByPlace[roomId]['all'])
            }
          }].concat(Object.keys(appointmentsByPlace[roomId]).filter(function (placeId) {
            return [
            /*'id', */
            'all'].indexOf(placeId) == -1;
          })).reduce(function (acc2, placeId) {
            acc2[placeId] = {
              availableRanges: _this.availableRanges(appointmentsByPlace[roomId][placeId])
            };

            _this.cleanUpLocationRanges(acc2.all.availableRanges, appointmentsByPlace[roomId][placeId]);

            _this.cleanUpLocationRanges(acc2[placeId].availableRanges, appointmentsByPlace[roomId]['all']);

            return acc2;
          });
          return roomData;
        }); // FIXME: enable multiple-rooms-select in dialog

        var room = todaysLocationData[Object.keys(todaysLocationData || {})[0]];
        var availableRanges = todaysLocationData[room.id].all.availableRanges;
        if (availableRanges) availableRanges = availableRanges.map(function (range) {
          return [Math.max(range[0], slot.from), range[1]];
        });
        var curAvailableRange = (availableRanges || [[slot.from, slot.from + this.slotDuration]]).find(function (range) {
          return range[1] > slot.from;
        });
        var stepMinutes = 5;

        var availableTimes = this._availableTimes(stepMinutes, slot, curAvailableRange);

        this._openAppointmentDialog(slot, (0, _moment.default)(date, 'YYYYMMDD').format('YYYY-MM-DD'), this.practice.rooms[room.id], null, todaysLocationData, this.get('patients'), availableTimes[0], availableTimes[1], this.slotDuration / 100 * 60,
        /*, this.mergeSlotRanges*/
        this.formatTimeInt
        /*, this.parseTimeToInt*/
        , 'showTerminDialog', eventPlaceholder || event);

        return false;
      },
      openAppointmentDialog: function openAppointmentDialog(slot, date, room, place, eventPlaceholder) {
        var availableRanges = place != null ? this.availabilities.todaysLocationData[room.id][place.id].availableRanges : this.availabilities.todaysLocationData[room.id].all.availableRanges;
        if (availableRanges) availableRanges = availableRanges.map(function (range) {
          return [Math.max(range[0], slot.fromInt), range[1]];
        });
        var curAvailableRange = (availableRanges || [[slot.fromInt, slot.fromInt + this.slotDuration]]).find(function (range) {
          return range[1] > slot.fromInt;
        });
        var stepMinutes = 5;

        var availableTimes = this._availableTimes(stepMinutes, slot, curAvailableRange); // let timesFrom = [{ hour: parseInt(curAvailableRange[0] / 100), minutes: [('0' + (curAvailableRange[0] % 100)).match(/[0-9]{2}$/)[0]] }];
        // // let timesFrom = [{ hour: slot.fromInt / 100, minutes: [('0' + (curAvailableRange[0] % 100)).match(/[0-9]{2}$/)[0]] }];
        // for (var timeInt=(curAvailableRange[0]+stepMinutes) ; timeInt<Math.min(curAvailableRange[1], slot.fromInt+this.slotDuration/100*60) ; timeInt+=stepMinutes) {
        //   timesFrom[timesFrom.length-1].minutes.push(('0' + (timeInt % 100)).match(/[0-9]{2}$/)[0]);
        // }
        // let timesUntil = [].concat(timesFrom);
        // if (timesUntil[timesUntil.length-1].minutes[timesUntil[timesUntil.length-1].minutes.length-1] == (60 - stepMinutes)) {
        //   timesUntil = timesUntil.concat({ hour: timesUntil[timesUntil.length-1].hour + 1, minutes: ['00'] });
        //   while (timesUntil[timesUntil.length-1].hour * 100 + parseInt(timesUntil[timesUntil.length-1].minutes[timesUntil[timesUntil.length-1].minutes.length-1]) < curAvailableRange[1]) {
        //     if (timesUntil[timesUntil.length-1].minutes[timesUntil[timesUntil.length-1].minutes.length-1] == (60 - stepMinutes))
        //       timesUntil.push({ hour: timesUntil[timesUntil.length-1].hour + 1, minutes: ['00'] });
        //     else
        //       timesUntil[timesUntil.length-1].minutes.push(('0'+(parseInt(timesUntil[timesUntil.length-1].minutes[timesUntil[timesUntil.length-1].minutes.length-1]) + stepMinutes)).match(/[0-9]{2}$/)[0]);
        //   }
        // } else
        //   timesUntil.push({ hour: timesUntil[timesUntil.length-1].hour, minutes: [('0'+(curAvailableRange[1]%100)).match(/[0-9]{2}$/)[0]]});
        // this._openAppointmentDialog(slot, date, room, place, this.availabilities.todaysLocationData, this.get('patients'), timesFrom, timesUntil, (this.slotDuration/100*60)/*, this.mergeSlotRanges*/, this.formatTimeInt/*, this.parseTimeToInt*/, 'showTerminDialog', (eventPlaceholder || event));


        this._openAppointmentDialog(slot, date, room, place, this.availabilities.todaysLocationData, this.get('patients'), availableTimes[0], availableTimes[1], this.slotDuration / 100 * 60,
        /*, this.mergeSlotRanges*/
        this.formatTimeInt
        /*, this.parseTimeToInt*/
        , 'showTerminDialog', eventPlaceholder || event);

        return false;
      },
      closeAppointmentDialog: function closeAppointmentDialog() {
        this.set('showTerminDialog', false);
        return false;
      }
    },
    _availableTimes: function _availableTimes(stepMinutes, slot, availableRange) {
      var timesFrom = [{
        hour: parseInt(availableRange[0] / 100),
        minutes: [('0' + availableRange[0] % 100).match(/[0-9]{2}$/)[0]]
      }]; // let timesFrom = [{ hour: slot.fromInt / 100, minutes: [('0' + (availableRange[0] % 100)).match(/[0-9]{2}$/)[0]] }];

      for (var timeInt = availableRange[0] + stepMinutes; timeInt < Math.min(availableRange[1], slot.fromInt + this.slotDuration / 100 * 60); timeInt += stepMinutes) {
        timesFrom[timesFrom.length - 1].minutes.push(('0' + timeInt % 100).match(/[0-9]{2}$/)[0]);
      }

      var timesUntil = [].concat(timesFrom);

      if (timesUntil[timesUntil.length - 1].minutes[timesUntil[timesUntil.length - 1].minutes.length - 1] == 60 - stepMinutes) {
        timesUntil = timesUntil.concat({
          hour: timesUntil[timesUntil.length - 1].hour + 1,
          minutes: ['00']
        });

        while (timesUntil[timesUntil.length - 1].hour * 100 + parseInt(timesUntil[timesUntil.length - 1].minutes[timesUntil[timesUntil.length - 1].minutes.length - 1]) < availableRange[1]) {
          if (timesUntil[timesUntil.length - 1].minutes[timesUntil[timesUntil.length - 1].minutes.length - 1] == 60 - stepMinutes) timesUntil.push({
            hour: timesUntil[timesUntil.length - 1].hour + 1,
            minutes: ['00']
          });else timesUntil[timesUntil.length - 1].minutes.push(('0' + (parseInt(timesUntil[timesUntil.length - 1].minutes[timesUntil[timesUntil.length - 1].minutes.length - 1]) + stepMinutes)).match(/[0-9]{2}$/)[0]);
        }
      } else timesUntil.push({
        hour: timesUntil[timesUntil.length - 1].hour,
        minutes: [('0' + availableRange[1] % 100).match(/[0-9]{2}$/)[0]]
      });

      return [timesFrom, timesUntil];
    },
    _openAppointmentDialog: function _openAppointmentDialog(slot, date, room, place, todaysLocationData, patients, timesFrom, timesUntil, slotDuration
    /*, mergeSlotRanges*/
    , formatTimeInt
    /*, parseTimeToInt*/
    , dialogKey, _event) {
      this.setProperties({
        newAppointment: _appointment.default.create({
          room: room,
          patients: patients,
          // @see model Appointment patients-property
          slot: slot,
          allPlaces: place == null,
          todaysLocationData: todaysLocationData,
          slotDuration: slotDuration,
          place: place,
          date: date,
          timesFrom: timesFrom,
          timesUntil: timesUntil,
          from: formatTimeInt(timesFrom[0].hour + timesFrom[0].minutes[0]),
          until: formatTimeInt(timesUntil[timesUntil.length - 1].hour + timesUntil[timesUntil.length - 1].minutes[timesUntil[timesUntil.length - 1].minutes.length - 1])
        }),
        slot: slot,
        room: room,
        allPlaces: place == null,
        place: place
      });
      /*
      this.setProperties({ newAppointment: EmberObject.create({ room: room,
                                                                allPlaces: place == null,
                                                                todaysLocationData: todaysLocationData,
                                                                place: place,
                                                                date: date,
                                                                timesFrom: timesFrom,
                                                                timesUntil: timesUntil/*,
                                                                from: formatTimeInt(timesFrom[0].hour+timesFrom[0].minutes[0]),
                                                                until: formatTimeInt(timesUntil[timesUntil.length-1].hour+timesUntil[timesUntil.length-1].minutes[timesUntil[timesUntil.length-1].minutes.length-1]) /}),
                           slot: slot, room: room, allPlaces: place == null, place: place });
      this.get('newAppointment').reopen({
        patient: computed({
          get(_key) {
            return this.get('_patient');
          },
          set(_key, selectChangeEventOrValue) {
            let patient = patients.find(p => p.id == (selectChangeEventOrValue.target != null ? selectChangeEventOrValue.target.value : selectChangeEventOrValue));
            return this.set('_patient', selectChangeEventOrValue.target != null ? (selectChangeEventOrValue.target.value == '' ? null : patient) : selectChangeEventOrValue);
          }
        }),
        from: computed({
          get(_key) {
            return this.get('_from');
          },
          set(_key, selectChangeEventOrValue) {
            let _from = selectChangeEventOrValue.target != null ? (selectChangeEventOrValue.target.value == '' ? null : selectChangeEventOrValue.target.value) : selectChangeEventOrValue;
            if (this.get('until') == null || parseTimeToInt(this.get('until')) < parseTimeToInt(_from || '0:00')) this.set('until', selectChangeEventOrValue);
            return this.set('_from', _from);
          }
        }),
        until: computed({
          get(_key) {
            return this.get('_until');
          },
          set(_key, selectChangeEventOrValue) {
            return this.set('_until', selectChangeEventOrValue.target != null ? (selectChangeEventOrValue.target.value == '' ? null : selectChangeEventOrValue.target.value) : selectChangeEventOrValue);
          }
        }),
        // lastAppointments: computed(function(){
        //   return [{ all: room.appointments.reverse().find(a => a.range[0] >= slot.fromInt && a.range[0] < slot.fromInt+slotDuration) }].concat(room.places).reduce((acc, curPlace) => {
        //     acc[curPlace.id] = curPlace.appointments.reverse().find(a => a.range[0] >= slot.fromInt && a.range[0] < slot.fromInt+slotDuration);
        //     if (acc[curPlace.id] != null && (acc.all == null || acc[curPlace.id].range[0] >= acc.all.range[0])) acc.all = acc[curPlace.id];
        //     return acc;
        //   });
        // }),
        // nextAppointments: computed(function(){
        //   return [{ all: room.appointments.find(a => a.range[0] >= slot.fromInt+slotDuration) }].concat(room.places).reduce((acc, curPlace) => {
        //     acc[curPlace.id] = curPlace.appointments.find(a => a.range[0] >= slot.fromInt+slotDuration);
        //     if (acc[curPlace.id] != null && (acc.all == null || acc[curPlace.id].range[0] < acc.all.range[0])) acc.all = acc[curPlace.id];
        //     return acc;
        //   });
        // }),
        availabilities: computed(function(){
          // let locationAvailabilities = [{ all: this.todaysLocationData[this.room.id].all.availableRanges.filter(range => range[0] >= slot.fromInt && range[0] < slot.fromInt+slotDuration).map(aR => { return { range: aR }; }) }].concat(room.places).reduce((acc, curPlace) => {
          let locationAvailabilities = [{ all: [] }].concat(room.places).reduce((acc, curPlace) => {
            acc[curPlace.id] = [];
            let appointments = curPlace.appointments.filter(a => a.range[1] > slot.fromInt/* && a.range[0] < slot.fromInt+slotDuration/).
                               concat(room.appointments.filter(a => a.range[1] > slot.fromInt/* && a.range[0] < slot.fromInt+slotDuration/)).
                               sort((a1, a2) => a1.range[0] > a2.range[0] ? 1 : -1);
            mergeSlotRanges(room, curPlace, acc[curPlace.id], slot, slotDuration, curPlace.lastSlotEnd, this.todaysLocationData[this.room.id][curPlace.id].availableRanges, appointments);
             return acc;
          });
           let appointments = [{ appointments: room.appointments.filter(a => a.range[1] > slot.fromInt/* && a.range[0] < slot.fromInt+slotDuration/) }].
                             concat(room.places).reduce((acc, curPlace) => {
                               let curPlaceSlotAppointments = curPlace.appointments.filter(a => a.range[1] > slot.fromInt/* && a.range[0] < slot.fromInt+slotDuration/);
                               acc.appointments = acc.appointments.concat(curPlaceSlotAppointments);
                               return acc;
                             }).appointments.
                             sort((a1, a2) => a1.range[0] > a2.range[0] ? 1 : -1);
          mergeSlotRanges(room, null, locationAvailabilities.all, slot, slotDuration, room.slotLimits[1], this.todaysLocationData[this.room.id].all.availableRanges, appointments);
           return locationAvailabilities;
        })
      });
      this.get('newAppointment').setProperties({
        from: formatTimeInt(timesFrom[0].hour+timesFrom[0].minutes[0]),
        until: formatTimeInt(timesUntil[timesUntil.length-1].hour+timesUntil[timesUntil.length-1].minutes[timesUntil[timesUntil.length-1].minutes.length-1]) });
      */
      // this.set('dialogOrigin', $(event.target));
      // this.set('dialogOrigin', 'ul[data-name=' + $(event.target).closest('ul').attr('data-name') + ']');

      this.set(dialogKey, true);
    }
    /*,
    mergeSlotRanges(room, place, availabilities, slot, slotDuration, lastSlotEnd, availableRanges, appointments) {
     let numAvailabilities = availabilities.length;
     let curStartTimeInt = slot.fromInt;
     availableRanges.filter(range => range[1] > slot.fromInt && range[0] < slot.fromInt+slotDuration).forEach(availableRange => {
       // f.ex. slot 900-1000
       if (availableRange[0] <= curStartTimeInt) { // availableRange [900, 915]
         //
         availabilities.push({ range: [curStartTimeInt, availableRange[1]], room: room, place: place });
         if (availableRange[1] < lastSlotEnd/*slot.fromInt+slotDuration/) {
           // there must be another appointment
           let appointment = appointments.find(a => a.range[0] >= availableRange[1]);
           availabilities.push({ isAppointment: true, appointment: appointment, range: appointment.range });
         }
       } else {  // appointment [<=900, 900+]
         let appointment = appointments.find(a => a.range[1] > curStartTimeInt);
         availabilities.push({ isAppointment: true, appointment: appointment, range: appointment.range });
         if (availableRange[1] >= slot.fromInt+slotDuration) {
           availabilities.push({ range: [availableRange[0], availableRange[1]], room: room, place: place });
           if (appointment.nextAppointment != null) availabilities.push({ isAppointment: true, appointment: appointment.nextAppointment, range: appointment.nextAppointment.range });
         }
       }
       curStartTimeInt = availabilities[availabilities.length-1].range[1];
     });
     if (numAvailabilities == availabilities.length) // no availableRanges means full of appointments
       appointments.forEach((a, idx, as) => { if (idx == 0 || as[idx-1].range[0] < slot.fromInt+slotDuration) availabilities.push({ isAppointment: true, appointment: a, range: a.range }) });
    }*/

    /*
     * from:
     *   start: slot.fromInt || lastLocationLimit.end
     *   end: slot.fromInt + slotDuration || nextLocationLimit.start
     * until:
     *   start: from
     *   end: nextLocationLimit.start || this.lastSlotEnd
     *
     * no check necessary for complete slot unavailable because select won't be displayed then (with wrong values)
     */
    // calcRangeLimits(rangeLimits, locationAppointments, slot) {
    //   // f.ex. slot: [1000, 1100]
    //   var nextLocationLimit = locationAppointments.find(app => app.range[1] > slot.fromInt); // locationLimit: [_,     1001+]
    //   if (nextLocationLimit.range[0] < (slot.fromInt + (this.slotDuration/100*60))) {        // locationLimit: [1099-, 1001+]
    //     if (nextLocationLimit.range[0] < slot.fromInt) {                                     // locationLimit: [999-,  1001+]
    //       rangeLimits.fromStart = nextLocationLimit.range[1]; // no-check
    //       if (nextLocationLimit.range[1] < (slot.fromInt + (this.slotDuration/100*60))) {    // locationLimit: [999-,  1099-]
    //         nextLocationLimit = locationAppointments.find(app => app.range[0] > slot.fromInt);
    //         rangeLimits.fromEnd = Math.min(rangeLimits.fromEnd, Math.min(rangeLimits.fromEnd, nextLocationLimit.range[0]));
    //       }
    //     } else {
    //       rangeLimits.fromEnd = nextLocationLimit.range[0];                                  // locationLimit: [1000+, 1001+]
    //     }
    //   }
    // }

  });

  _exports.default = _default;
});