define("digiprax-web/mixins/invoice-dialogs", ["exports", "moment", "digiprax-web/models/new-treatment-placeholder", "digiprax-web/mixins/dialogs-util"], function (_exports, _moment, _newTreatmentPlaceholder, _dialogsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ChainCategories from '../models/chain-categories';
  // import { A } from '@ember/array';
  var _default = Ember.Mixin.create(_dialogsUtil.default, {
    print: Ember.inject.service(),
    showGebuehDialog: Ember.computed(function () {
      return null;
    }),
    showZusatztexteDialog: Ember.computed(function () {
      return null;
    }),
    showTestdetailsDialog: Ember.computed(function () {
      return null;
    }),
    showKettenDialog: Ember.computed(function () {
      return null;
    }),
    tariffs: Ember.computed(function () {
      return this.get('store').peekAll('tariff').sortBy('position'); // itemSortKey
    }),
    // chainCategories: computed(function() {
    //   return ChainCategories.create({store: this.get('store')});
    // }),
    chainCategories: Ember.computed.alias('invoiceEditor.chainCategories'),
    additionalTexts: Ember.computed(function () {
      return this.get('store').findAll('additional-text');
    }),
    testDetails: Ember.computed(function () {
      return this.get('store').findAll('test-detail');
    }),
    gebuehPrefix: '',
    actions: {
      editPatient: function editPatient() {
        this.transitionToRoute('patients.edit.index', this.get('model.patientId') || this.get('model.patient.id'));
      },
      focusInput: function focusInput(treatment, eventPlaceholder) {
        // console.log('InvoiceDialogs - focusInput: ...');
        this.focusInput(treatment, (eventPlaceholder || event).target);
        var dateLi = (eventPlaceholder || event).target.closest('li');
        if (dateLi && dateLi.getAttribute('name') == 'date') treatment.set('showPikaDayDate', true);
        return false;
      },
      focusOutInput: function focusOutInput(treatment, eventPlaceholder) {
        // console.log('InvoiceDialogs - focusOutInput: ...');
        var dateLi = (eventPlaceholder || event).target.closest('li');
        var isDateFocus = dateLi && dateLi.getAttribute('name') == 'date';
        var treatmentChanged = this.get('focusedTreatment') != treatment;

        if (treatmentChanged) {
          this.set('focusedTreatment', treatment);

          this._focusLine(treatment, Ember.$((eventPlaceholder || event).target).closest('ul'));
        }

        if (this.get('focusedTreatment.date') != null) {
          this.set('lastFocusedTreatmentDate', (0, _moment.default)(this.get('focusedTreatment.date')).format('DD.MM.YYYY')); // console.log('setting lastFocusedTreatmentDate to ' + this.get('lastFocusedTreatmentDate'));
        }

        this.focusNewInput((eventPlaceholder || event).target);
        /*var isClickOnGebuehDialog = (eventPlaceholder || event).relatedTarget.type == 'button' &&
                                    $((eventPlaceholder || event).relatedTarget).closest('li').attr('name') == 'gebuehInput';*/

        if (Ember.$((eventPlaceholder || event).target).closest('li').attr('name') == 'gebuehInput' && (eventPlaceholder || event).relatedTarget != null && (eventPlaceholder || event).relatedTarget.type != 'button') {
          // var gebuehInput = $((eventPlaceholder || event).target).closest('ul').find('li[name=gebuehInput] input').val();
          this._checkGebuehInput(treatment, treatment.get('tariffItem'), true);
        }
        /*let touchNumNewTreatmentsDirty = */


        this.get('model.numNewTreatmentsDirty'); // prevents X to remove last clean line

        if (isDateFocus) treatment.set('showPikaDayDate', false);
        return false;
      },

      /* GebüH Dialog */
      openGebuehDialog: function openGebuehDialog(treatment, eventPlaceholder) {
        if (treatment == null) {
          this._openDialogWithFocusedTreatment(eventPlaceholder || event, 'showGebuehDialog', function () {
            alert('Bitte wählen Sie zuerst ein Eingabe-Element aus einer Behandlungs-Zeile für die Gebühren-Zuordnung.');
          });
        } else {
          if (this._checkGebuehInput(treatment, treatment.get('tariffItem'), false)) {
            this._openTreatmentDialog(treatment, 'showGebuehDialog', eventPlaceholder || event);
          }
        }

        return false;
      },
      closeGebuehDialog: function closeGebuehDialog() {
        this.set('showGebuehDialog', null);
      },

      /* Zusatztexte Dialog */
      openZusatztexteDialog: function openZusatztexteDialog(treatment, eventPlaceholder) {
        if (treatment == null) {
          this._openDialogWithFocusedTreatment(eventPlaceholder || event, 'showZusatztexteDialog', function () {
            alert('Bitte wählen Sie zuerst ein Eingabe-Element aus einer Behandlungs-Zeile für den Zusatztext.');
          });
        } else {
          this._openTreatmentDialog(treatment, 'showZusatztexteDialog', eventPlaceholder || event);
        }
      },
      closeZusatztexteDialog: function closeZusatztexteDialog(_result) {
        this.set('showZusatztexteDialog', null);
      },

      /* Testdetails Dialog */
      openTestdetailsDialog: function openTestdetailsDialog(treatment, eventPlaceholder) {
        if (treatment == null) {
          this._openDialogWithFocusedTreatment(eventPlaceholder || event, 'showTestdetailsDialog', function () {
            alert('Bitte wählen Sie zuerst ein Eingabe-Element aus einer Behandlungs-Zeile für die Testdetails.');
          });
        } else {
          this._openTreatmentDialog(treatment, 'showTestdetailsDialog', eventPlaceholder || event);
        }
      },
      closeTestdetailsDialog: function closeTestdetailsDialog(_result) {
        this.set('showTestdetailsDialog', null);
      },

      /* Ketten Dialog */
      openKettenDialog: function openKettenDialog(treatment, eventPlaceholder) {
        if (treatment == null) {
          this._openDialogWithFocusedTreatment(eventPlaceholder || event, 'showKettenDialog', function () {
            alert('Bitte wählen Sie zuerst ein Eingabe-Element aus einer Behandlungs-Zeile für die Testdetails.');
          });
        } else {
          this._openTreatmentDialog(treatment, 'showKettenDialog', eventPlaceholder || event);
        }
      },
      closeKettenDialog: function closeKettenDialog() {
        this.set('showKettenDialog', null);
      },
      appendToInvoice: function appendToInvoice(dialogKey, dialogParam) {
        var _this = this;

        if (this.get('application.practice.trial')) {
          this.set('application.alertMessage', 'Wir bitten um Verständnis, dass mit dem Probezugang keine Rechnungen erstellt werden können.');
          Ember.$('#alertMessage').trigger('click'); // send action

          return this.send(dialogKey.replace(/^show/, 'close'));
        } // set together with other props due premature-observer-action
        // if (! (["showZusatztexteDialog", "showGebuehDialog"].indexOf(dialogKey) >= 0 && ["0", "0.0"].indexOf(dialogParam.get('item')) >= 0)) {
        //   if (this.get('focusedTreatment.date') == null) this.set('focusedTreatment.dateTotal', (this.get('lastFocusedTreatmentDate') != null ? moment(this.get('lastFocusedTreatmentDate'), 'DD.MM.YYYY') : moment()).toDate());
        // }
        // console.log('dialogKey = ' + dialogKey + ', focusedTreatment.date = ' + this.get('focusedTreatment.date'));


        if (dialogKey == 'showGebuehDialog') {
          if (["0", "0.0"].indexOf(dialogParam.get('item')) == -1) {
            dialogParam.set('curSettlementId', this.get('model.loadedPatient.settlementId'));

            if (parseInt(dialogParam.get('settlementTariff.default')) == 0) {
              this.set('focusedTreatment.tariffItem', null);
              this.set('application.alertMessage', 'Für diese Ziffer können keine Gebühren verrechnet werden.');
              Ember.$('#alertMessage').trigger('click');
              return this.send(dialogKey.replace(/^show/, 'close'));
            }

            this._setTreatmentTariff(this.get('focusedTreatment'), dialogParam);
          } else {
            // --- NO DATE_FIX ---
            this._add0Dot0ToDayGroup(
            /*{id: dialogParam.id, text: */
            dialogParam.get('text')
            /*}*/
            );

            if (this.get('focusedTreatment.tariffItem') == '0.0') {
              // reset tariff if zusatztext was added
              if (this.get('focusedTreatment.tariff.item') != null) this.set('focusedTreatment.tariffItem', this.get('focusedTreatment.tariff.item'));else this.set('focusedTreatment.tariffItem', null);
            }
          } // console.log('closing ...');


          this.send('closeGebuehDialog');
          return false;
        } else if (dialogKey == 'showKettenDialog') {
          // KettenDialog
          if (this.get('focusedTreatment.date') == null) this.set('focusedTreatment.dateTotal', (this.get('lastFocusedTreatmentDate') != null ? (0, _moment.default)(this.get('lastFocusedTreatmentDate'), 'DD.MM.YYYY') : (0, _moment.default)()).toDate());
          this.set('firstModifiedInput', Ember.$('[data-name=treatment-new-' + (this.get('model.newTreatmentPlaceholders.length') - 1) + ']')); // $('[data-name=treatment-new-'+(this.get('model.newTreatmentPlaceholders.length')-1)+']').find('li input')[1].focus();

          var targetDate = (this.get('focusedTreatment.date') != null ? (0, _moment.default)(this.get('focusedTreatment.date')) : (0, _moment.default)()).toDate(); //.format('DD.MM.YYYY');

          var numNoFee = 0;
          var noFeeTariffs = '';

          if (dialogParam.get('items') == null) {
            this.get('application').debugRemote('showKettenDialog: items == null for tariffChain[id=' + dialogParam.id + ']');
          }

          var newTreatmentPlaceholders = [];
          (dialogParam.get('items') || []).toArray().forEach(function (curTariffChainItem, idx) {
            curTariffChainItem.set('tariff.curSettlementId', _this.get('model.loadedPatient.settlementId'));

            if (curTariffChainItem.get('tariff.settlementTariff.default') > 0) {
              /*
              var positionTS = this.get('model.sortedNewTreatmentPlaceholders.' + (this.get('model.newTreatmentPlaceholders.length') - 1) + '.extraInfo.positionTS');
              if (positionTS == null/* && treatment.get('tmpId') != null/) positionTS = new Date().getTime();
               // this.get('model.sortedNewTreatmentPlaceholders.lastObject').
              this.get('model.sortedNewTreatmentPlaceholders.' + (this.get('model.newTreatmentPlaceholders.length') - 1)).
                                                                    setProperties({ date: targetDate,
                                                                                    tariff: curTariffChainItem.get('tariff'),
                                                                                    tariffId: curTariffChainItem.get('tariff.id'),
                                                                                    // _tariffItem: null,
                                                                                    // cost: curTariffChainItem.get('tariff.settlementTariff.default'),
                                                                                    number: curTariffChainItem.get('count'),
                                                                                    extraInfo: { tariffChain: dialogParam.get('item'),
                                                                                                 tariffChainPosition: curTariffChainItem.get('position'),
                                                                                                 // position: this.get('model.treatments').toArray().length + this.get('model.numNewTreatmentsDirty') + 1,
                                                                                                 positionTS: positionTS } });
              /*let touchNumNewTreatmentsDirty = /this.get('model.numNewTreatmentsDirty'); // treatmentsDirty
              // Ember.Logger.debug('nTPs.length = '+this.get('model.newTreatmentPlaceholders').toArray().length+', numDirty = '+this.get('model.numNewTreatmentsDirty'));
              */
              var newTreatment = _newTreatmentPlaceholder.default.create({
                tmpId: Math.round(Math.random(1000000) * 1000000),
                patientId: _this.get('model.patient.id') || _this.get('model.patientId'),
                invoiceId: _this.get('model.id'),
                invoice: _this.get('model'),
                invoiceText: curTariffChainItem.get('extraInfo.tariff_text'),
                date: targetDate,
                tariff: curTariffChainItem.get('tariff'),
                tariffId: curTariffChainItem.get('tariff.id'),
                // _tariffItem: null,
                // cost: curTariffChainItem.get('tariff.settlementTariff.default'),
                number: curTariffChainItem.get('count'),
                extraInfo: {
                  tariffChain: dialogParam.get('item'),
                  tariffChainPosition: curTariffChainItem.get('position'),
                  // position: this.get('model.treatments').toArray().length + this.get('model.numNewTreatmentsDirty') + 1,
                  positionTS: new Date().getTime() + (idx + 1)
                }
              });

              _this._initAdditionalText(newTreatment, curTariffChainItem);

              newTreatmentPlaceholders.pushObject(newTreatment);
            } else {
              console.debug('no fee for = ' + curTariffChainItem.get('tariff.item'));
              numNoFee += 1;
              noFeeTariffs += '\n' + curTariffChainItem.get('tariff.item') + ' ' + curTariffChainItem.get('tariff.name');
            }
          });

          if (newTreatmentPlaceholders.length >= 1) {
            var _this$get;

            // this.set('model.newTreatmentPlaceholders', A(this.get('model.newTreatmentPlaceholders').concat(newTreatmentPlaceholders)));
            this.get('model.newTreatmentPlaceholders.' + (this.get('model.newTreatmentPlaceholders.length') - 1)).setProperties({
              date: null,
              extraInfo: {
                positionTS: new Date().getTime()
              }
            }); // this.set('model.newTreatmentPlaceholders.' + (this.get('model.newTreatmentPlaceholders.length') - 1) + '.extraInfo', { positionTS: new Date().getTime() })

            (_this$get = this.get('model.newTreatmentPlaceholders')).splice.apply(_this$get, [this.get('model.newTreatmentPlaceholders.length') - 1, 0].concat(newTreatmentPlaceholders));

            this.set('model.savedAt', new Date());
          }

          if (numNoFee > 0) {
            this.set('application.alertMessage', 'Für folgende Ziffern können bei ' + this.get('model.loadedPatient.settlementName') + ' keine Gebühren verrechnet werden:' + noFeeTariffs);
            Ember.$('#alertMessage').trigger('click');
            this.send(dialogKey.replace(/^show/, 'close'));
          }
          /*let touchDayGroups = */


          this.get('model.dayGroups');
          Ember.run.schedule("afterRender", function () {
            if (_this.get('firstModifiedInput') != null && _this.get('firstModifiedInput').length >= 1) {
              Ember.$('html, body').animate({
                scrollTop: _this.get('firstModifiedInput').offset().top - Ember.$('.nav').height()
              }, 500);
            } else {
              Ember.$('html, body').animate({
                scrollTop: Ember.$('[data-name=treatment-new-0]').offset().top - Ember.$('.nav').height()
              }, 500);
            }
          });
          this.send('closeKettenDialog');
          return false;
        } else if (dialogKey == 'showZusatztexteDialog') {
          /*
          die Zusatztexte mit 0 und 0.0 sollten sowohl am Ende eines Behandlungstages als auch am Ende der Rechnung eingefügt werden können –
          je nachdem, ob der Inhalt des Textes nur für einen Behandlungstag oder für alle Behandlungen dieser Rechnung zutrifft.
          Alle anderen Zusätze der Ziffern 0.1 und 0.2, sowie alle Testdetails müssen direkt unter die Leistungsziffer der GebüH, zu der dieser
          Zusatz nötig ist. Es ist die Begründung, warum diese Leistungsziffer gewählt wurde (bei 0.1) oder warum die Anzahl bei dieser Leistungsziffer
          erhöht wurde. (z. B. bei 12.13 Untersuchung analog GOÄ 380: Unverträglichkeit von Schwermetallen  - dann werden mit 0.6 aus den Testdetails
          die untersuchten Schwermetalle aufgelistet und die Anzahl 1 entsprechend erhöht.)
          */
          if (["0", "0.0"].indexOf(dialogParam.get('item')) >= 0) {
            // --- NO DATE_FIX ---
            // if (this.get('model.gebuehDialogType') == 'daily0Dot0') {
            //   this._add0Dot0ToDayGroup(/*{id: dialogParam.id, text: */dialogParam.get('text')/*}*/);
            //   this.send('closeGebuehDialog');
            //   return false;
            // } else {
            this.set('model.additionalText', dialogParam); // }
          } else {
            if (this.get('focusedTreatment.date') == null) this.set('focusedTreatment.dateTotal', (this.get('lastFocusedTreatmentDate') != null ? (0, _moment.default)(this.get('lastFocusedTreatmentDate'), 'DD.MM.YYYY') : (0, _moment.default)()).toDate());
            var previousAdditionalText = null;
            var extraInfoAvailable = !(typeof this.get('focusedTreatment.extraInfo') == "undefined" || this.get('focusedTreatment.extraInfo') == null);
            var extraInfoAddTextAvailable = false;
            if (extraInfoAvailable) extraInfoAddTextAvailable = !(typeof this.get('focusedTreatment.extraInfo.additionalText') == "undefined" || this.get('focusedTreatment.extraInfo.additionalText') == null);

            if (extraInfoAddTextAvailable) {
              previousAdditionalText = this.set('focusedTreatment.extraInfo.additionalText.text');
              this.set('focusedTreatment.extraInfo.additionalText', {
                id: dialogParam.id,
                text: dialogParam.get('text')
              });
            } else {
              if (extraInfoAvailable) {
                this.set('focusedTreatment.extraInfo.additionalText', {
                  id: dialogParam.id,
                  text: dialogParam.get('text')
                });
              } else {
                this.set('focusedTreatment.extraInfo', {
                  additionalText: {
                    id: dialogParam.id,
                    text: dialogParam.get('text')
                  }
                });
              }
            }

            if (this.get('focusedTreatment.treatmentText') == null || this.get('focusedTreatment.treatmentText').trim() == '') {
              this.set('focusedTreatment.treatmentText', dialogParam.get('text'));
            } else {
              var currentText = this.get('focusedTreatment.treatmentText').trim();
              if (previousAdditionalText != null) currentText = currentText.replace(new RegExp('[ ,]*' + previousAdditionalText.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")), '');
              var newTreatmentText = currentText + ', ' + dialogParam.get('text');
              this.set('focusedTreatment.treatmentText', newTreatmentText);
            }
          }

          this.send('closeZusatztexteDialog');
          return false;
        } else if (dialogKey == 'showTestdetailsDialog') {
          if (this.get('focusedTreatment.date') == null) this.set('focusedTreatment.dateTotal', (this.get('lastFocusedTreatmentDate') != null ? (0, _moment.default)(this.get('lastFocusedTreatmentDate'), 'DD.MM.YYYY') : (0, _moment.default)()).toDate());
          var previousTestDetail = null;

          var _extraInfoAvailable = !(typeof this.get('focusedTreatment.extraInfo') == "undefined" || this.get('focusedTreatment.extraInfo') == null);

          var extraInfoTestDetAvailable = false;
          if (_extraInfoAvailable) extraInfoTestDetAvailable = !(typeof this.get('focusedTreatment.extraInfo.testDetails') == "undefined" || this.get('focusedTreatment.extraInfo.testDetails') == null);

          if (extraInfoTestDetAvailable) {
            previousTestDetail = this.set('focusedTreatment.extraInfo.testDetails.text');
            this.set('focusedTreatment.extraInfo.testDetails', {
              id: dialogParam.id,
              text: dialogParam.get('text')
            });
          } else {
            if (_extraInfoAvailable) {
              this.set('focusedTreatment.extraInfo.testDetails', {
                id: dialogParam.id,
                text: dialogParam.get('text')
              });
            } else {
              this.set('focusedTreatment.extraInfo', {
                testDetails: {
                  id: dialogParam.id,
                  text: dialogParam.get('text')
                }
              });
            }
          }

          if (this.get('focusedTreatment.treatmentText') == null || this.get('focusedTreatment.treatmentText').trim() == '') {
            this.set('focusedTreatment.treatmentText', dialogParam.get('text'));
          } else {
            var _currentText = this.get('focusedTreatment.treatmentText').trim();

            if (previousTestDetail != null) _currentText = _currentText.replace(new RegExp('[ ,]*' + previousTestDetail.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")), '');

            var _newTreatmentText = _currentText + ', ' + dialogParam.get('text');

            this.set('focusedTreatment.treatmentText', _newTreatmentText);
          }

          this.send('closeTestdetailsDialog');
          return false;
        } else {
          alert('TODO: appendToInvoice ' + dialogKey);
          return false;
        }
      },
      showKette: function showKette(category, event) {
        if (Ember.$(event.target).closest('.kettenKategorie').hasClass('current')) {
          Ember.$('.kettenKategorie').removeClass('current');
        } else {
          this.set('chainCategories.selectedCategoryIndex', category.get('index'));
          Ember.$('.kettenKategorie').removeClass('current');
          Ember.$(event.target).closest('.kettenKategorie').addClass('current');
        }
      },
      deleteTariffChain: function deleteTariffChain(tariffChain, eventPlaceholder) {
        var _this2 = this;

        (eventPlaceholder || event).stopPropagation();
        this.set('application.confirmMessage', {
          message: 'Soll die Abrechnungskette unwiderruflich gelöscht werden?',
          callback: function callback() {
            _this2.invoiceEditor.deleteCustomTariffChain(tariffChain, function (error) {
              if (error) {
                _this2.get('flashMessages').error('Unbekannter Fehler', {
                  timeout: 3000
                });
              } else {
                Ember.run.schedule("afterRender", function () {
                  _this2.get('flashMessages').success('Abrechnungskette erfolgreich gelöscht ...', {
                    timeout: 3000
                  });
                });

                _this2.send('closeKettenDialog');
              }
            });
          }
        });
        Ember.$('#confirmMessage').trigger('click');
        return false;
      },
      finalizeInvoice: function finalizeInvoice(action) {
        var _this3 = this;

        if (this.get('model.notEditable')) {
          this.send('generatePdf', action);
        } else {
          var listener = {
            inValid: function inValid(errors) {
              _this3.inValid(errors);
            },
            afterSave: function afterSave(invoice) {
              // $('html, body').animate({scrollTop: 0}, 500);
              if (invoice.get('invoiceNumber') == null && invoice.get('extraInfo.finalized.date') != null) {
                Ember.getOwner(_this3).lookup('route:' + _this3.target.currentRouteName).refresh();
              }

              _this3.send('generatePdf', action);
            }
          };
          this.get('invoiceEditor').finalizeInvoice(this.get('model'), listener);
        }
      },
      checkDoneEditTreatmentDate: function checkDoneEditTreatmentDate(treatment, eventPlaceholder) {
        if ((eventPlaceholder || window.event || {}).keyCode == 13) {
          // this.focusOutInput(treatment, (eventPlaceholder || event).target);
          if (treatment._pikaDayDateInput) treatment._pikaDayDateInput.blur();
          treatment.set('showPikaDayDate', false);
        }
      },

      /* pdf creation ---------------------------------------------------------------------------------------- */
      generatePdf: function generatePdf(action) {
        var _this4 = this;

        if (this.get('model.id') == null || this.get('model.isDirty')) {
          this.set('application.alertMessage', 'Bitte speichern Sie zuerst die Rechnung.');
          Ember.$('#alertMessage').trigger('click'); // send action

          return false;
        }

        this.get('model.patient').then(function (patient) {
          // patient.get('store').findRecord('practice', patient.get('practiceId')).then((practice) =>  {
          //   this._generatePdf(action, patient, practice);
          // });
          _this4.get('print')._generatePdf(action, patient, _this4.get('application.practice'), _this4.get('model'), _this4);
        });
      },

      /* /pdf creation ---------------------------------------------------------------------------------------- */
      printPdf: function printPdf() {
        document.querySelector('#pdf_invoice').contentWindow.print();
      },
      closePdf: function closePdf(_result) {
        this.set('showPdf', false);
      }
    },

    /* /actions */
    _initAdditionalText: function _initAdditionalText(treatment, curTariffChainItem) {
      if (curTariffChainItem.get('extraInfo') != null) {
        if (curTariffChainItem.get('extraInfo.additional_text_id') != null) {
          this.get('additionalTexts').then(function (additionalTexts) {
            var additionalText = additionalTexts.find(function (aT) {
              return aT.id == curTariffChainItem.get('extraInfo.additional_text_id');
            });

            if (additionalText != null) {
              treatment.set('extraInfo.additionalText', {
                id: additionalText.id,
                text: additionalText.get('text')
              }); // let previousAdditionalText = treatment.get('extraInfo.additionalText.text');

              var currentText = treatment.get('treatmentText').trim(); // if (previousAdditionalText != null) currentText = currentText.replace(new RegExp('[ ,]*' + previousAdditionalText.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")), '');

              var newTreatmentText = currentText + ', ' + additionalText.get('text');
              treatment.set('treatmentText', newTreatmentText);
            }
          });
        } else if (curTariffChainItem.get('extraInfo.test_detail_id') != null) {
          this.get('testDetails').then(function (testDetails) {
            var testDetail = testDetails.find(function (tD) {
              return tD.id == curTariffChainItem.get('extraInfo.test_detail_id');
            });

            if (testDetail != null) {
              treatment.set('extraInfo.testDetails', {
                id: testDetail.id,
                text: testDetail.get('text')
              }); // let previousAdditionalText = treatment.get('extraInfo.additionalText.text');

              var currentText = treatment.get('treatmentText').trim(); // if (previousAdditionalText != null) currentText = currentText.replace(new RegExp('[ ,]*' + previousAdditionalText.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")), '');

              var newTreatmentText = currentText + ', ' + testDetail.get('text');
              treatment.set('treatmentText', newTreatmentText);
            }
          });
        }
      }
    },
    _checkGebuehInput: function _checkGebuehInput(treatment, gebuehInput, autoSet) {
      this.set('gebuehPrefix', ((gebuehInput || '') + '.').replace(/\.\.$/, '.').replace(/^\.$/, ''));

      if ( //(treatment.get('tariffId') == null) &&
      [null, ''].indexOf(gebuehInput) == -1) {
        var tariff = this.get('store').peekAll('tariff').filter(function (t) {
          // return t.get('item') == gebuehInput;
          return t.get('item').startsWith(gebuehInput);
        }).sort(function (t1, t2) {
          return t1.get('position') >= t2.get('position') ? 1 : -1;
        })[0];

        if (tariff != null) {
          tariff.set('curSettlementId', this.get('model.loadedPatient.settlementId'));

          if (parseInt(tariff.get('settlementTariff.default')) == 0) {
            this.set('application.alertMessage', 'Für diese Ziffer können keine Gebühren verrechnet werden.');
            Ember.$('#alertMessage').trigger('click');
            Ember.$('ul[data-name=treatment-new-0] li[name=gebuehInput] input').focus();
            Ember.$('ul[data-name=treatment-new-0] li[name=gebuehInput] input').select();
            return false;
          } else {
            if (autoSet) {
              // this._setTreatmentTariff(treatment, tariff);
              if (treatment.get('treatmentText') == null) this._setTreatmentTariff(treatment, tariff);
            }
          }
        } else {
          if (gebuehInput == '0.0') {
            this.model.set('gebuehDialogType', 'daily0Dot0');
            return true;
          } else {
            this.set('application.alertMessage', 'Diese Ziffer ist unbekannt.');
            Ember.$('#alertMessage').trigger('click');
            Ember.$('ul[data-name=treatment-new-0] li[name=gebuehInput] input').focus();
            Ember.$('ul[data-name=treatment-new-0] li[name=gebuehInput] input').select();
            return false;
          }
        }
      }

      return true;
    },
    _setTreatmentTariff: function _setTreatmentTariff(treatment, tariff) {
      var previousGebuehName = null;

      if (treatment.get('tariffId') != null) {
        previousGebuehName = this.get('store').peekRecord('tariff', treatment.get('tariffId')).get('name');
      } else if (treatment.get('tariff') != null) {
        previousGebuehName = treatment.get('tariff.name');
      } //*let touchNumNewTreatmentsDirty = */this.get('model.numNewTreatmentsDirty');


      var treatmentText;

      if (treatment.get('treatmentText') == null || treatment.get('treatmentText').trim() == '') {
        treatmentText = this.get('store').peekRecord('tariff', tariff.id).get('name');
      } else {
        treatmentText = treatment.get('treatmentText').trim() + ', ' + this.get('store').peekRecord('tariff', tariff.id).get('name');
        if (previousGebuehName != null) treatmentText = treatmentText.replace(new RegExp(previousGebuehName.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '[ ,]*'), '');
        if (treatmentText == '') treatmentText = null;
      } // if (treatment.get('withBioresonanz')) treatment.set('treatmentText', treatmentText + ' *');
      // else treatment.set('treatmentText', treatmentText.replace(/\s*\*\s*$/, '').replace(/^\s*\*\s*,\s*/, '').replace(/\s*,\s*$/, ''));


      if (treatment.get('withBioresonanz')) treatmentText = treatmentText + ' *';else treatmentText = treatmentText.replace(/\s*\*\s*$/, '').replace(/^\s*\*\s*,\s*/, '').replace(/\s*,\s*$/, '');
      var treatmentProps = {
        tariff: tariff,
        tariffId: tariff.id,
        _tariffItem: null,
        cost: parseInt(tariff.get('settlementTariff.default'))
        /**/
        ,
        treatmentText: treatmentText
      };
      if (treatment == this.focusedTreatment && this.get('focusedTreatment.date') == null) treatmentProps.dateTotal = (this.get('lastFocusedTreatmentDate') != null ? (0, _moment.default)(this.get('lastFocusedTreatmentDate'), 'DD.MM.YYYY') : (0, _moment.default)()).toDate();
      var positionTS = treatment.get('extraInfo.positionTS');
      if (positionTS == null && treatment.get('tmpId') != null) positionTS = new Date().getTime();
      if (treatment.get('extraInfo') == null) treatmentProps.extraInfo = {
        positionTS: positionTS
      };
      if (treatment.get('date') == null) treatmentProps.date = this.get('lastFocusedTreatmentDate') != null ? (0, _moment.default)(this.get('lastFocusedTreatmentDate'), 'DD.MM.YYYY').toDate() : new Date(); // console.log('tariff = ' + tariff);

      treatment.setProperties(treatmentProps);
      /*let touchNumNewTreatmentsDirty = */

      this.get('model.numNewTreatmentsDirty');
      if (treatment.get('invoiceId') != null) this.set('model.dayGroups.updatedAt', new Date());
    },
    _focusLine: function _focusLine(treatment, lineSelector) {
      this.focusLine(treatment, lineSelector); //*let touchNumNewTreatmentsDirty = */this.get('model.numNewTreatmentsDirty'); // prevents X to remove last clean line
    },
    _add0Dot0ToDayGroup: function _add0Dot0ToDayGroup(invoiceText) {
      var _this5 = this;

      // this.set('firstModifiedInput', $('[data-name=treatment-new-'+(this.get('model.newTreatmentPlaceholders.length')-1)+']'));
      if (this.get('focusedTreatment') == null) {
        this.get('application').debugRemote('no focusedTreatment for dailyGroup for invoice ' + this.get('model.id'));
        return;
      }

      if (invoiceText == 'Leerzeile') {
        if (this.get('focusedTreatment.tmpId') != null) {
          var treatmentIdx = this.get('focusedTreatment.invoice.sortedNewTreatmentPlaceholders').findIndex(function (t) {
            return t.tmpId == _this5.get('focusedTreatment.tmpId');
          });
          var targetTreatment;

          if (treatmentIdx >= 1) {
            targetTreatment = this.get('focusedTreatment.invoice.sortedNewTreatmentPlaceholders.' + (treatmentIdx - 1));
          } else {
            targetTreatment = this.get('focusedTreatment.invoice.sortedTreatments.lastObject');
          }
        } else {
          targetTreatment = this.get('focusedTreatment');
        }

        var _newExtraInfo = targetTreatment.get('extraInfo') || {};

        _newExtraInfo.extraLine = ' '; // will be trimmed in model

        targetTreatment.setProperties({
          extraInfo: _newExtraInfo,
          extraInfoIsDirtyFlag: new Date()
        });
        return;
      } // var targetDate = moment(this.get('focusedTreatment.date'));//.toDate();//.format('DD.MM.YYYY');


      var _tD = this.get('focusedTreatment.date') || (this.get('model.sortedNewTreatmentPlaceholders')[this.get('model.sortedNewTreatmentPlaceholders').indexOf(this.get('focusedTreatment')) - 1] || {}).date;

      var targetDate = _tD ? (0, _moment.default)(_tD) : (0, _moment.default)(this.get('model.sortedTreatments.lastObject.date'));
      var newExtraInfo = this.get('model.extraInfo') || {};
      if (newExtraInfo.dayGroupData == null) newExtraInfo.dayGroupData = {};

      if (newExtraInfo.dayGroupData[targetDate.format('YYYYMMDD')] == null) {
        newExtraInfo.dayGroupData[targetDate.format('YYYYMMDD')] = {
          extraLine: invoiceText
        };
      } else {
        newExtraInfo.dayGroupData[targetDate.format('YYYYMMDD')].extraLine = invoiceText;
      }

      this.model.setProperties({
        extraInfo: newExtraInfo,
        extraInfoIsDirtyFlag: new Date(),
        savedAt: new Date(),
        gebuehDialogType: 'tariff'
      });
      /*let touchDayGroups = */

      this.get('model.dayGroups'); // schedule("afterRender",() => {
      //     if ((this.get('firstModifiedInput') != null) && (this.get('firstModifiedInput').length >= 1)) {
      //       $('html, body').animate({scrollTop: this.get('firstModifiedInput').offset().top - $('.nav').height()}, 500);
      //     } else {
      //       $('html, body').animate({scrollTop: $('[data-name=treatment-new-0]').offset().top - $('.nav').height()}, 500);
      //     }
      //   });
    }
  });

  _exports.default = _default;
});