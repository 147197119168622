define("digiprax-web/templates/api-calls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "penWDHKt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"monthStats\"]],[8],[0,\"month_stats\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "digiprax-web/templates/api-calls.hbs"
    }
  });

  _exports.default = _default;
});