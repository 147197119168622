define("digiprax-web/helpers/is-last-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isLastIndex = isLastIndex;
  _exports.default = void 0;

  // params[0] ... idx
  // params[1] ... array
  function isLastIndex(params, _hash) {
    return params[1].length - 1 == params[0];
  }

  var _default = Ember.Helper.helper(isLastIndex);

  _exports.default = _default;
});