define("digiprax-web/models/day-group-data", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    targetInvoice: null,
    dayGroup: null,
    extraLine: Ember.computed({
      get: function get(_key) {
        return this.targetInvoice.get('extraInfo.dayGroupData.' + (0, _moment.default)(this.dayGroup.last.date).format('YYYYMMDD') + '.extraLine') || null;
      },
      set: function set(_key, extraLine) {
        if ([null, ''].indexOf(extraLine) >= 0) {
          var dayGroupData = this.targetInvoice.get('extraInfo.dayGroupData');
          delete dayGroupData[(0, _moment.default)(this.dayGroup.last.date).format('YYYYMMDD')];
          this.targetInvoice.set('extraInfo.dayGroupData', dayGroupData);
        } else {
          this.targetInvoice.set('extraInfo.dayGroupData.' + (0, _moment.default)(this.dayGroup.last.date).format('YYYYMMDD') + '.extraLine', extraLine);
        }

        this.targetInvoice.set('extraInfoIsDirtyFlag', new Date());
        return extraLine;
      }
    })
  });

  _exports.default = _default;
});