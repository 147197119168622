define("digiprax-web/models/invoice", ["exports", "ember-data", "digiprax-web/mixins/invoice-model", "digiprax-web/mixins/invoice-treatments"], function (_exports, _emberData, _invoiceModel, _invoiceTreatments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_invoiceModel.default, _invoiceTreatments.default, {});

  _exports.default = _default;
});