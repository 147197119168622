define("digiprax-web/controllers/base", ["exports", "digiprax-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    practice: Ember.computed.alias('application.practice'),
    // patients: alias('application.patients'),
    currentUser: Ember.computed.alias('application.currentUser'),
    currentPatient: Ember.computed.alias('application.currentPatient'),
    history: Ember.computed.alias('application.history'),
    featureAuth: Ember.computed.alias('application.featureAuth'),
    showDirty: Ember.computed('model.isDirty', function () {
      return _environment.default.environment != 'production' && this.get('model.isDirty');
    }),
    currentSubDomain: Ember.computed.alias('application.currentSubDomain'),
    showProgressbar: function showProgressbar() {
      this.application.showProgressbar();
    },
    hideProgressbar: function hideProgressbar() {
      this.application.hideProgressbar();
    }
  });

  _exports.default = _default;
});