define("digiprax-web/serializers/treatment", ["exports", "digiprax-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(_snapshot, _options) {
      var json = this._super.apply(this, arguments);
      /*
      json.data.attributes.cost = {
        amount: json.data.attributes.amount,
        currency: json.data.attributes.currency
      };
      */
      // if (json.date.match(/T00:00:00\.[0-9]+Z$/) == null) {
      //   let dateMom = moment(snapshot.attr('date'));
      //   json.date = dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON();
      // }


      delete json.tariffSettlementId;
      if ([null, 'new'].indexOf(json.invoiceId) >= 0) delete json.invoiceId;
      if ([null, ''].indexOf(json.extraInfo) >= 0) delete json.extraInfo;
      if ([''].indexOf(json.invoiceText) >= 0) json.invoiceText = null;
      if ([null, ''].indexOf(json.cost) >= 0) json.cost = -1;
      delete json.factor;
      /*if (! snapshot.record.get('isNew')) {
        delete json.patientId;
      }*/

      return json; // return JSON.parse(JSON.stringify(json));
    }
  });

  _exports.default = _default;
});