define("digiprax-web/controllers/patients/new", ["exports", "digiprax-web/controllers/base", "digiprax-web/mixins/auto-complete-patient-search"], function (_exports, _base, _autoCompletePatientSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_autoCompletePatientSearch.default, {
    patientEditor: Ember.inject.service(),
    // genderList: computed('application.currentUser.plus', function() {
    //   return this.get('application.currentUser.plus') ? ['', 'Frau', 'Herr'] : ['', 'Frau', 'Herr', 'Kind (Junge)', 'Kind (Mädchen)'];
    // }),
    genderList: Ember.computed(function () {
      return ['', 'Frau', 'Herr', 'Kind (Junge)', 'Kind (Mädchen)'];
    }),
    genderListInvoiceRecipient: Ember.computed(function () {
      return ['', 'Frau', 'Herr', 'Firma'];
    }),
    differentInvoiceRecipient: Ember.computed(function () {
      return ['Nein', 'Ja'];
    }),
    disabledSettlements: Ember.computed(function () {
      return ['bh-br', 'bh-hh', 'bh-sl'];
    }),
    actions: {
      savePatient: function savePatient() {
        this.get('patientEditor').createPatient(this.get('model'), this);
      },
      checkDoneEditInitReception: function checkDoneEditInitReception(eventPlaceholder) {
        if ((eventPlaceholder || window.event || {}).keyCode == 13) {
          if (this.model._pikaDayInitReceptionInput) this.model._pikaDayInitReceptionInput.blur();
          this.model.set('showPikaDayInitReception', false);
        }
      },
      checkDoneEditBirthdate: function checkDoneEditBirthdate(eventPlaceholder) {
        if ((eventPlaceholder || window.event || {}).keyCode == 13) {
          if (this.model._pikaDayBirthdateInput) this.model._pikaDayBirthdateInput.blur();
          this.model.set('showPikaDayBirthdate', false);
        }
      }
    },
    inValid: function inValid(errors, config) {
      Ember.$('html, body').animate({
        scrollTop: 0
      }, 500);
      var configAvailable = !(typeof config == "undefined" || config == null);
      if (!configAvailable) config = {
        timeout: 10000
      };
      var errorHtml = '';
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = errors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var error = _step.value;
          errorHtml += '<li>' + error + '</li>';
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.get('flashMessages').error('<ul>' + errorHtml + '</ul>', config);
    },
    afterSave: function afterSave(patient) {
      var _this = this;

      Ember.run.schedule("afterRender", function () {
        Ember.$('html, body').animate({
          scrollTop: 0
        }, 500);

        _this.get('flashMessages').success((_this.get('application.currentUser.plus') ? 'Kundenkarte' : 'Patientenkarte') + ' erfolgreich angelegt ...', {
          timeout: 3000
        });
      });
      if (this.get('application.currentUser.plus')) this.transitionToRoute('customers.edit.index', patient.get('id'));else this.transitionToRoute('patients.edit.index', patient.get('id'));
    }
  });

  _exports.default = _default;
});