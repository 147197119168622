define("digiprax-web/models/patient", ["exports", "ember-data", "moment", "digiprax-web/mixins/patient-model", "digiprax-web/mixins/customer-model"], function (_exports, _emberData, _moment, _patientModel, _customerModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { sort } from '@ember/object/computed';
  var _default = _emberData.default.Model.extend(_patientModel.default, _customerModel.default, {
    afterSave: function afterSave() {
      this.setProperties({
        _newTreatmentPlaceholders: null,
        _newLotPlaceholders: null,
        savedAt: new Date(),
        _treatmentsAddedOrDeleted: [this.get('treatments.length') || 0, 1],
        _lotsAddedOrDeleted: [this.get('lots.length') || 0, 1]
      });
    },
    isDirty: Ember.computed('hasDirtyAttributes', 'treatmentsDirty', 'treatmentsAddedOrDeleted', 'lotsDirty', 'lotsAddedOrDeleted', function () {
      return this.get('hasDirtyAttributes') && !this.ignoreDirtyAttributes() || this.get('treatmentsAddedOrDeleted') || this.get('treatmentsDirty') || this.get('lotsAddedOrDeleted') || this.get('lotsDirty');
    }),
    ignoreDirtyAttributes: function ignoreDirtyAttributes() {
      var attrs = Object.keys(this.changedAttributes()); // patientId, extraInfo, date

      if (attrs.length >= 2) return false;
      var numToIgnore = 0;
      if (this.changedAttributes()['date'] != null && this.changedAttributes()['date'][0] == null) numToIgnore += 1;
      return attrs.length == numToIgnore;
    },

    /*
    diagnoses: DS.hasMany('diagnosis'), //, { inverse: 'patient' }),
    treatments: DS.hasMany('treatment'), //, { inverse: 'patient' }),
    invoices: DS.hasMany('invoice'),
    */
    invoices: Ember.A([]),
    practiceId: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    birthdate: _emberData.default.attr('BackendDate'),
    addressStreet: _emberData.default.attr('string'),
    addressHouseNumber: _emberData.default.attr('string'),
    addressZip: _emberData.default.attr('string'),
    addressCity: _emberData.default.attr('string'),
    initReception: _emberData.default.attr('BackendDate'),
    invoiceSalutation: _emberData.default.attr('string'),
    invoiceFirstName: _emberData.default.attr('string'),
    invoiceLastName: _emberData.default.attr('string'),
    invoiceAddressStreet: _emberData.default.attr('string'),
    invoiceAddressHouseNumber: _emberData.default.attr('string'),
    invoiceAddressZip: _emberData.default.attr('string'),
    invoiceAddressCity: _emberData.default.attr('string'),
    invoiceCopies: _emberData.default.attr('string'),
    phoneLandline: _emberData.default.attr('string'),
    phoneCellular: _emberData.default.attr('string'),
    phoneFax: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    trial: _emberData.default.attr('boolean'),
    profession: _emberData.default.attr('string'),
    is_customer: _emberData.default.attr('boolean'),
    // sortedInvoices: computed.sort('invoices', 'invoiceSortDef'),
    // invoiceSortDef: computed(function() {
    //   return ['date:desc'];
    // }),
    sortedInvoices: Ember.computed('invoices', function () {
      var sort = function sort(i1, i2) {
        // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
        return i2.get('date') - i1.get('date') >= 0 ? -1 : 1; // asc
      };

      return (this.invoices || []).toArray().sort(sort);
    }),
    sortByPosition: function sortByPosition(t1, t2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (t1.get('extraInfo') != null) {
        if (t2.get('extraInfo') != null) {
          if (t1.get('extraInfo.positionTS') != null) {
            if ((t1.get('extraInfo.positionTS') || 0) == (t2.get('extraInfo.positionTS') || 0)) {
              return -1;
            } else {
              return (t1.get('extraInfo.positionTS') || 0) > (t2.get('extraInfo.positionTS') || 0) ? 1 : -1;
            }
          } else {
            return t2.get('extraInfo.positionTS') != null ? 1 : -1;
          }
        } else {
          return -1;
        }
      } else if (t2.get('extraInfo') != null && t2.get('extraInfo.positionTS') != null) {
        return 1;
      }

      return null;
    },
    savedAt: null,
    // showPikaDayInitReception: false,
    showPikaDayInitReception: Ember.computed('initReception', {
      get: function get(_key) {
        // console.log('showPikaDayInitReception - get: _showPikaDayInitReception = '+this.get('_showPikaDayInitReception'));
        return this.get('_showPikaDayInitReception') || false;
      },
      set: function set(_key, value, prevValue, eventPlaceholder) {
        // console.log('showPikaDayInitReception - set: value = '+value+', prevValue = '+prevValue+', _showPikaDayInitReception = '+this.get('_showPikaDayInitReception')+', tag = '+((eventPlaceholder||window.event).relatedTarget||{}).tagName);
        if (((eventPlaceholder || window.event || {}).relatedTarget || {}).tagName == 'SELECT') return true;
        if (value) this.set('_pikaDayInitReceptionInput', (eventPlaceholder || window.event || {}).target);
        return this.set('_showPikaDayInitReception', value);
      }
    }),
    initReceptionFmtd: Ember.computed('initReception', {
      get: function get(_key) {
        if (this.get('initReception') != null) {
          // console.log('initReceptionFmtd - get: _showPikaDayInitReception = '+this.get('_showPikaDayInitReception'));
          // eslint-disable-next-line ember/no-side-effects
          this.set('_showPikaDayInitReception', false);
          return (0, _moment.default)(this.get('initReception')).format('DD.MM.YYYY');
        } else {
          return null;
        }
      },
      set: function set(_key, value, prevValue, eventPlaceholder) {
        // console.log('initReceptionFmtd - set: _showPikaDayInitReception = '+this.get('_showPikaDayInitReception')+', value = '+value+', tag = '+((eventPlaceholder||window.event).target||{}).tagName);
        if (((eventPlaceholder || window.event || {}).target || {}).tagName == 'BUTTON' || ((eventPlaceholder || window.event || {}).target || {}).tagName == null && typeof value.getFullYear == 'function') {
          // click on pikaday-calendar-popup
          this.setProperties({
            initReception: value,
            showPikaDayInitReception: false
          });
          if (this._pikaDayInitReceptionInput) this._pikaDayInitReceptionInput.blur();
          return (0, _moment.default)(value).format('DD.MM.YYYY');
        }

        if (typeof value.getFullYear == 'function') {
          this.set('initReception', value);
          return (0, _moment.default)(value).format('DD.MM.YYYY');
        } // if T > 31 -> make T.M


        var m = value.match(/^([0-9])([0-9])$/);
        if (m != null && parseInt(m[0]) >= 32) value = m[1] + '.' + m[2]; // if M > 12 -> make T.M.Y

        m = value.match(/^([0-9]{1,2}\.)(([0-9])([0-9]))$/);
        if (m != null && parseInt(m[2]) >= 13) value = m[1] + m[3] + '.' + m[4];
        this.set('initReception', (0, _moment.default)(value, 'DD.MM.YYYY').toDate());

        if (value.match(/^[0-9]{1,2}$/) != null && (prevValue || '').match(/^[0-9]{1,2}\.$/) != null) {
          // schedule("afterRender", () => (eventPlaceholder || window.event).srcElement.selectionStart = (eventPlaceholder || window.event).srcElement.selectionEnd = value.length);
          return value;
        } else if (value.match(/^[0-9]{1,2}\.[0-9]{1,2}$/) != null && (prevValue || '').match(/^[0-9]{1,2}\.[0-9]{1,2}\.$/) != null) {
          // schedule("afterRender", () => (eventPlaceholder || window.event).srcElement.selectionStart = (eventPlaceholder || window.event).srcElement.selectionEnd = value.length);
          return value;
        } else {
          return value.replace(/^([0-9]{2})$/, '$1.').replace(/^([0-9]{2})([0-9])/, '$1.$2').replace(/^([0-9]{1,2}.[0-9]{2})$/, '$1.').replace(/^([0-9]{1,2}.[0-9]{2})([0-9])/, '$1.$2');
        }
      }
    }),
    // showPikaDayBirthdate: false,
    showPikaDayBirthdate: Ember.computed('birthdate', {
      get: function get(_key) {
        return this.get('_showPikaDayBirthdate') || false;
      },
      set: function set(_key, value, prevValue, eventPlaceholder) {
        // console.log('showPikaDayBirthdate: value = '+value+', prevValue = '+prevValue+', _showPikaDayBirthdate = '+this.get('_showPikaDayBirthdate')+', tag = '+((eventPlaceholder||window.event).relatedTarget||{}).tagName);
        if (((eventPlaceholder || window.event || {}).relatedTarget || {}).tagName == 'SELECT') return true;
        if (value) this.set('_pikaDayBirthdateInput', (eventPlaceholder || window.event || {}).target);
        return this.set('_showPikaDayBirthdate', value);
      }
    }),
    birthdateFmtd: Ember.computed('birthdate', {
      get: function get(_key) {
        if (this.get('birthdate') != null) {
          // console.log('birthdateFmtd: _showPikaDayBirthdate = '+this.get('_showPikaDayBirthdate'));
          // eslint-disable-next-line ember/no-side-effects
          this.set('_showPikaDayBirthdate', false);
          return (0, _moment.default)(this.get('birthdate')).format('DD.MM.YYYY');
        } else {
          return null;
        }
      },
      set: function set(_key, value, prevValue, eventPlaceholder) {
        // console.log('birthdateFmtd - set: _showPikaDayBirthdate = '+this.get('_showPikaDayBirthdate')+', value = '+value+', tag = '+((eventPlaceholder||window.event).target||{}).tagName);
        if (((eventPlaceholder || window.event || {}).target || {}).tagName == 'BUTTON' || ((eventPlaceholder || window.event || {}).target || {}).tagName == null && typeof value.getFullYear == 'function') {
          // click on pikaday-calendar-popup
          this.setProperties({
            birthdate: value,
            showPikaDayBirthdate: false
          });
          if (this._pikaDayBirthdateInput) this._pikaDayBirthdateInput.blur();
          return (0, _moment.default)(value).format('DD.MM.YYYY');
        }

        if (typeof value.getFullYear == 'function') {
          this.set('birthdate', value);
          return (0, _moment.default)(value).format('DD.MM.YYYY');
        } // if T > 31 -> make T.M


        var m = value.match(/^([0-9])([0-9])$/);
        if (m != null && parseInt(m[0]) >= 32) value = m[1] + '.' + m[2]; // if M > 12 -> make T.M.Y

        m = value.match(/^([0-9]{1,2}\.)(([0-9])([0-9]))$/);
        if (m != null && parseInt(m[2]) >= 13) value = m[1] + m[3] + '.' + m[4];
        this.set('birthdate', (0, _moment.default)(value, 'DD.MM.YYYY').toDate());

        if (value.match(/^[0-9]{1,2}$/) != null && (prevValue || '').match(/^[0-9]{1,2}\.$/) != null) {
          // schedule("afterRender", () => (eventPlaceholder || window.event).srcElement.selectionStart = (eventPlaceholder || window.event).srcElement.selectionEnd = value.length);
          return value;
        } else if (value.match(/^[0-9]{1,2}\.[0-9]{1,2}$/) != null && (prevValue || '').match(/^[0-9]{1,2}\.[0-9]{1,2}\.$/) != null) {
          // schedule("afterRender", () => (eventPlaceholder || window.event).srcElement.selectionStart = (eventPlaceholder || window.event).srcElement.selectionEnd = value.length);
          return value;
        } else {
          return value.replace(/^([0-9]{2})$/, '$1.').replace(/^([0-9]{2})([0-9])/, '$1.$2').replace(/^([0-9]{1,2}.[0-9]{2})$/, '$1.').replace(/^([0-9]{1,2}.[0-9]{2})([0-9])/, '$1.$2');
        }
      }
    }),
    birthdateFmtdDE: Ember.computed({
      get: function get(_key) {
        if (this.get('birthdate') != null) {
          return (0, _moment.default)(this.get('birthdate')).format('DD.MM.YYYY');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        this.set('birthdate', (0, _moment.default)(value, 'DD.MM.YYYY').toDate());
        return value;
      }
    }),
    isChild: Ember.computed('birthdate', 'genderMut', function () {
      return ['j', 'k'].indexOf(this.get('gender')) >= 0;
    }),
    genderMut: Ember.computed({
      get: function get(_key) {
        var genderMap = {
          f: 'Frau',
          m: 'Herr',
          j: 'Kind (Junge)',
          k: 'Kind (Mädchen)'
        };
        return genderMap[this.get('gender')] || '';
      },
      set: function set(_key, value) {
        var genderMap = {
          'Frau': 'f',
          'Herr': 'm',
          'Kind (Junge)': 'j',
          'Kind (Mädchen)': 'k'
        };
        this.set('gender', genderMap[value]);
        return value;
      }
    }),
    invoiceSalutationMut: Ember.computed({
      get: function get(_key) {
        var genderMap = {
          f: 'Frau',
          m: 'Herr',
          c: 'Firma'
        };
        return genderMap[this.get('invoiceSalutation')] || '';
      },
      set: function set(_key, value) {
        var genderMap = {
          'Frau': 'f',
          'Herr': 'm',
          'Firma': 'c'
        };
        this.set('invoiceSalutation', genderMap[value]);
        return value;
      }
    }),
    differentInvoiceRecipient: Ember.computed('invoiceSalutationMut', {
      get: function get(_key) {
        if (['', null].indexOf(this.get('invoiceSalutationMut')) == -1) return this.get('_differentInvoiceRecipient') != 'Nein' ? 'Ja' : 'Nein';
        return this.get('_differentInvoiceRecipient') || (['', null].indexOf(this.get('invoiceLastName') || null) == -1 ? 'Ja' : 'Nein');
      },
      set: function set(_key, value) {
        if (value == 'Nein') {
          this.set('_invoiceAddress', this.getProperties(['invoiceSalutationMut', 'invoiceFirstName', 'invoiceLastName', 'invoiceAddressStreet', 'invoiceAddressHouseNumber', 'invoiceAddressZip', 'invoiceAddressCity']));
          this.setProperties({
            invoiceSalutationMut: null,
            invoiceFirstName: null,
            invoiceLastName: null,
            invoiceAddressStreet: null,
            invoiceAddressHouseNumber: null,
            invoiceAddressZip: null,
            invoiceAddressCity: null
          });
        } else {
          if (this.get('_invoiceAddress') != null) this.setProperties(this.get('_invoiceAddress'));
        }

        this.set('_differentInvoiceRecipient', value);
        return value;
      }
    }),
    invoiceOriginals: Ember.computed(function () {
      return 1;
    }),
    invoiceCopiesTemp: Ember.computed({
      get: function get(_key) {
        var invoiceCopies = this.get('invoiceCopies') || 0;
        return this.get('_invoiceCopiesTemp') || invoiceCopies;
      },
      set: function set(_key, value) {
        this.set('_invoiceCopiesTemp', value);
        return value;
      }
    })
  });
  /*
   createdAt                 | timestamp with time zone | not null
   updatedAt                 | timestamp with time zone | not null
   deletedAt                 | timestamp with time zone |
  */


  _exports.default = _default;
});