define("digiprax-web/models/lot", ["exports", "ember-data", "moment", "digiprax-web/models/day-group-data"], function (_exports, _emberData, _moment, _dayGroupData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    isDirty: function isDirty() {
      // api-method
      return this.get('hasDirtyAttributes') && !this.ignoreDirtyAttributes() || this.get('extraInfoIsDirtyFlag') != null;
    },
    extraInfoIsDirtyFlag: null,
    ignoreDirtyAttributes: function ignoreDirtyAttributes() {
      var attrs = Object.keys(this.changedAttributes());
      if (attrs.length >= 2) return false;
      return this.changedAttributes()['number'] != null && typeof this.changedAttributes()['number'][0] !== 'undefined' && this.changedAttributes()['number'][0] == this.changedAttributes()['number'][1];
    },
    // patient: DS.belongsTo('patient'), // , { inverse: null }),
    // tariff: DS.belongsTo('tariff'), // , { inverse: null }),
    patient_id: _emberData.default.attr('string'),
    invoice_id: _emberData.default.attr('string'),
    product_id: _emberData.default.attr('number'),
    date: _emberData.default.attr('BackendDate'),
    number: _emberData.default.attr('number'),
    cost_cents: _emberData.default.attr('number'),
    tax_percentage: _emberData.default.attr('number'),
    invoice_text: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    extraInfo: _emberData.default.attr('raw'),
    patientId: Ember.computed.alias('patient_id'),
    invoiceId: Ember.computed.alias('invoice_id'),
    productId: Ember.computed.alias('product_id'),
    cost: Ember.computed.alias('cost_cents'),
    targetInvoice: null,
    // currently edited invoice, used for total calculations
    validDate: Ember.computed('date', 'targetInvoice.date', function () {
      if (this.get('date') != null && this.get('targetInvoice') != null) {
        return !(0, _moment.default)(this.get('date')).startOf('day').isAfter((0, _moment.default)(this.get('targetInvoice.date')).startOf('day'));
      }

      return true;
    }),
    // required to display correct pikaday-value: empty (no placeholder) if previous entry is on same day
    dateTotal: Ember.computed('date', {
      get: function get(_key) {
        return this.get('date');
      },
      set: function set(_key, value) {
        if (value != null) {
          this.set('date', value);
        }

        return this.get('date');
      }
    }),
    totalDate: Ember.computed('dateTotal', 'targetInvoice.dayGroups', 'targetInvoice.dayGroups.updatedAt', function () {
      if (this.get('targetInvoice.entries') != null) {
        var sameDay = this.get('targetInvoice.dayGroups')[this.get('dateFmtd')].entries;

        if (sameDay[0].get('id') == this.get('id')) {
          return this.get('date');
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    totalDateFmtd: Ember.computed('totalDate', function () {
      if (this.get('totalDate') != null) {
        return this.get('dateFmtd');
      } else {
        return '';
      }
    }),
    dayGroupLength: Ember.computed('dayGroupIdx', function () {
      if (this.get('targetInvoice.lots') != null) {
        var sameDay = this.get('targetInvoice.dayGroups')[this.get('dateFmtd')].entries; // console.log('lot-dayGroup: this.date = '+this.get('dateFmtd')+', sameDay.length = '+sameDay.length);

        if (sameDay.length >= 2) {
          return sameDay.length;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    dayGroupIdx: Ember.computed('totalDate', function () {
      if (this.get('targetInvoice.lots') != null) {
        /*let _initDayGroup = */
        this.get('dayGroup');
        var sameDay = this.get('targetInvoice.dayGroups')[this.get('dateFmtd')].entries; // console.log('lot-dayGroupIdx: this.date = '+this.get('dateFmtd')+', sameDay.length = '+sameDay.length);

        if (sameDay.length >= 2) {
          return sameDay.indexOf(this);
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    dailyTotal: Ember.computed('targetInvoice.virtTotal', 'targetInvoice.dayGroups', 'targetInvoice.dayGroups.updatedAt', function () {
      if (this.get('targetInvoice.entries') != null) {
        var sameDay = this.get('targetInvoice.dayGroups')[this.get('dateFmtd')].entries;

        if (sameDay[sameDay.length - 1].get('id') == this.get('id')) {
          var total = sameDay.reduce(function (acc, curLot) {
            return acc + curLot.get('number') * curLot.getCost();
          }, 0);
          return (total / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',') + ' €';
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    lotText: Ember.computed('product_id', 'invoice_text', {
      // used for invoice
      get: function get(_key) {
        if (this.get('invoice_text') != null) {
          return this.get('invoice_text');
        } else if (this.get('product_id') != null) {
          return this.get('store').peekRecord('product', this.get('product_id')).get('name');
        } else if (this.get('notes') != null) {
          return this.get('notes');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        this.set('invoice_text', value);
        return value;
      }
    }),
    dateFmtd: Ember.computed('date', {
      get: function get(_key) {
        if (this.get('date') != null) {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        if ([null, ''].indexOf(value) == -1) {
          if (typeof value == 'string') {
            this.set('date', (0, _moment.default)(value, 'DD.MM.YYYY').toDate());
            return value;
          } else {
            this.set('date', value || new Date());
            return (0, _moment.default)(value).format('DD.MM.YYYY');
          }
        } else {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        }
      }
    }),
    getCost: function getCost() {
      return this.get('cost');
    },
    costFmtd: Ember.computed('cost', {
      // return ((this.get('cost') / 100).toFixed(2)).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
      get: function get(_key) {
        return (this.get('cost') / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
      },
      set: function set(_key, value) {
        var newCost = Math.round(parseFloat(value.replace(',', '.') * 100));
        this.set('cost', newCost); // return (this.get('cost') * this.get('number')) + ',00';

        return value;
      }
    }),

    /*costMut: computed('tariff', 'cost', 'number', function() {
      //this.set('cost', this.get('tariff.settlementTariff.default'));
      return (this.get('cost') * this.get('number')) + ',00';*/
    costMut: Ember.computed('cost', 'number', {
      get: function get(_key) {
        if ([-1].indexOf(this.get('cost')) >= 0) return ''; // return ((this.get('cost') * (this.get('number')||1) / 100).toFixed(2)).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');

        return (this.get('cost') * (this.get('number') || 1) / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
      },
      set: function set(_key, value) {
        var newCost;
        if (value == '') newCost = value = null; // else newCost = Math.round(parseFloat(value.replace(',', '.') * 100) / (this.get('number')||1));
        else newCost = Math.round(parseFloat(value.replace(',', '.').replace(/\s*€/, '') * 100) / (this.get('number') || 1));
        this.set('cost', newCost); // return (this.get('cost') * this.get('number')) + ',00';

        return value;
      }
    }),
    isToday: Ember.computed('date', function () {
      return (0, _moment.default)().format('DD.MM.YYYY') == (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
    }),
    positionTS: Ember.computed({
      get: function get(_key) {
        return this.get('extraInfo.positionTS') || null;
      },
      set: function set(_key, tSMillis) {
        var extraInfoAvailable = !(typeof this.get('extraInfo') == "undefined" || this.get('extraInfo') == null);

        if (extraInfoAvailable) {
          if (this.get('extraInfo.positionTS') == null) {
            this.set('extraInfo.positionTS', tSMillis);
          }
        } else {
          this.set('extraInfo', {
            positionTS: tSMillis
          });
        }

        return tSMillis;
      }
    }),
    clipboardPosition: Ember.computed('clipboard.lots.[]', function () {
      var _this = this;

      var idx = (this.get('clipboard.lots') || []).findIndex(function (t) {
        return t.model == _this;
      });
      return idx == -1 ? null : idx + 1;
    }),
    dayGroupData: Ember.computed('dayGroup.last', function () {
      // let dayGroupLots = this.dayGroup.lots.filter(t => !t.isNew);
      // if (this == dayGroupLots[dayGroupLots.length - 1] && this.get('date') != null) {
      if (this == (this.dayGroup || {}).last && this.get('date') != null) {
        // return this.targetInvoice.get('extraInfo.dayGroupData.'+moment(this.get('date')).format('YYYYMMDD'));
        return _dayGroupData.default.create({
          targetInvoice: this.invoice || this.targetInvoice,
          dayGroup: this.dayGroup
        });
      }

      return null;
    }),
    extraLine: Ember.computed(
    /**/
    'extraInfoIsDirtyFlag', {
      get: function get(_key) {
        return this.get('extraInfo.extraLine') || null;
      },
      set: function set(_key, extraLine) {
        if (extraLine == null || extraLine.trim() == '') {
          var extraInfo = this.get('extraInfo');
          delete extraInfo.extraLine;
          this.set('extraInfo', extraInfo);
        } else {
          this.set('extraInfo.extraLine', extraLine
          /*.trim()*/
          );
        }

        this.set('extraInfoIsDirtyFlag', new Date());
        return this.get('extraInfo.extraLine');
      }
    }),
    taxPercentage: Ember.computed('product_id', {
      get: function get(_key) {
        return this.get('product_id') ? this.product ? this.product.tax_percentage : null : this.get('tax_percentage');
      },
      set: function set(_key, taxPercentage) {
        if (this.get('product_id')) return this.product ? this.product.tax_percentage : null;
        return this.set('tax_percentage', taxPercentage.replace(',', '.'));
      }
    })
  });
  /*
   createdAt                 | timestamp with time zone | not null
   updatedAt                 | timestamp with time zone | not null
   deletedAt                 | timestamp with time zone |
  */


  _exports.default = _default;
});