define("digiprax-web/controllers/practice/edit", ["exports", "digiprax-web/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    practiceEditor: Ember.inject.service(),
    _productsImportFlag: Ember.computed.alias('application.productsImportFlag'),
    calendarDayTimes: Ember.computed(function () {
      var times = [];

      for (var i = 0; i < 2400; i += 100) {
        times.push(i);
      }

      return times;
    }),
    business_enabled: Ember.computed('currentUser.business_enabled', 'currentUser.account.plus.order_date', function () {
      return this.get('currentUser.business_enabled') || this.get('currentUser.account.plus.order_date');
    }),
    actions: {
      savePractice: function savePractice() {
        this.get('practiceEditor').savePractice(this);
      },
      updateBusinessEnabled: function updateBusinessEnabled() {
        this.set('confirmUpdateBusinessEnabled', true);
      },
      startPlus: function startPlus() {
        var _this = this;

        this.application.updatePlusMembership('start_plus', function (error) {
          if (error == null) _this.set('confirmUpdateBusinessEnabled', false);
        });
      },
      endPlus: function endPlus() {
        var _this2 = this;

        this.application.updatePlusMembership('end_plus', function (error) {
          if (error == null) _this2.set('confirmUpdateBusinessEnabled', false);
        });
      }
    },
    inValid: function inValid(errors) {
      Ember.$('html, body').animate({
        scrollTop: 0
      }, 500);
      var errorHtml = '';
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = errors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var error = _step.value;
          errorHtml += '<li>' + JSON.stringify(error) + '</li>';
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.get('flashMessages').error('<ul>' + errorHtml + '</ul>', {
        timeout: 10000
      });
    },
    afterSave: function afterSave(_practice) {
      Ember.$('html, body').animate({
        scrollTop: 0
      }, 500);
      this.get('flashMessages').success((this.get('application.currentUser.plus') ? 'Betriebsdaten' : 'Praxisdaten') + ' erfolgreich geändert ...', {
        timeout: 3000
      });
    },
    handleUpload: function handleUpload(uploadKey, _response) {
      var _this3 = this;

      if (uploadKey == 'importProducts') {
        // Handle success
        // ['product'].forEach(modelName => run(() => {
        //   this.store.unloadAll(modelName);
        // }));

        /*['product'].forEach(modelName => */
        Ember.run(function () {
          _this3.store.findAll('product').then(function (_products) {
            _this3.set('_productsImportFlag', new Date());
          });
        }); // );
      }
    }
  });

  _exports.default = _default;
});