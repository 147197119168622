define("digiprax-web/routes/customers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    patients: Ember.computed.alias('application.patients'),
    beforeModel: function beforeModel(transition) {
      // TODO: enable listview via param?
      var forceOverview = location.search.match(/[?&]overview=1/) != null || location.hash.match(/[?&]overview=1/) != null; // if (forceOverview) {
      //   if (this.get('controller.application') != null) {
      //     this.set('controller.application.currentPatient', null);
      //   }
      // }

      var lastViewedPatientId = forceOverview ? null : localStorage.getItem('lastViewedPatientId');
      if (lastViewedPatientId != null) return this.transitionTo('patients.edit.index', lastViewedPatientId);
      return this._super(transition);
    },
    model: function model(_params, _transition) {
      this.application.showProgressbar();
      return this.get('patients');
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model); // next(() => {


      Ember.run.schedule("afterRender", function () {
        _this.application.hideProgressbar();
      }); // });
      // case reload ...

      controller.get('application').setActiveNavLink('linkTo.allepatienten');
    }
  });

  _exports.default = _default;
});