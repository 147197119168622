define("digiprax-web/models/entry", ["exports", "ember-data", "moment", "digiprax-web/mixins/entry-utils"], function (_exports, _emberData, _moment, _entryUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_entryUtils.default, {
    accountDesignation: _emberData.default.attr('string'),
    accountNumber: _emberData.default.attr('string'),
    date: _emberData.default.attr('BackendDate'),
    expenditure: _emberData.default.attr('string'),
    invoiceNumber: _emberData.default.attr('string'),
    month: _emberData.default.attr('string'),
    revenue: _emberData.default.attr('string'),
    serialNumber: _emberData.default.attr('string'),
    transactiontype: _emberData.default.attr('string'),
    year: _emberData.default.attr('number'),
    isVisible: _emberData.default.attr('boolean'),
    // <=> is cashBook
    extraInfo: _emberData.default.attr('raw'),
    journalTransferred: _emberData.default.attr('boolean'),
    overviewTransferred: _emberData.default.attr('boolean'),
    isExpand: _emberData.default.attr('boolean'),
    isCollective: _emberData.default.attr('boolean'),
    isCollectiveHide: _emberData.default.attr('boolean'),
    showAddCollective: Ember.computed('accountNumber', 'isExpand', 'serialNumber', function () {
      if (this.store.adapterFor('entry').application.get('currentUser.plus')) return false; // {{#if (and (eq entry.accountNumber '110') (or (or entry.isCollective (not entry.serialNumber))))}}

      return String(this.get('accountNumber')) == '110' && (this.get('serialNumber') == null || this.get('isExpand'))
      /* &&
      (this.get('serialNumber') == null ^ (this.get('serialNumber').match(/\.[0-9]+$/) == null))*/
      ;
    }),
    block_transfer_part_ids: _emberData.default.attr('raw'),
    // notEditable: DS.attr('boolean'),
    notEditable: Ember.computed('journalTransferred', function () {
      return this.get('journalTransferred');
    }),
    isDirty: Ember.computed('hasDirtyAttributes', 'extraInfoIsDirtyFlag', function () {
      return this.get('hasDirtyAttributes') || this.get('extraInfoIsDirtyFlag') != null || this.get('extraInfoIsDirtyFlag') != null;
    }),
    hasErrors: Ember.computed('errors', function () {
      return (this.errors || []).length >= 1;
    }),
    extraInfoIsDirtyFlag: null,
    newBlockTransferPartFlag: null,
    deletedBlockTransferPartFlag: null,
    restoreBlockTransferFlag: null,
    serialNumberSort: Ember.computed('serialNumber', function () {
      if (this.get('serialNumber') != null) {
        var year = parseInt(this.get('serialNumber').match(/^[^-]+/)[0] + '00000');
        var number = parseFloat(this.get('serialNumber').match(/[^-]+$/)[0]);
        return year + number;
      }

      return null;
    }),
    blockTransferParts: Ember.computed('block_transfer_part_ids', 'newBlockTransferPartFlag', 'deletedBlockTransferPartFlag', function () {
      var _this = this;

      // children
      return this.block_transfer_part_ids == null ? [] : this.block_transfer_part_ids.map(function (bTPId) {
        return _this.store.peekRecord('entry', bTPId);
      }).compact().concat(this.get('newTransferPartEntries').toArray() || []);
    }),
    validBlockTransferParts: Ember.computed('blockTransferParts', function () {
      return this.get('blockTransferParts').filter(function (bTP) {
        return bTP.get('transactiontype') != 'Lfd. Nr. nicht verwendet' && bTP.get('tmpId') == null;
      });
    }),
    blockTransfer: Ember.computed('serialNumber', 'restoreBlockTransferFlag', function () {
      var _this2 = this;

      // parent
      // if (this.serialNumber.match(/\.[0-9]+$/) != null) {
      //   return this.store.peekAll('entry').find(e => e.serialNumber == this.serialNumber.match(/^[^.]+/)[0]);
      // }
      // return null;
      if (this.serialNumber != null) {
        if (this.serialNumber.match(/\.[0-9]+$/) != null) {
          return this.store.peekAll('entry').find(function (e) {
            return e.serialNumber == _this2.serialNumber.match(/^[^.]+/)[0];
          });
        }
      } else {
        var bTPIdPrefix = this.store.adapterFor('entry').application.get('currentUser.plus') ? 't' : 'p';
        var bTPId = this.tmpId != null ? bTPIdPrefix + this.tmpId : this.id;
        return this.store.peekAll('entry').toArray().find(function (e) {
          return e.block_transfer_part_ids != null && e.block_transfer_part_ids.indexOf(bTPId) >= 0;
        });
      }

      return null; // let bTPIdPrefix = this.store.adapterFor('entry').application.get('currentUser.plus') ? 't' : 'p';
      // return (this.store.peekAll('entry').find(e => e.block_transfer_part_ids != null && e.block_transfer_part_ids.indexOf(this.tmpId != null ? bTPIdPrefix+this.tmpId : this.id) >= 0)||[])[0];
    }),
    lastBlockTransferPart: Ember.computed('blockTransferParts', function () {
      if (this.get('blockTransferParts') != null) return this.get('blockTransferParts.lastObject');
      return null;
    }),
    displayNewTransferPartEntries: Ember.computed('blockTransfer.blockTransferParts.[]', 'blockTransfer.newBlockTransferPartFlag', function () {
      var blockTransfer = this.get('blockTransfer') || (this.get('blockTransferParts') != null ? this : null);

      if (blockTransfer != null) {
        if (blockTransfer.get('tmpId')) {
          return this == blockTransfer.get('blockTransferParts.lastObject');
        } else {
          var lastPersistedEntry = [].concat(blockTransfer.get('blockTransferParts')).reverse().find(function (e) {
            return e.tmpId == null;
          });
          return this == lastPersistedEntry;
        }
      }

      return false;
    }),
    newTransferPartEntries: Ember.computed(function () {
      return Ember.A([]);
    }),
    blockTransferRevFmtd: Ember.computed('blockTransferParts.@each.revenue', 'blockTransferParts.@each.expenditure', function () {
      var blockTransferRev = [0].concat(this.get('blockTransferParts')).reduce(function (acc, bTP) {
        var revenue = acc + parseFloat(bTP.get('revenue') || 0) - parseFloat(bTP.get('expenditure') || 0); // revenue += (this.get('entriesRev') || 0);

        return revenue;
      }); // blockTransferRev += this.get('entriesRev');

      return this.formatFloat(blockTransferRev || 0) + ' €';
    }),
    isBlockTransferPart: Ember.computed('serialNumber', function () {
      // return this.get('serialNumber') != null && this.get('serialNumber').match(/\.[0-9]+$/) != null;
      if (this.get('serialNumber') != null) return this.get('serialNumber').match(/\.[0-9]+$/) != null;else if (this.store.adapterFor('entry').application.get('currentUser.plus')) return this.get('blockTransfer') != null;else return null;
    }),
    dateFmtd: Ember.computed('date', {
      get: function get(_key) {
        if (this.get('date') != null) {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        if ([null, ''].indexOf(value) == -1) {
          if (typeof value == 'string') {
            this.set('date', (0, _moment.default)(value, 'DD.MM.YYYY').toDate());
            return value;
          } else {
            this.set('date', value || new Date());
            return (0, _moment.default)(value).format('DD.MM.YYYY');
          }
        } else {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        }
      }
    }),
    monthFmtd: Ember.computed('date', function () {
      return this.get('date') != null ? (0, _moment.default)(this.get('date')).format('MMM') : '';
    })
  });
  /*
   createdAt                 | timestamp with time zone | not null
   updatedAt                 | timestamp with time zone | not null
  */


  _exports.default = _default;
});