define("digiprax-web/models/tariff-chain-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    tariff_id: _emberData.default.attr('string'),
    tariff_chain_id: _emberData.default.attr('number'),
    count: _emberData.default.attr('number'),
    position: _emberData.default.attr('number'),
    extraInfo: _emberData.default.attr('raw'),
    tariff: Ember.computed(function () {
      return this.get('store').peekRecord('tariff', this.get('tariff_id'));
    })
  });
  /*
   createdAt                 | timestamp with time zone | not null
   updatedAt                 | timestamp with time zone | not null
  */


  _exports.default = _default;
});