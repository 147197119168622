define("digiprax-web/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{flash-message flash=flash}}
  var _default = Ember.Component.extend({
    flash: null,
    actions: {
      closeDialog: function closeDialog(flash) {
        this.get('flashMessages')._removeFlashMessage(flash.messageTs)();
      }
    }
  });

  _exports.default = _default;
});