define("digiprax-web/controllers/patients/edit/index", ["exports", "digiprax-web/controllers/base", "digiprax-web/mixins/patient-dialogs", "digiprax-web/mixins/auto-complete-patient-search"], function (_exports, _base, _patientDialogs, _autoCompletePatientSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_patientDialogs.default, _autoCompletePatientSearch.default, {
    patientEditor: Ember.inject.service(),
    scrollPadding: 23,
    scrollDownTreatments: Ember.computed('model.treatments', 'model.newTreatmentPlaceholders', function () {
      var _this = this;

      if (Ember.$('.all_treatments').length >= 1) {
        Ember.run.schedule("afterRender", function () {
          _this.send("scrollDownTreatments");
        });
        return '';
      } else {
        return '';
      }
    }),
    actions: {
      editPatient: function editPatient() {
        // angular app: document.location.href = '/patients/'+this.get('model.id')+'/edit';
        this.transitionToRoute('patients.edit.profile', this.get('model.id'));
      },
      createPatient: function createPatient() {
        this.transitionToRoute('/');
      },
      savePatient: function savePatient() {
        this.get('patientEditor').savePatient(this.get('model'), this.get('model.newTreatmentPlaceholders').toArray(), this);
      },
      scrollDownTreatments: function scrollDownTreatments() {
        var e = document.querySelector('.all_treatments'); // e.scrollTop = this.get('model.newTreatmentPlaceholders.length') >= 2 ? e.scrollHeight - $('.list.treatments').height() - this.scrollPadding : e.scrollHeight;

        e.scrollTop = false && this.get('model.newTreatmentPlaceholders.length') >= 2 ? e.scrollHeight - Ember.$('.list.treatments').height() - this.scrollPadding : e.scrollHeight;
      },
      oLDscrollDownTreatments: function oLDscrollDownTreatments() {
        var e = document.querySelector('.all_treatments'); // e.scrollTop = e.scrollHeight;
        // if (event.target != null && $(event.target).closest('li').length >= 1) {

        e.scrollTop = this.get('model.newTreatmentPlaceholders.length') >= 2 ? e.scrollHeight - Ember.$('.list.treatments').height() - this.scrollPadding : e.scrollHeight;
        /*} else {
          e.scrollTop = e.scrollHeight;
        }*/
        //$('html, body').animate({scrollTop: $('body').height()}, 500);
      }
    },
    inValid: function inValid(errors, config) {
      // $('html, body').animate({scrollTop: 0}, 500);
      var configAvailable = !(typeof config == "undefined" || config == null);
      if (!configAvailable) config = {
        timeout: 10000
      };
      var errorHtml = '';
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = errors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var error = _step.value;
          errorHtml += '<li>' + error + '</li>';
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.get('flashMessages').error('<ul>' + errorHtml + '</ul>', config);
    },
    afterSave: function afterSave(_patient) {
      // $('html, body').animate({scrollTop: 0}, 500);
      this.get('flashMessages').success('Patientenkarte erfolgreich geändert ...', {
        timeout: 3000
      });
      /* with transition:
      Ember.run.schedule("afterRender",() => {
          $('html, body').animate({scrollTop: 0}, 500);
          this.get('flashMessages').success('Patientenkarte erfolgreich geändert ...', {
            timeout: 3000
          });
        });
      this.transitionToRoute('patients.edit.invoices.new', patient.id);
      */
    }
  });

  _exports.default = _default;
});