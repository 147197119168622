define("digiprax-web/services/invoice-editor", ["exports", "moment", "digiprax-web/models/chain-categories"], function (_exports, _moment, _chainCategories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    clipboard: Ember.inject.service(),
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    chainCategories: Ember.computed(function () {
      return _chainCategories.default.create({
        store: this.get('application.store')
      });
    }),
    // FIXME: make task
    saveInvoice: function saveInvoice(invoice, newEntryPlaceholders, listener) {
      var _this = this;

      if (invoice.get('notNullUnlessFinalized') == null) {
        listener.inValid(['Die Rechnung wurde bereits finalisiert und kann nicht mehr geändert werden.']);
        return false;
      }

      var forBusiness = this.get('application.currentUser.plus');

      if (!forBusiness) {
        // diagnoseText can be generated from last entries' diagnoses. (only for save - not for (@see) finalize)
        if ([null, ''].indexOf(invoice.get('diagnoseText')) >= 0) {
          // no diagnoses available at all
          listener.inValid(['Bitte mindestens 1 Diagnose für die Rechnung auswählen']);
          return false;
        } else {
          // in case of generated diagnoseText call set to actually set it for this invoice
          // if (invoice.get('extraInfo.diagnoseText') == null) invoice.set('diagnoseText', invoice.get('diagnoseText'));
          if ([null, ''].indexOf(invoice.get('extraInfo.diagnoseText')) >= 0 || typeof invoice.get('extraInfo.diagnoseText') == "undefined") invoice.set('diagnoseText', invoice.get('diagnoseText'));
        }
      }

      var errors = [];

      if (this._validateEntries(invoice, errors, forBusiness)) {
        this.get('application').showProgressbar();

        if (invoice.get('isNew') || invoice.id == 'new') {
          var newInvoice = null;

          if (invoice.id == 'new') {
            newInvoice = invoice.get('store').createRecord(forBusiness ? 'invoice-for-business' : 'invoice', {});
          } else {
            newInvoice = invoice;
            invoice.set('id', null);
          }

          if (invoice.get('extraInfoIsDirtyFlag') != null) invoice.set('extraInfoIsDirtyFlag', null);
          newInvoice.set('patientId', invoice.get('patient.id'));
          newInvoice.set('date', (0, _moment.default)(invoice.get('dateFmtd'), 'DD.MM.YYYY').toDate());
          if (invoice.get('extraInfo.invoiceMonth') == null) invoice.set('invoiceMonth', invoice.get('invoiceMonth'));
          if (invoice.get('realTotal') != null) newInvoice.set('total', parseFloat(invoice.get('realTotal').replace(',', '.')) * 100);
          newInvoice.set('extraInfo', invoice.get('extraInfo'));
          newInvoice.set('notes', invoice.get('notes') || '-');
          newInvoice.set('liable_to_sales_tax', invoice.get('liable_to_sales_tax'));
          newInvoice.save().then(function (newInvoice) {
            _this._saveNewEntriesSequentially(newInvoice, newEntryPlaceholders, listener, errors, forBusiness);
          }, function (error) {
            _this.get('application').hideProgressbar();

            if (typeof error.get == 'function') {
              listener.inValid(error.get('messages').map(function (errorMessage) {
                if (errorMessage.match(/^['"]?extraInfo['"]?/)) return null;
                if (errorMessage.match(/^['"]?date['"]? can't be blank/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
                if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Rechnungs-Datum darf nicht in der Zukunft liegen.';
                if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
                return errorMessage;
              }));
            } else {
              _this.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

              listener.inValid(['Unbekannter Fehler!']);
            }
          });
        } else {
          this._fixMissingPositionTs(invoice);

          if (invoice.get('isDirty')) {
            // required for total
            if (invoice.get('extraInfoIsDirtyFlag') != null) invoice.set('extraInfoIsDirtyFlag', null);
            if (invoice.get('extraInfo.invoiceMonth') == null) invoice.set('invoiceMonth', invoice.get('invoiceMonth'));
            if (invoice.get('realTotal') != null) invoice.set('total', parseFloat(invoice.get('realTotal').replace(',', '.')) * 100);
            invoice.save().then(function (updatedInvoice) {
              _this._updateEntriesSequentially(updatedInvoice, invoice.get('entries').toArray(), true
              /*invoice.get('isDirtyForSave')*/
              , newEntryPlaceholders, listener, errors, forBusiness);
            }, function (error) {
              _this.get('application').hideProgressbar();

              if (typeof error.get == 'function') {
                listener.inValid(error.get('messages').map(function (errorMessage) {
                  if (errorMessage.match(/^['"]?extraInfo['"]?/)) return null;
                  if (errorMessage.match(/^['"]?date['"]? can't be blank/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
                  if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Rechnungs-Datum darf nicht in der Zukunft liegen.';
                  if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
                  return errorMessage;
                }));
              } else {
                _this.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

                listener.inValid(['Unbekannter Fehler!']);
              }
            });
          } else {
            this._updateEntriesSequentially(invoice, invoice.get('entries').toArray(), false, newEntryPlaceholders, listener, errors, forBusiness);
          }
        }
      } else {
        this.get('application').hideProgressbar();
        listener.inValid(errors.compact().uniq(), {
          timeout: 5000
        });
      }
    },
    _fixMissingPositionTs: function _fixMissingPositionTs(invoice) {
      invoice.get('sortedEntries').toArray().reverse().reduce(function (curMaxPositionTS, curEntry) {
        var extraInfoAvailable = !(typeof curEntry.get('extraInfo') == "undefined" || curEntry.get('extraInfo') == null);

        if (extraInfoAvailable) {
          if (curEntry.get('extraInfo.positionTS') == null) {
            curEntry.set('extraInfo.positionTS', curMaxPositionTS - 1000);
            curEntry.set('extraInfoIsDirtyFlag', new Date());
          } // if (curEntry.get('extraInfo.position') != null) delete curEntry.get('extraInfo').position;

        } else {
          curEntry.set('extraInfo', {
            positionTS: curMaxPositionTS - 1000
          });
          curEntry.set('extraInfoIsDirtyFlag', new Date());
        }

        return curEntry.get('extraInfo.positionTS');
      }, invoice.get('sortedNewEntryPlaceholders').toArray()[0].get('positionTS') || new Date().getTime());
    },
    finalizeInvoice: function finalizeInvoice(invoice, listener) {
      var _this2 = this;

      if (invoice.get('notNullUnlessFinalized') != null) {
        if ([null, ''].indexOf(invoice.get('extraInfo.diagnoseText')) >= 0) {
          listener.inValid(['Bitte mindestens 1 Diagnose für die Rechnung auswählen']);
          return false;
        }

        var extraInfoAvailable = !(typeof invoice.get('extraInfo') == "undefined" || invoice.get('extraInfo') == null);

        if (extraInfoAvailable) {
          if (invoice.get('extraInfoIsDirtyFlag') != null) invoice.set('extraInfoIsDirtyFlag', null);
          invoice.set('extraInfo.finalized', {
            date: (0, _moment.default)().format('YYYY-MM-DD HH:mm')
          });
        } else {
          invoice.set('extraInfo', {
            finalized: {
              date: (0, _moment.default)().format('YYYY-MM-DD HH:mm')
            }
          });
        }

        if (invoice.get('extraInfo.invoiceMonth') == null) invoice.set('invoiceMonth', invoice.get('invoiceMonth'));
        invoice.save().then(function (_updatedInvoice) {
          // listener.afterSave(invoice);
          invoice.reload().then(function (i) {
            return listener.afterSave(i);
          });
        }, function (error) {
          _this2.get('application').hideProgressbar();

          if (typeof error.get == 'function') {
            listener.inValid(error.get('messages'));
          } else {
            _this2.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

            listener.inValid(['Unbekannter Fehler!']);
          }
        });
      }
    },
    restoreInvoice: function restoreInvoice(invoice, listener, cancel) {
      var _this3 = this;

      if (invoice.get('notNullUnlessFinalized') == null) {
        if (invoice.get('extraInfoIsDirtyFlag') != null) invoice.set('extraInfoIsDirtyFlag', null);
        delete invoice.get('extraInfo').finalized; //invoice.set('extraInfo', JSON.parse(JSON.stringify(invoice.get('extraInfo'))));

        var extraInfo = [{}].concat(Object.keys(invoice.get('extraInfo'))).reduce(function (acc, attr) {
          acc[attr] = invoice.get('extraInfo.' + attr);
          return acc;
        });
        if (cancel) extraInfo.canceledAt = (0, _moment.default)().format('YYYY-MM-DD HH:mm');
        invoice.set('extraInfo', extraInfo);
        invoice.save().then(function (_updatedInvoice) {
          listener.afterSave(invoice);
        }, function (error) {
          _this3.get('application').hideProgressbar();

          if (typeof error.get == 'function') {
            listener.inValid(error.get('messages'));
          } else {
            _this3.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

            listener.inValid(['Unbekannter Fehler!']);
          }
        });
      }
    },
    cancelInvoice: function cancelInvoice(invoice, listener) {
      this.restoreInvoice(invoice, listener, true);
    },
    saveNewCustomTariffChain: function saveNewCustomTariffChain(name) {
      var _this4 = this;

      if ((name || '').trim() != '') {
        var tariffChainItems = this.get('clipboard.treatments').toArray().map(function (copyTreatment) {
          return {
            tariff_id: copyTreatment.model.get('tariffId'),
            tariff_text: copyTreatment.model.get('invoiceText'),
            number: copyTreatment.model.get('number'),
            cost: copyTreatment.model.get('cost'),
            count: copyTreatment.model.get('number')
          };
        });
        var newTariffChain = this.get('application.store').createRecord('custom-tariff-chain', {
          // id: name,
          name: name,
          tariffChainItems: tariffChainItems
        });
        return newTariffChain.save().then(function (nCTC) {
          var customTCIndex = _this4.chainCategories.categoryMap.findIndex(function (c) {
            return c.name == 'Eigene';
          });

          var curCategory = _this4.chainCategories.categoryMap[customTCIndex]; // if (this.chainCategories.get('categoryMap.'+customTCIndex+'.tariffChains.length') >= 1) {
          //   this.chainCategories.get('categoryMap.'+customTCIndex+'.tariffChains').clear();
          // }

          var newTariffChain = _this4.get('application.store').peekRecord('tariff-chain', nCTC.id);

          newTariffChain.set('items', _this4.get('application.store').peekAll('tariff-chain-item', nCTC.tariffChainItems.map(function (tCI) {
            return tCI.id;
          })));
          curCategory.get('tariffChains').push(newTariffChain);

          _this4.chainCategories.setProperties({
            selectedCategoryIndex: customTCIndex
            /**/
            ,
            newCustomTariffChainFlag: nCTC.id
          });

          _this4.get('clipboard').clear();

          _this4.get('application.flashMessages').success('Abrechnungskette erfolgreich eingetragen', {
            timeout: 3000
          });
        }, function (error) {
          _this4.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error)); // this.inValid(['Unbekannter Fehler!']);


          _this4.get('application.flashMessages').error('Unbekannter Fehler! ' + JSON.stringify(error), {
            timeout: 10000
          });
        });
      } else {
        this.get('application.flashMessages').error('Die Abrechnungskette kann nicht ohne Namen gespeichert werden.', {
          timeout: 3000
        });
        return null;
      }
    },
    deleteCustomTariffChain: function deleteCustomTariffChain(tariffChain, callback) {
      var _this5 = this;

      tariffChain.destroyRecord(tariffChain).then(function () {
        var customTCIndex = _this5.chainCategories.categoryMap.findIndex(function (c) {
          return c.name == 'Eigene';
        });

        var curCategory = _this5.chainCategories.categoryMap[customTCIndex]; // if (this.chainCategories.get('categoryMap.'+customTCIndex+'.tariffChains.length') >= 1) {
        //   this.chainCategories.get('categoryMap.'+customTCIndex+'.tariffChains').clear();
        // }

        curCategory.get('tariffChains').removeObject(tariffChain);

        _this5.get('application.store').peekAll('tariff-chain-item').filter(function (tCI) {
          return tCI.tariff_chain_id == tariffChain.id;
        }).forEach(function (tCI) {
          return tCI.unloadRecord();
        });

        _this5.chainCategories.setProperties({
          selectedCategoryIndex: customTCIndex
          /*, newCustomTariffChainFlag: -tariffChain.id*/

        });

        callback();
      }, function (error) {
        callback(error);
      });
    },
    _updateEntriesSequentially: function _updateEntriesSequentially(invoice, entries, forceUpdate, newEntryPlaceholders, listener, errors, forBusiness) {
      var _this6 = this;

      if (entries.length >= 1) {
        // if ((entries[0].get('tariffId') != null) && (forceUpdate || entries[0].get('hasDirtyAttributes'))) {
        if ((forBusiness || entries[0].get('tariffId') != null) && (forceUpdate || entries[0].isDirty())) {
          if (entries[0].get('extraInfoIsDirtyFlag') != null) entries[0].set('extraInfoIsDirtyFlag', null); // entries[0].set('date', moment(entries[0].get('dateFmtd'), 'DD.MM.YYYY').toDate());

          if (forBusiness) {
            entries[0].set('invoice_text', entries[0].get('lotText'));
            if (entries[0].product_id == null) entries[0].set('tax_percentage', entries[0].get('taxPercentage'));
          } else entries[0].set('invoiceText', entries[0].get('treatmentText'));

          if (entries[0].get('invoiceId') == null) {
            // all entries without invoiceId are displayed so the user can add them
            entries[0].set('invoiceId', invoice.id);
          }

          entries[0].save().then(function (_entry) {
            console.debug('updated ' + (forBusiness ? 'lot' : 'treatment') + ' ...');

            _this6._updateEntriesSequentially(invoice, entries.slice(1), forceUpdate, newEntryPlaceholders, listener, errors, forBusiness);
          }, function (error) {
            _this6.get('application').hideProgressbar();

            if (typeof error.get == 'function') {
              listener.inValid(error.get('messages').map(_this6._errorMessageMapperForEntries(forBusiness)).compact().uniq()); // listener.inValid(error.get('messages').map(errorMessage => {
              //     if (errorMessage.match(/^['"]?extraInfo['"]?/)) return null;
              //     if (errorMessage.match(/^['"]?date['"]? can't be blank/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              //     if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Rechnungs-Datum darf nicht in der Zukunft liegen.';
              //     if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              //     if (errorMessage.match(/^invoiceText: can't be blank/)) return 'Bezeichnung muss angegeben werden.';
              //     return errorMessage;
              //   }));
            } else {
              _this6.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

              listener.inValid(['Unbekannter Fehler!']);
            }
          });
        } else {
          this._updateEntriesSequentially(invoice, entries.slice(1), forceUpdate, newEntryPlaceholders, listener, errors, forBusiness);
        }
      } else this._saveNewEntriesSequentially(invoice, newEntryPlaceholders, listener, errors, forBusiness);
    },
    _saveNewEntriesSequentially: function _saveNewEntriesSequentially(invoice, newEntryPlaceholders, listener, errors, forBusiness) {
      var _this7 = this;

      if (newEntryPlaceholders.length >= 1) {
        // if ((newEntryPlaceholders[0].get('tariff.id') != null) && (newEntryPlaceholders[0].get('hasDirtyAttributes'))) {
        if ((forBusiness || newEntryPlaceholders[0].get('tariff.id') != null) && newEntryPlaceholders[0].isDirty()) {
          if (newEntryPlaceholders[0].get('extraInfoIsDirtyFlag') != null) newEntryPlaceholders[0].set('extraInfoIsDirtyFlag', null);
          var entryParams = {
            patientId: invoice.get('patient.id') || invoice.get('patientId'),
            invoiceId: invoice.get('id'),
            date: (0, _moment.default)(newEntryPlaceholders[0].get('dateFmtd'), 'DD.MM.YYYY').toDate(),
            number: newEntryPlaceholders[0].get('number'),
            cost: newEntryPlaceholders[0].getCost(),
            notes: newEntryPlaceholders[0].get('notes') || '-',
            extraInfo: newEntryPlaceholders[0].get('extraInfo')
          };

          if (forBusiness) {
            if (newEntryPlaceholders[0].get('product_id') == null) entryParams.tax_percentage = newEntryPlaceholders[0].get('taxPercentage');
            entryParams.product_id = parseInt(newEntryPlaceholders[0].get('product.id'));
            entryParams.invoice_text = newEntryPlaceholders[0].get('lotText');
          } else {
            entryParams.tariffId = newEntryPlaceholders[0].get('tariff.id');
            entryParams.factor = newEntryPlaceholders[0].get('factor');
            entryParams.invoiceText = newEntryPlaceholders[0].get('treatmentText');
          } // create


          invoice.get('store').createRecord(forBusiness ? 'lot' : 'treatment', entryParams).save().then(function (entry) {
            console.debug('created ' + (forBusiness ? 'lot' : 'treatment') + ' ...');
            if (forBusiness) entry.setProperties({
              targetInvoice: invoice
            });else entry.setProperties({
              targetInvoice: invoice,
              tariff: newEntryPlaceholders[0].get('tariff')
            });
            /*if (newEntryPlaceholders.length >= 2) */

            _this7._saveNewEntriesSequentially(invoice, newEntryPlaceholders.slice(1), listener, errors, forBusiness);
          }, function (error) {
            _this7.get('application').hideProgressbar();

            if (typeof error.get == 'function') {
              listener.inValid(error.get('messages').map(_this7._errorMessageMapperForEntries(forBusiness)).compact().uniq()); // listener.inValid(error.get('messages').map(errorMessage => {
              //     if (errorMessage.match(/^['"]?extraInfo['"]?/)) return null;
              //     if (errorMessage.match(/^['"]?date['"]? can't be blank/)) return 'Ein Behandlungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              //     if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Behandlungs-Datum darf nicht in der Zukunft liegen.';
              //     if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Behandlungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
              //     if (errorMessage.match(/^invoiceText: can't be blank/)) return 'Bezeichnung muss angegeben werden.';
              //     return errorMessage;
              //   }));
            } else {
              _this7.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

              listener.inValid(['Unbekannter Fehler!']);
            }
          });
        } else {
          /*if (newEntryPlaceholders.length >= 2) */
          this._saveNewEntriesSequentially(invoice, newEntryPlaceholders.slice(1), listener, errors, forBusiness);
        }
      } else {
        // done ...
        this.get('application').hideProgressbar();
        if (forBusiness) invoice.set('entries', invoice.get('store').peekAll('lot').filter(function (lot) {
          return lot.get('invoice_id') == invoice.id;
        }));else invoice.set('entries', invoice.get('store').peekAll('treatment').filter(function (treatment) {
          return treatment.get('invoiceId') == invoice.id;
        })); // invoice.set('entries', invoice.get('store').peekAll('treatment').filter(treatment => treatment.get('invoiceId') == invoice.id).sort((t1, t2) => t2.get('date') - t1.get('date') >= 0 ? -1 : 1));

        invoice.afterSave(); // after setting new entries because isDirty-flag

        listener.afterSave(invoice);
      }
    },
    _validateEntries: function _validateEntries(model, errors, forBusiness) {
      var dirtyEntries = model.get('entries').filter(function (e) {
        return e.isDirty() || (forBusiness ? e.lotText != null && e.lotText == '' : e.tariffId != null && e.tariffItem == '');
      });
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = dirtyEntries[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var dT = _step.value;

          if (forBusiness) {
            if ((dT.get('dateFmtd') || '').trim() == '') errors.push('Datum muss angegeben werden.');
            if ((dT.get('costFmtd') || '').trim() == '') errors.push('Brutto-Preis (Stk.) muss angegeben werden.');
            if (this.application.get('practice.liable_to_sales_tax') && dT.get('product_id') == null && (dT.get('taxPercentage') || '').trim() == '') errors.push('MwSt-Satz muss angegeben werden.');
          } else {
            if ((dT.get('tariffItem') || '').trim() == '') errors.push('GebüH-Ziffer muss angegeben werden.'); // if ((dT.get('invoiceText')||'').trim() == '') errors.push('Bezeichnung muss angegeben werden.');
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var dirtyNewEntryPlaceholders = model.get('newEntryPlaceholders').filter(function (e) {
        return e.isDirty() || (forBusiness ? e.lotText != null && e.lotText == '' : e.tariffId != null && e.tariffItem == '');
      });
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = dirtyNewEntryPlaceholders[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var dNTP = _step2.value;

          if (forBusiness) {
            if ((dNTP.get('dateFmtd') || '').trim() == '') errors.push('Datum muss angegeben werden.');
            if ((dNTP.get('costFmtd') || '').trim() == '') errors.push('Brutto-Preis (Stk.) muss angegeben werden.');
            if (this.application.get('practice.liable_to_sales_tax') && dNTP.get('product_id') == null && (dNTP.get('taxPercentage') || '').trim() == '') errors.push('MwSt-Satz muss angegeben werden.');
          } else {
            if ((dNTP.get('tariffItem') || '').trim() == '') errors.push('GebüH-Ziffer muss angegeben werden.'); // if ((dNTP.get('invoiceText')||'').trim() == '') errors.push('Bezeichnung muss angegeben werden.');
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return errors.length == 0;
    },
    _errorMessageMapperForEntries: function _errorMessageMapperForEntries(forBusiness) {
      return function (errorMessage) {
        if (errorMessage.match(/^['"]?extraInfo['"]?/)) return null;
        if (errorMessage.match(/^['"]?date['"]? can't be blank/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
        if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Rechnungs-Datum darf nicht in der Zukunft liegen.';
        if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
        if (forBusiness && errorMessage.match(/^invoiceText: can't be blank/)) return 'Bezeichnung muss angegeben werden.'; // if ((!forBusiness) && errorMessage.match(/^lotText: can't be blank/)) return 'Bezeichnung muss angegeben werden.';

        return errorMessage;
      };
    }
  });

  _exports.default = _default;
});