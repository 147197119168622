define("digiprax-web/templates/patients/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f8ZMdfra",
    "block": "{\"symbols\":[],\"statements\":[[15,\"patients/edit/edit\",[]],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "digiprax-web/templates/patients/new.hbs"
    }
  });

  _exports.default = _default;
});