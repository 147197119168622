define("digiprax-web/routes/patients/edit/invoices/index", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      // console.log('from.patient_id = ' + (transition.from ? transition.from.parent.parent.params.patient_id : null) + ', to.patient_id = ' + transition.to.parent.parent.params.patient_id);
      if (transition.to.parent.parent.params.patient_id == null) return this.transitionTo('patients.overview');
      return this._super(transition);
    },
    model: function model(params, transition) {
      // if (transition.from &&
      //     transition.from.parent.parent.params.patient_id != transition.to.parent.parent.params.patient_id &&
      //     transition.from.parent.parent.params.patient_id == localStorage.getItem('lastViewedPatientId')) {
      //     return this._invoicesPromise(this.set('patientId', transition.from.parent.parent.params.patient_id), defer());
      // }
      return this._invoicesPromise(this.set('patientId', transition.to.parent.parent.params.patient_id), Ember.RSVP.defer());
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('patient', this.get('store').peekRecord('patient', this.get('patientId')));

      if (controller.get('patient.id') != 'new') {
        localStorage.setItem('peru.lastViewedPatientId', '"' + controller.get('patient.id') + '"');
        localStorage.setItem('lastViewedPatientId', controller.get('patient.id'));
      } // case reload ...


      controller.get('application').setActiveNavLink('linkTo.allerechnungen');
    },
    _invoicesPromise: function _invoicesPromise(patientId, deferred) {
      var _this = this;

      var loadInvoices = function loadInvoices(patient) {
        _this.get('store').query('invoice', {
          patientId: patientId
        }).then(function (_invoices) {
          // patient.set('invoices', this.get('store').peekAll('invoice').filter((invoice) => {
          //     return (invoice.get('patientId') == patient.id) &&
          //            ([null,'new'].indexOf(invoice.get('id')) == -1) &&
          //            (invoice.get('extraInfo.finalized') != null || invoice.get('extraInfo.canceledAt') != null || invoice.get('printedAt') != null);
          //   }));
          // deferred.resolve(patient.get('sortedInvoices'));
          var sort = function sort(i1, i2) {
            // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
            return i2.get('date') - i1.get('date') >= 0 ? 1 : -1;
          };

          var sortedInvoicesForPractice = _this.get('store').peekAll('invoice').filter(function (invoice) {
            return invoice.get('patientId') == patient.id && [null, 'new'].indexOf(invoice.get('id')) == -1 && (invoice.get('extraInfo.finalized') != null || invoice.get('extraInfo.canceledAt') != null || invoice.get('printedAt') != null);
          }); //.sort(sort);
          // deferred.resolve(patient.set('invoices', sortedInvoicesForPractice));


          _this.get('store').query('invoice-for-business', {
            patientId: patientId,
            domain: 'business'
          }).then(function (_invoices) {
            var sortedInvoices = _this.get('store').peekAll('invoice-for-business').filter(function (invoice) {
              return invoice.get('patientId') == patient.id && [null, 'new'].indexOf(invoice.get('id')) == -1 && (invoice.get('extraInfo.finalized') != null || invoice.get('extraInfo.canceledAt') != null || invoice.get('printedAt') != null);
            }); //.sort(sort);


            deferred.resolve(patient.set('invoices', sortedInvoicesForPractice.concat(sortedInvoices).sort(sort)));
          });
        });
      };

      var loadedPatient = this.get('store').peekRecord('patient', patientId);
      if (loadedPatient != null) loadInvoices(loadedPatient);else this.store.findRecord('patient', patientId).then(function (patient) {
        return loadInvoices(patient);
      });
      return _emberData.default.PromiseArray.create({
        promise: deferred.promise
      });
    }
  });

  _exports.default = _default;
});