define("digiprax-web/mixins/dialogs-util", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    dirtyEntryObserver: Ember.observer('model.entriesDirty', function (_sender, _key, _value, _rev) {
      var curFocusedTreatment = Ember.$('#' + this.get('lastFocusedInputId')); // console.log('dirtyObserver: cur = ' + this.get('lastFocusedInputId') + ', css = ' + (curFocusedTreatment != null ? curFocusedTreatment.closest('md-input-container').attr('class') : ''));

      Ember.run.schedule("afterRender", function () {
        if (curFocusedTreatment != null) {
          if ((curFocusedTreatment.closest('md-input-container').attr('class') || 'md-focused').indexOf('md-focused') == -1) {
            // console.log('dirtyObserver: re-establish focus for: ' + this.get('lastFocusedInputId'));
            curFocusedTreatment.focus();
          }
        }
      });
    }),
    focusInput: function focusInput(treatment, focusedInput) {
      // console.log('focusInput: next = ' + focusedInput.id + ', prev = ' + this.get('lastFocusedInputId'));
      if (Ember.$('#' + this.get('lastFocusedInputId')).closest('md-input-container').hasClass('md-focused')) {
        // console.log('focusInput: unsetting focus for ' + this.get('lastFocusedInputId'));
        Ember.$('#' + this.get('lastFocusedInputId')).closest('md-input-container').toggleClass('md-focused');
        Ember.$('#' + this.get('lastFocusedInputId')).closest('md-input-container').toggleClass('md-input-focused');
      }

      Ember.run.schedule("afterRender", function () {
        Ember.$('#' + focusedInput.id).closest('md-input-container').toggleClass('md-focused');
        Ember.$('#' + focusedInput.id).closest('md-input-container').toggleClass('md-input-focused');
      });
      this.set('lastFocusedInputId', focusedInput.id);
    },
    focusNewInput: function focusNewInput(_focusedInput) {
      var _this = this;

      // console.log('focusNewInput: cur = ' + focusedInput.id + ', prev = ' + this.get('lastFocusedInputId'));
      if (this.get('lastFocusedInputId') != null) {
        Ember.run.schedule("afterRender", function () {
          Ember.$('#' + _this.get('lastFocusedInputId')).closest('md-input-container').toggleClass('md-focused');
          Ember.$('#' + _this.get('lastFocusedInputId')).closest('md-input-container').toggleClass('md-input-focused');
        });
      }
    },
    focusLine: function focusLine(treatment, lineSelector) {
      if (treatment.get('date') == null) {
        if (treatment.isDirty()) {
          // auto set date so user doesn't have to (but can change)
          var treatmentDate;
          var curLineData = lineSelector;
          var previousLineData = curLineData.attr('name') == 'treatment-new-0' ? [] : lineSelector.prev(); // if ([null,''].indexOf((treatmentDate = curLineData.find('li input[id$=-date]').val())) == -1) {

          if ([null, ''].indexOf(treatmentDate = curLineData.find('li input[placeholder=Datum]').val()) == -1) {
            this.set('lastFocusedTreatmentDate', treatmentDate);
            treatment.set('dateFmtd', treatmentDate);
          } else if (this.get('lastFocusedTreatmentDate') != null) {
            treatment.set('dateFmtd', this.get('lastFocusedTreatmentDate')); // } else if ((previousLineData.length >= 1) && ([null,''].indexOf((treatmentDate = previousLineData.find('li input[id$=-date]').val())) == -1)) {
          } else if (previousLineData.length >= 1 && [null, ''].indexOf(treatmentDate = previousLineData.find('li input[placeholder=Datum]').val()) == -1) {
            this.set('lastFocusedTreatmentDate', treatmentDate);
            treatment.set('dateFmtd', this.get('lastFocusedTreatmentDate'));
          } else {
            this.set('lastFocusedTreatmentDate', (0, _moment.default)().format('DD.MM.YYYY'));
            treatment.set('dateFmtd', this.get('lastFocusedTreatmentDate'));
          }
        }
      } else {
        this.set('lastFocusedTreatmentDate', treatment.get('dateFmtd'));
      }
    },
    _openDialogWithFocusedTreatment: function _openDialogWithFocusedTreatment(event, dialogKey, errorCallback) {
      this._openDialogWithFocusedEntry(event, 'treatment', dialogKey, errorCallback);
    },
    _openDialogWithFocusedEntry: function _openDialogWithFocusedEntry(event, entryKey, dialogKey, errorCallback) {
      var treatmentAvailable = !(typeof this.get('focusedTreatment') == "undefined" || this.get('focusedTreatment') == null);

      if (!treatmentAvailable) {
        treatmentAvailable = this._findFirstClickOpensDialogEntry(event, entryKey);
      } // test sentry: this.get('application').debugRemote('treatmentAvailable = ' + treatmentAvailable);


      if (treatmentAvailable) {
        this._openEntryDialog(this.get('focusedTreatment'), entryKey, dialogKey, event);
      } else {
        errorCallback();
      }
    },
    _openTreatmentDialog: function _openTreatmentDialog(treatment, dialogKey, event) {
      this._openEntryDialog(treatment, 'treatment', dialogKey, event);
    },
    _openEntryDialog: function _openEntryDialog(entry, entryKey, dialogKey, event) {
      this.set('selected' + entryKey.capitalize(), entry); // this.set('dialogOrigin', $(event.target));
      // this.set('dialogOrigin', 'ul[data-name=' + $(event.target).closest('ul').attr('data-name') + ']');

      if (entry.get('tmpId') == null) {
        // this.set('dialogOrigin', $('ul[data-name='+entryKey+'-saved-' + this.get('model.sortedEntries').toArray().indexOf(entry) + ']'));
        var idx = (this.get('model.sortedEntries') || []).toArray().indexOf(entry);

        if (idx == -1) {
          // this.get('application').debugRemote('Did not find focusedEntry[id=' + entry.get('id') + '] in sortedEntries[length=' + (this.get('model.sortedEntries') || []).toArray().length + '] of invoice[id=' + this.get('model.id') + ']');
          idx = 0;
        }

        if (Ember.$('ul[data-name=' + entryKey + '-saved-' + idx + ']').length >= 1) {
          this.set('dialogOrigin', 'ul[data-name=' + entryKey + '-saved-' + idx + ']');
        } else {
          this.get('application').debugRemote('Did not find DOM-Element (ul) focusedEntry[id=' + entry.get('id') + ']');
          this.set('dialogOrigin', Ember.$(event.target));
        } // => Uncaught TypeError: Cannot read property 'getBoundingClientRect' of undefined
        // this.set('dialogOrigin', '#doesnotexist');

      } else {
        // this.set('dialogOrigin', $('ul[data-name='+entryKey+'-new-' + this.get('model.sortedNewEntryPlaceholders').toArray().indexOf(entry) + ']'));
        var _idx = (this.get('model.sortedNewEntryPlaceholders') || []).toArray().indexOf(entry);

        if (_idx == -1) {
          // this.get('application').debugRemote('Did not find focusedEntry[tmpId=' + entry.get('tmpId') + '] in sortedNewEntryPlaceholders[length=' + (this.get('model.sortedNewEntryPlaceholders') || []).toArray().length + '] of invoice[id=' + this.get('model.id') + ']');
          _idx = 0;
        }

        if (Ember.$('ul[data-name=' + entryKey + '-new-' + _idx + ']').length >= 1) {
          this.set('dialogOrigin', 'ul[data-name=' + entryKey + '-new-' + _idx + ']');
        } else {
          this.get('application').debugRemote('Did not find DOM-Element (ul) focusedEntry[tmpId=' + entry.get('tmpId') + ']');
          this.set('dialogOrigin', Ember.$(event.target));
        } // => Uncaught TypeError: Cannot read property 'getBoundingClientRect' of undefined
        // this.set('dialogOrigin', '#doesnotexist');

      }

      this.set(dialogKey, new Date());
    },
    // requires _focusLine
    _findFirstClickOpensDialogEntry: function _findFirstClickOpensDialogEntry(event, entryKey) {
      var entryAvailable = false;
      var focusedEntry;

      if (Ember.$(event.target)[0].type == 'button') {
        // first click on dialog-button when no entry focused previously
        var focusLastExistingEntry = false;

        if (focusLastExistingEntry) {
          if (this.get('model.isNew') || (focusedEntry = this.get('model.sorted' + entryKey.capitalize().pluralize())[this.get('model.sorted' + entryKey.capitalize().pluralize()).length - 1]) == null) {
            focusedEntry = this.get('model.sortedNew' + entryKey.capitalize() + 'Placeholders').toArray()[this.get('model.sortedNew' + entryKey.capitalize() + 'Placeholders.length') - 1];
          }
        } else {
          if (this.get('model.sortedNew' + entryKey.capitalize() + 'Placeholders.length') >= 2) {
            focusedEntry = this.get('model.sortedNew' + entryKey.capitalize() + 'Placeholders').toArray()[this.get('model.sortedNew' + entryKey.capitalize() + 'Placeholders.length') - 2];
          } else {
            focusedEntry = this.get('model.sortedNew' + entryKey.capitalize() + 'Placeholders').toArray()[0];
          }
        }
      } else {
        // first click on any form-field is one that opens dialog
        var entryKeys = Ember.$(event.target).closest('div').attr('name').match(/([^-]+)-([^-])$/);

        if (entryKeys[1] == 'new') {
          focusedEntry = this.get('model.sortedNew' + entryKey.capitalize() + 'Placeholders').toArray()[parseInt(entryKeys[2])];
        } else {
          focusedEntry = this.get('model.sorted' + entryKey.capitalize().pluralize())[parseInt(entryKeys[2])];
        }
      }

      if (entryAvailable = focusedEntry != null) {
        //focusedEntry.set('date', moment().format('DD.MM.YYYY'));
        this.set('focusedTreatment', focusedEntry);
        this.set('focused' + entryKey.capitalize(), focusedEntry);

        this._focusLine(focusedEntry, Ember.$('[data-name=' + entryKey + '-new-' + (this.get('model.new' + entryKey.capitalize() + 'Placeholders.length') - 1) + ']'));
        /*Ember.run.schedule("afterRender",() => {
            $('[data-name='+entryKey+'-new-'+(this.get('model.new'+entryKey.capitalize()+'Placeholders.length')-1)+']').find('li input')[1].focus();
        });*/

      }

      return entryAvailable;
    }
  });

  _exports.default = _default;
});