define("digiprax-web/routes/patients/statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import moment from 'moment';
  var _default = Ember.Route.extend({
    // model(params, transition) {
    //   return this.store.findRecord('month-stat', moment().format('YYYYM'));
    // },
    setupController: function setupController(controller, model) {
      var result = this._super(controller, model);

      controller.set('statistics', this.store.queryRecord('statistic', {})); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.statistics');
      return result;
    }
  });

  _exports.default = _default;
});