define("digiprax-web/routes/registration/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_params, _transition) {
      if (this.newUser != null) return this.newUser;
      return this.set('newUser', this.store.createRecord('user', {}));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('success', location.search.match(/[?&]success=true/));
      var errorKeys = ['addressZip', 'phoneLandline', 'email', 'password', 'password_confirmation'].filter(function (k) {
        return controller.get(k) != null;
      });

      if (errorKeys.length >= 1) {
        controller.setErrors(errorKeys.map(function (k) {
          return {
            message: k + ": " + decodeURIComponent(controller.get(k))
          };
        }));
      }
    }
  });

  _exports.default = _default;
});