define("digiprax-web/helpers/present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.present = present;
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function present(params, _hash) {
    if (params[0] == null) return false;

    if (typeof params[0] == 'string') {
      return params[0].length;
    }

    if (_typeof(params[0]) == 'object') {
      return params[0].length ? params[0].length >= 1 : Object.keys(params[0]).length >= 1;
    }

    return true;
  }

  var _default = Ember.Helper.helper(present);

  _exports.default = _default;
});