define("digiprax-web/models/diagnosis", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    // patient: DS.belongsTo('patient'), // , { inverse: null }),
    // disease_pattern_id: DS.attr('string'),
    patientId: _emberData.default.attr('string'),
    // invoiceId: DS.attr('string'),
    date: _emberData.default.attr('date'),
    notes: _emberData.default.attr('string') // disease_characteristics: DS.attr('raw')

  });
  /*
   createdAt                 | timestamp with time zone | not null                                                                                                                                
   updatedAt                 | timestamp with time zone | not null                                                                                                                                
   deletedAt                 | timestamp with time zone |                                                                                                                                         
  */


  _exports.default = _default;
});