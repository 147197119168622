define("digiprax-web/mixins/patient-editor-p-route", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    hideInvoicedLots: true,
    actions: {
      deleteLot: function deleteLot(lot, idx) {
        var _this = this;

        this.set('application.confirmMessage', {
          message: 'Soll die Bestellung unwiderruflich gelöscht werden?',
          callback: function callback() {
            var lots = _this.get('model.sortedLots');

            lots.splice(idx, 1);

            _this.set('model.lots', lots.concat([]));

            lot.destroyRecord().then(function (_response) {
              lot.unloadRecord(); // $('html, body').animate({scrollTop: 0}, 500);

              _this.get('flashMessages').success('Bestellung erfolgreich gelöscht ...', {
                timeout: 3000
              });
            });
          }
        });
        Ember.$('#confirmMessage').trigger('click');
      },
      removeNewLot: function removeNewLot(lot, _idx) {
        this.get('model.newLotPlaceholders').removeObject(lot);
      }
    },
    _initPatientRelations: function _initPatientRelations(patient, callbacks) {
      var _this2 = this;

      this.get('store').findAll('product').then(function (_products) {
        var sort = function sort(pR1, pR2) {
          // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
          return pR2.get('date') - pR1.get('date') >= 0 ? -1 : 1;
        };

        var sorts = {
          /*diagnosis: sort, */
          lot: sort
        };
        var patientRelationTypes = [
        /*'diagnosis', */
        'lot'];

        _this2._loadPatientRelationsSequentially(patient, patientRelationTypes, sorts, callbacks);
      });
    },
    _loadPatientRelationsSequentially: function _loadPatientRelationsSequentially(patient, patientRelationTypes, sorts, callbacks) {
      this._loadPatientRelations(patient, patientRelationTypes[0], sorts[patientRelationTypes[0]], patientRelationTypes, sorts, callbacks);
    },
    _loadPatientRelations: function _loadPatientRelations(patient, patientRelationType, sort, patientRelationTypes, sorts, callbacks) {
      var _this3 = this;

      // this.get('store').findAll(patientRelationType).then(results => this._setPatientRelation(patient, patientRelationType, results.filter((patientRelation) => {
      this.get('store').query(patientRelationType, {
        patientId: patient.id
      }).then(function (results) {
        return _this3._setPatientRelation(patient, patientRelationType, results.filter(function (patientRelation) {
          if (patientRelation.constructor.modelName == 'lot' && _this3.get('hideInvoicedLots')) {
            if (patientRelation.get('invoice_id') != null) return false;
          }

          return patientRelation.get('patient_id') == patient.id;
        }), sort, patientRelationTypes, sorts, callbacks);
      });
    },
    _setPatientRelation: function _setPatientRelation(patient, patientRelationType, patientRelationList, sort, patientRelationTypes, sorts, callbacks) {
      patient.set((0, _emberInflector.pluralize)(patientRelationType), patientRelationList.sort(sort));
      if (callbacks && callbacks[(0, _emberInflector.pluralize)(patientRelationType)]) callbacks[(0, _emberInflector.pluralize)(patientRelationType)](patientRelationList.sort(sort));
      if (patientRelationTypes.length >= 2) this._loadPatientRelationsSequentially(patient, patientRelationTypes.slice(1), sorts, callbacks);
    }
  });

  _exports.default = _default;
});