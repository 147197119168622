define("digiprax-web/services/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    actions: {
      noAction: function noAction() {
        console.log('no action defined for this flash ...');
        return true;
      }
    },
    info: function info(message, config, action, actionContext) {
      /*if (this.get('application.flashes') == null) {
        this.set('application.flashes', EmberObject.create({queue: []}));
      }*/
      var messageTs = new Date();
      this.set('application.flashes.queue', this.get('application.flashes.queue').concat([{
        messageTs: messageTs,
        message: message,
        config: config,
        action: action || this.actions.noAction,
        actionContext: actionContext || {},
        class: 'flash-message info'
      }]));
      Ember.run.later(this._removeFlashMessage(messageTs), config.timeout || 3000);
      return messageTs;
    },
    success: function success(message, config, action, actionContext) {
      /*if (this.get('application.flashes') == null) {
        this.set('application.flashes', EmberObject.create({queue: []}));
      }*/
      var messageTs = new Date();
      this.set('application.flashes.queue', this.get('application.flashes.queue').concat([{
        messageTs: messageTs,
        message: message,
        config: config,
        action: action || this.actions.noAction,
        actionContext: actionContext || {},
        class: 'flash-message success'
      }]));
      Ember.run.later(this._removeFlashMessage(messageTs), config.timeout || 3000);
      return messageTs;
    },
    error: function error(message, config, action, actionContext) {
      /*if (this.get('application.flashes') == null) {
        this.set('application.flashes', EmberObject.create({queue: []}));
      }*/
      var messageTs = new Date();
      this.set('application.flashes.queue', this.get('application.flashes.queue').concat([{
        messageTs: messageTs,
        message: message,
        config: config,
        action: action || this.actions.noAction,
        actionContext: actionContext || {},
        class: 'flash-message error'
      }]));
      Ember.run.later(this._removeFlashMessage(messageTs), config.timeout || 30000);
      return messageTs;
    },
    _removeFlashMessage: function _removeFlashMessage(messageTs) {
      var _this = this;

      return function () {
        _this.set('application.flashes.queue', _this.get('application.flashes.queue').filter(function (m) {
          return m.messageTs != messageTs;
        }));
      };
    }
  });

  _exports.default = _default;
});