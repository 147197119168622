define("digiprax-web/controllers/patients/edit/invoices/edit", ["exports", "digiprax-web/controllers/base", "digiprax-web/mixins/invoice-dialogs", "digiprax-web/mixins/auto-complete-patient-search"], function (_exports, _base, _invoiceDialogs, _autoCompletePatientSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_invoiceDialogs.default, _autoCompletePatientSearch.default, {
    clipboard: Ember.inject.service(),
    invoiceEditor: Ember.inject.service(),
    actions: {
      checkCopy: function checkCopy(treatment) {
        return this.get('clipboard').checkCopyTreatment(treatment);
      },
      paste: function paste() {
        var _this2 = this;

        Ember.run.schedule("afterRender", function () {
          _this2.set('choosePaste', false);
        });
        return this.get('clipboard').pasteTreatments(this.get('model'));
      },
      saveInvoice: function saveInvoice() {
        this.get('invoiceEditor').saveInvoice(this.get('model'), this.get('model.newTreatmentPlaceholders').toArray(), this);
      },
      replaceComma: function replaceComma() {
        var _this = Ember.$('input:focus');

        _this.val(_this.val().replace(",", "."));

        return false;
      },
      checkCreateTariffChain: function checkCreateTariffChain() {
        if (event.button != 0
        /* || event.which != 0*/
        ) {
            this.set('choosePaste', true);
          }

        return false;
      },
      cancelPaste: function cancelPaste() {
        this.get('clipboard').clear();
        this.set('choosePaste', false);
      },
      newTariffChain: function newTariffChain() {
        this.set('choosePaste', false); // let name = prompt('Bitte geben Sie einen Namen für die Abrechnungskette ein.', '');
        // this.invoiceEditor.saveNewCustomTariffChain(name);

        this.set('promptNewCustomTariffChainName', true);
        return false;
      },
      saveNewCustomTariffChain: function saveNewCustomTariffChain() {
        this.set('promptNewCustomTariffChainName', false);
        return this.invoiceEditor.saveNewCustomTariffChain(this.newCustomTariffChainName);
      }
    },
    inValid: function inValid(errors) {
      // $('html, body').animate({scrollTop: 0}, 500);
      var errorHtml = '';
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = errors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var error = _step.value;
          errorHtml += '<li>' + error + '</li>';
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.get('flashMessages').error('<ul>' + errorHtml + '</ul>', {
        timeout: 10000
      });
    },
    afterSave: function afterSave(_invoice) {
      // $('html, body').animate({scrollTop: 0}, 500);
      this.get('flashMessages').success('Rechnung gespeichert', {
        timeout: 1000
      });
    }
  });

  _exports.default = _default;
});