define("digiprax-web/helpers/in-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // params[0] ... value (not null)
  // params[1] ... array
  // params[2](optional) ... params[1][0..n][params[2]] (attr-name)
  var _default = Ember.Helper.extend({
    compute: function compute(params, _hash) {
      if (params[1] == null) return false;

      if (params.length == 3) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = params[1][Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var o = _step.value;
            if (params[0] == o[params[2]]) return true;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return false;
      } else {
        return params[1].indexOf(params[0]) >= 0 || params[0] != null && (params[1].indexOf(params[0].toString()) >= 0 || params[1].indexOf(parseInt(params[0])) >= 0);
      }
    }
  });

  _exports.default = _default;
});