define("digiprax-web/mixins/patient-dialogs", ["exports", "ember-data", "moment", "digiprax-web/mixins/dialogs-util"], function (_exports, _emberData, _moment, _dialogsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_dialogsUtil.default, {
    diagnosticAreas: Ember.computed(function () {
      return this._diseasePatternsPromise(Ember.RSVP.defer());
    }),
    selectedDiseasePattern: null,
    diagnosisText: Ember.computed('selectedTreatment', {
      get: function get(_key) {
        var diagnosisText;

        if ((diagnosisText = this.get('_diagnosisText')) == null || this.get('_curTreatmentId') != this.get('selectedTreatment.id')) {
          // eslint-disable-next-line ember/no-side-effects
          this.set('_curTreatmentId', this.get('selectedTreatment.id')); // this.set('_diagnosisText', (diagnosisText = this.get('model.diagnoses').map((d) => d.get('notes')).join(', ')));
          // eslint-disable-next-line ember/no-side-effects

          this.set('_diagnosisText', diagnosisText = this.get('model.treatments').map(function (t) {
            return t.get('invoiceId') == null && t.get('diagnosisText') != null && t.get('diagnosisText').trim() != '' ? t.get('diagnosisText').trim() : null;
          }).compact().uniq().join(', '));
        }

        return diagnosisText;
      },
      set: function set(_key, value) {
        this.set('_diagnosisText', value);
        return value;
      }
    }),
    actions: {
      focusInput: function focusInput(treatment, eventPlaceholder) {
        // this.focusInput(treatment, eventPlaceholder);
        this.focusInput(treatment, (eventPlaceholder || event).target);
        return false;
      },
      focusOutInput: function focusOutInput(treatment, eventPlaceholder) {
        if (this.get('focusedTreatment') != treatment) {
          this.set('focusedTreatment', treatment);

          this._focusLine(treatment, Ember.$((eventPlaceholder || event).target).closest('ul'));
        }

        if (this.get('focusedTreatment.date') != null) {
          this.set('lastFocusedTreatmentDate', (0, _moment.default)(this.get('focusedTreatment.date')).format('DD.MM.YYYY')); // console.log('setting lastFocusedTreatmentDate to ' + this.get('lastFocusedTreatmentDate'));
        }

        this.focusNewInput((eventPlaceholder || event).target);
        /* 2018-11: diagnose can be left empty for a treatment - in that case first available diagnose for most recent treatment prior to this treatment is implicitly set
        if ($((eventPlaceholder || event).target).closest('li').attr('name') != 'diagnose') {
          // preset diagnose if user started editing treatment-row
          // if ((treatment.get('diagnosisText') == null || treatment.get('diagnosisText').trim() == '') && (this.get('model.sortedDiagnoses').toArray().length >= 1)) {
          //   if (treatment.isDirty()) treatment.set('diagnosisText', this.get('model.sortedDiagnoses.lastObject.notes'));
          // }
          if ((treatment.get('diagnosisText') == null || treatment.get('diagnosisText').trim() == '') && (this.get('model.sortedTreatments').toArray().length >= 1)) {
            if (treatment.isDirty()) treatment.set('diagnosisText', this.get('model.sortedTreatments.lastObject.diagnosisText'));
          }
        }
        */

        /*let touchNumNewTreatmentsDirty = */

        this.get('model.numNewTreatmentsDirty'); // prevents X to remove last clean line

        return false;
      },
      checkDoneEditInitReception: function checkDoneEditInitReception(eventPlaceholder) {
        if ((eventPlaceholder || window.event || {}).keyCode == 13) {
          if (this.model._pikaDayInitReceptionInput) this.model._pikaDayInitReceptionInput.blur();
          this.model.set('showPikaDayInitReception', false);
        }
      },
      checkDoneEditBirthdate: function checkDoneEditBirthdate(eventPlaceholder) {
        if ((eventPlaceholder || window.event || {}).keyCode == 13) {
          if (this.model._pikaDayBirthdateInput) this.model._pikaDayBirthdateInput.blur();
          this.model.set('showPikaDayBirthdate', false);
        }
      },
      openDiagnosenDialog: function openDiagnosenDialog(treatment, eventPlaceholder) {
        if (treatment == null) {
          this._openDialogWithFocusedTreatment(eventPlaceholder || event, 'showDiagnosenDialog', function () {
            alert('Bitte wählen Sie zuerst ein Eingabe-Element aus einer Behandlungs-Zeile für die Diagnosen-Zuordnung.');
          });
        } else {
          this._openTreatmentDialog(treatment, 'showDiagnosenDialog', eventPlaceholder || event);
        }

        return false;
      },
      closeDiagnosenDialog: function closeDiagnosenDialog() {
        this.set('showDiagnosenDialog', false);
        return false;
      },
      showDiseasePatterns: function showDiseasePatterns(idx, event) {
        if (Ember.$(event.target).closest('.kettenKategorie').hasClass('current')) {
          Ember.$('.kettenKategorie').removeClass('current');
          this.set('selectedDiseasePatternIdxPath', [idx, -1]);
        } else {
          // this.set('selecteDiagnosticArea', this.get('diagnosticAreas').toArray()[idx]);
          Ember.$('.kettenKategorie').removeClass('current');
          Ember.$(event.target).closest('.kettenKategorie').addClass('current');
        }
      },

      /*
       * pro treatment 1 diagnose
       * diagnosis hat [date, notes] ist wrapper für extraInfo
       * diagnosis.notes ist der komplette text
       * diagnosisText ist feld in extraInfo
       *
       * diagnosisText:
       *  1) treatment.extraInfo
       *     { diseasePatterns: [{ id: ..., characteristics: ... }],
       *       diagnoseParts: [] } // diagnoses.join(', ')
       *
       * diagnosePart ... { diagnosisText: '...', diseasePattern: null }
       */
      appendDiseasePattern: function appendDiseasePattern(diseasePattern) {
        if (this.get('focusedTreatment.date') == null) this.set('focusedTreatment.dateTotal', (this.get('lastFocusedTreatmentDate') != null ? (0, _moment.default)(this.get('lastFocusedTreatmentDate'), 'DD.MM.YYYY') : (0, _moment.default)()).toDate());

        var checkedValuesCSV = this._checkedValuesCSV(this.get('selectedTreatment.diseaseCharacteristics'));

        var diseasePatternText = diseasePattern.get('name') + (checkedValuesCSV != '' ? ' (' + checkedValuesCSV + ')' : '');
        /*Ember.run.schedule("afterRender",() => {
            $('html, body').animate({scrollTop: this.get('firstModifiedInput').offset().top - $('.nav').height()}, 500);
          });*/
        // var previousDiagnosisText = this.get('selectedTreatment.diagnosisText');

        var diagnosePartsAvailable = false;
        var extraInfoAvailable = !(typeof this.get('selectedTreatment.extraInfo') == "undefined" || this.get('selectedTreatment.extraInfo') == null);
        if (extraInfoAvailable) diagnosePartsAvailable = !(typeof this.get('selectedTreatment.extraInfo.diagnoseParts') == "undefined" || this.get('selectedTreatment.extraInfo.diagnoseParts') == null);

        if (diagnosePartsAvailable) {
          if (this.get('selectedTreatment.extraInfo.diagnoseParts').find(function (dP) {
            return dP.diseasePattern != null && dP.diseasePattern.id == diseasePattern.get('id');
          }) == null) {
            // TODO check diseaseCharacteristics
            var newDiagnosePart = {
              text: diseasePatternText,
              diseasePattern: {
                id: diseasePattern.id,
                characteristics: checkedValuesCSV
              }
            }; // notify observers - todo: arrayproxy

            this.set('selectedTreatment.extraInfo.diagnoseParts', this.get('selectedTreatment.extraInfo.diagnoseParts').concat([newDiagnosePart])); // this.get('selectedTreatment.extraInfo.diagnoseParts').push(newDiagnosePart);
          }
        } else if (extraInfoAvailable) {
          this.set('selectedTreatment.extraInfo.diagnoseParts', [{
            text: diseasePatternText,
            diseasePattern: {
              id: diseasePattern.id,
              characteristics: checkedValuesCSV
            }
          }]);
        } else {
          this.set('selectedTreatment.extraInfo', {
            diagnoseParts: [{
              text: diseasePatternText,
              diseasePattern: {
                id: diseasePattern.id,
                characteristics: checkedValuesCSV
              }
            }]
          });
        }

        if (this.get('selectedTreatment.diagnosisText') == null || this.get('selectedTreatment.diagnosisText').trim() == '') {
          this.set('selectedTreatment.diagnosisText', diseasePatternText); // diagnosis.get('notes'));
        } else {
          var currentText = this.get('selectedTreatment.diagnosisText').trim(); // if (previousDiagnosisText != null) currentText = currentText.replace(new RegExp('[ ,]*' + previousDiagnosisText.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")), '');

          var newDiagnosisText = currentText + (currentText.match(/,\s*$/) == null ? ', ' : '') + diseasePatternText;
          this.set('selectedTreatment.diagnosisText', newDiagnosisText);
        }

        this.send('closeDiagnosenDialog'); // return false;
      },
      appendDiagnose: function appendDiagnose(eventPlaceholder) {
        this.set('selectedTreatment.diagnosisText', Ember.$((eventPlaceholder || event).target).closest('div.diagnosis-text-field').find('textarea').val());
        this.send('closeDiagnosenDialog');
      }
    },
    _diseasePatternsPromise: function _diseasePatternsPromise(deferred) {
      this.get('store').findAll('disease-pattern').then(function (diseasePatterns) {
        var map = {};
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = diseasePatterns.toArray()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var diseasePattern = _step.value;
            if (map[diseasePattern.get('diagnosticArea')] == null) map[diseasePattern.get('diagnosticArea')] = [];
            map[diseasePattern.get('diagnosticArea')].push(diseasePattern);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        deferred.resolve(Object.keys(map).map(function (diagnosticArea) {
          return Ember.Object.create({
            diagnosticArea: diagnosticArea,
            diseasePatterns: map[diagnosticArea]
          });
        }));
      });
      return _emberData.default.PromiseArray.create({
        promise: deferred.promise
      });
    },
    _focusLine: function _focusLine(treatment, lineSelector) {
      this.focusLine(treatment, lineSelector);
    },
    _checkedValuesJSON: function _checkedValuesJSON(values) {
      var checkedValues = {};

      var _arr = Object.keys(values);

      for (var _i = 0; _i < _arr.length; _i++) {
        var attr = _arr[_i];

        if (values[attr]) {
          checkedValues[attr] = true;
        }
      }

      return checkedValues;
    },
    _checkedValuesCSV: function _checkedValuesCSV(values) {
      var checkedValues = [];

      var _arr2 = Object.keys(values);

      for (var _i2 = 0; _i2 < _arr2.length; _i2++) {
        var attr = _arr2[_i2];

        if (values[attr]) {
          checkedValues.push(attr);
        }
      }

      return checkedValues.join(',');
    }
  });

  _exports.default = _default;
});