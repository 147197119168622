define("digiprax-web/serializers/invoice", ["exports", "digiprax-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(snapshot, _options) {
      var _this = this;

      var json = this._super.apply(this, arguments);
      /*
      json.data.attributes.cost = {
        amount: json.data.attributes.amount,
        currency: json.data.attributes.currency
      };
      */
      // if (json.date.match(/T00:00:00\.[0-9]+Z$/) == null) {
      //   let dateMom = moment(snapshot.attr('date'));
      //   json.date = dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON();
      // }


      delete json.paidAt;
      delete json.patient;
      if (json.extraInfo == null || json.extraInfo == '') delete json.extraInfo;
      if (json.printedAt == null) delete json.printedAt;
      delete json.paidAmount; // json.date = new Date(json.date);
      // json.treatments = snapshot.record.get('treatments').toArray().map(treatment => {return {id: treatment.id, checked: true};});

      if (snapshot.record.get('treatments') != null) json.treatments = snapshot.record.get('treatments').toArray().map(function (treatment) {
        return _this.undecorateTreatment(treatment);
      });
      return json; // return JSON.parse(JSON.stringify(json));
    },
    undecorateTreatment: function undecorateTreatment(treatment) {
      var t = Object.assign(treatment.toJSON(), {
        id: treatment.id,
        checked: true
      });
      delete t.patientId; // delete t.invoiceId;

      delete t.tariffId;
      delete t.tariffSettlementId;
      delete t.date;
      delete t.factor;
      delete t.notes;
      return t;
    }
  });

  _exports.default = _default;
});