define("digiprax-web/serializers/entry", ["exports", "digiprax-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(modelClass, resourceHash) {
      modelClass.eachTransformedAttribute(function (name, type) {
        if (type == 'date') {
          resourceHash[name] = new Date(resourceHash[name]);
        } // resourceHash[camelize(name)] = resourceHash[name];

      });
      var data = {
        id: resourceHash.id,
        type: modelClass.modelName || 'entry',
        attributes: resourceHash
      };
      return {
        data: data
      };
    },
    serialize: function serialize(_snapshot, _options) {
      var json = this._super.apply(this, arguments); // if (json.date.match(/T00:00:00\.[0-9]+Z$/) == null) {
      //   let dateMom = moment(snapshot.attr('date'));
      //   json.date = dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON();
      // }


      return json;
    }
  });

  _exports.default = _default;
});