define("digiprax-web/helpers/mail-sender", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mailSender = mailSender;
  _exports.default = void 0;

  // params[0] ... practice
  // params[1] ... user
  function mailSender(params, _hash) {
    if (params[0] != null && params[0].name != null && params[0].name.trim() != '' && ['1833bc6b-37ae-4712-81a6-fda9f838bf54'].indexOf(params[0].id) == -1) return params[0].name;else return 'HP ' + params[1].firstName + ' ' + params[1].lastName;
  }

  var _default = Ember.Helper.helper(mailSender);

  _exports.default = _default;
});