define("digiprax-web/routes/practice/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    practice: Ember.computed.alias('application.practice'),
    model: function model(_params, _transition) {
      // if ((this.get('application.practice') == null || typeof(this.get('application.practice.then')) == 'function') &&
      //     this.get('application.practiceId') != null) {
      //   return this.get('store').findRecord('practice', this.get('application.practiceId'));
      // }
      // return this._super(params, transition);
      // this.application.showProgressbar();
      return this.get('practice');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.application.hideProgressbar(); // case reload ...

      controller.get('application').setActiveNavLink('linkTo.edit');
    }
  });

  _exports.default = _default;
});