define("digiprax-web/controllers/registration/index", ["exports", "digiprax-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['addressZip', 'phoneLandline', 'email', 'password', 'password_confirmation'],
    registrationWebUrl: Ember.computed(function () {
      var adapter = this.store.adapterFor('user');
      return adapter.get('host') + '/web/users';
    }),
    registrationApiUrl: Ember.computed(function () {
      var adapter = this.store.adapterFor('user');
      return adapter.get('host') + ([null, ''].indexOf(adapter.get('namespace')) == -1 ? '/' : '') + adapter.get('namespace') + '/users';
    }),
    resendConfirmationMailUrl: Ember.computed(function () {
      return _environment.default.APP.resendConfirmationMailUrl;
    }),
    actions: {
      doRegister: function doRegister() {
        var _this = this;

        this.set('errorMsgMap', {});
        if (!this.isValid()) return false;
        var headers = new Headers();
        headers.append('Content-type', 'application/json; charset=UTF-8');
        var requestData = {
          "practice": {
            "name": this.model.get('practiceName'),
            "addressZip": this.model.get('addressZip'),
            "phoneLandline": this.model.get('phoneLandline'),
            "email": this.model.get('email')
          },
          "user": {
            "firstName": this.model.get('firstName'),
            "lastName": this.model.get('lastName'),
            "password": this.model.get('password'),
            "password_confirmation": this.model.get('passwordConfirmation'),
            "privacy": true
          }
        };
        var requestOpts = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(requestData)
        };
        fetch(this.registrationApiUrl, requestOpts).then(function (response) {
          response.json().then(function (registeredUser) {
            if (response.status == 422) {
              // this.setErrors(registeredUser.errors);
              var msgMap = _this.msgMap();

              _this.set('errorMsgMap', [{}].concat(Object.keys(registeredUser.errors)).reduce(function (acc, e) {
                acc[e] = msgMap[e + ": " + registeredUser.errors[e][0]];
                return acc;
              }));

              _this.setErrors(Object.keys(registeredUser.errors).map(function (e) {
                return {
                  message: e + ": " + registeredUser.errors[e][0]
                };
              }));
            } else {
              // this.transitionToRoute('auth.login');
              _this.set('success', true);
            }
          });
          return true;
        }).catch(function (error) {
          alert(JSON.stringify(error)); // this.setErrors(error.map(k => { return {message: k + ": " + decodeURIComponent(this.get(k))}; }));
        }); // this.model.save().then(newUser => {
        //   this.transitionToRoute('auth.login');
        // }, errors => {
        //   // this.set('errors', Object.keys(errors.toArray()).map(errorKey => msgMap[errors.toArray()[errorKey].message] || errors.toArray()[errorKey].message));
        //   this.set('errors', errors.toArray());
        // });
      },
      resendConfirmationMail: function resendConfirmationMail() {
        var _this2 = this;

        this.resendConfirmationMail(this.get('model.email'), function (errors) {
          if (errors == null) _this2.get('flashMessages').success('Bestätigungs-Email wurde erneut gesendet.', {
            timeout: 5000
          });else {
            if (errors[0].code == 404) {
              _this2.get('flashMessages').error('Email-Adresse unbekannt!', {
                timeout: 5000
              });
            } else if (errors[0].code == 422) {
              _this2.get('flashMessages').error(errors[0].detail, {
                timeout: 5000
              });
            } else {
              var errorMsg = JSON.stringify(errors);

              _this2.get('flashMessages').error(errorMsg, {
                timeout: 5000
              });
            }
          }
        });
      }
    },
    resendConfirmationMail: function resendConfirmationMail(email, callback) {
      var headers = new Headers();
      headers.append('Content-type', 'application/json; charset=UTF-8');
      var requestData = {
        email: email
      };
      var requestOpts = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(requestData)
      };
      fetch(this.resendConfirmationMailUrl, requestOpts).then(function (response) {
        response.json().then(function (response) {
          if (response.errors) {
            callback(response.errors);
          } else {
            callback();
          }
        });
        return true;
      }).catch(function (error) {
        return callback(error);
      });
    },
    isValid: function isValid() {
      var _this3 = this;

      var errors = ['firstName', 'lastName', 'addressZip', 'phoneLandline', 'email', 'password', 'password_confirmation', 'privacy'].filter(function (e) {
        return [null, '', false].indexOf(_this3.model.get(Ember.String.camelize(e || '')) || null) >= 0;
      });

      if (errors.length >= 1) {
        var msgMap = this.msgMap();
        this.set('errorMsgMap', [{}].concat(errors).reduce(function (acc, e) {
          acc[e] = msgMap[e + ': can\'t be blank'];
          return acc;
        }));
      }

      return Object.keys(this.errorMsgMap || {}).length == 0;
    },
    msgMap: function msgMap() {
      return {
        'firstName: can\'t be blank': 'Vorname muß angegeben werden',
        'lastName: can\'t be blank': 'Nachname muß angegeben werden',
        'addressZip: has invalid format': 'Ungültiges PLZ-Format',
        'addressZip: can\'t be blank': 'PLZ muß angegeben werden',
        'phoneLandline: can\'t be blank': 'Telefonnummer muß angegeben werden',
        'phoneLandline: has invalid format': 'Ungültiges Telefonnummer-Format',
        'email: can\'t be blank': 'Emailadresse muß angegeben werden',
        'email: can\'t be blank, can\'t be blank': 'Emailadresse muß angegeben werden',
        'email: has already been taken': 'Emailadresse ist schon im System angemeldet',
        'password: can\'t be blank': 'Passwort muß angegeben werden',
        'password: should be at least %{count} character(s)': 'Passwort muß aus mindestens 6 Zeichen bestehen',
        'password_confirmation: can\'t be blank': 'Passwortbestätigung muss übereinstimmen',
        'password_confirmation: does not match confirmation': 'Passwortbestätigung muss übereinstimmen',
        'password_confirmation: can\'t be blank, does not match confirmation': 'Passwortbestätigung muss übereinstimmen',
        'password_confirmation: does not match confirmation, can\'t be blank': 'Passwortbestätigung muss übereinstimmen',
        'privacy: can\'t be blank': 'Datenschutzerklärung muß akzeptiert werden'
      };
    },
    setErrors: function setErrors(errors) {
      var msgMap = this.msgMap(); // this.set('errors', Object.keys(errors).map(errorKey => msgMap[errors[errorKey].message] || errors[errorKey].message));

      this.set('errors', errors.map(function (error) {
        return msgMap[error.message] || error.message;
      }));
    }
  });

  _exports.default = _default;
});