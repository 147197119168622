define("digiprax-web/mixins/auto-complete-entry-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { debounce, schedule } from '@ember/runloop';
  var _default = Ember.Mixin.create({
    application: Ember.inject.controller(),
    // searchTerm: null,
    debounceDelay: 400,
    actions: {
      searchForEntries: function searchForEntries() {
        var searchWordsAnd = this.get('searchTerm').replace(/ +/, ' ').replace(' ', '+');
        Ember.run.debounce(this, this._searchForEntries(searchWordsAnd, this.get('searchTerm'), null
        /*this.get('curPage')*/
        , this.get('selectedYear')), this.debounceDelay);
      },
      incrementPage: function incrementPage() {
        var page = this.get('curPage');
        var max = this.get('pageLimit');

        if (page < max) {
          this.set('curPage', page + 1);
          var searchWordsAnd = this.get('searchTerm').replace(/ +/, ' ').replace(' ', '+');
          Ember.run.debounce(this._searchForEntries(searchWordsAnd, this.get('searchTerm'), page, this.get('selectedYear')), this.debounceDelay);
        }
      },
      decrementPage: function decrementPage() {
        var page = this.get('curPage');

        if (page > 1) {
          this.set('curPage', page - 1);
          var searchWordsAnd = this.get('searchTerm').replace(/ +/, ' ').replace(' ', '+');
          Ember.run.debounce(this._searchForEntries(searchWordsAnd, this.get('searchTerm'), page, this.get('selectedYear')), this.debounceDelay);
        }
      }
    },
    _searchForEntries: function _searchForEntries(searchWordsAnd, searchTerm, page, year) {
      var _this = this;

      return function () {
        if (searchTerm == _this.get('searchTerm')) {
          console.debug("Searching for ... " + _this.get('searchTerm'));

          if (page == null) {
            _this.set('curPage', 1);
          }

          var constraints = {
            query: 'q_and=' + searchWordsAnd,
            page: _this.get('curPage'),
            limit: _this.get('pageLimit')
          };
          if (year != null) constraints.year = year;

          _this.set('searching', true);

          var searchModel = _this.toString().match(/controller:practice\/([^:]+)/)[1];

          _this.get('store').queryRecord(searchModel, constraints).then(function (cB) {
            console.debug('Search OK ...');

            _this.set('searching', false);

            _this.set('journal', cB);
            /*let _initNumNewEntriesDirty = */


            _this.get('journal.numNewEntriesDirty');
          });
        }
      };
    }
  });

  _exports.default = _default;
});