define("digiprax-web/controllers/customers/edit/profile", ["exports", "digiprax-web/controllers/patients/edit/profile"], function (_exports, _profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _profile.default.extend({});

  _exports.default = _default;
});