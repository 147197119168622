define("digiprax-web/initializers/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'flashMessages', 'service:flash-messages');
    application.inject('component', 'flashMessages', 'service:flash-messages');
  }
});