define("digiprax-web/models/account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nettoAccountNumbers = _nettoAccountNumbers;
  _exports.default = void 0;

  function _nettoAccountNumbers() {
    return ['110', '120', '130', '140', '210', '211', '212', '220', '221', '230', '231', '232', '233', '234', '235', '240', '241', '242', '243', '250', '251', '252', '260', '272', '280', '281', '290', '291', '300', '310'];
  }

  var _default = _emberData.default.Model.extend({
    account_number: _emberData.default.attr('string'),
    account_designation: _emberData.default.attr('string'),
    is_debit: _emberData.default.attr('boolean'),
    netto: Ember.computed('account_number', function () {
      // this.store.adapterFor('entry').application.get('practice.liable_to_sales_tax')
      return this.nettoAccountNumbers().indexOf(this.account_number) >= 0; // return this.notNettoAccountNumbers().indexOf(this.account_number) == -1;
    }),
    nettoAccountNumbers: function nettoAccountNumbers() {
      return _nettoAccountNumbers();
    },
    notNettoAccountNumbers: function notNettoAccountNumbers() {
      return ['111', '112', '261', '262', '270', '273'];
    }
  });
  /*
   createdAt                 | timestamp with time zone | not null
   updatedAt                 | timestamp with time zone | not null
   deletedAt                 | timestamp with time zone |
  */


  _exports.default = _default;
});