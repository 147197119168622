define("digiprax-web/mixins/practice-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ResetScroll from '../../mixins/reset-scroll';
  var _default = Ember.Mixin.create({
    addClass: Ember.on('activate', function () {
      Ember.run.schedule("afterRender", function () {
        // $('body > .ember-view').css('class', 'digiprax-web-app');
        Ember.$('body > .ember-view').attr('class', (Ember.$('body > .ember-view').attr('class') + ' practice-route').trim());
      });
    }),
    remClass: Ember.on('deactivate', function () {
      Ember.run.schedule("afterRender", function () {
        // $('body > .ember-view').css('class', 'digiprax-web-app');
        Ember.$('body > .ember-view').attr('class', (Ember.$('body > .ember-view').attr('class') || '').replace('practice-route', '').trim());
      });
    })
  });

  _exports.default = _default;
});