define("digiprax-web/controllers/customers/edit/invoices/index", ["exports", "digiprax-web/controllers/base", "moment", "digiprax-web/mixins/invoice-dialogs-p", "digiprax-web/mixins/auto-complete-patient-search"], function (_exports, _base, _moment, _invoiceDialogsP, _autoCompletePatientSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = _base.default.extend(_invoiceDialogsP.default, _autoCompletePatientSearch.default, {
    invoiceEditor: Ember.inject.service(),
    showInvoicesForAllPatients: false,
    invoicesForAllPatients: null,
    selectedYear: new Date().getFullYear(),
    years: Ember.computed('application.currentUser', function () {
      return _toConsumableArray(Array((0, _moment.default)().year() - (0, _moment.default)(this.get('application.currentUser.practice_register_date')).year() + 1).keys()).map(function (numPast) {
        return (0, _moment.default)().year() - numPast;
      });
    }),
    filterObserver: Ember.observer('showInvoicesForAllPatients', 'selectedYear', function (_sender, _key, _value, _rev) {
      var _this = this;

      if (this.showInvoicesForAllPatients) {
        var invoices = this.store.query('invoice', {
          year: this.selectedYear
        });
        invoices.then(function (_i) {
          Ember.run.schedule("afterRender", function () {
            _this.application.hideProgressbar();
          });
        }).catch(function (error) {
          _this.application.hideProgressbar();

          _this.get('application.flashMessages').error('Fehler beim Laden der Rechnungen: ' + error, {
            timeout: 5000
          });
        });
        this.application.showProgressbar();
        this.set('invoicesForAllPatients', invoices);
      } else {
        this.set('invoicesForAllPatients', null);
      }
    }),
    actions: {
      // createInvoice(patientId) {
      //   this.transitionToRoute('customers.edit.invoices.new', patientId);
      // },
      restoreInvoice: function restoreInvoice(invoice, _idx) {
        var _this2 = this;

        var unfinalizedInvoice = this.get('store').peekAll('invoice-for-business').filter(function (storedInvoice) {
          return storedInvoice.get('patientId') == invoice.get('patientId') && [null, 'new'].indexOf(storedInvoice.get('id')) == -1 && storedInvoice.get('extraInfo.finalized') == null;
        })[0];
        console.log('open invoice exists = ' + (unfinalizedInvoice != null));

        if (unfinalizedInvoice != null) {
          var messageTs = this.get('flashMessages').error('<ul>Bitte finalisieren sie zuerst die offene Rechnung [<span>' + unfinalizedInvoice.get('id') + '</span>]</ul>', {
            timeout: 10000
          }, this.actions.editInvoiceToRestore, this);
          this.set('invoiceToRestore', {
            unfinalizedInvoice: unfinalizedInvoice,
            messageTs: messageTs
          });
        } else {
          var listener = {
            inValid: function inValid(errors) {
              // $('html, body').animate({scrollTop: 0}, 500);
              var errorHtml = '';
              var _iteratorNormalCompletion = true;
              var _didIteratorError = false;
              var _iteratorError = undefined;

              try {
                for (var _iterator = errors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                  var error = _step.value;
                  errorHtml += '<li>' + error + '</li>';
                }
              } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                  }
                } finally {
                  if (_didIteratorError) {
                    throw _iteratorError;
                  }
                }
              }

              _this2.get('flashMessages').error('<ul>' + errorHtml + '</ul>', {
                timeout: 10000
              });
            },
            afterSave: function afterSave(_invoice) {
              _this2.transitionToRoute('customers.edit.invoices.edit', invoice.get('patientId'), invoice.get('id'));
            }
          };
          this.get('invoiceEditor').restoreInvoice(invoice, listener);
        }
      },
      cancelInvoice: function cancelInvoice(invoice, _idx) {
        var _this3 = this;

        var listener = {
          inValid: function inValid(errors) {
            // $('html, body').animate({scrollTop: 0}, 500);
            var errorHtml = '';
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = errors[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var error = _step2.value;
                errorHtml += '<li>' + error + '</li>';
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }

            _this3.get('flashMessages').error('<ul>' + errorHtml + '</ul>', {
              timeout: 10000
            });
          },
          afterSave: function afterSave(_invoice) {
            Ember.getOwner(_this3).lookup('route:' + _this3.target.currentRouteName).refresh();
            Ember.run.schedule("afterRender", function () {
              // $('html, body').animate({scrollTop: 0}, 500);
              _this3.get('flashMessages').success('Rechnung erfolgreich storniert ...', {
                timeout: 3000
              });
            });
          }
        };
        this.get('invoiceEditor').cancelInvoice(invoice, listener);
      },
      editInvoiceToRestore: function editInvoiceToRestore(self) {
        self.get('flashMessages')._removeFlashMessage(self.get('invoiceToRestore.messageTs'))();

        self.transitionToRoute('customers.edit.invoices.edit', self.get('invoiceToRestore.unfinalizedInvoice.patientId'), self.get('invoiceToRestore.unfinalizedInvoice.id'));
      },
      editInvoice: function editInvoice(invoice) {
        var _this4 = this;

        if ([null, 'practice'].indexOf(invoice.domain) >= 0) {
          this.application.setApiDomain.perform('practice', function () {
            _this4.transitionToRoute('patients.edit.invoices.edit', invoice.patientId, invoice.id);
          });
          return;
        }

        this.transitionToRoute('customers.edit.invoices.edit', invoice.patientId, invoice.id);
      }
    }
  });

  _exports.default = _default;
});