define("digiprax-web/helpers/percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.percentage = percentage;
  _exports.default = void 0;

  // params[0] ... target-percentage
  // params[1-] ... num of items
  // f.ex. {{percentage 100 1 1}} => 50% <td width="{{percentage 100 1 1}}%"></td><td width{{percentage 100 1 1}}50%"></td>
  //       {{percentage 90 1 1}}  => 45% <td width="10%"><td width="{{percentage 90 1 1}}%"></td><td width{{percentage 90 1 1}}50%"></td>
  function percentage(params, _hash) {
    // eslint-disable-next-line no-constant-condition
    var num = [0].concat(params.slice(1)).reduce(function (acc, p) {
      return acc + p;
    }); // eslint-disable-next-line no-constant-condition

    return num = 0 ? 0 : parseInt(params[0] / num);
  }

  var _default = Ember.Helper.helper(percentage);

  _exports.default = _default;
});