define("digiprax-web/models/location-appointments", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ArrayProxy from '@ember/array/proxy';
  var _default = Ember.Object.extend({
    /**
    { all: { 2020-01-20: [Appointment], ... }, 3: {  2020-01-20: [Appointment] }}
     */
    // per day: key: YYYY-MM-DD
    locations: Ember.computed(function () {
      return Ember.Object.create({});
    }),
    dates: Ember.computed('locations', function () {
      var byDate = {};

      var _arr = Object.keys(this.locations);

      for (var _i = 0; _i < _arr.length; _i++) {
        var locationId = _arr[_i];

        var _arr2 = Object.keys(this.locations.get(locationId));

        for (var _i2 = 0; _i2 < _arr2.length; _i2++) {
          var dateKey = _arr2[_i2];
          if (byDate[dateKey] == null) byDate[dateKey] = [];
          byDate[dateKey] = byDate[dateKey].concat(this.locations.get(locationId + '.' + dateKey));
          byDate[dateKey].sort(function (a1, a2) {
            return a1.range[0] > a2.range[0] ? 1 : -1;
          });
        }
      }

      return Ember.Object.create(byDate);
    }),
    addByDate: function addByDate(appointmentsByDate) {
      var _arr3 = Object.keys(this.locations);

      for (var _i3 = 0; _i3 < _arr3.length; _i3++) {
        var locationId = _arr3[_i3];

        var _arr4 = Object.keys(this.locations.get(locationId));

        for (var _i4 = 0; _i4 < _arr4.length; _i4++) {
          var dateKey = _arr4[_i4];
          if (appointmentsByDate[dateKey] == null) appointmentsByDate[dateKey] = [];
          appointmentsByDate[dateKey] = appointmentsByDate[dateKey].concat(this.locations.get(locationId + '.' + dateKey));
          appointmentsByDate[dateKey].sort(function (a1, a2) {
            return a1.range[0] > a2.range[0] ? 1 : -1;
          });
        }
      }
    },
    addAppointment: function addAppointment(locationId, appointment) {
      var location = this.locations.get(locationId);
      if (location == null) location = this.locations.set(locationId, Ember.Object.create({}));
      var dayKey = (0, _moment.default)(appointment.date).format('YYYY-MM-DD');
      var appointmentsForDay = location.get(dayKey);
      if (appointmentsForDay == null) appointmentsForDay = location.set(dayKey, Ember.A([appointment]));else appointmentsForDay.pushObject(appointment);
      appointmentsForDay.sort(function (a1, a2) {
        return a1.range[0] > a2.range[0] ? 1 : -1;
      });
      return appointment;
    }
  });

  _exports.default = _default;
});