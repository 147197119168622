define("digiprax-web/templates/practice/confirm-plus-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A7JSOYqa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-dialog\",null,[[\"class\",\"onClose\",\"origin\",\"clickOutsideToClose\"],[\"flex-80\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"confirmUpdateBusinessEnabled\"]]],null],false],null],[24,[\"dialogOrigin\"]],true]],{\"statements\":[[4,\"paper-toolbar\",null,null,{\"statements\":[[4,\"paper-toolbar-tools\",null,null,{\"statements\":[[0,\"      \"],[7,\"h2\",true],[8],[0,\"Bestellung Digiprax plus\"],[9],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n      \"],[4,\"paper-button\",null,[[\"iconButton\",\"onClick\"],[true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"confirmUpdateBusinessEnabled\"]]],null],false],null]]],{\"statements\":[[1,[28,\"paper-icon\",null,[[\"icon\"],[\"close\"]]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-dialog-content\",null,null,{\"statements\":[[4,\"if\",[[24,[\"currentUser\",\"business_enabled\"]]],null,{\"statements\":[[0,\"    Um Digiprax-Plus abzubestellen senden Sie bitte eine E-Mail an \"],[7,\"a\",true],[10,\"href\",\"mailto\"],[8],[0,\"info@digiprax.com\"],[9],[0,\".\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Um Digiprax-Plus zu bestellen senden Sie bitte eine E-Mail an \"],[7,\"a\",true],[10,\"href\",\"mailto\"],[8],[0,\"info@digiprax.com\"],[9],[0,\".\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "digiprax-web/templates/practice/confirm-plus-order.hbs"
    }
  });

  _exports.default = _default;
});