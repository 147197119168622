define("digiprax-web/models/new-treatment-placeholder", ["exports", "moment", "digiprax-web/models/day-group-data"], function (_exports, _moment, _dayGroupData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    store: Ember.inject.service(),
    isDirty: function isDirty() {
      // api-method
      return this.get('hasDirtyAttributes') || this.get('extraInfoIsDirtyFlag') != null;
    },
    isNew: true,
    isLast: Ember.computed('hasDirtyAttributes', 'extraInfoIsDirtyFlag', function () {
      return !this.isDirty();
    }),
    extraInfoIsDirtyFlag: null,
    hasDirtyAttributes: Ember.computed('date', 'tariff', 'treatmentText', 'cost', 'treatmentNotes', 'diagnosisText', function () {
      if (this.get('invoiceId') == null) {
        // patientenkarte
        return this.get('date') != null || this.get('cost') != null && ('' + this.get('cost')).trim() != '' || this.get('treatmentNotes') != null && this.get('treatmentNotes').trim() != '' || this.get('diagnosisText') != null && this.get('diagnosisText').trim() != '';
      } else {
        // patientenrechnung
        // return this.get('date') != null ||
        return this.get('tariff') != null || this.get('treatmentText') != null && this.get('treatmentText').trim() != '';
      }
    }),

    /*ignoreDirtyAttributes() {
      var attrs = Object.keys(this.changedAttributes());
      // patientId, extraInfo, date
      if (attrs.length >= 4) return false;
      if (this.changedAttributes()['date'] != null && typeof this.changedAttributes()['date'][0] !== 'undefined') return false;
      if (this.changedAttributes()['extraInfo'] != null && typeof this.changedAttributes()['extraInfo'][0] !== 'undefined') return false;
      return true;
    },*/
    diagnosis: null,
    invoiceId: null,
    invoice: Ember.computed('invoiceId', function () {
      if (this.get('invoiceId') != null) {
        return this.store.peekRecord('invoice', this.get('invoiceId'));
      }

      return null;
    }),
    date: null,

    /*tariff: function() {
      return null;
    }.property(),*/
    tariff: Ember.computed({
      // return ((this.get('cost') / 100).toFixed(2)).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
      get: function get(_key) {
        return this.get('_tariff');
      },
      set: function set(_key, value) {
        this.set('_cost', null);
        this.set('_tariff', value);
        return value;
      }
    }),
    notes: null,
    invoiceText: null,
    factor: 1,
    number: 1,
    diseaseCharacteristics: Ember.computed('extraInfo.diagnoseParts.[]', function () {
      /*var diagnosisAvailable = !(typeof(this.get('diagnosis')) == "undefined"||this.get('diagnosis')==null);
      if (diagnosisAvailable) {
        return this.get('diagnosis.disease_characteristics');
      } else {*/
      return {
        akut: false,
        chronisch: false,
        entzuendlich: false,
        fieberhaft: false,
        juckend: false,
        rezidivierend: false,
        schmerzend: false,
        rechts: false,
        links: false
      }; //}
    }),
    tariffItem: Ember.computed('tariff', {
      // required for view: value=treatment.tariff.item onChange=(action (mut treatment.tariff.item)) maybe use gebuehPrefix
      // return this.get('store').peekRecord('tariff', this.get('tariffId')).get('name');
      get: function get(_key) {
        if (this.get('_tariffItem') != null) {
          return this.get('_tariffItem');
        } else if (this.get('tariff') != null) {
          return this.get('tariff.item');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        this.set('_tariffItem', value);
        return value;
      }
    }),
    validDate: Ember.computed('date', 'invoice.date', function () {
      if (this.get('date') != null && this.get('invoice') != null) {
        return !(0, _moment.default)(this.get('date')).startOf('day').isAfter((0, _moment.default)(this.get('invoice.date')).startOf('day'));
      }

      return true;
    }),
    // container: computed('date', function() {
    //   let idx = this.invoice.sortedNewTreatmentPlaceholders.indexOf(this);
    //   return document.querySelector('ul[data-name="treatment-new-'+idx+'"] li[name="date"]');
    // }),
    showPikaDayDate: Ember.computed('date', {
      get: function get(_key) {
        return this.get('_showPikaDayDate') || false;
      },
      set: function set(_key, value, prevValue, eventPlaceholder) {
        // console.log('NewTreatmentPlaceholder - showPikaDayDate: value = '+value+', prevValue = '+prevValue+', dateTotal = '+this.dateTotal+', _showPikaDayDate = '+this.get('_showPikaDayDate')+', tag = '+((eventPlaceholder||window.event).relatedTarget||{}).tagName);
        if ((this.dateTotal || '').match(/^[0-9]{1,2}(\.([0-9]{1,2}(\.([0-9]{1,3}|)|)|)|)$/)) {
          // let dateInput = (eventPlaceholder||window.event||{}).target;
          // let idx = this.invoice.sortedTreatments.indexOf(this);
          // let dateInput = document.querySelector('ul[data-name="treatment-saved-'+idx+'"] li[name="date"] input');
          // this.set('_pikaDayDateInput', dateInput);
          // console.log('NewTreatmentPlaceholder - showPikaDayDate: target = '+dateInput.closest('ul').getAttribute('data-name'));
          // dateInput.focus();
          return true;
        }

        if (((eventPlaceholder || window.event || {}).relatedTarget || {}).tagName == 'SELECT') return true;
        if (value) this.set('_pikaDayDateInput', (eventPlaceholder || window.event || {}).target);
        return this.set('_showPikaDayDate', value);
      }
    }),
    // required to display correct pikaday-value: empty (no placeholder) if previous entry is on same day
    // dateTotal: computed('date', {
    //   get(_key) {
    //     return this.get('date');
    //   },
    //   set(_key, value) {
    //     if (value != null) {
    //       this.set('date', value);
    //     }
    //
    //     return this.get('date');
    //   }
    // }),
    // dateTotal: computed('date', {
    //   get(_key) {
    //     return this.get('date');
    //     // let dateFmtd = moment(this.get('date')).format('DD.MM.YYYY');
    //     // if (this.get('invoice.dayGroups')[dateFmtd] == null || this.get('invoice.newTreatmentPlaceholders') == null) return this.get('date');
    //     // var sameDay = this.get('invoice.dayGroups')[dateFmtd].entries;
    //     // if (sameDay[sameDay.findIndex(t => t.get('tmpId') != null)].get('tmpId') == this.get('tmpId')) {
    //     //   return this.get('date');
    //     // } else {
    //     //   return null;
    //     // }
    //   },
    //   set(_key, value) {
    //     // if (value != null) {
    //     if ([null,''].indexOf(value) == -1) {
    //       this.set('date', value);
    //     }
    //
    //     // let textInput = document.querySelector('ul[data-name="treatment-new-'+this.invoice.sortedNewTreatmentPlaceholders.indexOf(this)+'"] li[name="date"] input');
    //     // if (textInput != null && this.get('dateFmtd') != null) textInput.value = this.get('dateFmtd');
    //     // this.set('dateTotalFlag', new Date());
    //
    //     return this.get('date');
    //     // let dateFmtd = moment(this.get('date')).format('DD.MM.YYYY');
    //     // if (this.get('invoice.dayGroups')[dateFmtd] == null || this.get('invoice.newTreatmentPlaceholders') == null) return this.get('date');
    //     // var sameDay = this.get('invoice.dayGroups')[dateFmtd].entries;
    //     // if (sameDay[sameDay.findIndex(t => t.get('tmpId') != null)].get('tmpId') == this.get('tmpId')) {
    //     //   return this.get('date');
    //     // } else {
    //     //   return null;
    //     // }
    //   }
    // }),
    dateTotal: Ember.computed('date', {
      get: function get(_key) {
        // console.log('NewTreatmentPlaceholder - dateTotal/get: _dateTotal = '+this._dateTotal+', date = '+this.date+', focusedTreatmentChanged = '+this.focusedTreatmentChangedFlag+', _showPikaDayDate = '+this.get('_showPikaDayDate'));
        if (this.get('date') != null) {
          // eslint-disable-next-line ember/no-side-effects
          this.set('_showPikaDayDate', false);
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        } else {
          return null;
        }
      },
      set: function set(_key, value, prevValue, eventPlaceholder) {
        // console.log('NewTreatmentPlaceholder - dateTotal: value = '+value+', prevValue = '+prevValue+', _showPikaDayDate = '+this.get('_showPikaDayDate')+', tag = '+((eventPlaceholder||window.event).target||{}).tagName);
        if (((eventPlaceholder || window.event || {}).target || {}).tagName == 'BUTTON' || ((eventPlaceholder || window.event || {}).target || {}).tagName == null && typeof value.getFullYear == 'function') {
          // click on pikaday-calendar-popup
          this.setProperties({
            date: value,
            showPikaDayDate: false
          });
          if (this._pikaDayDateInput) this._pikaDayDateInput.blur();
          return (0, _moment.default)(value).format('DD.MM.YYYY');
        }

        if (typeof value.getFullYear == 'function') {
          this.set('date', value);
          return (0, _moment.default)(value).format('DD.MM.YYYY');
        } // if T > 31 -> make T.M


        var m = value.match(/^([0-9])([0-9])$/);
        if (m != null && parseInt(m[0]) >= 32) value = m[1] + '.' + m[2]; // if M > 12 -> make T.M.Y

        m = value.match(/^([0-9]{1,2}\.)(([0-9])([0-9]))$/);
        if (m != null && parseInt(m[2]) >= 13) value = m[1] + m[3] + '.' + m[4]; // remove double-point

        m = value.match(/^([0-9]{1,2}\.)\.$/);
        if (m != null) value = m[1];
        m = value.match(/^([0-9]{1,2}\.[0-9]{1,2}\.)\.$/);
        if (m != null) value = m[1];
        if (value.match(/^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/) != null) this.set('date', (0, _moment.default)(value, 'DD.MM.YYYY').toDate());

        if (value.match(/^[0-9]{1,2}$/) != null && (prevValue || '').match(/^[0-9]{1,2}\.$/) != null) {
          // schedule("afterRender", () => (eventPlaceholder || window.event).srcElement.selectionStart = (eventPlaceholder || window.event).srcElement.selectionEnd = value.length);
          return this.set('_dateTotal', value);
        } else if (value.match(/^[0-9]{1,2}\.[0-9]{1,2}$/) != null && (prevValue || '').match(/^[0-9]{1,2}\.[0-9]{1,2}\.$/) != null) {
          // schedule("afterRender", () => (eventPlaceholder || window.event).srcElement.selectionStart = (eventPlaceholder || window.event).srcElement.selectionEnd = value.length);
          return this.set('_dateTotal', value);
        } else {
          return this.set('_dateTotal', value.replace(/^([0-9]{2})$/, '$1.').replace(/^([0-9]{2})([0-9])/, '$1.$2').replace(/^([0-9]{1,2}.[0-9]{2})$/, '$1.').replace(/^([0-9]{1,2}.[0-9]{2})([0-9])/, '$1.$2'));
        }
      }
    }),
    totalDate: Ember.computed('dateTotal', 'invoice.dayGroups', 'invoice.dayGroups.updatedAt', function () {
      // console.log('newTreatmentPlaceholder - totalDate: tariff = ' + this.get('tariff.name') + ', dateFmtd = ' + this.get('dateFmtd'));
      if (this.get('dateFmtd') == null) return null;
      if (this.get('invoice.dayGroups')[this.get('dateFmtd')] == null) return null;

      if (this.get('invoice.newTreatmentPlaceholders') != null) {
        var sameDay = this.get('invoice.dayGroups')[this.get('dateFmtd')].entries;

        if (sameDay[sameDay.findIndex(function (t) {
          return t.get('tmpId') != null;
        })].get('tmpId') == this.get('tmpId')) {
          return this.get('date');
        } else {
          return null;
        }
      } else {
        return null;
      }
    }),
    totalDateFmtd: Ember.computed('totalDate', function () {
      if (this.get('totalDate') != null) {
        return this.get('dateTotal');
      } else {
        // return '';
        // if ((this.dateTotal||'').match(/^[0-9]{1,2}(\.([0-9]{1,2}|)|)(\.([0-9]{1}|)|)$/)) {
        if ((this.dateTotal || '').match(/^[0-9]{1,2}(\.([0-9]{1,2}(\.([0-9]{1,3}|)|)|)|)$/)) {
          return this.dateTotal;
        } else {
          return '';
        }
      }
    }),
    dailyTotal: Ember.computed('invoice.virtTotal', 'invoice.dayGroups', 'invoice.dayGroups.updatedAt', function () {
      if (this.get('dateFmtd') == null) return null;
      if (this.get('invoice.dayGroups')[this.get('dateFmtd')] == null) return null;
      var sameDay = this.get('invoice.dayGroups')[this.get('dateFmtd')].entries;

      if (sameDay[sameDay.length - 1].get('tmpId') == this.get('tmpId')) {
        var total = sameDay.reduce(function (acc, curTreatment) {
          return acc + curTreatment.get('number') * curTreatment.getCost();
        }, 0);
        return (total / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',') + ' €';
      } else {
        return null;
      }
    }),
    treatmentText: Ember.computed('tariff', {
      get: function get(_key) {
        if (this.get('invoiceText') != null) {
          return this.get('invoiceText');
        } else if (this.get('tariff') != null) {
          return this.get('tariff.name');
        } else if (this.get('notes') != null) {
          return this.get('notes');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        this.set('invoiceText', value);
        return value;
      }
    }),
    treatmentNotes: Ember.computed({
      // used for patientenkarte
      get: function get(_key) {
        if (this.get('notes') != null && this.get('notes') != '-') {
          return this.get('notes');
        } else if (this.get('invoiceText') != null) {
          return this.get('invoiceText');
        } else {
          return null; // '-'
        }
      },
      set: function set(_key, value) {
        this.set('notes', value);
        return value;
      }
    }),
    diagnosisText: Ember.computed({
      get: function get(_key) {
        if (this.get('extraInfo.diagnosisText') != null) {
          return this.get('extraInfo.diagnosisText');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        /*
         * extraInfo:
         *  { text: '...', diseasePattern: null }
         */
        var extraInfoAvailable = !(typeof this.get('extraInfo') == "undefined" || this.get('extraInfo') == null);

        if (extraInfoAvailable) {
          this.set('extraInfo.diagnosisText', value);
        } else {
          this.set('extraInfo', {
            diagnosisText: value,
            diagnoseParts: [{
              text: value,
              diseasePattern: null
            }]
          });
        } // this.set('diagnosis.notes', value);


        return value;
      }
    }),
    // _curPartText(value, editIndex) {
    //   var curPartNextCommaIndex = value.indexOf(',', editIndex);
    //   var curPartText;
    //   if (curPartNextCommaIndex == -1) curPartText = value.substring(value.substring(0, editIndex).lastIndexOf(',') + 1);
    //   else curPartText = value.substring(value.substring(0, editIndex).lastIndexOf(',') + 1, curPartNextCommaIndex);
    //   return curPartText;
    // },
    dateFmtd: Ember.computed('date', {
      get: function get(_key) {
        if (this.get('date') != null) {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        if ([null, ''].indexOf(value) == -1) {
          if (typeof value == 'string') {
            this.set('date', (0, _moment.default)(value, 'DD.MM.YYYY').toDate());
            return value;
          } else {
            this.set('date', value || new Date());
            return (0, _moment.default)(value).format('DD.MM.YYYY');
          }
        } else {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        }
      }
    }),
    // _cost: null,
    cost: Ember.computed('_cost', function () {
      if (this.get('_cost') != null) return this.get('_cost');else if (this.get('tariff') != null) return this.get('tariff.settlementTariff.default');else return null;
    }),
    getCost: function getCost() {
      return typeof this.get('_cost') == 'undefined' || this.get('_cost') == null ? this.get('cost') : this.get('_cost');
    },
    costFmtd: Ember.computed('cost', {
      // return ((this.get('cost') / 100).toFixed(2)).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
      get: function get(_key) {
        var cost = this.getCost();

        if (cost != null) {
          return (cost / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        var newCost = Math.round(parseFloat(value.replace(',', '.') * 100));
        this.set('_cost', newCost); // return (this.get('cost') * this.get('number')) + ',00';

        return value;
      }
    }),
    costMut: Ember.computed('cost', '_cost', 'number', {
      get: function get(_key) {
        var cost = this.getCost();
        if (cost != null) // return ((cost * (this.get('number')||1) / 100).toFixed(2)).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
          return (cost * (this.get('number') || 1) / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');else return null;
      },
      set: function set(_key, value) {
        // var newCost = Math.round(parseFloat(value.replace(',', '.') * 100) / (this.get('number')||1));
        var newCost = Math.round(parseFloat(value.replace(',', '.').replace(/\s*€/, '') * 100) / (this.get('number') || 1));
        this.set('_cost', newCost);
        return value;
      }
    }),
    positionTS: Ember.computed({
      get: function get(_key) {
        return this.get('extraInfo.positionTS') || null;
      },
      set: function set(_key, tSMillis) {
        var extraInfoAvailable = !(typeof this.get('extraInfo') == "undefined" || this.get('extraInfo') == null);

        if (extraInfoAvailable) {
          if (this.get('extraInfo.positionTS') == null) {
            this.set('extraInfo.positionTS', tSMillis);
          }
        } else {
          this.set('extraInfo', {
            positionTS: tSMillis
          });
        }

        return tSMillis;
      }
    }),
    clipboardPosition: Ember.computed('clipboard.treatments.[]', function () {
      var _this = this;

      var idx = (this.get('clipboard.treatments') || []).findIndex(function (t) {
        return t.model == _this;
      });
      return idx == -1 ? null : idx + 1;
    }),
    dayGroupData: Ember.computed('dayGroup.last', function () {
      if (this == (this.dayGroup || {}).last && this.get('date') != null) {
        // return this.targetInvoice.get('extraInfo.dayGroupData.'+moment(this.get('date')).format('YYYYMMDD'));
        return _dayGroupData.default.create({
          targetInvoice: this.invoice || this.targetInvoice,
          dayGroup: this.dayGroup
        });
      }

      return null;
    }),
    extraLine: Ember.computed(
    /**/
    'extraInfoIsDirtyFlag', {
      get: function get(_key) {
        return this.get('extraInfo.extraLine') || null;
      },
      set: function set(_key, extraLine) {
        if (extraLine == null || extraLine.trim() == '') {
          var extraInfo = this.get('extraInfo');
          delete extraInfo.extraLine;
          this.set('extraInfo', extraInfo);
        } else {
          this.set('extraInfo.extraLine', extraLine
          /*.trim()*/
          );
        }

        this.set('extraInfoIsDirtyFlag', new Date());
        return this.get('extraInfo.extraLine');
      }
    })
  });

  _exports.default = _default;
});