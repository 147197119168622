define("digiprax-web/models/invoice-for-business", ["exports", "ember-data", "digiprax-web/mixins/invoice-model", "digiprax-web/mixins/invoice-lots"], function (_exports, _emberData, _invoiceModel, _invoiceLots) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_invoiceModel.default, _invoiceLots.default, {
    liable_to_sales_tax: _emberData.default.attr('boolean'),
    liableToSalesTax: Ember.computed.alias('liable_to_sales_tax')
  });

  _exports.default = _default;
});