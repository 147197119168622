define("digiprax-web/mixins/invoice-model", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isDirty: Ember.computed('hasDirtyAttributes', 'extraInfoIsDirtyFlag', 'additionalText', 'entriesDirty', 'entriesAddedOrDeleted', function () {
      return this.get('hasDirtyAttributes') && !this.ignoreDirtyAttributes() || this.get('extraInfoIsDirtyFlag') != null || this.get('entriesAddedOrDeleted') || this.get('entriesDirty');
    }),
    isDirtyForSave: Ember.computed('hasDirtyAttributes', 'extraInfoIsDirtyFlag', 'additionalText', 'entriesAddedOrDeleted', function () {
      return this.get('hasDirtyAttributes') && !this.ignoreDirtyAttributes() || this.get('extraInfoIsDirtyFlag') != null || this.get('entriesAddedOrDeleted');
    }),
    extraInfoIsDirtyFlag: null,
    ignoreDirtyAttributes: function ignoreDirtyAttributes() {
      var attrs = Object.keys(this.changedAttributes()); // patientId, extraInfo, date

      if (attrs.length >= 5) return false;
      var numToIgnore = 0;
      if (this.changedAttributes()['patientId'] != null) numToIgnore += 1;
      if (this.changedAttributes()['date'] != null && this.changedAttributes()['date'][0] == null) numToIgnore += 1;
      if (this.changedAttributes()['extraInfo'] != null && this.changedAttributes()['extraInfo'][0] == null) numToIgnore += 1;
      if (this.changedAttributes()['printedAt'] != null) numToIgnore += 1;
      return attrs.length == numToIgnore;
    },
    // _entriesAddedOrDeleted: computed('entries', function() {
    //   return [this.get('entries.length')||0, 1];
    // }),
    _entriesAddedOrDeleted: Ember.computed('entries', {
      get: function get(_key) {
        return this.get('__entriesAddedOrDeleted') || [this.get('entries.length') || 0, 1];
      },
      set: function set(_key, value, _prevValue) {
        return this.set('__entriesAddedOrDeleted', value);
      }
    }),
    entriesAddedOrDeleted: Ember.computed('entries.[]', 'newEntryPlaceholders.[]', '_entriesAddedOrDeleted', function () {
      return this.get('entries.length') != this.get('_entriesAddedOrDeleted')[0]; //  || this.get('newEntryPlaceholders.length') != this.get('_entriesAddedOrDeleted')[1];
    }),
    afterSave: function afterSave() {
      this.setProperties({
        /*_*/
        newEntryPlaceholders: null,
        savedAt: new Date(),
        _entriesAddedOrDeleted: [this.get('entries.length') || 0, 1]
      });
    },
    sortByPosition: function sortByPosition(t1, t2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (t1.get('extraInfo') != null) {
        if (t2.get('extraInfo') != null) {
          if (t1.get('extraInfo.positionTS') != null) {
            if ((t1.get('extraInfo.positionTS') || 0) == (t2.get('extraInfo.positionTS') || 0)) {
              return -1;
            } else {
              return (t1.get('extraInfo.positionTS') || 0) > (t2.get('extraInfo.positionTS') || 0) ? 1 : -1;
            }
          } else {
            // DEPRECATED - backwared-compatibility only
            if ((t1.get('extraInfo.position') || 0) == (t2.get('extraInfo.position') || 0)) {
              return -1;
            } else {
              return (t1.get('extraInfo.position') || 0) > (t2.get('extraInfo.position') || 0) ? 1 : -1;
            }
          }
        } else {
          return -1;
        }
      } else if (t2.get('extraInfo') != null && (t2.get('extraInfo.positionTS') != null ||
      /*DEPRECATED*/
      t2.get('extraInfo.position') != null)) {
        return 1;
      }

      return null; // this.sortByTariffChain(t1, t2);
    },
    patient: _emberData.default.belongsTo('patient'),
    loadedPatient: null,
    // set whenever
    patientId: _emberData.default.attr('string'),
    invoiceNumber: _emberData.default.attr('string'),
    date: _emberData.default.attr('BackendDate'),
    notes: _emberData.default.attr('string'),
    total: _emberData.default.attr('number'),
    paidAt: _emberData.default.attr('date'),
    paidAmount: _emberData.default.attr('number'),
    extraInfo: _emberData.default.attr('raw'),
    printedAt: _emberData.default.attr('date'),
    patientInfo: _emberData.default.attr('raw'),
    invoiceMonths: Ember.computed(function () {
      return ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
    }),
    invoiceMonthFmtd: Ember.computed('invoiceMonth', function () {
      if (this.get('extraInfo.invoiceMonth') != null) return ('0' + this.get('extraInfo.invoiceMonth.month')).match(/[0-9]{2}$/)[0] + '-' + this.get('extraInfo.invoiceMonth.year');
      if (this.get('date') != null) return (0, _moment.default)(this.get('date')).format('MM-YYYY');
      return null;
    }),
    invoiceMonth: Ember.computed('date', {
      get: function get(_key) {
        // console.log('_invoiceMonth = ' + this.get('_invoiceMonth') + ', date = ' + this.get('date'));
        if (this.get('extraInfo.invoiceMonth') != null) return this.get('invoiceMonths')[this.get('extraInfo.invoiceMonth.month') - 1];

        if (this.get('date') != null) {
          var alwaysShowCurMonth = true; // 2018-11-12::gisela:

          if (alwaysShowCurMonth || this.get('date').getDate() >= 16) {
            // current month
            return this.get('invoiceMonths')[this.get('date').getMonth()];
          } else {
            // previous month
            return this.get('invoiceMonths')[(0, _moment.default)(this.get('date')).add(-1, 'month').toDate().getMonth()];
          }
        }

        return null;
      },
      set: function set(_key, month) {
        /**
         * wenn der (param-)monat > als rechnungs-datum-monat ist dann ist er aus dem vorigen jahr
         */
        var iMIdx = this.get('invoiceMonths').indexOf(month);
        var year = this.get('date').getMonth() < iMIdx ? this.get('date').getFullYear() - 1 : this.get('date').getFullYear(); // console.log('_invoiceMonth = ' + this.get('_invoiceMonth') + ', month = ' + month);

        var extraInfoAvailable = !(typeof this.get('extraInfo') == "undefined" || this.get('extraInfo') == null);

        if (extraInfoAvailable) {
          this.set('extraInfo.invoiceMonth', {
            month: iMIdx + 1,
            year: year
          });
        } else {
          this.set('extraInfo', {
            invoiceMonth: {
              month: iMIdx + 1,
              year: year
            }
          });
        }

        this.set('extraInfoIsDirtyFlag', new Date());
        return month;
      }
    }),
    invoiceMonthNum: Ember.computed('invoiceMonth', function () {
      return ('0' + (this.get('invoiceMonths').indexOf(this.get('invoiceMonth')) + 1)).match(/[0-9]{2}$/)[0];
    }),
    additionalText: Ember.computed({
      get: function get(_key) {
        return this.get('extraInfo.additionalText.text');
      },
      set: function set(_key, value) {
        var extraInfoAvailable = !(typeof this.get('extraInfo') == "undefined" || this.get('extraInfo') == null);
        var extraInfoAddTextAvailable = false;
        if (extraInfoAvailable) extraInfoAddTextAvailable = !(typeof this.get('extraInfo.additionalText') == "undefined" || this.get('extraInfo.additionalText') == null);

        if (extraInfoAddTextAvailable) {
          if (typeof value == 'string') {
            this.set('extraInfo.additionalText.text', value);
          } else {
            this.set('extraInfo.additionalText', {
              id: value.id,
              text: value.get('text')
            });
          }
        } else {
          if (extraInfoAvailable) {
            if (typeof value == 'string') {
              this.set('extraInfo.additionalText', {
                id: null,
                text: value
              });
            } else {
              this.set('extraInfo.additionalText', {
                id: value.id,
                text: value.get('text')
              });
            }
          } else {
            if (typeof value == 'string') {
              this.set('extraInfo', {
                additionalText: {
                  id: null,
                  text: value
                }
              });
            } else {
              this.set('extraInfo', {
                additionalText: {
                  id: value.id,
                  text: value.get('text')
                }
              });
            }
          }
        }

        this.set('extraInfoIsDirtyFlag', new Date());
        return this.get('extraInfo.additionalText.text');
      }
    }),
    savedAt: null,
    numNewTreatmentPlaceholders: 1,
    notNullUnlessFinalized: Ember.computed('extraInfo.finalized', function () {
      if (this.get('extraInfo') != null && this.get('extraInfo.finalized') != null) {
        return null;
      } else {
        return {};
      }
    }),
    hasEntries: Ember.computed('entries.length', 'newEntryPlaceholders.length', function () {
      return (this.get('entries') || []).toArray().length >= 1 || (this.get('newEntryPlaceholders') || []).toArray().length >= 2;
    }),
    notEditable: Ember.computed('extraInfo.finalized', 'extraInfo.canceledAt', function () {
      return this.get('extraInfo.finalized') != null || this.get('extraInfo.canceledAt') != null;
    }),
    isCanceled: Ember.computed('extraInfo.canceledAt', function () {
      return this.get('extraInfo.canceledAt') != null ? true : false;
    }),
    canceledAtFmtd: Ember.computed('extraInfo.canceledAt', function () {
      if (this.get('extraInfo.canceledAt') != null) {
        return (0, _moment.default)(this.get('extraInfo.canceledAt')).format('DD.MM.YYYY');
      } else {
        return null;
      }
    }),
    paidAtFmtd: Ember.computed('paidAt', function () {
      if (this.get('paidAt') != null) {
        return (0, _moment.default)(this.get('paidAt')).format('DD.MM.YYYY');
      } else {
        return null;
      }
    }),
    dateFmtd: Ember.computed('date', {
      get: function get(_key) {
        if (this.get('date') != null) {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        if ([null, ''].indexOf(value) == -1) {
          if (typeof value == 'string') {
            this.set('date', (0, _moment.default)(value, 'DD.MM.YYYY').toDate());
            return value;
          } else {
            this.set('date', value);
            return (0, _moment.default)(value).format('DD.MM.YYYY');
          }
        } else {
          return (0, _moment.default)(this.get('date')).format('DD.MM.YYYY');
        }
      }
    }),
    dayGroups: Ember.computed('entries.@each.date', 'newEntryPlaceholders.@each.date', 'dayGroups.updatedAt', function () {
      var dayGroups = this.get('_dayGroups');

      if (dayGroups == null) {
        dayGroups = {}; // eslint-disable-next-line ember/no-side-effects

        this.set('_dayGroups', dayGroups);
      }

      Object.keys(dayGroups).forEach(function (dayKey) {
        delete dayGroups[dayKey];
      });
      var newEntries = this.get('sortedNewEntryPlaceholders').toArray().filter(function (newEntry) {
        return newEntry.get('date'
        /*'tariff'*/
        ) != null;
      });
      this.get('sortedEntries').concat(newEntries).forEach(function (entry, _index) {
        if (dayGroups[entry.get('dateFmtd')] == null) {
          dayGroups[entry.get('dateFmtd')] = {
            entries: []
          };
        }

        dayGroups[entry.get('dateFmtd')].entries.push(entry);
        entry.set('dayGroup', dayGroups[entry.get('dateFmtd')]); // if (entry.get('targetInvoice') == null) entry.set('targetInvoice', this);
      });

      var _arr = Object.keys(dayGroups);

      for (var _i = 0; _i < _arr.length; _i++) {
        var date = _arr[_i];
        dayGroups[date].last = dayGroups[date].entries[dayGroups[date].entries.length - 1];
      }

      dayGroups.updatedAt = new Date();
      return Ember.Object.create(dayGroups); //dayGroups;
    }),
    totalFmtd: Ember.computed('total', function () {
      return (this.get('total') / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
    }),
    paidSome: Ember.computed('total', 'paidAmount', function () {
      return this.get('paidAmount') != null && this.get('paidAmount') != 0 && this.get('paidAmount') != this.get('total');
    }),
    paidAmountFmtd: Ember.computed('paidAmount', function () {
      if (this.get('paidAmount') == null) return null;
      return (this.get('paidAmount') / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
    }),
    // old:
    virtTotal: Ember.computed('entries.@each.costFmtd', 'entries.@each.number', 'newEntryPlaceholders.@each.costFmtd', 'newEntryPlaceholders.@each.number', function () {
      // new: virtTotal: computed('entries.@each.dailyTotal', 'newEntryPlaceholders.@each.dailyTotal', function() {
      var newEntries = this.get('newEntryPlaceholders').toArray().filter(function (newEntry) {
        return newEntry.get('date'
        /*'tariff'*/
        ) != null;
      });
      var invoiceTotal = 0;

      if (this.get('entries') != null) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.get('entries').concat(newEntries)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var entry = _step.value;
            // invoiceTotal = invoiceTotal + entry.get('number') * entry.get('cost');
            // invoiceTotal = invoiceTotal + entry.get('number') * parseFloat(entry.get('costFmtd').replace(',', '.').replace(/^$/, '0')) * 100;
            invoiceTotal = invoiceTotal + entry.get('number') * entry.getCost();
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      } // return invoiceTotal + ',00';


      return (invoiceTotal / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
    }),
    realTotal: Ember.computed('entries.@each.costFmtd', 'entries.@each.number', 'newEntryPlaceholders.@each.costFmtd', 'newEntryPlaceholders.@each.number', function () {
      var newEntries = this.get('newEntryPlaceholders').toArray().filter(function (newEntry) {
        return newEntry.get('date'
        /*'tariff'*/
        ) != null;
      });
      var invoiceTotal = 0;

      if (this.get('entries') != null) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = this.get('entries').concat(newEntries)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var entry = _step2.value;
            if (entry.get('tariffId') == null && this.toString().match('@model:invoice-for-business') == null) continue;
            invoiceTotal = invoiceTotal + entry.get('number') * entry.getCost();
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }
      } // return invoiceTotal + ',00';


      return (invoiceTotal / 100).toFixed(2).replace(/(\.\d)$/, '$10').replace(/^([0-9]+$)/, '$1,00').replace('.', ',');
    })
  });
  /*
   createdAt                 | timestamp with time zone | not null
   updatedAt                 | timestamp with time zone | not null
   deletedAt                 | timestamp with time zone |
  */


  _exports.default = _default;
});