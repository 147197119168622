define("digiprax-web/templates/practice/templates/new-patient-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1+l0ZYyl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"print-content\"],[10,\"class\",\"printing\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"padding\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"layout\",\"row\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[8],[0,\"Patientenanmeldung\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex\"],[8],[9],[0,\"\\n\"],[0,\"      \"],[7,\"button\",true],[10,\"class\",\"md-primary md-raised print_template md-button md-ink-ripple\"],[10,\"onclick\",\"var pdf = document.querySelector('.printPdf'); pdf.focus(); pdf.contentWindow.print();\"],[10,\"type\",\"button\"],[8],[0,\"Drucken\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[0,\"    \"],[7,\"iframe\",true],[10,\"class\",\"printPdf\"],[10,\"src\",\"/assets/images/pat_anmeldung.pdf\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "digiprax-web/templates/practice/templates/new-patient-template.hbs"
    }
  });

  _exports.default = _default;
});