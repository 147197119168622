define("digiprax-web/models/practice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    invoices: _emberData.default.hasMany('invoice'),
    user: _emberData.default.attr('raw'),
    rooms: _emberData.default.attr('raw'),
    name: _emberData.default.attr('string'),
    info: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    logo_fs: _emberData.default.attr('string'),
    delete_logo: _emberData.default.attr('boolean'),
    delete_logo_fs: _emberData.default.attr('boolean'),
    addressStreet: _emberData.default.attr('string'),
    addressHouseNumber: _emberData.default.attr('string'),
    addressZip: _emberData.default.attr('string'),
    addressCity: _emberData.default.attr('string'),
    phoneLandline: _emberData.default.attr('string'),
    phoneCellular: _emberData.default.attr('string'),
    phoneFax: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    hideAccount: _emberData.default.attr('boolean'),
    accountBank: _emberData.default.attr('string'),
    accountIBAN: _emberData.default.attr('string'),
    accountBIC: _emberData.default.attr('string'),
    memberName: _emberData.default.attr('string'),
    memberNumber: _emberData.default.attr('string'),
    trial: _emberData.default.attr('boolean'),
    misc1: _emberData.default.attr('string'),
    misc2: _emberData.default.attr('string'),
    addressShow: _emberData.default.attr('boolean'),
    emailShow: _emberData.default.attr('boolean'),
    addresseeSenderHide: _emberData.default.attr('boolean'),
    addresseeOffsetX: _emberData.default.attr('number'),
    addresseeOffsetY: _emberData.default.attr('number'),
    praxisheaderSenderHide: _emberData.default.attr('boolean'),
    praxisheaderOffsetX: _emberData.default.attr('number'),
    praxisheaderOffsetY: _emberData.default.attr('number'),
    cal_first_slot_start: _emberData.default.attr('number'),
    cal_last_slot_end: _emberData.default.attr('number'),
    liable_to_sales_tax: _emberData.default.attr('boolean'),
    tax_number: _emberData.default.attr('string'),
    logoUrl: Ember.computed('logo', 'savedAt', function () {
      if (this.get('logo') != null) return this.get('logo') + '?' + new Date().getTime();
      return null;
    }),
    logoFsUrl: Ember.computed('logo_fs', 'savedAt', function () {
      if (this.get('logo_fs') != null) return this.get('logo_fs') + '?' + new Date().getTime();
      return null;
    }),
    logoType: Ember.computed('logo', 'logo_fs', {
      get: function get(_key) {
        if (this.get('logo') != null && !this.get('delete_logo')) {
          return 'top-right';
        } else if (this.get('logo_fs') != null && !this.get('delete_logo_fs')) {
          return 'top-center';
        } else {
          return null;
        }
      },
      set: function set(_key, value) {
        if (value == 'top-right') {
          this.setProperties({
            delete_logo: null,
            delete_logo_fs: true
          });
        } else if (value == 'top-center') {
          this.setProperties({
            delete_logo: true,
            delete_logo_fs: null
          });
        } else {
          this.setProperties({
            delete_logo: true,
            delete_logo_fs: true
          });
        }

        return value;
      }
    }),
    syncLogos: function syncLogos() {
      this.setProperties({
        delete_logo: null,
        delete_logo_fs: null
      });

      if (this.get('logoType') == 'top-right') {
        this.setProperties({
          logo_fs: null
        });
      } else if (this.get('logoType') == 'top-center') {
        this.setProperties({
          logo: null
        });
      } else {
        this.setProperties({
          logo: null,
          logo_fs: null
        });
      }
    }
  });
  /*
   createdAt                 | timestamp with time zone | not null
   updatedAt                 | timestamp with time zone | not null
   deletedAt                 | timestamp with time zone |
  */


  _exports.default = _default;
});