define("digiprax-web/router", ["exports", "digiprax-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('index', {
      path: '/'
    });
    this.route('auth', function () {
      this.route('index', {
        path: '/'
      });
      this.route('login');
      this.route('logout');
      this.route('reset-password', {
        path: '/:confirmation_token'
      });
    });
    this.route('registration', {
      path: '/registration'
    }, function () {
      this.route('index', {
        path: '/'
      });
      this.route('confirmation', {
        path: '/:confirmation_token'
      });
    });
    this.route('api-calls', function () {});
    this.route('patients', {
      path: '/patients'
    }, function () {
      this.route('overview', {
        path: '/overview'
      });
      this.route('new', {
        path: '/new'
      });
      this.route('month-stats', {
        path: '/month-stats'
      });
      this.route('statistics', {
        path: '/statistics'
      });
      this.route('edit', {
        path: '/:patient_id'
      }, function () {
        this.route('index', {
          path: '/'
        });
        this.route('profile', {
          path: '/edit'
        });
        this.route('invoices', {
          path: '/invoices'
        }, function () {
          this.route('index', {
            path: '/'
          });
          this.route('new', {
            path: '/new'
          });
          this.route('edit', {
            path: '/:invoice_id'
          });
        });
      });
    });
    this.route('customers', {
      path: '/customers'
    }, function () {
      this.route('overview', {
        path: '/overview'
      });
      this.route('edit', {
        path: '/:patient_id'
      }, function () {
        this.route('index', {
          path: '/'
        });
        this.route('profile', {
          path: '/edit'
        });
        this.route('invoices', {
          path: '/invoices'
        }, function () {
          this.route('index', {
            path: '/'
          });
          this.route('new', {
            path: '/new'
          });
          this.route('edit', {
            path: '/:invoice_id'
          });
        });
      });
    });
    this.route('practice', {
      path: '/practice'
    }, function () {
      this.route('cashbook', {
        path: '/cashbook'
      });
      this.route('journal', {
        path: '/journal'
      });
      this.route('year-summary', {
        path: '/year-summary'
      });
      this.route('open-invoices', {
        path: '/open-invoices'
      });
      this.route('revenue-statistics', {
        path: '/revenue-statistics'
      });
      this.route('templates', {
        path: '/templates'
      });
      this.route('edit', {
        path: '/edit'
      });
    });
    this.route('calendar', {
      path: '/calendar'
    }, function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('not-found', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});