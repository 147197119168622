define("digiprax-web/routes/auth/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      this.get('session').invalidate().catch(function (_reason) {
        return _this.transitionTo('auth.login');
      }).then(function () {
        return _this.transitionTo('auth.login');
      });
      return false;
    }
  });

  _exports.default = _default;
});