define("digiprax-web/models/appointment-planner", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ArrayProxy from '@ember/array/proxy';
  // import AppointmentLocation from '../mixins/appointment-location';
  var _default = Ember.Object.extend({
    // AppointmentLocation, {
    appointmentsLoadedFlag: Ember.computed.alias('calendarEditor.appointmentsLoadedFlag'),
    roomsAsList: Ember.computed('rooms', 'selectedRoom'
    /*, 'patient' ??? */
    , function () {
      var _this = this;

      // return Object.keys(this.get('rooms')).map(roomId => this.get('rooms.'+roomId)).filter(room => /*this.patient == null && */this.selectedRoom != null ? room.id == this.selectedRoom : true);
      if (this.rooms != null) {
        return Ember.Object.keys(this.get('rooms')).map(function (roomId) {
          return _this.get('rooms.' + roomId);
        }).filter(function (room) {
          return (
            /*this.patient == null && */
            _this.selectedRoom != null ? room.id == _this.selectedRoom : true
          );
        });
      }

      return [];
    }),
    slots: Ember.computed('firstSlotStart', 'lastSlotEnd', 'slotDuration', function () {
      var slotMinutes = 60 / String(Math.pow(10, 100 / this.slotDuration) / 10).split('').length;
      var slotLimits = String(Math.pow(10, 100 / this.slotDuration) / 10).split('').map(function (x, idx) {
        return ('0' + String(idx * slotMinutes)).match(/[0-9]{2}$/)[0];
      });
      var slots = [];

      for (var i = this.firstSlotStart / 100; i < this.lastSlotEnd / 100; i++) {
        slots = slots.concat(slotLimits.map(function (min, idx, mins) {
          return Ember.Object.create({
            from: i + ':' + min,
            until: (idx == slotLimits.length - 1 ? i + 1 : i) + ':' + (mins[idx + 1] || mins[0]),
            fromInt: i * 100 + idx * slotMinutes
          });
        }));
      }

      return slots;
    }),
    // appointmentsByPlace: alias('calendarEditor.appointmentsByPlace'),
    newAppointmentFlag: Ember.computed.alias('calendarEditor.newAppointmentFlag'),
    appointmentsByPlace: Ember.computed('patients', 'newAppointmentFlag', 'selectedYear', 'selectedWeek', 'selectedDate', function () {
      return this.calendarEditor.appointmentsForDateByPlace((0, _moment.default)(this.selectedDate).format('YYYY-MM-DD'));
    }),

    /**
     * view for day (all slots, all rooms) with displays text(time) and available marker
     * ...
     * place1: {
     *  ...
     *  10:00: { time: null, available: 'all' }
     *  11:00: { time: '11:00-12:15 Mr. Harper', available: 'none' }
     *  12:00: { time: '11:00-12:15 Mr. Harper, 12:15-12:45 Mrs. Lloyd', available: 'part' }
     *  ...
     * }
     * ...
     */
    availabilities: Ember.computed('searchType', 'timeWindow', 'selectedRoom', 'appointmentsByPlace', 'slots', 'patient', 'showOnlyAppointments', 'appointmentsLoadedFlag', function () {
      var _this2 = this;

      // { room1: { all: {}, place1_1: {}, ... }, room2: { all: {}, place2_1: {}, ... } }
      var todaysLocationData = [{}].concat(this.get('roomsAsList').map(function (room) {
        return room.id;
      })).reduce(function (roomData, roomId) {
        roomData[roomId] = [{
          slots: [],
          all: {
            availableRanges: _this2.availableRanges(_this2.get('appointmentsByPlace.' + roomId + '.all'))
          }
        }].concat(_this2.get('rooms.' + roomId + '.places')).reduce(function (acc2, place) {
          acc2[place.id] = {
            availableRanges: _this2.availableRanges(_this2.get('appointmentsByPlace.' + roomId + '.' + place.id))
          };

          _this2.cleanUpLocationRanges(acc2.all.availableRanges, _this2.get('appointmentsByPlace.' + roomId + '.' + place.id));

          _this2.cleanUpLocationRanges(acc2[place.id].availableRanges, _this2.get('appointmentsByPlace.' + roomId + '.all'));

          return acc2;
        });
        return roomData;
      });
      var availabilities = [Ember.Object.create({
        todaysLocationData: todaysLocationData
      })].concat(this.get('slots')).reduce(function (acc, slot) {
        var slotAvailabilities = [Ember.Object.create({
          locationAvailabilities: Ember.Object.create({}),
          todaysLocationData: acc.todaysLocationData
        })].concat(_this2.get('roomsAsList').map(function (room) {
          return room.id;
        })).reduce(function (slotLocationAvailabilities, roomId) {
          var slotAppointmentsForRoom = (_this2.get('appointmentsByPlace.' + roomId + '.all') || []).filter(function (app) {
            return _this2.overlappingRanges(app.range, [slot.fromInt, slot.fromInt +
            /*(this.slotDuration/100*60)*/
            _this2.slotDuration]);
          });
          var roomBlocked = false; // meaning that the current slot is completely consumed by room/all-appointments

          if (slotAppointmentsForRoom != null && slotAppointmentsForRoom.length >= 1) {
            roomBlocked = [[slot.fromInt, slot.fromInt]].concat(slotAppointmentsForRoom).reduce(function (continuousRange, app) {
              if (_this2.overlappingRanges(continuousRange, app.range)) return [continuousRange[0], app.range[1]];else return continuousRange;
            })[1] >= slot.fromInt +
            /*(this.slotDuration/100*60)*/
            _this2.slotDuration;
          }

          var timeWindowDiff = null;

          if (_this2.get('timeWindow') != null) {
            var hours = parseInt(_this2.get('timeWindow') / 60);
            timeWindowDiff = hours * 100 + (_this2.get('timeWindow') / 60 - hours) * 60;
          }

          var curAvailableSlotRangesForRoom = slotLocationAvailabilities.todaysLocationData[roomId].all.availableRanges.filter(function (range) {
            return range[0] < slot.fromInt +
            /*(this.slotDuration/100*60)*/
            _this2.slotDuration && range[1] > slot.fromInt;
          });

          var slotAvailabilityStates = _this2.slotAvailabilityStates(slot, curAvailableSlotRangesForRoom);

          var slotAllNone = slotAvailabilityStates.none;
          var slotAllPart = slotAvailabilityStates.part;

          _this2.get('rooms.' + roomId + '.places').forEach(function (place) {
            // let slotAppointmentsForPlace = this.get('slotAppointments.'+roomId+'.'+slot.from+'.'+place.id);
            var slotAppointmentsForPlace = (_this2.get('appointmentsByPlace.' + roomId + '.' + place.id) || []).filter(function (app) {
              return _this2.overlappingRanges(app.range, [slot.fromInt, slot.fromInt +
              /*(this.slotDuration/100*60)*/
              _this2.slotDuration]);
            });
            var curAvailableSlotRangesForPlace = slotLocationAvailabilities.todaysLocationData[roomId][place.id].availableRanges.filter(function (range) {
              return range[0] < slot.fromInt +
              /*(this.slotDuration/100*60)*/
              _this2.slotDuration && range[1] > slot.fromInt;
            });

            var slotAvailabilityStates = _this2.slotAvailabilityStates(slot, curAvailableSlotRangesForPlace);

            var slotNone = slotAvailabilityStates.none;
            var slotPart = slotAvailabilityStates.part;
            if (slotNone) slotAllNone = true;
            if (slotPart) slotAllPart = true;

            if (_this2.searchType == 'available') {
              if ((acc.todaysLocationData[roomId].slots[acc.todaysLocationData[roomId].slots.length - 1] || {}).fromInt != slot.fromInt) acc.todaysLocationData[roomId].slots.push(slot);

              if (slotNone || roomBlocked) {
                // if (slotAppointmentsForPlace == null || slotAppointmentsForPlace.length == 0) {
                slotLocationAvailabilities.locationAvailabilities.set(place.id, {
                  slotAppointments: slotAppointmentsForPlace,
                  time: null,
                  available: slotNone || roomBlocked ? 'none' : slotPart ? 'part' : 'all'
                });
              } else {
                // timewindow is a search-param for minimum-available-duration
                slotLocationAvailabilities.locationAvailabilities.set(place.id, {
                  slotAppointments: slotAppointmentsForPlace,
                  time: _this2.formatTimeInt(Math.max(curAvailableSlotRangesForPlace[0][0], slot.fromInt)) + ' - ' + _this2.formatTimeInt(curAvailableSlotRangesForPlace[0][1]),
                  available: slotNone ? 'none' : slotPart ? 'part' : 'all',
                  // timewindow: timeWindowDiff != null && Math.abs(curAvailableSlotRangesForPlace[0][1] - (lastAppointmentForPlace||{range:[-1,this.firstSlotStart]}).range[1]) >= timeWindowDiff
                  timewindow: timeWindowDiff != null && Math.abs(curAvailableSlotRangesForPlace[0][1] - Math.max(curAvailableSlotRangesForPlace[0][0], slot.fromInt)) >= timeWindowDiff
                });
              }
            } else {
              if ((acc.todaysLocationData[roomId].slots[acc.todaysLocationData[roomId].slots.length - 1] || {}).fromInt != slot.fromInt) {
                var hide = _this2.get('patient') != null && slotAppointmentsForPlace.find(function (a) {
                  return a.patient.id == _this2.get('patient.id') && a.roomId == roomId;
                }) == null;
                if (!hide) acc.todaysLocationData[roomId].slots.push(slot);
              }

              if (slotAppointmentsForPlace == null || slotAppointmentsForPlace.length == 0) {
                slotLocationAvailabilities.locationAvailabilities.set(place.id, {
                  slotAppointments: [],
                  time: null,
                  available: slotNone ? 'none' : slotPart ? 'part' : 'all'
                });
              } else {
                var maybeFilteredData = slotAppointmentsForPlace;
                if (_this2.get('patient') != null && slotAppointmentsForPlace != null) maybeFilteredData = slotAppointmentsForPlace.filter(function (app) {
                  return app.patient.id == _this2.get('patient.id');
                });
                slotLocationAvailabilities.locationAvailabilities.set(place.id, {
                  slotAppointments: slotAppointmentsForPlace,
                  time: maybeFilteredData.join('\n') != '' ? maybeFilteredData.join('\n') : null,
                  available: slotNone ? 'none' : slotPart ? 'part' : 'all'
                });
              }
            }
          }); // this.get('rooms.'+roomId+'.places').forEach


          if (_this2.searchType == 'available') {
            if ((acc.todaysLocationData[roomId].slots[acc.todaysLocationData[roomId].slots.length - 1] || {}).fromInt != slot.fromInt) acc.todaysLocationData[roomId].slots.push(slot);

            if (curAvailableSlotRangesForRoom.length == 0 || slotAllNone) {
              // if (slotAppointmentsForRoom == null || slotAppointmentsForRoom.length == 0) {
              slotLocationAvailabilities.locationAvailabilities.set('r' + roomId, {
                slotAppointments: [],
                time: null,
                available: slotAllNone ? 'none' : slotAllPart ? 'part' : 'all'
              });
            } else {
              slotLocationAvailabilities.locationAvailabilities.set('r' + roomId, {
                slotAppointments: slotAppointmentsForRoom,
                time: _this2.formatTimeInt(Math.max(curAvailableSlotRangesForRoom[0][0], slot.fromInt)) + ' - ' + _this2.formatTimeInt(curAvailableSlotRangesForRoom[0][1]),
                available: slotAllNone ? 'none' : slotAllPart ? 'part' : 'all',
                // timewindow: timeWindowDiff != null && Math.abs(curAvailableSlotRangesForRoom[0][1] - (lastAppointmentForRoom||{range:[-1,this.firstSlotStart]}).range[1]) >= timeWindowDiff
                timewindow: timeWindowDiff != null && Math.abs(curAvailableSlotRangesForRoom[0][1] - Math.max(curAvailableSlotRangesForRoom[0][0], slot.fromInt)) >= timeWindowDiff
              });
            }
          } else {
            if ((acc.todaysLocationData[roomId].slots[acc.todaysLocationData[roomId].slots.length - 1] || {}).fromInt != slot.fromInt) {
              var hide = _this2.get('patient') != null && slotAppointmentsForRoom.find(function (a) {
                return a.patient.id == _this2.get('patient.id') && a.roomId == roomId;
              }) == null;
              if (!hide) acc.todaysLocationData[roomId].slots.push(slot);
            }

            if (slotAppointmentsForRoom == null || slotAppointmentsForRoom.length == 0) {
              slotLocationAvailabilities.locationAvailabilities.set('r' + roomId, {
                slotAppointments: [],
                time: null,
                available: slotAllNone ? 'none' : slotAllPart ? 'part' : 'all'
              });
            } else {
              var maybeFilteredData = slotAppointmentsForRoom;
              if (_this2.get('patient') != null) maybeFilteredData = slotAppointmentsForRoom.filter(function (app) {
                return app.patient.id == _this2.get('patient.id');
              });
              slotLocationAvailabilities.locationAvailabilities.set('r' + roomId, {
                slotAppointments: slotAppointmentsForRoom,
                time: maybeFilteredData.join('\n') != '' ? maybeFilteredData.join('\n') : null,
                available: slotAllNone ? 'none' : slotAllPart ? 'part' : 'all'
              });
            }
          }

          return slotLocationAvailabilities;
        });
        acc.set(slot.from, slotAvailabilities.locationAvailabilities);
        return acc;
      });
      return availabilities;
    })
  });

  _exports.default = _default;
});