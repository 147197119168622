define("digiprax-web/controllers/customers/edit/invoices/new", ["exports", "digiprax-web/controllers/base", "digiprax-web/mixins/invoice-dialogs-p", "digiprax-web/mixins/auto-complete-patient-search"], function (_exports, _base, _invoiceDialogsP, _autoCompletePatientSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_invoiceDialogsP.default, _autoCompletePatientSearch.default, {
    clipboard: Ember.inject.service(),
    invoiceEditor: Ember.inject.service(),
    actions: {
      checkCopy: function checkCopy(treatment) {
        return this.get('clipboard').checkCopyTreatment(treatment);
      },
      paste: function paste() {
        var _this2 = this;

        Ember.run.schedule("afterRender", function () {
          _this2.set('choosePaste', false);
        });
        return this.get('clipboard').pasteTreatments(this.get('model'));
      },
      saveInvoice: function saveInvoice() {
        this.get('invoiceEditor').saveInvoice(this.get('model'), this.get('model.newLotPlaceholders').toArray(), this);
      },
      replaceComma: function replaceComma() {
        var _this = Ember.$('input:focus');

        _this.val(_this.val().replace(",", "."));

        return false;
      },
      openClipboardMenu: function openClipboardMenu() {
        if (event.button != 0
        /* || event.which != 0*/
        ) {
            this.set('choosePaste', true);
          }

        return false;
      },
      cancelPaste: function cancelPaste() {
        this.get('clipboard').clear();
        this.set('choosePaste', false);
      }
    },
    inValid: function inValid(errors, config) {
      // $('html, body').animate({scrollTop: 0}, 500);
      var configAvailable = !(typeof config == "undefined" || config == null);
      if (!configAvailable) config = {
        timeout: 10000
      };
      var errorHtml = '';
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = errors[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var error = _step.value;
          errorHtml += '<li>' + error + '</li>';
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.get('flashMessages').error('<ul>' + errorHtml + '</ul>', config);
    },
    afterSave: function afterSave(invoice) {
      var _this3 = this;

      var _patient = invoice.get('store').peekRecord('patient', invoice.get('patientId')); // patient.set('invoices', patient.get('invoices').toArray().filter(i => i.get('id') != 'new').concat([invoice]).sort((i1, i2) => i2.get('date') - i1.get('date') >= 0 ? -1 : 1));


      var newInvoice = this.get('store').peekRecord('invoice-for-business', 'new');
      if (newInvoice != null) newInvoice.unloadRecord();
      Ember.getOwner(this).lookup('route:customers.edit.invoices.new').set('newInvoiceInProgress', null);
      Ember.run.schedule("afterRender", function () {
        // $('html, body').animate({scrollTop: 0}, 500);
        _this3.get('flashMessages').success('Rechnung gespeichert', {
          timeout: 1000
        });
      });
      this.transitionToRoute('customers.edit.invoices.edit', invoice.get('patient.id') || invoice.get('patientId'), invoice.get('id'));
    }
  });

  _exports.default = _default;
});