define("digiprax-web/components/how-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isMobile: false,
    subject: null,
    currentTopic: Ember.computed.alias('subject.currentHowToTopic'),
    currentStep: Ember.computed.alias('subject.currentHowToStep')
  });

  _exports.default = _default;
});