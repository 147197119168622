define("digiprax-web/services/practice-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    savePractice: function savePractice(listener) {
      var _this = this;

      var errors = [];
      var practice = this.get('application.practice');
      var user = Ember.Object.create(this.get('application.user'));
      delete user.practice;
      practice.set('user', user);

      if (this._validatePractice(practice, errors)) {
        this.get('application').showProgressbar();
        practice.save().then(function (updatedPractice) {
          _this.get('application').hideProgressbar();

          updatedPractice.syncLogos();
          listener.afterSave(updatedPractice);
        }, function (error) {
          _this.get('application').hideProgressbar();

          if (typeof error.get == 'function') {
            listener.inValid(error.get('messages'));
          } else {
            _this.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

            listener.inValid(['Unbekannter Fehler!']);
          }
        });
      } else {
        listener.inValid(errors, {
          timeout: 5000
        });
      }
    },
    _validatePractice: function _validatePractice(practice, errors) {
      // if ([null, ''].indexOf(practice.get('name')) >= 0) errors.push('Praxisname muss angegeben werden.');
      if ([null, ''].indexOf(practice.get('email')) >= 0) errors.push('Email muss angegeben werden.');
      if ([null, ''].indexOf(practice.get('addressZip')) >= 0) errors.push('Postleitzahl muss angegeben werden.');
      if ([null, ''].indexOf(practice.get('phoneLandline')) >= 0) errors.push('Festnetz-Telefonnummer muss angegeben werden.');
      return errors.length == 0;
    }
  });

  _exports.default = _default;
});