define("digiprax-web/transforms/backend-date", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(date, _options) {
      if (date != null) {
        // return new Date(moment(date) + moment(date).toDate().getTimezoneOffset()*1000*60);
        var dateMom = (0, _moment.default)(date);
        return dateMom.add(dateMom.utcOffset(), 'minutes').toDate();
      }

      return date;
    },
    serialize: function serialize(deserialized, _options) {
      if (deserialized != null) {
        // return (new Date(moment(deserialized) - moment(deserialized).toDate().getTimezoneOffset()*1000*60)); // .toISOString();
        if (typeof deserialized == 'string' && deserialized.match(/T00:00:00\.[0-9]+Z$/) == null) {
          var _dateMom = (0, _moment.default)(deserialized);

          return _dateMom.add(_dateMom.utcOffset(), 'minutes').toDate().toJSON().replace(/T.+Z/, 'T00:00:00Z');
        }

        var dateMom = (0, _moment.default)(deserialized);
        if (dateMom.toDate().toJSON().match(/T00:00:00\.[0-9]+Z$/) == null) return dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON().replace(/T.+Z/, 'T00:00:00Z');
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});