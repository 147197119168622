define("digiprax-web/routes/patients/edit/index", ["exports", "digiprax-web/mixins/patient-editor-route"], function (_exports, _patientEditorRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_patientEditorRoute.default, {
    calendarEditor: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      // console.log('from.patient_id = ' + (transition.from ? transition.from.parent.params.patient_id : null) + ', to.patient_id = ' + transition.to.parent.params.patient_id);
      if (transition.to.parent.params.patient_id == null) return this.transitionTo('patients.overview');
      return this._super(transition);
    },
    // model(params, transition) {
    //   let patient = this.store.peekRecord('patient', transition.to.parent.params.patient_id);
    //   return patient != null ? patient : this.store.queryRecord('patient', transition.to.parent.params.patient_id);
    // },
    onTreatmentsLoaded: function onTreatmentsLoaded(patient) {
      var _this = this;

      return function (treatments) {
        if (_this.calendarEditor.curAppointment != null) {
          var associatedTreatment;

          if (_this.calendarEditor.curAppointment.treatment_id == null) {
            associatedTreatment = treatments.find(function (t) {
              return _this.calendarEditor.curAppointment.date == t.date;
            });

            if (associatedTreatment == null) {
              var existingAssociatedTreatment = patient.get('sortedNewTreatmentPlaceholders').find(function (t) {
                return _this.calendarEditor.curAppointment.date == t.date;
              });

              if (existingAssociatedTreatment == null) {
                patient.get('sortedNewTreatmentPlaceholders')[patient.get('newTreatmentPlaceholders').length - 1].setProperties({
                  date: _this.calendarEditor.curAppointment.date,
                  notes: '-',
                  appointment: _this.calendarEditor.curAppointment
                }); // let _initNumDirty = patient.get('numNewTreatmentsDirty');
              }
            } else {// existing persisted treatment has same date as curAppointment
              }
          }

          _this.calendarEditor.set('curAppointment', null);
        }
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get('id') != 'new') {
        localStorage.setItem('peru.lastViewedPatientId', '"' + model.get('id') + '"');
        localStorage.setItem('lastViewedPatientId', model.get('id'));

        this._initPatientRelations(model, {
          treatments: this.onTreatmentsLoaded(model)
        });

        controller.set('application.currentPatient', model); // controller.set('newTreatmentPlaceholders', this._newTreatmentPlaceholders(model));

        var _initNumDirty = model.get('numNewTreatmentsDirty');

        controller.set('actions.deleteTreatment', this.get('actions.deleteTreatment'));
        controller.set('actions.removeNewTreatment', this.get('actions.removeNewTreatment'));
        controller.set('actions.moveTreatment', this.get('actions.moveTreatment'));
      } // case reload ...


      controller.get('application').setActiveNavLink('linkTo.patientenkarte');
    }
  });

  _exports.default = _default;
});