define("digiprax-web/controllers/practice/templates", ["exports", "digiprax-web/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    queryParams: ['currentTemplate'],
    actions: {
      showTemplate: function showTemplate(templateId) {
        this.transitionToRoute(this.currentRouteName, {
          queryParams: {
            currentTemplate: templateId
          }
        });
      }
    }
  });

  _exports.default = _default;
});