define("digiprax-web/mixins/invoice-editor-p-route", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      deleteLot: function deleteLot(lot, idx) {
        var _this = this;

        this.set('application.confirmMessage', {
          message: 'Soll die Bestellung unwiderruflich gelöscht werden?',
          callback: function callback() {
            lot.destroyRecord().then(function (_response) {
              lot.unloadRecord();

              if (lot.get('invoice_id') != null) {
                _this.get('model').reload().then(function (_i) {
                  // this.set('model.entries', this.get('model.store').peekAll('lot').filter(lot => lot.get('invoice_id') == model.id));
                  _this.set('model._entriesAddedOrDeleted', [_this.get('model.entries.length'), 1]);
                });
              }

              var entries = _this.get('model.sortedEntries');

              entries.splice(idx, 1);

              _this.set('model.entries', entries.concat([]));

              _this.get('flashMessages').success('Behandlung erfolgreich gelöscht ...', {
                timeout: 3000
              });
            });
          }
        });
        Ember.$('#confirmMessage').trigger('click');
      },
      removeNewLot: function removeNewLot(lot, _idx) {
        this.get('model.newEntryPlaceholders').removeObject(lot);
      },
      moveLot: function moveLot(invoice, lot, idx, direction) {
        if (direction == 'u') {
          lot.extraInfo.oldPositionTS = lot.extraInfo.positionTS;
          lot.extraInfo.positionTS = invoice.sortedEntries[idx - 1].extraInfo.positionTS - 1;
          lot.set('extraInfo', JSON.parse(JSON.stringify(lot.extraInfo))); // lot.extraInfo.setProperties({oldPositionTS: lot.extraInfo.positionTS,
          //                                    positionTS: invoice.sortedEntries[idx-1].extraInfo.positionTS - 1});
        } else {
          lot.extraInfo.oldPositionTS = lot.extraInfo.positionTS;
          lot.extraInfo.positionTS = invoice.sortedEntries[idx + 1].extraInfo.positionTS + 1;
          lot.set('extraInfo', JSON.parse(JSON.stringify(lot.extraInfo))); // lot.extraInfo.setProperties({oldPositionTS: lot.extraInfo.positionTS,
          //                                    positionTS: invoice.sortedEntries[idx-1].extraInfo.positionTS + 1});
        }

        invoice.set('dayGroups.updatedAt', new Date());
      }
    },
    _initInvoiceParams: function _initInvoiceParams(invoice) {
      var _this2 = this;

      invoice.get('patient').then(function (patient) {
        invoice.set('loadedPatient', patient);

        _this2.get('store').findAll('product').then(function (_products) {
          var sort = function sort(iP1, iP2) {
            // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
            return iP2.get('date') - iP1.get('date') >= 0 ? -1 : 1;
          };

          var sorts = {
            /*diagnosis: sort, */
            lot: sort
          };
          /*
           * entries X invoices ... 1:1 :: lot.invoice_id (FIXME: clarify? 1 behandlung kann nur einmal verrechnet werden)
           * diagnoses  X invoices ... n:m :: invoice.extraInfo.diagnoseIds
           */
          // show entries without invoiceId: var filters = {diagnosis: (invoice, diagnose) => { return invoice.hasDiagnose(diagnose); }, lot: (invoice, lot) => { return [null, invoice.id].indexOf(lot.get('invoiceId')) >= 0; }};
          // show entries with current-model-invoiceId:

          var filters = {
            /*diagnosis: (invoice, diagnose) => { return invoice.hasDiagnose(diagnose); }, */
            lot: function lot(invoice, _lot) {
              return _lot.get('invoice_id') == invoice.id;
            }
          };
          var invoiceParamTypes = [
          /*'diagnosis', */
          'lot'];

          _this2._loadInvoiceParamsSequentially(invoice, invoiceParamTypes, filters, sorts);
          /*for (var i=0 ; i<invoiceParamTypes.length ; i++) {
            this._loadInvoiceParams(invoice, invoiceParamTypes[i], sorts[invoiceParamTypes[i]]);
          }*/

        });
      });
    },
    _loadInvoiceParamsSequentially: function _loadInvoiceParamsSequentially(invoice, invoiceParamTypes, filters, sorts) {
      this._loadInvoiceParams(invoice, invoiceParamTypes[0], filters[invoiceParamTypes[0]], sorts[invoiceParamTypes[0]], invoiceParamTypes, filters, sorts);
    },
    _loadInvoiceParams: function _loadInvoiceParams(invoice, invoiceParamType, filter, sort, invoiceParamTypes, filters, sorts) {
      var _this3 = this;

      // this.get('store').findAll(invoiceParamType).then(results => this._setInvoiceParam(invoice, invoiceParamType, results.filter((invoiceParam) => {
      this.get('store').query(invoiceParamType, {
        patientId: invoice.get('patientId')
      }).then(function (results) {
        return _this3._setInvoiceParam(invoice, invoiceParamType, results.filter(function (invoiceParam) {
          return invoiceParam.get('patient_id') == invoice.get('patient.id');
        }), filter, sort, invoiceParamTypes, filters, sorts);
      });
    },
    _setInvoiceParam: function _setInvoiceParam(invoice, invoiceParamType, invoiceParam, filter, sort, invoiceParamTypes, filters, sorts) {
      invoice.set('patient.' + (0, _emberInflector.pluralize)(invoiceParamType), invoiceParam);
      var iPList = invoice.get('patient.' + (0, _emberInflector.pluralize)(invoiceParamType));
      invoiceParam = new Array();

      for (var i = 0; i < iPList.length; i++) {
        if (filter(invoice, iPList[i])) {
          // iPList[i].type = invoiceParamType; // required by ember _buildInternalModel
          invoiceParam.push(iPList[i]);

          if (invoiceParamType == 'lot') {
            if (iPList[i].get('product_id') != null) {
              var product = this.get('store').peekRecord('product', iPList[i].get('product_id'));
              iPList[i].set('product', product);
            } else {
              iPList[i].set('product', Ember.Object.create({
                name: iPList[i].get('notes')
              }));
              iPList[i].set('number', 1);
            }

            if (iPList[i].get('invoice_id') != null) {
              iPList[i].set('invoice', invoice);
            }

            iPList[i].set('targetInvoice', invoice);
          }
        }
      }

      invoice.set((0, _emberInflector.pluralize)(invoiceParamType), invoiceParam.sort(sort)); // this.set('controller.'+pluralize(invoiceParamType), invoiceParam.sort(sort));

      if (invoiceParamTypes.length >= 2) {
        this._loadInvoiceParamsSequentially(invoice, invoiceParamTypes.slice(1), filters, sorts);
      }
    }
  });

  _exports.default = _default;
});