define("digiprax-web/mixins/invoice-lots", ["exports", "digiprax-web/models/new-lot-placeholder"], function (_exports, _newLotPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import moment from 'moment';
  var _default = Ember.Mixin.create({
    invoiceNumberPrint: Ember.computed('invoiceNumber', function () {
      return 'G-' + this.get('invoiceNumber');
    }),
    domain: 'business',
    entries: Ember.computed.alias('lots'),
    sortedEntries: Ember.computed.alias('sortedLots'),
    newEntryPlaceholders: Ember.computed.alias('newLotPlaceholders'),
    sortedNewEntryPlaceholders: Ember.computed.alias('sortedNewLotPlaceholders'),
    // _newEntryPlaceholders: alias('_newLotPlaceholders'),
    hasLots: Ember.computed.alias('hasEntries'),
    entriesDirty: Ember.computed.alias('lotsDirty'),
    sortableLots: Ember.computed('entries', 'entries.@each.date', 'entries.@each.extraInfo'
    /*.positionTS'*/
    , function () {
      return this.get('entries');
    }),
    sortedLots: Ember.computed.sort('sortableLots', function (i1, i2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (i1.get('dateFmtd') == i2.get('dateFmtd')) {
        var positionSort = this.sortByPosition(i1, i2);
        if (positionSort != null) return positionSort;
      }

      return i2.get('date') - i1.get('date') >= 0 ? -1 : 1;
    }),
    sortableNewLotPlaceholders: Ember.computed('newLotPlaceholders', 'newLotPlaceholders.@each.date', 'newLotPlaceholders.@each.extraInfo'
    /*.positionTS'*/
    , function () {
      return this.get('newLotPlaceholders');
    }),
    sortedNewLotPlaceholders: Ember.computed.sort('sortableNewLotPlaceholders', function (i1, i2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (!i1.isDirty()) return 1;
      if (!i2.isDirty()) return -1;
      if (i1.get('date') == null) return 1;
      if (i2.get('date') == null) return -1;

      if (i1.get('dateFmtd') == i2.get('dateFmtd')) {
        var positionSort = this.sortByPosition(i1, i2);
        if (positionSort != null) return positionSort;
      }

      return i2.get('date') - i1.get('date') >= 0 ? -1 : 1;
    }),
    lotsDirty: Ember.computed('lots.@each.date', 'lots.@each.tariffId', 'lots.@each.lotText', 'lots.@each.number', 'lots.@each.costFmtd', 'numNewLotsDirty', function () {
      return this.get('numNewLotsDirty') >= 1 || (this.get('lots') || []).toArray().filter(function (t) {
        return t.isDirty();
      }).length >= 1;
    }),
    numNewLotPlaceholders: 1,
    newLotPlaceholders: Ember.computed('numNewLotsDirty', 'savedAt', {
      get: function get(_key) {
        var newLotPlaceholders = this.get('_newLotPlaceholders');

        if (newLotPlaceholders == null) {
          var nTPs = new Array();

          for (var i = 0; i < this.get('numNewLotPlaceholders'); i++) {
            nTPs.push(_newLotPlaceholder.default.create({
              tmpId: Math.round(Math.random(1000000) * 1000000),
              patient_id: this.get('patient.id') || this.get('patientId'),
              invoice_id: this.get('id'),
              invoice: this,
              positionTS: new Date().getTime() + (i + 1)
            }));
          }

          newLotPlaceholders = Ember.A(nTPs); // eslint-disable-next-line ember/no-side-effects

          this.set('_newLotPlaceholders', newLotPlaceholders);
        } else {
          var numDirty = this._numNewLotsDirty(newLotPlaceholders.toArray());

          if (newLotPlaceholders.toArray().length < this.get('numNewLotPlaceholders') + numDirty) {
            // Ember.Logger.debug('nTPs.length = '+newLotPlaceholders.toArray().length+', numDirty = '+numDirty);
            newLotPlaceholders.pushObject(_newLotPlaceholder.default.create({
              tmpId: Math.round(Math.random(1000000) * 1000000),
              patient_id: this.get('patient.id') || this.get('patientId'),
              invoice_id: this.get('id'),
              invoice: this,
              positionTS: new Date().getTime()
            }));
          }
        }

        return newLotPlaceholders;
      },
      set: function set(_key, value) {
        this.set('_newLotPlaceholders', value);
        return value;
      }
    }),
    numNewLotsDirty: Ember.computed('newLotPlaceholders.@each.date', 'newLotPlaceholders.@each.lotText', 'newLotPlaceholders.@each.number', 'newLotPlaceholders.@each.costFmtd', function () {
      return this._numNewLotsDirty(this.get('newLotPlaceholders').toArray());
    }),
    _numNewLotsDirty: function _numNewLotsDirty(newLotPlaceholders) {
      var num = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = newLotPlaceholders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var lot = _step.value;
          // if (lot.get('hasDirtyAttributes')) num = num + 1;
          if (lot.isDirty()) num = num + 1;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return num;
    },
    totalsWithoutSalesTax: Ember.computed('lots.@each.number', 'lots.@each.costFmtd', 'lots.@each.taxPercentage', 'newLotPlaceholders.@each.number', 'newLotPlaceholders.@each.costFmtd', 'newLotPlaceholders.@each.taxPercentage', function () {
      var totals = {
        tax_percentages: []
      };
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.lots.concat(this.newLotPlaceholders)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var lot = _step2.value;
          var lotProduct = (lot.product || {}).id != null ? lot.product : null;
          var product = lotProduct || (this.liable_to_sales_tax ? {
            tax_percentage: parseFloat(lot.taxPercentage || 0)
          } : null); //if (lot.product == null) continue;

          if (product == null || [null, 0].indexOf(product.tax_percentage) >= 0) continue;

          if (!totals[product.tax_percentage]) {
            totals.tax_percentages.push(product.tax_percentage);
            totals[product.tax_percentage] = {
              net: 0,
              tax: 0
            };
          }

          var curLotTax = Math.floor(parseFloat(lot.costFmtd.replace(',', '.')) * 100 * lot.number / (100 + product.tax_percentage) * product.tax_percentage);
          totals[product.tax_percentage].tax += curLotTax;
          totals[product.tax_percentage].net += parseFloat(lot.costFmtd.replace(',', '.')) * 100 * lot.number - curLotTax;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      totals.totNet = [parseFloat(this.virtTotal.replace(',', '.')) * 100].concat(totals.tax_percentages).reduce(function (acc, tP) {
        return acc - totals[tP].tax;
      });
      totals.totTax = [0].concat(totals.tax_percentages).reduce(function (acc, tP) {
        return acc + totals[tP].tax;
      });
      totals.tax_percentages.sort(function (tP1, tP2) {
        return tP1 >= tP2 ? 1 : -1;
      });
      return Ember.Object.create(totals);
    })
  });

  _exports.default = _default;
});