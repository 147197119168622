define("digiprax-web/mixins/patient-editor-route", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    hideInvoicedTreatments: true,
    actions: {
      deleteTreatment: function deleteTreatment(treatment, idx) {
        var _this = this;

        this.set('application.confirmMessage', {
          message: 'Soll die Behandlung unwiderruflich gelöscht werden?',
          callback: function callback() {
            var treatments = _this.get('model.sortedTreatments');

            treatments.splice(idx, 1);

            _this.set('model.treatments', treatments.concat([]));

            treatment.destroyRecord().then(function (_response) {
              treatment.unloadRecord(); // $('html, body').animate({scrollTop: 0}, 500);

              _this.get('flashMessages').success('Behandlung erfolgreich gelöscht ...', {
                timeout: 3000
              });
            });
          }
        });
        Ember.$('#confirmMessage').trigger('click');
      },
      removeNewTreatment: function removeNewTreatment(treatment, _idx) {
        this.get('model.newTreatmentPlaceholders').removeObject(treatment);
      }
    },
    _initPatientRelations: function _initPatientRelations(patient, callbacks) {
      var sort = function sort(pR1, pR2) {
        // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
        return pR2.get('date') - pR1.get('date') >= 0 ? -1 : 1;
      };

      var sorts = {
        /*diagnosis: sort, */
        treatment: sort
      };
      var patientRelationTypes = [
      /*'diagnosis', */
      'treatment'];

      this._loadPatientRelationsSequentially(patient, patientRelationTypes, sorts, callbacks);
    },
    _loadPatientRelationsSequentially: function _loadPatientRelationsSequentially(patient, patientRelationTypes, sorts, callbacks) {
      this._loadPatientRelations(patient, patientRelationTypes[0], sorts[patientRelationTypes[0]], patientRelationTypes, sorts, callbacks);
    },
    _loadPatientRelations: function _loadPatientRelations(patient, patientRelationType, sort, patientRelationTypes, sorts, callbacks) {
      var _this2 = this;

      // this.get('store').findAll(patientRelationType).then(results => this._setPatientRelation(patient, patientRelationType, results.filter((patientRelation) => {
      this.get('store').query(patientRelationType, {
        patientId: patient.id
      }).then(function (results) {
        return _this2._setPatientRelation(patient, patientRelationType, results.filter(function (patientRelation) {
          if (patientRelation.constructor.modelName == 'treatment' && _this2.get('hideInvoicedTreatments')) {
            if (patientRelation.get('invoiceId') != null) return false;
          }

          return patientRelation.get('patientId') == patient.id;
        }), sort, patientRelationTypes, sorts, callbacks);
      });
    },
    _setPatientRelation: function _setPatientRelation(patient, patientRelationType, patientRelationList, sort, patientRelationTypes, sorts, callbacks) {
      patient.set((0, _emberInflector.pluralize)(patientRelationType), patientRelationList.sort(sort));
      if (callbacks && callbacks[(0, _emberInflector.pluralize)(patientRelationType)]) callbacks[(0, _emberInflector.pluralize)(patientRelationType)](patientRelationList.sort(sort));

      if (patientRelationTypes.length >= 2) {
        this._loadPatientRelationsSequentially(patient, patientRelationTypes.slice(1), sorts, callbacks);
      }
    }
  });

  _exports.default = _default;
});