define("digiprax-web/components/calendar-week", ["exports", "moment", "digiprax-web/mixins/appointment-dialogs", "digiprax-web/mixins/calendar-utils", "digiprax-web/models/slot-appointment-data", "digiprax-web/models/slot-appointments"], function (_exports, _moment, _appointmentDialogs, _calendarUtils, _slotAppointmentData, _slotAppointments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{calendar-week kwSelector=kwSelector}}
  var _default = Ember.Component.extend(_calendarUtils.default, _appointmentDialogs.default,
  /*AutoCompletePatientSearchMixin, */
  {
    calendarEditor: Ember.inject.service(),
    practice: Ember.computed.alias('calendarEditor.application.practice'),
    patients: Ember.computed.alias('calendarEditor.application.patients'),
    sortedPatients: Ember.computed.sort('patients', 'patientSortDef'),
    patientSortDef: Ember.computed(function () {
      return ['lastName:asc', 'firstName:asc'];
    }),
    progressHandler: Ember.computed.alias('calendarEditor.application'),
    searchTerm: null,
    kwSelector: null,
    // param
    firstSlotStart: Ember.computed.alias('kwSelector.firstSlotStart'),
    lastSlotEnd: Ember.computed.alias('kwSelector.lastSlotEnd'),
    slotDuration: Ember.computed.alias('kwSelector.slotDuration'),
    searchType: Ember.computed.alias('kwSelector.searchType'),
    counterMap: Ember.computed('weekDays', {
      get: function get(_key) {
        // eslint-disable-next-line ember/no-side-effects
        return this.get('_counterMap') || this.set('_counterMap', Ember.Object.create({}));
      },
      set: function set(_key, counterMap) {
        return this.set('_counterMap', counterMap);
      }
    }),
    year: Ember.computed.alias('kwSelector.selectedYear'),
    week: Ember.computed.alias('kwSelector.selectedWeek'),
    weekDays: Ember.computed('year', 'week', function () {
      return this.weekdays(this.year, this.week);
    }),
    prevKW: Ember.computed('year', 'week', function () {
      return this.weekDays[0].clone().add(-1, 'day').week();
    }),
    nextKW: Ember.computed('year', 'week', function () {
      return this.weekDays[6].clone().add(1, 'day').week();
    }),
    appointmentsByPlace: Ember.computed('year', 'week', function () {
      var _this = this;

      // console.log('CalendarWeek - appointmentsByPlace: year = '+this.year+', week = '+this.week);
      var result = this.get('_prevAvaiabilities');

      if (result == null) {
        // _prevAvaiabilities will be updated in
        var weekDayData = Ember.Object.create({
          counterMap: Ember.Object.create({}),
          firstDay: this.weekDays[0].clone(),
          lastDay: this.weekDays[6].clone(),
          slots: this.slotTimes(),
          appointmentsByDay: Ember.Object.create({}),
          locationDataByDay: Ember.Object.create({})
        }); // eslint-disable-next-line ember/no-side-effects

        result = this.set('_prevAvaiabilities', Ember.A([weekDayData]));

        var weekSetupCallbackFactory = function weekSetupCallbackFactory() {
          var weekSetupCB;

          if (_this.kwSelector.preselectedSlot != null) {
            /**
             * kwSelector - showWeek-action called when in month view and click on any slot-square of any day.
             * results in week-view scrolling to and highlighting slot.
             */
            weekSetupCB = function weekSetupCB(weekDayData) {
              _this.get('_prevAvaiabilities').replace(0, 1, [weekDayData]); // eslint-disable-next-line ember/no-side-effects


              _this.set('counterMap', weekDayData.get('counterMap'));

              _this._setSlotSideCss();

              Ember.run.scheduleOnce("afterRender", function () {
                // let wdKey = Object.keys(this.availabilities.locationDataByDay)[this.kwSelector.preselectedSlot.wd];
                // let targetSlot = this.availabilities.get('locationDataByDay.'+wdKey+'.slots.'+this.availabilities.slots.indexOf(this.kwSelector.preselectedSlot.slot));
                // let slotEl = document.querySelector('#slot-'+this.kwSelector.preselectedSlot.wd+'-'+this.kwSelector.preselectedSlot.slot);
                // slotEl.classList.add('preselected');
                var slotEl = document.querySelectorAll('[name="slot-' + _this.kwSelector.preselectedSlot.wd + '-' + _this.kwSelector.preselectedSlot.slotFullHour + '"]');
                slotEl.forEach(function (sEl) {
                  return sEl.classList.add('preselected');
                });
                slotEl[0].scrollIntoView({
                  left: 0,
                  block: 'start',
                  behavior: 'smooth'
                });

                _this.kwSelector.set('preselectedSlot', null);
              });
            };
          } else {
            weekSetupCB = function weekSetupCB(weekDayData) {
              _this.get('_prevAvaiabilities').replace(0, 1, [weekDayData]); // eslint-disable-next-line ember/no-side-effects


              _this.set('counterMap', weekDayData.get('counterMap'));

              _this._setSlotSideCss();
            };
          }

          return weekSetupCB;
        };

        this.kwSelector.setTimePeriodViewer('week', 20, weekSetupCallbackFactory);
        var weekSetup = this.weekSetup(weekDayData, 20, weekSetupCallbackFactory());
        this.calendarEditor.appointmentsForTimespanByPlace(weekDayData.firstDay.format('YYYY-MM-DD'), weekDayData.lastDay.format('YYYY-MM-DD'), this.kwSelector, weekSetup, weekDayData); // [0];
      }

      return result;
    }),
    availabilities: Ember.computed.alias('appointmentsByPlace'),
    actions: {
      ziehen: function ziehen(sourceSlot, eventPlaceholder) {
        var sourceAppointmentKey = (eventPlaceholder || event).target.dataset.appointmentkey; // console.log('ziehen: sourceSlotKey = ' + sourceSlotKey);

        (eventPlaceholder || event).dataTransfer.effectAllowed = 'move'; // (eventPlaceholder||event).dataTransfer.setData('appointmentkey', sourceAppointmentKey);

        (eventPlaceholder || event).dataTransfer.setData(sourceAppointmentKey, "");
      },
      ablegenErlauben: function ablegenErlauben(shiftWeek, eventPlaceholder) {
        // let sourceAppointmentKey = (eventPlaceholder||event).dataTransfer.getData('appointmentkey');
        if (shiftWeek != null && !this.get("shiftPerformed")) {
          // console.log('calendarWeek - ablegenErlauben: !shiftPerformed, shiftWeek = '+JSON.stringify(shiftWeek)+' shiftWeekSlot = '+JSON.stringify(this.get('shiftWeekSlot')));
          if (this.get('shiftWeekSlot') == null) {
            var sourceAppointmentKey = (eventPlaceholder || event).dataTransfer.types[0];
            var weekDayDataKey = sourceAppointmentKey.match(/^[^-]+/)[0];
            var slotIndex = sourceAppointmentKey.match(/^[^-]+-([^-]+)-/)[1]; // let sourceSlot = this.availabilities.locationDataByDay[weekDayDataKey].slots[slotIndex];

            var sourceSlot = this.availabilities[0].get('locationDataByDay.' + weekDayDataKey + '.slots.' + slotIndex);
            var patientId = sourceAppointmentKey.match(/^[^-]+-[^-]+-(.*)/)[1];
            this.setProperties({
              shiftPerformed: true,
              shiftWeekSlot: {
                sourceSlot: sourceSlot,
                patientId: patientId
              }
            });
          } else {
            this.set("shiftPerformed", true);
          }

          this.kwSelector.get('shiftWeek').perform(shiftWeek);
        } else if (shiftWeek == null) {
          this.set("shiftPerformed", null);
        }

        (eventPlaceholder || event).preventDefault();
      },
      ablegen: function ablegen(targetSlot, eventPlaceholder) {
        var _this2 = this;

        var leftOrRight = (event || eventPlaceholder).offsetX < (event || eventPlaceholder).target.offsetWidth / 2 ? 'l' : 'r';
        /**
         * the shiftWeekSlot is set in ablegenErlauben with = this.availabilities[0].get('locationDataByDay.'+weekDayDataKey+'.slots.'+slotIndex);
         */

        var sourceSlot, patientId;

        if (this.get("shiftWeekSlot") == null) {
          // let sourceAppointmentKey = (eventPlaceholder||event).dataTransfer.getData('appointmentkey');
          var sourceAppointmentKey = (eventPlaceholder || event).dataTransfer.types[0];
          var weekDayDataKey = sourceAppointmentKey.match(/^[^-]+/)[0];
          var slotIndex = sourceAppointmentKey.match(/^[^-]+-([^-]+)-/)[1]; // let sourceSlot = this.availabilities.locationDataByDay[weekDayDataKey].slots[slotIndex];

          /*let */

          sourceSlot = this.availabilities[0].get('locationDataByDay.' + weekDayDataKey + '.slots.' + slotIndex);
          /*let */

          patientId = sourceAppointmentKey.match(/^[^-]+-[^-]+-(.*)/)[1];
        } else {
          sourceSlot = this.get("shiftWeekSlot.sourceSlot");
          patientId = this.get("shiftWeekSlot.patientId");
          this.setProperties({
            shiftPerformed: null,
            shiftWeekSlot: null
          });

          if ((0, _moment.default)(sourceSlot.date).week() == (0, _moment.default)(targetSlot.date).week()) {
            // returned to originalweek
            this.send('ablegen', targetSlot, eventPlaceholder);
            return;
          }
        }

        var sourceAppointment
        /*, extra_info*/
        ;

        if (patientId.startsWith('new_patient_')) {
          sourceAppointment = sourceSlot.appointmentPatients.find(function (a) {
            return a.newPatientName == patientId.substring('new_patient_'.length);
          }); // extra_info = {slot_side: leftOrRight};
        } else {
          sourceAppointment = sourceSlot.appointmentPatients.find(function (a) {
            return a.patient.id == patientId;
          }); // extra_info = EmberObject.create(sourceAppointment.appointment.model.extra_info || {});
          // extra_info.set('slot_side', leftOrRight);
        }

        var extra_info = Ember.Object.create(sourceAppointment.appointment.model.extra_info || {}); // if (targetSlot.date == sourceAppointment.slot.date && targetSlot.from == sourceAppointment.slot.from)

        if (targetSlot == sourceAppointment.slot && extra_info.slot_side == leftOrRight) return true;
        extra_info.set('slot_side', leftOrRight);
        sourceAppointment.appointment.model.setProperties({
          date: targetSlot.date,
          from: targetSlot.from,
          until: targetSlot.until,
          extra_info: extra_info
        });
        this.calendarEditor.saveAppointment(sourceAppointment.appointment.model, {
          before: sourceAppointment.appointment,
          afterSave: function afterSave(updatedAppointment) {
            // // delete sourceAppointment.appointment;
            // sourceSlot.appointmentPatients.removeObject(sourceAppointment);
            var sourceAppointmentApp = sourceAppointment.appointment;
            sourceSlot.removeAppointmentData(sourceAppointment);

            _this2._setSlotSideCss();

            sourceAppointmentApp.setProperties({
              // date: targetSlot.date,
              slot: targetSlot,
              from: String(targetSlot.from).replace(/([0-9]{2})$/, ':$1'),
              until: String(targetSlot.until).replace(/([0-9]{2})$/, ':$1'),
              model: updatedAppointment.model
            });
            updatedAppointment.set('color', sourceAppointmentApp.color);
            var targetAppointment;

            if (patientId.startsWith('new_patient_')) {
              targetAppointment = targetSlot.addAppointment(updatedAppointment);
            } else {
              // sourceAppointment.set('appointment', null);
              if (!sourceSlot.readOnly) sourceSlot.noAppointmentPatients.pushObject(sourceAppointment);
              sourceSlot.appointmentPatients.sort(function (a1, a2) {
                var result = a1.patient.lastName.localeCompare(a2.patient.lastName);
                if (result == 0) result = a1.patient.firstName.localeCompare(a2.patient.firstName);
                return result;
              });

              if (!targetSlot.readOnly) {
                targetAppointment = targetSlot.noAppointmentPatients.find(function (a) {
                  return a.patient.id == patientId;
                });
                targetSlot.noAppointmentPatients.removeObject(targetAppointment);
                targetAppointment.set('appointment', updatedAppointment);
                targetSlot.addAppointmentData(targetAppointment);
              } else targetAppointment = targetSlot.addAppointment(updatedAppointment);
            }

            return _this2.kwSelector.afterSave(updatedAppointment);
          },
          inValid: this.kwSelector.inValid.bind(this.kwSelector)
        });
      },
      openCalendarForDate: function openCalendarForDate(date) {
        this.kwSelector.setProperties({
          timePeriod: 'date',
          selectedDate: (0, _moment.default)(date, 'YYYYMMDD').format('YYYY-MM-DD')
        }); // this.router.transitionTo('calendar.index', { queryParams: { date: date } });
      },
      selectNewAppointmentRange: function selectNewAppointmentRange(_availability) {
        // debugger;
        // this.send('closeAppointmentDialog');
        this.get('flashMessages').error('Muss noch programmiert werden ...', {
          timeout: 3000
        });
      },
      addAppointment: function addAppointment(appointmentData, _eventPlaceholder) {
        var _this3 = this;

        // (eventPlaceholder||event).target.removeEventListener('focus');
        if (Object.keys(appointmentData).length == 1 || appointmentData.search != null || typeof appointmentData == 'string') {
          // new patient / no erstaufnahme
          if (appointmentData.search != null) appointmentData = {
            newPatientName: appointmentData.search.searchText
          };else if (typeof appointmentData == 'string') appointmentData = {
            newPatientName: appointmentData
          }; // let slotKeyMatch = document.querySelector('md-autocomplete-wrap.md-menu-showing').closest('md-chips').getAttribute('class').match(/([0-9]{8})\.([0-9]+)/);

          var slotKeyMatch = document.querySelector('md-autocomplete-wrap.md-menu-showing').closest('md-chips').closest('div[data-slotKey]').getAttribute('data-slotKey').match(/([0-9]{8})\.([0-9]+)/);
          appointmentData.slot = this.availabilities[0].get('locationDataByDay.' + slotKeyMatch[1] + '.slots.' + slotKeyMatch[2]);
        }

        var aMParams = {
          hp_id: this.kwSelector.get('currentUser.id'),
          date: appointmentData.slot.date,
          from: appointmentData.slot.from,
          until: appointmentData.slot.until
        };

        if (appointmentData.patient) {
          aMParams.patient_id = appointmentData.patient.id;
          aMParams.patient_info = {
            firstName: appointmentData.patient.firstName,
            lastName: appointmentData.patient.lastName
          };
          aMParams.extra_info = {
            slot_side: appointmentData.slot.leftOrRight
            /*appointmentData.leftOrRight*/

          };
        } else {
          aMParams.extra_info = {
            new_patient: appointmentData.newPatientName,
            slot_side: appointmentData.slot.leftOrRight
            /*appointmentData.leftOrRight*/

          };
          var patientNameMatch = appointmentData.newPatientName.match(/^([^,]+)(,.*|)$/);
          appointmentData.patient_info = {
            firstName: patientNameMatch[2].replace(',', '').trim(),
            lastName: patientNameMatch[1].trim()
          };
          appointmentData.patient = Ember.Object.create(appointmentData.patient_info);
          appointmentData = _slotAppointmentData.default.create(appointmentData);
        }

        aMParams.extra_info.color = parseInt('0x' + this.kwSelector.currentColor.substring(1, 3)) + ',' + parseInt('0x' + this.kwSelector.currentColor.substring(3, 5)) + ',' + parseInt('0x' + this.kwSelector.currentColor.substring(5, 7));
        appointmentData.slot.set('leftOrRight', aMParams.extra_info.slot_side == 'r' ? 'l' : 'r');
        this.calendarEditor.saveAppointment(this.calendarEditor.store.createRecord('appointment-model', aMParams), {
          afterSave: function afterSave(newAppointment) {
            if (newAppointment.get('model.patient_id')) appointmentData.slot.noAppointmentPatients.removeObject(appointmentData);
            appointmentData.set('appointment', newAppointment);
            delete appointmentData.showBirthDay;
            appointmentData.slot.addAppointmentData(appointmentData);
            Ember.run(function () {
              return _this3._setSlotSideCss();
            });
            appointmentData.slot.set('readOnly', true);
            return _this3.kwSelector.afterSave(newAppointment);
          },
          inValid: this.kwSelector.inValid.bind(this.kwSelector)
        });
      },
      removeAppointment: function removeAppointment(appointmentData) {
        var _this4 = this;

        // prevent event to show patientenkarte
        this.set('removeAppointment', appointmentData);
        appointmentData.appointment.model.destroyRecord().then(function (_deletedAppointment) {
          appointmentData.slot.removeAppointmentData(appointmentData); // // this.kwSelector.afterSave(newAppointment);

          _this4._setSlotSideCss();

          _this4.kwSelector.get('flashMessages').success('Termin erfolgreich gelöscht ...', {
            timeout: 3000
          });
        }, function (error) {
          if (error.errors && error.errors[0] && error.errors[0].status == "404") {
            appointmentData.slot.removeAppointmentData(appointmentData);

            _this4._setSlotSideCss();
          }
        });
        return false;
      },
      addAppointmentNote: function addAppointmentNote(appointmentData, slot, eventPlaceholder) {
        var _this5 = this;

        if (appointmentData.editMode) {
          this.calendarEditor.saveAppointment(appointmentData.model, {
            afterSave: function afterSave(newAppointment) {
              appointmentData.set('editMode', false);
              slot.set('readOnly', true);
              return _this5.kwSelector.afterSave(newAppointment);
            },
            inValid: this.kwSelector.inValid.bind(this.kwSelector)
          });
        } else {
          var cWSDiv = (eventPlaceholder || event).target.closest('div.calendar-week-slot');
          Ember.run.schedule("afterRender", function () {
            cWSDiv.querySelector('input').focus();
          });
          appointmentData.set('editMode', true);
        }
      },

      /*searchForPatients(searchTerm, autoCompleteComponent, event) {
        this.set('searchTerm', searchTerm);
        Ember.run.debounce(this, this._searchForPatients(searchTerm), 800);
      },*/
      searchForPatients: function searchForPatients(searchWord, search) {
        // let result = search.options.filter(appointmentData => appointmentData.patient.lastName.toLowerCase().startsWith(searchWord.toLowerCase()));
        var result = search.options.filter(function (appointmentData) {
          return appointmentData.patient != null ? appointmentData.patient.lastName.toLowerCase().startsWith(searchWord.toLowerCase()) : false;
        });

        if (result.length == 0) {
          if (search.options.length >= 1) result = [{
            newPatientName: null,
            patient: {
              lastName: 'Neuen ' + (this.get('kwSelector.currentUser.plus') ? 'Kunden' : 'Patient') + ' eintragen'
            },
            search: search,
            leftOrRight: search.options[0].slot.leftOrRight
            /*search.options[0].leftOrRight*/

          }];else result = [{
            newPatientName: null,
            patient: {
              lastName: 'Neuen ' + (this.get('kwSelector.currentUser.plus') ? 'Kunden' : 'Patient') + ' eintragen'
            },
            search: search,
            leftOrRight: 'l'
          }];
        }

        return result;
      },

      /**
       * click on appoinment-chip 2 use-cases:
       * 1) transtion to patienten-karte or patienten-aufnahme
       * 2) set color
       */
      showPatientenKarte: function showPatientenKarte(appointmentData, eventPlaceholder) {
        var _this6 = this;

        if (this.get('removeAppointment') != null) {
          this.set('removeAppointment', null);
          return false;
        }

        if (this.get('kwSelector.appointmentClickSetsColor')) {
          appointmentData.appointment.set('color', parseInt('0x' + this.kwSelector.currentColor.substring(1, 3)) + ',' + parseInt('0x' + this.kwSelector.currentColor.substring(3, 5)) + ',' + parseInt('0x' + this.kwSelector.currentColor.substring(5, 7)));
          this.calendarEditor.saveAppointment(appointmentData.appointment.model, {
            afterSave: function afterSave(newAppointment) {
              return _this6.kwSelector.afterSave(newAppointment);
            },
            inValid: this.kwSelector.inValid.bind(this.kwSelector)
          });
          return false;
        } // console.log('showPatientenKarte ...');


        if (appointmentData.patient.id != null) {
          this.calendarEditor.set('curAppointment', appointmentData.appointment.model); // this.router.transitionTo('patients.edit.index', appointmentData.patient.id);

          if (this.get('calendarEditor.application.currentUser.plus') && !appointmentData.get('appointment.model.isBusiness')) {
            this.calendarEditor.application.setApiDomain.perform('practice', function () {
              _this6.router.transitionTo('patients.edit.index', appointmentData.patient.id);
            });
          } else if (!this.get('calendarEditor.application.currentUser.plus') && appointmentData.get('appointment.model.isBusiness')) {
            this.calendarEditor.application.setApiDomain.perform('business', function () {
              _this6.router.transitionTo('customers.edit.index', appointmentData.patient.id);
            });
          } else {
            this.router.transitionTo((this.get('calendarEditor.application.currentUser.plus') ? 'customers' : 'patients') + '.edit.index', appointmentData.patient.id);
          }
        } else {
          this.calendarEditor.set('curAppointment', appointmentData.appointment);
          this.router.transitionTo('patients.new');
        }

        (event || eventPlaceholder).stopPropagation();
        return true;
      },
      saveAppointment: function saveAppointment() {
        this.send('closeAppointmentDialog');
        this.get('flashMessages').error('Muss noch programmiert werden ...', {
          timeout: 3000
        });
      },
      showInput: function showInput(slot, leftOrRight, eventPlaceholder) {
        var _this7 = this;

        if (slot.readOnly) {
          var noAppointmentPatients = [];
          this.sortedPatients.forEach(function (p) {
            if (p.id != null) {
              if (slot.appointmentPatients.find(function (a) {
                return a.patient.id == p.id;
              }) == null) noAppointmentPatients.push(_slotAppointmentData.default.create({
                patient: p,
                slot: slot,
                showBirthDay: true
                /*, leftOrRight: leftOrRight*/

              }));
            } else {
              if (slot.appointmentPatients.find(function (a) {
                return a.newPatientName == (0, _slotAppointments.newPatientName)(p);
              }) == null) noAppointmentPatients.push(_slotAppointmentData.default.create({
                newPatientName: (0, _slotAppointments.newPatientName)(p),
                patient: p,
                slot: slot
                /*, leftOrRight: leftOrRight*/

              }));
            }
          });

          if (this.sortedPatients.length == 0) {
            noAppointmentPatients.push({
              slot: slot
              /*, leftOrRight: leftOrRight*/

            });
          }

          var parentElement = (eventPlaceholder || event).target.closest('li');
          Ember.run.scheduleOnce("afterRender", function () {
            _this7._setSlotSideCss(true); // FIXME: causes EmberError 'You modified "isFocused" tqwice ...'
            //        no prblem whenn patient ist clicked


            parentElement.querySelector('input').focus(); // run(() => parentElement.querySelector('input').focus());
          });
          slot.noAppointmentPatients.pushObjects(noAppointmentPatients);
          slot.setProperties({
            leftOrRight: leftOrRight,
            readOnly: false
          });
        }
      },
      checkDoneEditNote: function checkDoneEditNote(slot, _eventPlaceholder) {
        if (event.keyCode == 13) {
          this.send('addAppointmentNote', slot.get('editNoteAppointment.appointment'), slot);
        }
      }
    },
    slotTimes: function slotTimes() {
      var slots = [];

      for (var i = this.get('practice.cal_first_slot_start') || 0; i <= (this.get('practice.cal_last_slot_end') || 100) - 100; i += 100) {
        for (var k = 0; k <= 40; k += 20) {
          slots.push(i + k);
        }
      }

      return slots;
    },
    _setSlotSideCss: function _setSlotSideCss(immediate) {
      var _this8 = this;

      if (immediate) {
        document.querySelectorAll('md-chip.appointment-slot-side-r').forEach(function (mdChip) {
          if (mdChip.classList.contains('appointment-slot-side-r')) mdChip.classList.remove('appointment-slot-side-r');
        });
        document.querySelectorAll('md-chip .appointment-slot-side-r').forEach(function (a) {
          var mdChip = a.closest('md-chip');
          if (!mdChip.classList.contains('appointment-slot-side-r')) mdChip.classList.add('appointment-slot-side-r');
        });
      } else {
        Ember.run.schedule("afterRender", function () {
          _this8._setSlotSideCss(true);
        });
      }
    }
  });

  _exports.default = _default;
});