define("digiprax-web/helpers/value-counter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.valueCounter = valueCounter;
  _exports.default = void 0;

  // params[0] ... current value
  // params[1] ... object/map
  // params[2] ... optional - true|false set
  // params[3] ... return value
  // ...
  // {{#let (ember-object) as |counterMap|}}
  // {{value-counter '*' counterMap true ''}}
  function valueCounter(params, _hash) {
    if (typeof params[1].getProperties == 'function') {
      if (params[2]) params[1].set(params[0], (params[1].get(params[0]) || 0) + 1);
      return params[3] != null ? params[3] : params[1].get(params[0]);
    } else {
      if (params[2]) params[1][params[0]] = (params[1][params[0]] || 0) + 1;
      return params[3] != null ? params[3] : params[1][params[0]];
    }
  }

  var _default = Ember.Helper.helper(valueCounter);

  _exports.default = _default;
});