define("digiprax-web/services/entry-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    saveEntries: function saveEntries(model, listener) {
      var _this = this;

      var errors = []; // for (var i=0 ; i<=10000000 ; i++)
      //   [].concat(errors);

      if (this._validateEntries(model, errors)) {
        var task = model.save();
        task.then(function (updatedModel) {
          var _errorEntries, _errorEntries2;

          // done ...
          _this.get('application').hideProgressbar();

          if (updatedModel.get('id') == 'transferred') {
            updatedModel.unloadRecord();
            updatedModel = _this.store.peekRecord(model.toString().match(/model:([^:]+)/)[1], 'current');
          }

          var errorEntries = (updatedModel.newEntryPlaceholders || []).filter(function (e) {
            return (e.errors || []).length >= 1;
          });
          errorEntries = (_errorEntries = errorEntries).concat.apply(_errorEntries, _toConsumableArray((updatedModel.newEntryPlaceholders || []).map(function (nEPh) {
            return (nEPh.blockTransferParts || []).concat(nEPh.newTransferPartEntries || []).filter(function (e) {
              return e.errors != null;
            });
          })));

          if (errorEntries.length == 0) {
            // let allEntries = updatedModel.get('store').peekAll('entry');
            if (model.toString().match(/model:([^.]+)::/)[1] == 'cashbook') {
              updatedModel.setProperties({
                /*entries: allEntries.filter(e => e.isVisible), */
                newEntryPlaceholders: null
              });
            } else {
              updatedModel.setProperties({
                /*entries: allEntries.filter(e => e.isJournalTransferred == null || !e.isJournalTransferred), */
                newEntryPlaceholders: null
              });
            }
          }

          updatedModel.set('savedAt', new Date());
          /*let _initNumNewEntriesDirty = */

          updatedModel.get('numNewEntriesDirty');
          errorEntries = errorEntries.concat((updatedModel.entries || []).filter(function (e) {
            return (e.errors || []).length >= 1;
          }));
          errorEntries = (_errorEntries2 = errorEntries).concat.apply(_errorEntries2, _toConsumableArray(errorEntries.map(function (e) {
            return (e.newTransferPartEntries || []).length >= 1 ? e.newTransferPartEntries.filter(function (e2) {
              return (e2.errors || []).length >= 1;
            }) : null;
          }).compact()));
          if (errorEntries.length == 0) listener.afterSave(updatedModel);else {
            listener.inValid(errorEntries.map(function (e) {
              return e.errors.map(_this._errorMessageMapper()).join('\n\r<br>');
            }).uniq());
          }
        }, function (error) {
          _this.get('application').hideProgressbar();

          if (typeof error.get == 'function') {
            listener.inValid(error.get('messages').map(function (e) {
              return e.errors.map(_this._errorMessageMapper()).join('\n\r<br>');
            }).uniq());
          } else {
            _this.get('application').debugRemote('error.get == undefined: ' + JSON.stringify(error));

            listener.inValid(['Unbekannter Fehler!']);
          }
        });
        return task;
      } else {
        this.get('application').hideProgressbar();
        listener.inValid(errors.uniq(), {
          timeout: 5000
        });
      }
    },
    // transferEntries: function (model, listener) {
    // },
    // showOverview(accountNumber, listener) {
    //   let accountSummary = this.store.peekRecord('account-summary', accountNumber + '-' + this.get('loadedYear'));
    //   if (accountSummary != null) {
    //     this.setProperties({ entryDetail: accountNumber, accountSummary: accountSummary.reload() });
    //   } else {
    //     this.setProperties({ entryDetail: accountNumber, accountSummary: this.store.findRecord('account-summary', accountNumber + '-' + this.get('loadedYear')) });
    //   }
    // },
    _validateEntries: function _validateEntries(model, errors) {
      var errorMessages = {};
      var dirtyEntries = model.get('entries').filter(function (e) {
        return e.isDirty;
      });
      dirtyEntries.forEach(function (e) {
        if (e.date == null) {
          // && errorMessages.date == null) {
          errorMessages.date = 'Datum muss angegeben werden.';
          e.set('errors', ['Datum muss angegeben werden.']);
          errors.push(errorMessages.date);
        }
      });
      var newBlockPartEntries = model.get('entries').filter(function (e) {
        return e.get('newTransferPartEntries') != null;
      });
      newBlockPartEntries.forEach(function (e) {
        // e.set('accountNumber', '110');
        // e.set('accountDesignation', 'Einnahmen aus Pat.-Behandlung');
        if (e.date == null) {
          // && errorMessages.date == null) {
          errorMessages.date = 'Datum muss angegeben werden.';
          e.set('errors', ['Datum muss angegeben werden.']);
          errors.push(errorMessages.date);
        }
      });
      var dirtyNewEntries = model.get('newEntryPlaceholders').filter(function (e) {
        return e.isDirty;
      });
      dirtyNewEntries.forEach(function (e) {
        if (e.date == null) {
          // && errorMessages.date == null) {
          errorMessages.date = 'Datum muss angegeben werden.';
          e.set('errors', ['Datum muss angegeben werden.']);
          errors.push(errorMessages.date);
        }
      });
      return errors.length == 0;
    },
    _errorMessageMapper: function _errorMessageMapper() {
      return function (errorMessage) {
        if (errorMessage.match(/^['"]?date['"]? must be less than or equal/)) return 'Das Rechnungs-Datum darf nicht in der Zukunft liegen.';
        if (errorMessage.match(/^['"]?date['"]? must be a valid/)) return 'Ein Rechnungs-Datum im Format TT.MM.JJJJ muss angegeben werden.';
        if (errorMessage.match(/^transactiontype: can't be blank/)) return 'Art des Zahlungsvorganges muss angegeben werden.';
        if (errorMessage.match(/^accountNumber: can't be blank/)) return 'Konto-Nr. muss angegeben werden.';
        if (errorMessage.match(/^revenue: both 0/)) return 'Einnahmen oder Ausgaben müssen größer 0 sein.';
        if (errorMessage.match(/^invoiceNumber: doesn't exist/)) return 'Rechnungsnummer unbekannt.';
        if (errorMessage.match(/^block_transfer_part_ids: min. 2/)) return 'Eine Sammelüberweisung erfordert min. 2 Einträge.';
        if (errorMessage.match(/^block_transfer: contains errors/)) return 'Sammelüberweisung enthält Fehler.';
        return errorMessage;
      };
    }
  });

  _exports.default = _default;
});