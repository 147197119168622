define("digiprax-web/models/cashbook", ["exports", "ember-data", "digiprax-web/models/new-entry-placeholder"], function (_exports, _emberData, _newEntryPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    total: _emberData.default.attr('number'),
    from: _emberData.default.attr('number'),
    to: _emberData.default.attr('number'),
    curPage: _emberData.default.attr('number'),
    pageLimit: _emberData.default.attr('number'),
    // pageNums: A([]),
    pageNums: Ember.computed('total', 'curPage', 'pageLimit', function () {
      return Ember.A(this.getPageNums(this.get('curPage'), (this.get('total') - 1) / this.get('pageLimit') + 1));
    }),
    // << < 4 5 (6) 7 8 > >>
    // getPageNums(pageNum, numPages) {
    //   return [[],pageNum-2,pageNum-1,pageNum,pageNum+1,pageNum+2,pageNum+3,pageNum+4].reduce((acc, num, idx) => {
    //     if (numPages >= num) {
    //       if (num >= 1 && acc.length <= 4) acc.push(num);
    //       return acc;
    //     } else {
    //       // acc.insertAt(0, num + '.' + idx);
    //       if (numPages >= 5 && acc.length <= 4) acc.insertAt(0, (acc[0] + acc.length - idx));
    //       return acc;
    //     }
    //   });
    // },
    getPageNums: function getPageNums(pageNum, numPages) {
      var pages = [];

      for (var i = 1; i <= numPages; i++) {
        pages.push(i);
      }

      return pages;
    },
    entries: _emberData.default.attr('raw'),
    selectedYear: null,
    savedAt: null,
    searchTerm: null,
    searchableEntries: Ember.computed('entries', 'entriesDirty', 'entries.@each.extraInfo'
    /*.positionTS'*/
    , 'searchTerm', function () {
      var _this = this;

      if ([null, ''].indexOf(this.get('searchTerm')) >= 0) return this.get('entries');else return this.get('entries').filter(function (e) {
        return !(e.get('serialNumber') == null || e.get('serialNumber').match(new RegExp(_this.get('searchTerm'), ['i'])) == null) || !(e.get('accountNumber') == null || e.get('accountNumber').match(new RegExp(_this.get('searchTerm'), ['i'])) == null) || !(e.get('accountDesignation') == null || e.get('accountDesignation').match(new RegExp(_this.get('searchTerm'), ['i'])) == null) || !(e.get('invoiceNumber') == null || e.get('invoiceNumber').match(new RegExp(_this.get('searchTerm'), ['i'])) == null) || !(e.get('transactiontype') == null || e.get('transactiontype').match(new RegExp(_this.get('searchTerm'), ['i'])) == null);
      });
    }),
    // sorted by backend
    sortedEntries: Ember.computed('searchableEntries', 'entries.@each.newBlockTransferPartFlag', function () {
      return this.get('searchableEntries');
    }),
    // sortedEntries: sort('searchableEntries', function(e1, e2) {
    //     // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
    //     /**
    //      * maybe differen for cashbook and journal (serials not visible in cashbook)
    //      */
    //     if (e1.get('serialNumberSort') != null) {
    //       if (e2.get('serialNumberSort') == null) return -1;
    //       else return e2.get('serialNumberSort') >= e1.get('serialNumberSort') ? -1 : 1;
    //     } else if (e2.get('serialNumberSort') != null)
    //       return 1;
    //
    //     if (e1.get('dateFmtd') == e2.get('dateFmtd')) {
    //       var positionSort = this.sortByPosition(e1, e2);
    //       if (positionSort != null) return positionSort;
    //     }
    //     return e2.get('date') > e1.get('date') ? -1 : 1;
    // }),
    sortableNewEntryPlaceholders: Ember.computed('newEntryPlaceholders', 'numNewEntriesDirty', function () {
      return this.get('newEntryPlaceholders');
    }),
    sortedNewEntryPlaceholders: Ember.computed.sort('sortableNewEntryPlaceholders', function (e1, e2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (!e1.get('isDirty')) return 1;
      if (!e2.get('isDirty')) return -1; // if (e1.get('dateFmtd') == e2.get('dateFmtd')) {
      //   var positionSort = this.sortByPosition(e1, e2);
      //   if (positionSort != null) return positionSort;
      // }

      var positionSort = this.sortByPosition(e1, e2);
      if (positionSort != null) return positionSort;
      return e2.get('date') - e1.get('date') >= 0 ? -1 : 1;
    }),
    entriesDirty: Ember.computed('entries.@each.isDirty', 'numNewEntriesDirty', 'numNewBlockTransferEntries', function () {
      return this.get('numNewEntriesDirty') >= 1 || (this.get('entries') || []).toArray().filter(function (t) {
        return t.get('isDirty');
      }).length >= 1 || this.get('numNewBlockTransferEntries') >= 1;
    }),
    hasEntries: Ember.computed('entries.length', 'newEntryPlaceholders.length', function () {
      return (this.get('entries') || []).toArray().length >= 1 || (this.get('newEntryPlaceholders') || []).toArray().length >= 2;
    }),
    numNewEntryPlaceholders: 1,
    newEntryPlaceholders: Ember.computed('numNewEntriesDirty', 'savedAt', {
      get: function get(_key) {
        var newEntryPlaceholders = this.get('_newEntryPlaceholders');

        if (newEntryPlaceholders == null) {
          var nTPs = new Array();

          for (var i = 0; i < this.get('numNewEntryPlaceholders'); i++) {
            nTPs.push(_newEntryPlaceholder.default.create({
              tmpId: Math.round(Math.random(1000000) * 1000000),
              extraInfo: {
                positionTS: new Date().getTime() + (i + 1)
              },
              store: this.store
            }));
          }

          newEntryPlaceholders = Ember.A(nTPs); // eslint-disable-next-line ember/no-side-effects

          this.set('_newEntryPlaceholders', newEntryPlaceholders);
        } else {
          var numDirty = this._numNewEntriesDirty(newEntryPlaceholders.toArray());

          if (newEntryPlaceholders.toArray().length < this.get('numNewEntryPlaceholders') + numDirty) {
            // Ember.Logger.debug('nTPs.length = '+newEntryPlaceholders.toArray().length+', numDirty = '+numDirty);
            newEntryPlaceholders.pushObject(_newEntryPlaceholder.default.create({
              tmpId: Math.round(Math.random(1000000) * 1000000),
              extraInfo: {
                positionTS: new Date().getTime()
              },
              store: this.store
            }));
          }
        }

        return newEntryPlaceholders;
      },
      set: function set(_key, value) {
        this.set('_newEntryPlaceholders', value);
        return value;
      }
    }),
    // numNewEntriesDirty: computed('newEntryPlaceholders.@each.date', 'newEntryPlaceholders.@each.accountDesignation', 'newEntryPlaceholders.@each.accountNumber', 'newEntryPlaceholders.@each.invoiceNumber', 'newEntryPlaceholders.@each.revenue', 'newEntryPlaceholders.@each.transactiontype', 'newEntryPlaceholders.@each.extraInfo'/*.positionTS'*/, function() {
    numNewEntriesDirty: Ember.computed('newEntryPlaceholders.@each.isDirty', function () {
      return this._numNewEntriesDirty((this.get('newEntryPlaceholders') || []).toArray());
    }),
    _numNewEntriesDirty: function _numNewEntriesDirty(newEntryPlaceholders) {
      var num = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = newEntryPlaceholders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var entry = _step.value;
          if (entry.get('isDirty')) num = num + 1;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return num;
    },
    numNewBlockTransferEntries: Ember.computed('entries.@each.lastBlockTransferPart', 'numNewEntriesDirty', function () {
      // return (this.get('entries').find(e => e.get('newTransferPartEntries') != null) || { entries: [] }).entries.length;
      return (this.get('entries').find(function (e) {
        return e.get('newTransferPartEntries').length >= 1;
      }) || {
        newTransferPartEntries: []
      }).newTransferPartEntries.length;
    }),
    // only for newEntryPlaceholders
    sortByPosition: function sortByPosition(e1, e2) {
      if (e1.get('extraInfo') != null) {
        if (e2.get('extraInfo') != null) {
          if ((e1.get('extraInfo.positionTS') || 0) == (e2.get('extraInfo.positionTS') || 0)) {
            return -1;
          } else {
            return (e1.get('extraInfo.positionTS') || 0) > (e2.get('extraInfo.positionTS') || 0) ? 1 : -1;
          }
        } else {
          return -1;
        }
      } else if (e2.get('extraInfo') != null && (e2.get('extraInfo.positionTS') != null ||
      /*DEPRECATED*/
      e2.get('extraInfo.position') != null)) {
        return 1;
      }

      return null;
    }
  });
  /*
   createdAt                 | timestamp with time zone | not null
   updatedAt                 | timestamp with time zone | not null
  */


  _exports.default = _default;
});