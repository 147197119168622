define("digiprax-web/mixins/calendar-utils", ["exports", "moment", "digiprax-web/models/slot-appointments", "digiprax-web/models/location-data-for-day"], function (_exports, _moment, _slotAppointments, _locationDataForDay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { newPatientName, SlotAppointments } from '../models/slot-appointments';
  var _default = Ember.Mixin.create({
    holidays: Ember.computed(function () {
      var _yearData$0$, _yearData$1$, _yearData$3$, _yearData$4$, _yearData$5$, _yearData$9$, _yearData$10$, _yearData$11$;

      var yearData = [{
        1: [["01.01.20", "Neujahr"], ["06.01.20", "Hl. Drei Könige"]]
      }, {
        2: []
      }, {
        3: []
      }, {
        4: [["10.04.20", "Karfreitag"], ["12.04.20", "Ostern"], ["13.04.20", "Ostermontag"]]
      }, {
        5: [["01.05.20", "Maifeiertag"], ["21.05.20", "Chr. Himmelfahrt"], ["31.05.20", "Pfingsten"]]
      }, {
        6: [["01.06.20", "Pfingstmontag"], ["11.06.20", "Fronleichnam"]]
      }, {
        7: []
      }, {
        8: [["15.08.20", "Mariä Himmelfahrt"]]
      }, {
        9: []
      }, {
        10: [["03.10.20", "Tag der Dt. Einheit"], ["31.10.20", "Reformationstag"]]
      }, {
        11: [["01.11.20", "Allerheiligen"], ["18.11.20", "Buß- und Bettag"]]
      }, {
        12: [["25.12.20", "1. Weihnachtsfeiertag"], ["26.12.20", "2. Weihnachtsfeiertag"]]
      }];

      (_yearData$0$ = yearData[0][1]).splice.apply(_yearData$0$, [0, 0].concat([["01.01.21", "Neujahr"], ["06.01.21", "Hl. Drei Könige"]]));

      (_yearData$1$ = yearData[1][2]).splice.apply(_yearData$1$, [0, 0].concat([["15.02.21", "Rosenmontag"]]));

      (_yearData$3$ = yearData[3][4]).splice.apply(_yearData$3$, [0, 0].concat([["02.04.21", "Karfreitag"], ["04.04.21", "Ostern"], ["05.04.21", "Ostermontag"]]));

      (_yearData$4$ = yearData[4][5]).splice.apply(_yearData$4$, [0, 0].concat([["01.05.21", "Maifeiertag"], ["13.05.21", "Chr. Himmelfahrt"], ["24.05.21", "Pfingstmontag"]]));

      (_yearData$5$ = yearData[5][6]).splice.apply(_yearData$5$, [0, 0].concat([["03.06.21", "Fronleichnam"]]));

      (_yearData$9$ = yearData[9][10]).splice.apply(_yearData$9$, [0, 0].concat([["03.10.21", "Tag der Dt. Einheit"]]));

      (_yearData$10$ = yearData[10][11]).splice.apply(_yearData$10$, [0, 0].concat([["01.11.21", "Allerheiligen"], ["17.11.21", "Buß- und Bettag"]]));

      (_yearData$11$ = yearData[11][12]).splice.apply(_yearData$11$, [0, 0].concat([["25.12.21", "1. Weihnachtsfeiertag"], ["26.12.21", "2. Weihnachtsfeiertag"]]));

      return Ember.A(yearData);
    }),
    // Die erste Kalenderwoche eines Jahres ist die Woche, die mindestens vier Tage des neuen Jahres beinhaltet.
    startOfCalendarWeek: function startOfCalendarWeek(year, kw) {
      var firstWeekDay = (0, _moment.default)(year + '-01-01'); // moment('2020-01-01').weekday(); // mon 0|1 => >=4 0 53

      if (firstWeekDay.week() == 1) {
        firstWeekDay = firstWeekDay.startOf('week');
      } else {
        firstWeekDay = firstWeekDay.endOf('week').startOf('day').add(1, 'day');
      }

      return firstWeekDay.add(kw - 1, 'week').clone();
    },
    weekdays: function weekdays(year, kw) {
      var weekDays = [];
      var firstWeekDay = this.startOfCalendarWeek(year, kw);

      for (var i = 0; i <= 6; i++) {
        weekDays.push(firstWeekDay.clone());
        firstWeekDay.add(1, 'day');
      }

      return Ember.A(weekDays);
    },
    slotAvailabilityStates: function slotAvailabilityStates(slot, availableSlotRanges) {
      var states = {
        none: false,
        part: false
      };

      if (availableSlotRanges != null && availableSlotRanges.length >= 1) {
        states.none = availableSlotRanges[0][0] <= slot.fromIn && availableSlotRanges[0][1] > slot.fromInt + this.slotDuration / 100 * 60;
        states.part = availableSlotRanges[0][0] > slot.fromInt || availableSlotRanges[availableSlotRanges.length - 1][1] < slot.fromInt + this.slotDuration / 100 * 60;
      } else states.none = true;

      return states;
    },

    /**
     * remove all intersections from availableRanges with appointments for a location
     */
    cleanUpLocationRanges: function cleanUpLocationRanges(availableRangesForLocation, appointments) {
      if (availableRangesForLocation != null && appointments != null) {
        var newRanges;

        (function () {
          var rmRanges = []; // ranges that will be removed due to 0 duration/availabilty

          do {
            newRanges = [];
            availableRangesForLocation.forEach(function (roomRange, idxRR, _rRApps) {
              appointments.forEach(function (appointment, _idxPR, _pRApps) {
                if (roomRange[0] < appointment.range[0]) {
                  // [100, _]   - [200, _]
                  if (roomRange[1] > appointment.range[0]) {
                    // [100, 300] - [200, _]
                    if (roomRange[1] <= appointment.range[1]) {
                      // [100, 300] - [200, 300+]
                      roomRange[1] = appointment.range[0]; // => [100, 200] !!!

                      if (roomRange[0] == roomRange[1]) rmRanges.push(idxRR); // => [] !!!
                    } else {
                      // [100, 400] - [200, 300]
                      newRanges.push([appointment.range[1], roomRange[1]]);
                      roomRange[1] = appointment.range[0]; // => [100, 200], push: [300, 400] !!!
                    }
                  } // else                                     // [100, 200-] - [200, 200+]: no intersection

                } else {
                  // [200+, _]   - [200, _]
                  if (roomRange[0] < appointment.range[1]) {
                    // [200+, _]   - [200, 300]
                    if (roomRange[1] < appointment.range[1]) {
                      // [200+, 299] - [200, 300]
                      rmRanges.push(idxRR); // => [] !!!
                    } else {
                      // [200+, 300+] - [200, 300]
                      roomRange[0] = appointment.range[1]; // => [300, 300+] !!!

                      if (roomRange[0] == roomRange[1]) rmRanges.push(idxRR);
                    }
                  } // else                                     // [200+, _]    - [200, 200]: no intersection

                }
              });
            });
            if (rmRanges.length >= 1) rmRanges.reverse().forEach(function (rmIdx) {
              return availableRangesForLocation.splice(rmIdx, 1);
            });
            newRanges.forEach(function (nR) {
              return availableRangesForLocation.splice(availableRangesForLocation.length, 0, nR);
            });
            rmRanges = [];
          } while (newRanges.length >= 1);
        })();
      }
    },
    // appointments must be sorted asc and not overlapping
    availableRanges: function availableRanges(appointments) {
      var _this = this;

      if (appointments == null || appointments.length == 0) return [[this.firstSlotStart, this.lastSlotEnd]];
      var result = {
        availableRanges: [],
        curParseOff: this.firstSlotStart,
        numAppointmentsLeft: appointments.length
      };
      return [result].concat(appointments).reduce(function (acc, appointment) {
        if (appointment.range[0] > acc.curParseOff) acc.availableRanges.push([acc.curParseOff, appointment.range[0]]);
        acc.numAppointmentsLeft = acc.numAppointmentsLeft - 1;
        acc.curParseOff = appointment.range[1];
        if (acc.numAppointmentsLeft == 0 && acc.curParseOff < _this.lastSlotEnd) acc.availableRanges.push([acc.curParseOff, _this.lastSlotEnd]);
        return acc;
      }).availableRanges;
    },
    overlappingRanges: function overlappingRanges(range1, range2) {
      return range1[0] >= range2[0] && range1[0] < range2[1] || // [200-250, _] - [200, 300]
      range1[0] < range2[0] && range1[1] > range2[0]; // [150, 250]   - [200, _]
    },
    formatTimeInt: function formatTimeInt(timeInt) {
      var hour = parseInt(timeInt / 100);
      return (hour + ':' + (timeInt - hour * 100) + '0').match(/[0-9]{1,2}:[0-9]{2}/)[0];
    },
    parseTimeToInt: function parseTimeToInt(timeString) {
      var hours = parseInt(timeString.match(/(^00?|[1-9][0-9]?):/)[1]);
      return hours * 100 + parseInt(timeString.match(/[0-9][0-9]$/)[0]); // return parseInt(timeString.replace(':', ''));
    },
    weekSetup: function weekSetup(weekDayData, slotStep, setupCompleteCB) {
      var _this2 = this;

      // console.log('CalendarUtils - weekSetup: from ' + weekDayData.firstDay.format('YYYY-MM-DD') + ' until ' + weekDayData.lastDay.format('YYYY-MM-DD'));
      // var curRoom, curPlace, curAppointment;
      var setup = function setup(locationAppointments
      /*appointmentsByPlace*/
      ) {
        // for (curRoom of Object.keys(appointmentsByPlace)) {
        //   for (curPlace of Object.keys(appointmentsByPlace[curRoom])) {
        //     for (curAppointment of appointmentsByPlace[curRoom][curPlace]) {
        //       if (weekDayData.appointmentsByDay.get(moment(curAppointment.date).format('YYYY-MM-DD')) == null)
        //         weekDayData.appointmentsByDay.set(moment(curAppointment.date).format('YYYY-MM-DD'), A([]));
        //       weekDayData.appointmentsByDay.get(moment(curAppointment.date).format('YYYY-MM-DD')).pushObject(curAppointment);
        //     }
        //   }
        // }
        var appointmentsByDate = Object.keys(locationAppointments).length >= 1 ? locationAppointments[Object.keys(locationAppointments)[0]].dates : Ember.Object.create({});
        weekDayData.set('appointmentsByDay', appointmentsByDate); // if (Object.keys(locationAppointments).length >= 1)
        //   locationAppointments[Object.keys(locationAppointments)[0]].addByDate(weekDayData.appointmentsByDay);

        _this2.setupSlots(weekDayData, slotStep);

        if (setupCompleteCB != null) Ember.run(function () {
          return setupCompleteCB(weekDayData);
        });
      };

      return setup;
    },
    setupSlots: function setupSlots(weekDayData, slotStep) {
      var _this3 = this;

      var curWeekDay = weekDayData.firstDay.clone(),
          beforeDay = weekDayData.lastDay.clone().add(1, 'day'),
          curSlot,
          curAppointment;

      var _loop = function _loop() {
        // let curLocationData = EmberObject.create({dateMom: curWeekDay.clone(), slots: A([])});
        var curLocationData = _locationDataForDay.default.create({
          dateMom: curWeekDay.clone(),
          slots: Ember.A([])
        });

        var monthHolidays = _this3.holidays.find(function (h) {
          return Object.keys(h)[0] == curLocationData.dateMom.month() + 1;
        });

        if (monthHolidays != null) {
          var holiday = monthHolidays[curLocationData.dateMom.month() + 1].find(function (h) {
            return h[0] == curLocationData.dateMom.format('DD.MM.YY');
          });

          if (holiday != null) {
            curLocationData.set('holiday', holiday[1]);
          }
        } // weekDayData.locationDataByDay.set(curLocationData.dateMom.format('YYYYMMDD'), curLocationData);


        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = weekDayData.slots[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            curSlot = _step.value;
            var until = curSlot + slotStep;
            var remainder = until % 100;
            if (remainder >= 60) until = until + (100 - remainder);

            var curSlotData = _slotAppointments.default.create({
              searchEngine: _this3,
              // sortedPatients: this.sortedPatients,
              from: curSlot,
              fromInt: curSlot,
              until: until,
              // curSlot + slotStep, // Math.round(curSlot / 100) * 100 + 100, // curSlot + 100,
              fullHour: Math.round(curSlot / 100) * 100,
              date: curWeekDay.clone().add(curWeekDay.utcOffset(), 'minutes').toDate()
            }); // console.log('curSlotData.date = '+curSlotData.date);


            curLocationData.slots.pushObject(curSlotData); // TODO: this is done for all slots of 7 days - for > 100 patients to much. => lazy load
            // curSlotData.set('noAppointmentPatients', A(this.sortedPatients.map(p => { return EmberObject.create({patientId: p.lastName.toLowerCase(), patient: p, slot: curSlotData}); })));

            continuousRange = [curSlot, curSlot];
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = (weekDayData.appointmentsByDay.get(curWeekDay.format('YYYY-MM-DD')) || Ember.A([]))[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                curAppointment = _step2.value;

                if (_this3.overlappingRanges(curAppointment.range, [curSlot, curSlotData.until])) {
                  if (curSlotData.available == 'all' && _this3.overlappingRanges(continuousRange, curAppointment.range)) {
                    continuousRange = [continuousRange[0], curAppointment.range[1]];
                    curSlotData.available = continuousRange[1] >= curSlotData.until ? 'none' : 'part';
                  } // console.log(curWeekDay.format('YYYYMMDD') + '/' + curSlot + ': ' + curAppointment.range + ' overlaps ' + [curSlot, curSlotData.until] + ', appointment: ' + curAppointment.toString());
                  // if (curSlotData.appointments.indexOf(curAppointment.toString()) == -1) {


                  curSlotData.addAppointment(curAppointment); // }
                }
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        weekDayData.locationDataByDay.set(curLocationData.dateMom.format('YYYYMMDD'), curLocationData);
        curWeekDay.add(1, 'day');
      };

      while (curWeekDay.isBefore(beforeDay)) {
        var continuousRange;

        _loop();
      } // this.set('lastAppointmentsLoadedFlagData', { flag: this.appointmentsLoadedFlag, data: result });


      return weekDayData;
    }
  });

  _exports.default = _default;
});