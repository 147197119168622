define("digiprax-web/models/settlement", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    shortId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    info: _emberData.default.attr('string'),
    group: _emberData.default.attr('number')
  });
  /*
   createdAt                 | timestamp with time zone | not null                                                                                                                                
   updatedAt                 | timestamp with time zone | not null                                                                                                                                
   deletedAt                 | timestamp with time zone |                                                                                                                                         
  */


  _exports.default = _default;
});