define("digiprax-web/initializers/router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;

  function initialize(application) {
    application.inject('component', 'router', 'router:main');
    application.inject('service', 'router', 'router:main');
  }
});