define("digiprax-web/mixins/patient-model", ["exports", "ember-data", "digiprax-web/models/new-treatment-placeholder"], function (_exports, _emberData, _newTreatmentPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    settlementId: _emberData.default.attr('string'),
    settlementName: _emberData.default.attr('string'),
    // _treatmentsAddedOrDeleted: computed('treatments', function() {
    //   return [this.get('treatments.length')||0, 1];
    // }),
    _treatmentsAddedOrDeleted: Ember.computed('treatments', {
      get: function get(_key) {
        return this.get('__treatmentsAddedOrDeleted') || [this.get('treatments.length') || 0, 1];
      },
      set: function set(_key, value, _prevValue) {
        return this.set('__treatmentsAddedOrDeleted', value);
      }
    }),
    treatmentsAddedOrDeleted: Ember.computed('treatments.[]', 'newTreatmentPlaceholders.[]', function () {
      return this.get('treatments.length') != this.get('_treatmentsAddedOrDeleted')[0]; //  || this.get('newTreatmentPlaceholders.length') != this.get('_treatmentsAddedOrDeleted')[1];
    }),
    treatmentsDirty: Ember.computed('treatments.@each.date', 'treatments.@each.treatmentNotes', 'treatments.@each.diagnosisText', 'treatments.@each.cost', 'numNewTreatmentsDirty', function () {
      return this.get('numNewTreatmentsDirty') >= 1 || (this.get('treatments') || []).toArray().filter(function (t) {
        return t.isDirty();
      }).length >= 1;
    }),
    numNewTreatmentsDirty: Ember.computed('newTreatmentPlaceholders.@each.date', 'newTreatmentPlaceholders.@each.treatmentNotes', 'newTreatmentPlaceholders.@each.diagnosisText', 'newTreatmentPlaceholders.@each.cost', function () {
      return this._numNewTreatmentsDirty(this.get('newTreatmentPlaceholders').toArray());
    }),
    _numNewTreatmentsDirty: function _numNewTreatmentsDirty(newTreatmentPlaceholders) {
      var num = 0;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = newTreatmentPlaceholders[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var treatment = _step.value;
          // if (treatment.get('hasDirtyAttributes')) num = num + 1;
          if (treatment.isDirty()) num = num + 1;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return num;
    },

    /*sortedTreatments: Ember.computed.sort('treatments', 'treatmentSortDef'),
    treatmentSortDef: function() {
      return ['date:asc'];
    }.property(),*/
    sortableTreatments: Ember.computed('treatments', 'treatments.@each.date', 'treatments.@each.extraInfo'
    /*.positionTS'*/
    , function () {
      return this.get('treatments');
    }),
    sortedTreatments: Ember.computed.sort('sortableTreatments', function (i1, i2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (i1.get('dateFmtd') == i2.get('dateFmtd')) {
        if ([null, ''].indexOf(i1.get('diagnosisText')) >= 0) {
          if ([null, ''].indexOf(i2.get('diagnosisText')) == -1) return 1;
        } else if ([null, ''].indexOf(i2.get('diagnosisText')) >= 0) {
          if ([null, ''].indexOf(i1.get('diagnosisText')) == -1) return -1;
        }

        var positionSort = this.sortByPosition(i1, i2);
        if (positionSort != null) return positionSort;
      }

      return i2.get('date') - i1.get('date') >= 0 ? -1 : 1;
    }),
    sortableNewTreatmentPlaceholders: Ember.computed('newTreatmentPlaceholders', 'newTreatmentPlaceholders.@each.date', 'newTreatmentPlaceholders.@each.extraInfo'
    /*.positionTS'*/
    , function () {
      return this.get('newTreatmentPlaceholders');
    }),
    sortedNewTreatmentPlaceholders: Ember.computed.sort('sortableNewTreatmentPlaceholders', function (i1, i2) {
      // if compareFunction(a, b) is greater than 0, sort b to a lower index than a.
      if (!i1.isDirty()) return 1;
      if (!i2.isDirty()) return -1;
      if (i1.get('date') == null) return 1;
      if (i2.get('date') == null) return -1;

      if (i1.get('dateFmtd') == i2.get('dateFmtd')) {
        var positionSort = this.sortByPosition(i1, i2);
        if (positionSort != null) return positionSort;
      }

      return i2.get('date') - i1.get('date') >= 0 ? -1 : 1;
    }),

    /*
    diagnoses: computed({
      get(_key) {
        console
        let diagnoses = this.get('_diagnoses');
        if (diagnoses == null) {
          diagnoses = this.get('store').peekAll('diagnosis').toArray().filter((diagnose) => diagnose.get('patientId') == this.get('id') && diagnose.get('invoiceId') == null);
          if (diagnoses.length == 0) return null;
          this.set('_diagnoses', diagnoses);
        }
        return diagnoses;
      },
      set(_key, diagnoses) {
        this.set('_diagnoses', diagnoses);
        return diagnoses;
      }
    }),
    */
    // sortedDiagnoses: computed.sort('diagnoses', 'diagnoseSortDef'),
    // diagnoseSortDef: computed(function() {
    //   return ['date:asc'];
    // }),
    numNewTreatmentPlaceholders: 1,
    newTreatmentPlaceholders: Ember.computed('numNewTreatmentsDirty', 'savedAt', function () {
      var newTreatmentPlaceholders = this.get('_newTreatmentPlaceholders');

      if (newTreatmentPlaceholders == null) {
        var nTPs = new Array();

        for (var i = 0; i < this.get('numNewTreatmentPlaceholders'); i++) {
          nTPs.push(_newTreatmentPlaceholder.default.create({
            tmpId: Math.round(Math.random(1000000) * 1000000),
            patientId: this.get('id'),
            positionTS: new Date().getTime() + (i + 1)
          }));
        } // newTreatmentPlaceholders = ArrayProxy.create({ content: A(nTPs) });


        newTreatmentPlaceholders = Ember.A(nTPs); // eslint-disable-next-line ember/no-side-effects

        this.set('_newTreatmentPlaceholders', newTreatmentPlaceholders);
      } else {
        // var numDirty = 0;
        // for (var treatment of newTreatmentPlaceholders.toArray()) {
        //   if (treatment.get('date') != null) numDirty = numDirty + 1;
        // }
        var numDirty = this._numNewTreatmentsDirty(newTreatmentPlaceholders.toArray());

        if (newTreatmentPlaceholders.toArray().length < this.get('numNewTreatmentPlaceholders') + numDirty) {
          newTreatmentPlaceholders.pushObject(_newTreatmentPlaceholder.default.create({
            tmpId: Math.round(Math.random(1000000) * 1000000),
            patientId: this.get('id'),
            positionTS: new Date().getTime()
          }));
        }
      }

      return newTreatmentPlaceholders;
    }),

    /*numNewTreatmentsDirty: function() {
      var num = 0;
      for (var treatment of this.get('newTreatmentPlaceholders').toArray()) {
        if (treatment.get('date') != null) num = num + 1;
      }
      return num;
    }.property('newTreatmentPlaceholders.@each.date'),*/
    // diagnoseText: function() {
    //   if (this.get('sortedDiagnoses') != null) {
    //     return this.get('sortedDiagnoses').map((d) => d.get('notes')).join(', ');
    //   }
    //   return null;
    // }.property('diagnoses.[]'),
    settlementObserver: Ember.observer('settlementId', function (_sender, _key, _value, _rev) {
      if ([null, '', 'new'].indexOf(this.id) >= 0 && this.settlementId != null) {
        if (this.get('_numCopiesForSettlementChecked') == null) {
          if (String(this.invoiceCopies) == '1') {
            var settlement = this.store.peekRecord('settlement', this.settlementId);

            if (settlement != null && (settlement.name.match(/^beihilfe /i) != null || settlement.name.match(/^post/i) != null)) {
              this.setProperties({
                _numCopiesForSettlementChecked: new Date(),
                invoiceCopies: 2
              });
            }
          }
        }
      }
    })
  });

  _exports.default = _default;
});