define("digiprax-web/routes/customers/edit/index", ["exports", "digiprax-web/mixins/patient-editor-p-route"], function (_exports, _patientEditorPRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_patientEditorPRoute.default, {
    calendarEditor: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      // console.log('from.patient_id = ' + (transition.from ? transition.from.parent.params.patient_id : null) + ', to.patient_id = ' + transition.to.parent.params.patient_id);
      if (transition.to.parent.params.patient_id == null) return this.transitionTo('patients.overview');
      return this._super(transition);
    },
    onLotsLoaded: function onLotsLoaded(patient) {
      var _this = this;

      return function (lots) {
        if (_this.calendarEditor.curAppointment != null) {
          var associatedLot;

          if (_this.calendarEditor.curAppointment.treatment_id == null) {
            associatedLot = lots.find(function (t) {
              return _this.calendarEditor.curAppointment.date == t.date;
            });

            if (associatedLot == null) {
              var existingAssociatedLot = patient.get('sortedNewLotPlaceholders').find(function (t) {
                return _this.calendarEditor.curAppointment.date == t.date;
              });

              if (existingAssociatedLot == null) {
                patient.get('sortedNewLotPlaceholders')[patient.get('newLotPlaceholders').length - 1].setProperties({
                  date: _this.calendarEditor.curAppointment.date,
                  appointment: _this.calendarEditor.curAppointment
                }); // let _initNumDirty = patient.get('numNewLotsDirty');
              }
            } else {// existing persisted lot has same date as curAppointment
              }
          }

          _this.calendarEditor.set('curAppointment', null);
        }
      };
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get('id') != 'new') {
        localStorage.setItem('peru.lastViewedPatientId', '"' + model.get('id') + '"');
        localStorage.setItem('lastViewedPatientId', model.get('id'));

        this._initPatientRelations(model, {
          lots: this.onLotsLoaded(model)
        });

        controller.set('application.currentPatient', model); // controller.set('newLotPlaceholders', this._newLotPlaceholders(model));

        var _initNumDirty = model.get('numNewLotsDirty');

        controller.set('actions.deleteLot', this.get('actions.deleteLot'));
        controller.set('actions.removeNewLot', this.get('actions.removeNewLot'));
        controller.set('actions.moveLot', this.get('actions.moveLot'));
      } // case reload ...


      controller.get('application').setActiveNavLink('linkTo.patientenkarte');
    }
  });

  _exports.default = _default;
});