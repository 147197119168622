define("digiprax-web/initializers/deprecation", ["exports", "digiprax-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Debug.registerDeprecationHandler(function (message, options, next) {
      if (_environment.default.environment === 'test') {
        return;
      } else {
        next(message, options);
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});