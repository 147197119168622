define("digiprax-web/models/tariff-chain-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    name: null,
    itemKey: null,
    tariffChains: Ember.computed(function () {
      return [];
    })
  });

  _exports.default = _default;
});