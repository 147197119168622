define("digiprax-web/mixins/invoice-dialogs-p", ["exports", "moment", "digiprax-web/mixins/dialogs-util"], function (_exports, _moment, _dialogsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ChainCategories from '../models/chain-categories';
  // import NewLotPlaceholder from '../models/new-lot-placeholder';
  var _default = Ember.Mixin.create(_dialogsUtil.default, {
    print: Ember.inject.service(),
    showProductsDialog: null,
    _productsImportFlag: Ember.computed.alias('application.productsImportFlag'),
    products: Ember.computed('_productsImportFlag', function () {
      return this.get('store').peekAll('product').sortBy('name'); // itemSortKey
    }),
    additionalTexts: Ember.A([Ember.Object.create({
      id: 1,
      item: '1',
      text: 'Betrag von … € dankend erhalten'
    }), Ember.Object.create({
      id: 2,
      item: '2',
      text: 'Teilbetrag von … € dankend erhalten,\nbitte zahlen Sie nur noch … €'
    }), Ember.Object.create({
      id: 3,
      item: '0.0',
      text: 'Leerzeile'
    })]),
    actions: {
      editPatient: function editPatient() {
        this.transitionToRoute('patients.edit.index', this.get('model.patientId') || this.get('model.patient.id'));
      },
      focusInput: function focusInput(lot, eventPlaceholder) {
        this.focusInput(lot, (eventPlaceholder || event).target);
        return false;
      },
      focusOutInput: function focusOutInput(lot, eventPlaceholder) {
        if (this.get('focusedLot') != lot) {
          this.set('focusedLot', lot);

          this._focusLine(lot, Ember.$((eventPlaceholder || event).target).closest('ul'));
        }

        if (this.get('focusedLot.date') != null) {
          this.set('lastFocusedLotDate', (0, _moment.default)(this.get('focusedLot.date')).format('DD.MM.YYYY')); // console.log('setting lastFocusedLotDate to ' + this.get('lastFocusedLotDate'));
        }

        this.focusNewInput((eventPlaceholder || event).target);
        /*var isClickOnGebuehDialog = (eventPlaceholder || event).relatedTarget.type == 'button' &&
                                    $((eventPlaceholder || event).relatedTarget).closest('li').attr('name') == 'gebuehInput';*/

        /*let touchNumNewLotsDirty = */

        this.get('model.numNewLotsDirty'); // prevents X to remove last clean line

        return false;
      },
      openProductDialog: function openProductDialog(lot, eventPlaceholder) {
        if (lot == null) {
          this._openDialogWithFocusedEntry(eventPlaceholder || event, 'lot', 'showProductsDialog', function () {
            alert('Bitte wählen Sie zuerst ein Eingabe-Element aus einer Artikel/Angebots-Zeile für die Artikel-Zuordnung.');
          });
        } else {
          this._openEntryDialog(lot, 'lot', 'showProductsDialog', eventPlaceholder || event);
        }

        return false;
      },
      closeProductDialog: function closeProductDialog() {
        this.set('showProductsDialog', null);
      },
      openZusatztexteDialog: function openZusatztexteDialog(lot, eventPlaceholder) {
        if (lot == null) {
          this._openDialogWithFocusedEntry(eventPlaceholder || event, 'lot', 'showZusatztexteDialog', function () {
            alert('Bitte wählen Sie zuerst ein Eingabe-Element aus einer Artikel/Angebots-Zeile für den Zusatztext.');
          });
        } else {
          this._openEntryDialog(lot, 'lot', 'showZusatztexteDialog', eventPlaceholder || event);
        }
      },
      closeZusatztexteDialog: function closeZusatztexteDialog(_result) {
        this.set('showZusatztexteDialog', null);
      },
      appendToInvoice: function appendToInvoice(dialogKey, dialogParam) {
        var _this = this;

        if (this.get('application.practice.trial')) {
          this.set('application.alertMessage', 'Wir bitten um Verständnis, dass mit dem Probezugang keine Rechnungen erstellt werden können.');
          Ember.$('#alertMessage').trigger('click'); // send action

          return this.send(dialogKey.replace(/^show/, 'close'));
        } // if (! (dialogKey == 'showZusatztexteDialog' && ["0", "0.0"].indexOf(dialogParam.get('item')) >= 0)) {
        // if (! (["showZusatztexteDialog", "showProductsDialog"].indexOf(dialogKey) >= 0 && ["0", "0.0"].indexOf(dialogParam.get('item')) >= 0)) {
        //   if (this.get('focusedLot.date') == null) this.set('focusedLot.dateTotal', (this.get('lastFocusedLotDate') != null ? moment(this.get('lastFocusedLotDate'), 'DD.MM.YYYY') : moment()).toDate());
        // }
        // console.log('dialogKey = ' + dialogKey + ', focusedLot.date = ' + this.get('focusedLot.date'));


        if (dialogKey == 'showProductsDialog') {
          this._setLotProduct(this.get('focusedLot'), dialogParam); // console.log('closing ...');


          this.send('closeProductDialog');
          return false;
        } else if (dialogKey == 'showZusatztexteDialog') {
          if (dialogParam.get('item') == "0.0") {
            if (this.get('focusedTreatment.tmpId') != null) {
              var treatmentIdx = this.get('focusedTreatment.invoice.sortedNewEntryPlaceholders').findIndex(function (t) {
                return t.tmpId == _this.get('focusedTreatment.tmpId');
              });
              var targetEntry;

              if (treatmentIdx >= 1) {
                targetEntry = this.get('focusedTreatment.invoice.sortedNewEntryPlaceholders.' + (treatmentIdx - 1));
              } else {
                targetEntry = this.get('focusedTreatment.invoice.sortedEntries.lastObject');
                if (targetEntry == null) targetEntry = this.get('focusedTreatment.invoice.sortedNewEntryPlaceholders.0');
              }
            } else {
              targetEntry = this.get('focusedTreatment');
            }

            var newExtraInfo = targetEntry.get('extraInfo') || {};
            newExtraInfo.extraLine = ' '; // will be trimmed in model

            targetEntry.setProperties({
              extraInfo: newExtraInfo,
              extraInfoIsDirtyFlag: new Date()
            });
          } else {
            this.set('model.additionalText', dialogParam);
          }

          this.send('closeZusatztexteDialog');
          return false;
        } else {
          alert('TODO: appendToInvoice ' + dialogKey);
          return false;
        }
      },
      finalizeInvoice: function finalizeInvoice(action) {
        var _this2 = this;

        if (this.get('model.notEditable')) {
          this.send('generatePdf', action);
        } else {
          var listener = {
            inValid: function inValid(errors) {
              _this2.inValid(errors);
            },
            afterSave: function afterSave(invoice) {
              // $('html, body').animate({scrollTop: 0}, 500);
              if (invoice.get('invoiceNumber') == null && invoice.get('extraInfo.finalized.date') != null) {
                Ember.getOwner(_this2).lookup('route:' + _this2.target.currentRouteName).refresh();
              }

              _this2.send('generatePdf', action);
            }
          };
          this.get('invoiceEditor').finalizeInvoice(this.get('model'), listener);
        }
      },

      /* pdf creation ---------------------------------------------------------------------------------------- */
      generatePdf: function generatePdf(action) {
        var _this3 = this;

        if (this.get('model.id') == null || this.get('model.isDirty')) {
          this.set('application.alertMessage', 'Bitte speichern Sie zuerst die Rechnung.');
          Ember.$('#alertMessage').trigger('click'); // send action

          return false;
        }

        this.get('model.patient').then(function (patient) {
          // patient.get('store').findRecord('practice', patient.get('practiceId')).then((practice) =>  {
          //   this._generatePdf(action, patient, practice);
          // });
          _this3.get('print')._generatePdf(action, patient, _this3.get('application.practice'), _this3.get('model'), _this3);
        });
      },

      /* /pdf creation ---------------------------------------------------------------------------------------- */
      printPdf: function printPdf() {
        document.querySelector('#pdf_invoice').contentWindow.print();
      },
      closePdf: function closePdf(_result) {
        this.set('showPdf', false);
      }
    },

    /* /actions */
    _setLotProduct: function _setLotProduct(lot, product) {
      var previousProductName = null;

      if (lot.get('product_id') != null) {
        previousProductName = this.get('store').peekRecord('product', lot.get('product_id')).get('name');
      } else if (lot.get('product') != null) {
        previousProductName = lot.get('product.name');
      } //*let touchNumNewLotsDirty = */this.get('model.numNewLotsDirty');


      var lotText;

      if (lot.get('lotText') == null || lot.get('lotText').trim() == '') {
        lotText = this.get('store').peekRecord('product', product.id).get('name');
      } else {
        lotText = lot.get('lotText').trim() + ', ' + this.get('store').peekRecord('product', product.id).get('name');
        if (previousProductName != null) lotText = lotText.replace(new RegExp(previousProductName.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '[ ,]*'), '');
        if (lotText == '') lotText = null;
      } // lot.set('lotText', lotText.replace(/\s*\*\s*$/, '').replace(/^\s*\*\s*,\s*/, '').replace(/\s*,\s*$/, ''));


      lotText = lotText.replace(/\s*\*\s*$/, '').replace(/^\s*\*\s*,\s*/, '').replace(/\s*,\s*$/, '');
      var lotProps = {
        product: product,
        product_id: product.id,
        cost_cents: parseInt(product.price_cents)
        /**/
        ,
        lotText: lotText
      };
      if (lot == this.focusedLot && this.get('focusedLot.date') == null) lotProps.dateTotal = (this.get('lastFocusedLotDate') != null ? (0, _moment.default)(this.get('lastFocusedLotDate'), 'DD.MM.YYYY') : (0, _moment.default)()).toDate();
      var positionTS = lot.get('extraInfo.positionTS');
      if (positionTS == null && lot.get('tmpId') != null) positionTS = new Date().getTime();
      if (lot.get('extraInfo') == null) lotProps.extraInfo = {
        positionTS: positionTS
      };
      if (lot.get('date') == null) lotProps.date = this.get('lastFocusedLotDate') != null ? (0, _moment.default)(this.get('lastFocusedLotDate'), 'DD.MM.YYYY').toDate() : new Date(); // console.log('tariff = ' + tariff);

      lot.setProperties(lotProps);
      /*let touchNumNewLotsDirty = */

      this.get('model.numNewLotsDirty');
      if (lot.get('invoice_id') != null) this.set('model.dayGroups.updatedAt', new Date());
    },
    _focusLine: function _focusLine(lot, lineSelector) {
      this.focusLine(lot, lineSelector); //*let touchNumNewLotsDirty = */this.get('model.numNewLotsDirty'); // prevents X to remove last clean line
    },
    handleUpload: function handleUpload(uploadKey, _response) {
      var _this4 = this;

      if (uploadKey == 'importProducts') {
        // Handle success
        // ['product'].forEach(modelName => run(() => {
        //   this.store.unloadAll(modelName);
        // }));

        /*['product'].forEach(modelName => */
        Ember.run(function () {
          _this4.store.findAll('product').then(function (_products) {
            _this4.set('_productsImportFlag', new Date());
          });
        }); // );
      }
    }
  });

  _exports.default = _default;
});