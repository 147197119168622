define("digiprax-web/helpers/value-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.valueChanged = valueChanged;
  _exports.default = void 0;

  // params[0] ... current value
  // params[1] ... array; f.ex.: {{#let (array) as |shiftCategoryIds|}}
  // ...
  function valueChanged(params, _hash) {
    if (params[1].content != null) {
      // ArrayProxy
      if (params[1].lastObject != params[0]) {
        params[1].pushObject(params[0]);
        return true;
      }
    } else {
      if (params[1][params[1].length - 1] != params[0]) {
        params[1].push(params[0]);
        return true;
      }
    }

    return false;
  }

  var _default = Ember.Helper.helper(valueChanged);

  _exports.default = _default;
});