define("digiprax-web/serializers/practice", ["exports", "digiprax-web/serializers/application", "digiprax-web/models/room", "digiprax-web/models/place"], function (_exports, _application, _room, _place) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { run } from "@ember/runloop";
  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(_store, _primaryModelClass, payload, _id, _requestType) {
      // run(() => store.unloadAll('appointment'));
      payload.response.rooms = [{}].concat(payload.response.rooms).reduce(function (acc, room) {
        var curRoomPlaces = room.places;
        room.places = []; // A([]);

        for (var k = 0; k < curRoomPlaces.length; k++) {
          curRoomPlaces[k].firstSlotStart = curRoomPlaces[k].slots_from;
          curRoomPlaces[k].lastSlotEnd = curRoomPlaces[k].slots_until;
          curRoomPlaces[k].times = [curRoomPlaces[k].slots_from, curRoomPlaces[k].slots_until];
          delete curRoomPlaces[k].slots_from;
          delete curRoomPlaces[k].slots_until;
          room.places.push
          /*Object*/
          (_place.default.create(curRoomPlaces[k]));
        }

        acc[room.id] = _room.default.create(room);
        return acc;
      });
      return this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, _options) {
      var json = this._super.apply(this, arguments);

      var roomsJSON = [];
      var curRoomId;

      var _arr = Object.keys(snapshot.record.rooms || {});

      for (var _i = 0; _i < _arr.length; _i++) {
        curRoomId = _arr[_i];
        roomsJSON.push(snapshot.record.rooms[curRoomId]);
      }

      json.rooms = roomsJSON;
      return json;
    }
  });

  _exports.default = _default;
});