define("digiprax-web/routes/auth/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    application: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:application');
    }),
    activate: function activate() {
      this.application.hideProgressbar();
      return this._super();
    },
    model: function model() {
      if ((document.location.search || '').match(/logout=true/) && this.application != null) {
        this.application.get('flashMessages').success('Abgemeldet!', {
          timeout: 3000
        });
      }

      return Ember.Object.create({
        email: '',
        password: ''
      });
    }
  });

  _exports.default = _default;
});