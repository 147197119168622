define("digiprax-web/helpers/ember-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberObject = emberObject;
  _exports.default = void 0;

  // if params[0] is an array then an arrayproxy will be returned
  // else:
  // params[n*2]   ... optional - key
  // params[n*2+1] ... optional - value
  function emberObject(params, _hash) {
    if (params.length >= 1 && typeof params[0].pop == 'function') {
      return Ember.ArrayProxy.create({
        content: Ember.A(params.slice(1))
      });
    } else {
      var listAsHash = {};
      params.slice(0, params.length / 2).forEach(function (p, idx) {
        listAsHash[params[idx * 2]] = params[idx * 2 + 1];
      });
      return Ember.ObjectProxy.create({
        content: Ember.Object.create(listAsHash)
      });
    }
  }

  var _default = Ember.Helper.helper(emberObject);

  _exports.default = _default;
});