define("digiprax-web/serializers/journal", ["exports", "digiprax-web/serializers/application", "moment", "digiprax-web/models/new-entry-placeholder"], function (_exports, _application, _moment, _newEntryPlaceholder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Entry from '../models/entry';
  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (requestType == 'updateRecord' && (payload.response.errors || []).length >= 1) {
        // keep changed attributes for previously/already persisted entries
        payload.response.errors.forEach(function (entry) {
          if (entry.id != null) {
            // new BlockTransferEntries get an id but are rolled back than
            var maybePersisted = store.peekRecord('entry', entry.id);

            if (maybePersisted != null) {
              var changedAttributes = maybePersisted.changedAttributes();
              Object.keys(changedAttributes).forEach(function (cAName) {
                payload.response.entries.find(function (e) {
                  return e.id == entry.id;
                })[cAName] = changedAttributes[cAName][1];
              });
            }
          }
        });
      }

      Ember.run(function () {
        return store.unloadAll('entry');
      });
      store.pushPayload({
        entries: payload.response.entries
      });
      var journal = store.peekRecord('journal', 'current');
      if (journal != null) // reset block_transfer-errors
        journal.newEntryPlaceholders.filter(function (nEPh) {
          return nEPh.isExpand;
        }).forEach(function (nEPh) {
          nEPh.set('errors', null);
          (nEPh.newTransferPartEntries || []).forEach(function (nTPE) {
            return nTPE.set('errors', null);
          });
        });
      var journalProps = {};
      journalProps.entries = payload.response.entries = store.peekAll('entry').toArray();

      if ((payload.response.errors || []).length >= 1) {
        var curNEP, curBlockTransfer;
        journalProps.newEntryPlaceholders = payload.response.newEntryPlaceholders = (payload.response.errors || []).map(function (e) {
          if (e.id != null) {
            // let entry = store.peekRecord('entry', e.id);
            // entry.set('errors', e.errors);
            var entry = journalProps.entries.find(function (e2) {
              return e2.id == e.id;
            });

            if (entry != null) {
              entry.errors.addObjects(e.errors);
              return null;
            } // new block_transfer with errors

          }

          if (e.errors != null) {
            if (e.isExpand != null) {
              // only newEntryPlaceholders (none of persisted)
              curBlockTransfer = journal.newEntryPlaceholders.find(function (nEPh) {
                return nEPh.extraInfo.positionTS == e.extraInfo.positionTS;
              });
              var addBlockTransfer = curBlockTransfer.errors == null;
              curBlockTransfer.set('errors', e.errors);
              return addBlockTransfer ? curBlockTransfer : null;
            } else if (e.block_transfer_serial_number != null) {
              // could be both newEntryPlaceholders or (persisted) entries
              curBlockTransfer = journal.entries.concat(journal.newEntryPlaceholders).find(function (nEPh) {
                return nEPh.newTransferPartEntries != null && nEPh.newTransferPartEntries.find(function (nTPE) {
                  return nTPE.extraInfo.positionTS == e.extraInfo.positionTS;
                }) != null;
              });

              if (curBlockTransfer.id == null) {
                var _addBlockTransfer = curBlockTransfer.errors == null || curBlockTransfer.errors.length == 0;

                if (_addBlockTransfer) curBlockTransfer.set('errors', ['block_transfer: contains errors']);
                var origBTPartEntry = curBlockTransfer.newTransferPartEntries.find(function (nTPE) {
                  return nTPE.extraInfo.positionTS == e.extraInfo.positionTS;
                });
                origBTPartEntry.set('errors', e.errors);
                return _addBlockTransfer ? curBlockTransfer : null;
              } else {
                // new/unpersisted part for previeously persisted block-transfer
                var reloadCurBlockTransfer = journalProps.entries.find(function (e2) {
                  return e2.serialNumber == e.block_transfer_serial_number;
                });

                if ((reloadCurBlockTransfer.newTransferPartEntries || []).length == 0) {
                  reloadCurBlockTransfer.set('newTransferPartEntries', curBlockTransfer.newTransferPartEntries);
                  reloadCurBlockTransfer.block_transfer_part_ids.addObjects(curBlockTransfer.newTransferPartEntries.map(function (nTPE) {
                    return nTPE.tmpId;
                  }));
                  reloadCurBlockTransfer.errors.addObjects(['block_transfer: contains errors']);
                  reloadCurBlockTransfer.newTransferPartEntries.forEach(function (e2) {
                    if (e2.errors != null) e2.set('errors', null); // e2.errors.clear();

                    e2.set('blockTransfer', reloadCurBlockTransfer);
                  });
                }

                var _origBTPartEntry = reloadCurBlockTransfer.newTransferPartEntries.find(function (nTPE) {
                  return nTPE.extraInfo.positionTS == e.extraInfo.positionTS;
                });

                if (_origBTPartEntry.errors == null) _origBTPartEntry.set('errors', e.errors);else _origBTPartEntry.errors.
                /*clear().*/
                addObjects(e.errors); // origBTPartEntry.set('blockTransfer', reloadCurBlockTransfer);
                // // origBTPartEntry.set('blockTransfer.newBlockTransferPartFlag', new Date());

                return null;
              }
            }
          }

          e.store = store;
          curNEP = _newEntryPlaceholder.default.create(e);

          if (e.serialNumber == null || e.serialNumber.match(/^[pt]/) == null) {
            e.tmpId = Math.round(Math.random(1000000) * 1000000);
            if (e.isExpand) curBlockTransfer = curNEP;
            return curNEP;
          } else {
            // if curBlockTransfer is not error (and sorted before curTaxPartEntry) than must be stored entry
            if (!curBlockTransfer.block_transfer_part_ids.includes(e.serialNumber)) curBlockTransfer = journalProps.entries.find(function (e) {
              return e.isExpand && e.block_transfer_part_ids.includes(e.serialNumber);
            }); // first time curBlockTransfer._taxPartEntries(curBlockTransfer.accountNumber);

            if (curBlockTransfer.newTransferPartEntries == null) curBlockTransfer._taxPartEntries(curBlockTransfer.accountNumber);
            var taxPartEntryIdx = curBlockTransfer.newTransferPartEntries.findIndex(function (e) {
              return e.taxPercentage == curNEP.serialNumber.match(/([0-9.]+$)/)[0];
            });
            curBlockTransfer.newTransferPartEntries[taxPartEntryIdx].setProperties(e);
            curBlockTransfer.newTransferPartEntries[taxPartEntryIdx].setProperties({
              tmpId: e.serialNumber,
              serialNumber: null,
              blockTransfer: curBlockTransfer
            });
            return null;
          }
        }).compact();
      } // let journal = store.peekRecord('journal', 'current');


      if (journal != null) {
        if ((payload.response.errors || []).length >= 1) {
          var okNewEntryPlaceholders = journal.newEntryPlaceholders.filter(function (e) {
            return e.isDirty && (e.errors == null || ['block_transfer_part_ids: min. 2', 'block_transfer: contains errors'].indexOf(e.errors.objectAt(0)) == 0) && journalProps.newEntryPlaceholders.find(function (e2) {
              return e.extraInfo.positionTS == e2.extraInfo.positionTS;
            }) == null;
          });
          okNewEntryPlaceholders.forEach(function (e) {
            return e.set('errors', null);
          });
          journalProps.newEntryPlaceholders = (journalProps.newEntryPlaceholders || []).concat(okNewEntryPlaceholders);
        }

        if (journalProps.newEntryPlaceholders == undefined) {
          // no errors in response => all saved entries ok
          var previousErrors = journal.newEntryPlaceholders.filter(function (e) {
            return (e.errors || []).length >= 1;
          });

          if (previousErrors.length >= 1) {
            var unfixedErrors = previousErrors.filter(function (pE) {
              return journalProps.entries.find(function (e) {
                return e.extraInfo.positionTS == pE.extraInfo.positionTS;
              }) == null;
            });
            if (unfixedErrors.length == 0 || payload.response.to < payload.response.total) journalProps.newEntryPlaceholders = null;
          }
        } // run(() => journal.set('entries', payload.response.entries));


        Ember.run(function () {
          return journal.setProperties(journalProps);
        });
      }

      return this._super(store, primaryModelClass, payload, requestType == 'createRecord' ? 'transferred' : 'current', requestType);
    },
    serialize: function serialize(snapshot, _options) {
      var newBlockTransferParts = snapshot.record.entries.toArray().map(function (e) {
        if (e.get('newTransferPartEntries') == null) return null;
        return e.get('newTransferPartEntries').filter(function (e2) {
          return e2.tmpId.startsWith('p') || e2.tmpId.startsWith('t');
        });
      }).compact().flat().concat(snapshot.record.sortedNewEntryPlaceholders.toArray().map(function (e) {
        if (e.get('newTransferPartEntries') == null) return null;
        return e.get('newTransferPartEntries').filter(function (e2) {
          return e2.tmpId.startsWith('p') || e2.tmpId.startsWith('t');
        });
      }).compact().flat());
      var json = snapshot.record.entries.toArray().map(function (e) {
        if (!(e.get('isDirty') || // transfer: (e.get('id') && snapshot.record.get('id') != 'current') ||
        e.get('isExpand') && e.get('block_transfer_part_ids').find(function (pId) {
          return pId.startsWith('p') || pId.startsWith('t');
        }) != null)) return null;
        var entry = e.toJSON();
        entry.id = e.get('id'); // if (entry.date.match(/T00:00:00\.[0-9]+Z$/) == null) {
        //   let dateMom = moment(e.get('date'));
        //   entry.date = dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON();
        // }

        return entry;
      }).compact().concat(snapshot.record.sortedNewEntryPlaceholders.toArray().map(function (e) {
        if (!(e.get('isDirty') || e.get('id') && snapshot.record.get('id') != 'current')) return null;
        var persisted = e.store.createRecord('entry', {});
        var entry = e.getProperties(Object.keys(persisted.toJSON()));
        e.store.unloadRecord(persisted);

        if ((0, _moment.default)(e.get('date')).toDate().toJSON().match(/T00:00:00\.[0-9]+Z$/) == null) {
          var dateMom = (0, _moment.default)(e.get('date'));
          entry.date = dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON().replace(/T.+Z/, 'T00:00:00Z');
        }

        if ([null, 'practice'].indexOf(snapshot.record.domain) >= 0 && entry.isExpand && entry.block_transfer_part_ids != null) entry.revenue = entry.expenditure = 0;
        return entry;
      }).compact()).concat(newBlockTransferParts.map(function (e) {
        e.set('serialNumber', null); // transfer: if (! (e.get('isDirty') || (e.get('id') && snapshot.record.get('id') != 'current'))) return null;

        if (!e.get('isDirty')) return null;
        if (e.domain == 'business' && e.tmpId.startsWith('t') && e.revenue == 0 && e.expenditure == 0) return null;
        var persisted = e.store.createRecord('entry', {});
        var entry = e.getProperties(Object.keys(persisted.toJSON()));
        e.store.unloadRecord(persisted);
        entry.serialNumber = e.get('tmpId'); // overwrite X.x

        if ((0, _moment.default)(e.get('date')).toDate().toJSON().match(/T00:00:00\.[0-9]+Z$/) == null) {
          var dateMom = (0, _moment.default)(e.get('date'));
          entry.date = dateMom.add(dateMom.utcOffset(), 'minutes').toDate().toJSON().replace(/T.+Z/, 'T00:00:00Z');
        }

        return entry;
      }).compact());
      return json;
    }
  });

  _exports.default = _default;
});