define("digiprax-web/serializers/month-stat", ["exports", "digiprax-web/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      Object.keys(payload.response.patientsVisits).forEach(function (year) {
        Object.keys(payload.response.patientsVisits[year]).forEach(function (month) {
          var byPatient = payload.response.patientsVisits[year][month].byPatient;
          var sortedByName = Object.keys(byPatient).sort(function (id1, id2) {
            if (byPatient[id1].lastName > byPatient[id2].lastName) return 1;else if (byPatient[id1].lastName == byPatient[id2].lastName) return byPatient[id1].firstName > byPatient[id2].firstName ? 1 : -1;else return -1;
          });
          payload.response.patientsVisits[year][month].byPatient = sortedByName.map(function (id) {
            byPatient[id].id = id;
            return byPatient[id];
          });
        });
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});