define("digiprax-web/services/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /*
   * history provides back and forward navigation. at any point the user can go back and then forward to that point
   * using the same path. when a user goes back and changes the forward path/history, the previous forward-path will be
   * kept so the user can resume even after trying other paths meanwhile. from any point the last chosen route
   * will be used for forward-clicks.
   * example-use-case:
   * 1) user clicks on available slot and proceeds to registration.
   * 2) user decides to check waiting-list slots so he goes back to the availability-list and then proceeds with waiting-list.
   * 3) user decides to stick with his first idea and goes back to avaiability-list.
   * 4.1) clicking forward would continue to waiting-list
   * 4.2) selecting an available slot puts the user back on the first history-path and clicking forward will be possible up
   *      to the furthermost previously reached endpoint.
   */
  var _default = Ember.Service.extend({
    history: Ember.computed(function () {
      return [];
    }),
    curHistoryIndex: -1,
    showBack: Ember.computed('curHistoryIndex', function () {
      return this.get('curHistoryIndex') >= 1;
    }),
    showForward: Ember.computed('curHistoryIndex', function () {
      return this.get('curHistoryIndex') < this.get('history').length - 1;
    }),
    _initRoute: function _initRoute() {
      if (this.history.length == 0) {
        var curStateParams = this.router.currentState.routerJsState.params;
        var initHistoryParams = [[]].concat(Object.keys(curStateParams)).reduce(function (acc, paramKey) {
          if (Object.keys(curStateParams[paramKey]).length >= 1) acc.push(Object.keys(curStateParams[paramKey]).map(function (pK) {
            return curStateParams[paramKey][pK];
          })[0]);
          return acc;
        });
        this.history.push({
          routeName: this.router.currentRouteName,
          params: initHistoryParams,
          forwards: {}
        });
      }

      return this.history;
    },
    reset: function reset() {
      this.setProperties({
        history: [],
        curHistoryIndex: -1
      });
    },
    back: function back() {
      var _this$router;

      var backHistoryIndex = Math.max(this.get('curHistoryIndex') - 1, 0);

      if (backHistoryIndex == 0) {// check loop at first history-entry
        // search path
      }

      var historyEntry = this.get('history')[backHistoryIndex]; // if (historyEntry.routeName == 'restaurant.sign-in') {
      //   if (backHistoryIndex >= 1) historyEntry = this.get('history')[--backHistoryIndex];
      // }

      this.set('curHistoryIndex', backHistoryIndex);

      (_this$router = this.router).transitionTo.apply(_this$router, [historyEntry.routeName].concat(_toConsumableArray(historyEntry.params)));
    },
    forward: function forward() {
      var _this$router2;

      this.set('curHistoryIndex', Math.min(this.get('curHistoryIndex') + 1, this.get('history').length - 1));
      var historyEntry = this.get('history')[this.get('curHistoryIndex')];

      (_this$router2 = this.router).transitionTo.apply(_this$router2, [historyEntry.routeName].concat(_toConsumableArray(historyEntry.params)));
    },
    // params must be Array
    transitionWithHistory: function transitionWithHistory(routeName, params) {
      var _this$router3;

      // history must be initialized with offset
      var newHistory = this.get('history').length == 0 ? this._initRoute() : // [{ routeName: currentRouteName, params: params, forwards: {} }] :
      this.get('history'); // let newHistory = this.get('history');

      if ((newHistory[this.get('curHistoryIndex') + 1] || {}).routeName != routeName) {
        // only if route-name differs from current history-entry action is required. else just raise curHistoryIndex (like go forward)
        if (this.get('curHistoryIndex') == -1) {
          var newEntry = {
            routeName: routeName,
            params: params,
            forwards: {}
          };
          newHistory[0].forwards[routeName] = newEntry;
          newHistory[0].curForwardRoute = routeName;
          newHistory = newHistory.concat(newEntry);
          this.setProperties({
            history: newHistory,
            curHistoryIndex: newHistory.length - 1
          });
        } else {
          var _stoppedForwardPath = newHistory.splice(this.get('curHistoryIndex') + 1);

          var nextForwardRoute = newHistory[this.get('curHistoryIndex')].forwards[routeName];
          var skippedBack = false; // went back without back-button

          var checkRouteHistoryIndex = this.get('curHistoryIndex') - 1;

          while (!skippedBack && checkRouteHistoryIndex >= 0) {
            if (routeName == newHistory[checkRouteHistoryIndex].routeName) {
              this.set('curHistoryIndex', checkRouteHistoryIndex - 1);
              skippedBack = true;
            } else checkRouteHistoryIndex -= 1;
          }

          if (nextForwardRoute != null || skippedBack) {
            if (!skippedBack) {
              // the forward-path is rebuilt one step ahead of current point so "go forward" is available.
              // it could be nice to offer all end-points to the user for fast forwarding
              newHistory.insertAt(this.get('curHistoryIndex') + 1, nextForwardRoute);

              while (nextForwardRoute.curForwardRoute != null) {
                nextForwardRoute = nextForwardRoute.forwards[nextForwardRoute.curForwardRoute];
                newHistory = newHistory.concat(nextForwardRoute);
              }
            }
          } else {
            // add new history-entry for new routeName
            var _newEntry = {
              routeName: routeName,
              params: params,
              forwards: {}
            }; // set cur route as forward for previous entry

            newHistory[this.get('curHistoryIndex')].forwards[routeName] = _newEntry;
            newHistory[this.get('curHistoryIndex')].curForwardRoute = routeName;
            newHistory.insertAt(this.get('curHistoryIndex') + 1, _newEntry);
          }

          this.setProperties({
            history: [].concat(newHistory),
            curHistoryIndex: this.get('curHistoryIndex') + 1
          });
        }
      } else {
        // go forward
        if (!this.compareParams(params, newHistory[this.get('curHistoryIndex') + 1].params)) {
          // replace history-entry for existing routeName but new params
          var _newEntry2 = {
            routeName: routeName,
            params: params,
            forwards: {}
          };
          newHistory[this.get('curHistoryIndex')].forwards[routeName] = _newEntry2;
          newHistory[this.get('curHistoryIndex')].curForwardRoute = routeName;
          newHistory.splice(this.get('curHistoryIndex') + 1, 1, _newEntry2);
          newHistory.splice(this.get('curHistoryIndex') + 2);
          this.setProperties({
            history: [].concat(newHistory),
            curHistoryIndex: this.get('curHistoryIndex') + 1
          });
        } else this.setProperties({
          curHistoryIndex: this.get('curHistoryIndex') + 1
        });
      }

      (_this$router3 = this.router).transitionTo.apply(_this$router3, [routeName].concat(_toConsumableArray(params)));
    },
    // returns true if params are equal
    compareParams: function compareParams(currentParams, historyParams) {
      return !currentParams.find(function (p, idx) {
        // f.ex.: { restaurant: ember-ds-object, queryParams: { shiftCategoryId: 1 } }
        if (p.toString() == '[object Object]') {
          return !Object.keys(p).find(function (paramName) {
            if (paramName == 'queryParams') {
              return !Object.keys(p.queryParams).find(function (queryParamName) {
                return p.queryParams[queryParamName] != historyParams[idx].queryParams[queryParamName];
              });
            } else {
              return p[paramName] != historyParams[idx][paramName];
            }
          });
        } else {
          // likely ember-ds-object: "<bookings-frontend@model:restaurant::ember814:__ID__>
          return p != historyParams[idx];
        }
      });
    }
  });

  _exports.default = _default;
});